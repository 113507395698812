/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Container from "container/BrokerDashboard/editAgentContainer"
import { notify } from "react-notify-toast"
import SidebarContainer from "container/BrokerDashboard/sidebarContainer"
import BrokerContainer from "container/BrokerDashboard/subscriptionContainer"
import moment from "moment"
import cookie from "react-cookies"
import { filter, get, isEmpty } from "lodash"
import { withRouter, Link } from "react-router-dom"
import { ShimmerBox } from "shared/styles/animation"
import { PAST_BROKERAGE_NAME_OPTIONS } from "dumbComponents/BrokerDashboard/GlobalPages/Resources/data"
import {
  Switch,
  DatePicker,
  Select,
  Modal,
  Button,
  Radio,
} from "antd"
import { changeUserAgentTypeAPI } from "container/BrokerDashboard/api"
import Loader from "dumbComponents/common/UI/Loader"
import {
  SidebarTitle,
  SidebarHeading,
  TitleWrap,
  LicenseTransDateWrap,
  LicenseTransWrap,
  SwitchText,
  SwitchWrap,
  CustomInputNumber,
  CustomInput,
} from "../../common"
import { showConfirm } from "../../../ICA/components/ConfirmationModalUtil"

const baseUrl = process.env.REACT_APP_API_BASE_URL
const token = process.env.REACT_APP_SECRET_ID

const AGENT_TYPE = [
  { label: "Individual Agent", value: "1" },
  { label: "Team Member", value: "2" },
  { label: "Team Lead", value: "3" },
  { label: "Accelerator Agent", value: "4" },
  { label: "Internal", value: "5" },
  // { label: "Co Team Lead", value: "6" },
]

const Wrap = styled.div`
`

const DatePickerStyle = {
  height: "39px",
  padding: "10px 14px",
  borderRadius: "4px !important",
  background: "var(--White, #FFF)",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  border: "1px solid #D9D9D9 !important",
}

const CustomDatePicker = styled(DatePicker)`
  .ant-picker {
    border: 1px solid #D9D9D9;
    padding: 10px 14px;
    background: var(--White, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    border-radius: 4px;
    border: 1px solid #D9D9D9;
  }
`

const CustomSelect = styled(Select)`
  .ant-select-selection-overflow-item {
    align-self: auto;
  }
`

const LoaderWrap = styled.div`
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CreateTeam = styled(Link)`
  display: flex;
  justify-content: flex-end;
`

const CreateTeamButton = styled.button`
  background: transparent;
  color: #1890ff;
  cursor: pointer;
  border: none;

  &:hover {
    background-color: #1890ff;
    color: white;
  }
`

const disabledDate = (current, newDate) => {
  // Can not select days before today and today
  if (newDate) {
    return current && current < newDate
  }
  return current && current < moment().endOf("day")
}

const Licences = ({
  agentBrokerageInfoResponse,
  agentDetailsResponse,
  getUserAgentTypeResponse,
  updateAgentBrokerageInfo,
  getAgentBrokerageInfo,
  rarTeamListResponse,
  USStates,
  selectedAgentPlans,
  changeStatesForAgent,
  agentStates,
  fetchRecruiterAndAsmInfoResponse,
  fetchAgentAsmRecruiterInfoResponse,
  updateRecruiterAndAsmInfo,
  updateRecruiterAndAsmResponse,
  urlBasedTeamId = null,
  setTeamIDFromURL,
  toggleTeamCreateModal,
  updateSubscriptionDate,
  updateSubscriptionDateResponse,
  selectedPlan,
  ...props
}) => {
  const currentLoggedInSoulUserID = ""
  const { isFetching: updatingSubscriptionDate } = updateSubscriptionDateResponse || {}
  const { data: agentStatesData = [], isFetching } = agentStates || {}
  const { data: teams = [], isFetching: fetchingTeams } = rarTeamListResponse || {}
  const parsedList = teams && teams.length > 0 && teams.map(x => ({ label: x.name, value: x.id }))
  const agentId = get(props, "match.params.agentId", "")
  const [pastVolume, setPastVolume] = useState()
  const { data: agentData, isFetching: fetchingAgentDetails } = agentDetailsResponse || {}
  const { data: typeData, isFetching: fetchingUserAgentType } = getUserAgentTypeResponse || {}
  const { data: asmAndReruiterData, isFetching: fetchingASMAndRecruiters } = fetchRecruiterAndAsmInfoResponse || {}
  const { data: currentAgentAsmRecruiterData, isFetching: fetchingCurrentAgentASMAndRecruiters } = fetchAgentAsmRecruiterInfoResponse || {}
  const { isFetching: updatingAsmInfo } = updateRecruiterAndAsmResponse || {}
  const {
    team_name,
    brokerageTeamId,
    isBrokerageCoTeamLead
  } = agentData || {}
  const type = get(typeData, "result.type")
  const typeId = get(typeData, "result.type_id")
  const { data: brokerageData, isFetching: fetchingBrokerageDetails } = agentBrokerageInfoResponse || {}
  const {
    is_license_transferred, license_transferred_date,
    is_ica_signed, ica_signed_date, past_volume, dba, op_city_qualifications,
    cap_reset_date, past_brokerage_name, primary_sale_representative, cap,
    primary_sale_representative_split_percentage, secondary_sale_representative,
    secondary_sale_representative_split_percentage, other_past_brokerage_name,
    brokerage_team_id,
    credit_card_pop_up_blocker_time,
  } = brokerageData || {}

  const capResetDate = cap_reset_date ? new Date(cap_reset_date) : ""
  const fetchingLicenseDate = fetchingBrokerageDetails || (license_transferred_date && license_transferred_date === undefined)
  const fetchingICASignedDate = fetchingBrokerageDetails || (ica_signed_date && ica_signed_date === undefined)
  const fetchingCAPResetDate = fetchingBrokerageDetails || (cap_reset_date && cap_reset_date === undefined)
  const fetchingCreditCardBlockerPopupDate = updatingSubscriptionDate || fetchingBrokerageDetails
  || (credit_card_pop_up_blocker_time && credit_card_pop_up_blocker_time === undefined)

  const [isLicenseTransferred, setLicenseTransferred] = useState()
  const [isCoTeamLead, setIsCoTeamLead] = useState(null);
  const [isICASigned, setIsICASigned] = useState()
  const [icaSignedDate, setICASgnedDate] = useState(ica_signed_date)
  const [disabledDates, setDisabledDates] = useState(disabledDate)
  const [agentRole, setAgentRole] = useState(type)
  const [licenseTransferredDate, setLicenseTransferredDate] = useState("")
  const [creditCardBlockerPopupDate, setCreditCardBlockerPopupDate] = useState("")
  const [previousBrokerage, setPreviousBrokerage] = useState()
  const [agentCurrentStates, setAgentStates] = useState([])
  const [otherPastBrokerageName, setOtherPastBrokerageName] = useState()
  const [currentASM, setCurrentASM] = useState()
  const [currentRecruiter, setCurrentRecruiter] = useState()
  useEffect(() => {
    if (brokerageData?.license_transferred_date) {
      setLicenseTransferredDate(getNewDate(brokerageData?.license_transferred_date))
    }
    if (brokerageData?.ica_signed_date) {
      setICASgnedDate(getNewDate(brokerageData?.ica_signed_date))
    }
    if (brokerageData?.is_license_transferred) {
      setLicenseTransferred(is_license_transferred === 1)
    }
    if (brokerageData?.is_ica_signed) {
      setIsICASigned(is_ica_signed === 1)
    }
    if (brokerageData?.other_past_brokerage_name) {
      setOtherPastBrokerageName(other_past_brokerage_name)
    }
   

    if (brokerageData?.credit_card_pop_up_blocker_time) {
      //convert to date string
      const dateStringConv = moment(credit_card_pop_up_blocker_time * 1000).format("YYYY-MM-DD")
      const momentObj = moment(dateStringConv)
      setDisabledDates(momentObj)
      setCreditCardBlockerPopupDate(momentObj)
    }
  }, [brokerageData])

  useEffect(() => {
    if (agentStatesData && agentStatesData.length > 0) {
      setAgentStates(agentStatesData.map(x => x.id))
    }
  }, [agentStates])

  useEffect(()=>{
      setIsCoTeamLead(isBrokerageCoTeamLead)
  },[isBrokerageCoTeamLead])

  // useEffect(() => {
  //   console.log("here", creditCardBlockerPopupDate)
  // }, [creditCardBlockerPopupDate])

  const getNewDate = (newDate) => {
    const final = new Date(newDate * 1000)
    const finalDate = moment(final).format("MM-DD-YYYY")
    return finalDate || ""
  }

  const handleCoLeadChange = (e) => {
    setIsCoTeamLead(e.target.value);
  };

  const getTeamOfAgent = async (agentId) => {
    try {
      const res = await fetch(`${baseUrl}/v1.0/brokerage-agent-team?agentId=${agentId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "internal-id": token,
          "X-User-Agent": "agent-php",
        },
      })
      const json = await res.json()
      return json
    } catch (e) {
      console.log(e)
      notify.hide()
      notify.show("Something Went Wrong.", "error")
    }
  }

  const deleteTeamData = async (agentId, teamId) => {
    try {
      const res = await fetch(`${baseUrl}/v1.0/brokerage-team/${teamId}/member`, {
        method: "DELETE",
        body: JSON.stringify({
          agent_id: agentId,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "internal-id": token,
          "X-User-Agent": "agent-php",
        },
      })
      if (res.status != 200) {
        notify.hide()
        notify.show("Something went wrong! Please try again!", "error")
        return
      }
      //notify.hide()
      //notify.show("Agent Removed from", "success")
      return
    } catch (error) {
      console.log(error)
      notify.hide()
      notify.show("Something went wrong! Please try again!", "error")
    }
  }

  const setTeamOrTeamLead = async (agent_id, is_admin_or_not, team_id) => {
    const body = {
      members: [
        {
          agent_id: Number(agent_id),
          is_admin: Number(is_admin_or_not),
          brokerage_team_id: Number(team_id),
          invite_status:"joined",
        },
      ],
    }

    try {
      const res = await fetch(`${baseUrl}/v1.0/assign-brokerage-team`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "internal-id": token,
          "X-User-Agent": "agent-php",
        },
        body: JSON.stringify(body),
      })
      const json = await res.json()
      if (res.status !== 200) {
        const errorMessage = get(json, "response.error", "Oop! Something went wrong!")
        notify.hide()
        notify.show(errorMessage, "error")
        return
      }
      notify.hide()
      notify.show("Team Assigned Successfully", "success")
      setTeamIDFromURL(team_id)
      return
    } catch (e) {
      console.log(e)
      notify.hide()
      notify.show("Something Went Wrong, while assigning team", "error")
    }
  }

  const handleRoleChange = async (selectedRole) => {
    setAgentRole(selectedRole)
    const selectedAgentTeamInfo = await getTeamOfAgent(agentId)
    const teamId = get(selectedAgentTeamInfo, "response[0].team_id")

    if (selectedRole === "1" || selectedRole === "4") {
      if (teamId) {
        deleteTeamData(agentId, teamId)
      }
    }

    await changeUserAgentTypeAPI(agentId, selectedRole)

    if (selectedRole === "2" || selectedRole === "3") {
      if (!agentData?.isBrokerageCoTeamLead && teamId) {
        setTeamOrTeamLead(agentId, selectedRole === "2" ? 0 : 1, teamId)
      }
    }

    notify.hide()
    notify.show("Agent Type Changed", "success")
  }

  const getASMOptions = (allData) => {
    const {
      asm_info,
    } = allData || {}

    const options = asm_info && asm_info.length > 0 && asm_info.map(item => ({
      label: item.name,
      value: item.agent_id,
    })) || []

    return options || []
  }

  const getRecruiterOptions = (allData) => {
    const {
      recruiter_access,
    } = allData || {}

    const options = recruiter_access && recruiter_access.length > 0 && recruiter_access.map(item => ({
      label: item.name,
      value: item.agent_id,
    })) || []

    const user = cookie.load("user")
    const adminId = cookie.load("admin_id")
    const id = adminId ? parseInt(adminId, 10) : parseInt(user.agent_id, 10)

    if (id) {
      const loggedInUserName = user.name

      const loggedInUser = {
        label: loggedInUserName || "Radius User",
        value: id,
      }

      options.push(loggedInUser)
    }

    return options || []
  }

  useEffect(() => {
    if (!fetchingCurrentAgentASMAndRecruiters && currentAgentAsmRecruiterData) {
      const {
        asm_assigned,
        recruiter,
      } = currentAgentAsmRecruiterData || {}

      if (asm_assigned) {
        setCurrentASM(asm_assigned)
      }

      if (recruiter) {
        setCurrentRecruiter(recruiter)
      } else {
        const user = cookie.load("user")
        const adminId = cookie.load("admin_id")
        const id = adminId ? parseInt(adminId, 10) : parseInt(user.agent_id, 10)

        if (id) {
          updateRecruiterAndAsmInfo({
            agentId,
            payload: {
              recruiter: id,
            },
          })
        }
      }
    }
  }, [fetchingCurrentAgentASMAndRecruiters, currentAgentAsmRecruiterData])

  useEffect(() => {
    if (selectedAgentPlans) {
      const found = selectedAgentPlans.some(el => el.key === "production")
      if (found) {
        const index = selectedAgentPlans.findIndex(x => x.key === "production")
        if (selectedAgentPlans[index].name?.includes("Indie")) {
          handleRoleChange("1")
        } else if (!agentData?.isBrokerageCoTeamLead && selectedAgentPlans[index].name?.includes("Team Leader")) {
          handleRoleChange("3")
        } else if (selectedAgentPlans[index].name?.includes("Member") || selectedAgentPlans[index].name?.includes("Non Producing Team")) {
          handleRoleChange("2")
        }
      }
    }
  }, [selectedAgentPlans])

  const handleClick = (title, content, onClickConfirm) => {
    Modal.confirm({
      title,
      content,
      okText: "Yes",
      cancelText: "Not Now",
      onCancel() {
        onClickConfirm()
      },
    })
  }

  const getDefaultValueForTeam = (id) => {
    if (!id) return ""

    const filtered = parsedList.filter(item => item.value === parseInt(id, 10)) || []

    if (filtered.length > 0) {
      return filtered[0]
    }
    return ""
  }

  const handleSubmit = () => {
    const payload = {
      is_license_transferred: isLicenseTransferred ? 1 : 0,
      agent_id: parseInt(agentId, 10),
      primary_sale_representative: primary_sale_representative || null,
      secondary_sale_representative: secondary_sale_representative || null,
      primary_sale_representative_split_percentage: primary_sale_representative_split_percentage ? primary_sale_representative_split_percentage : null,
      secondary_sale_representative_split_percentage: secondary_sale_representative_split_percentage ? secondary_sale_representative_split_percentage : null,
      cap: cap || null,
      is_ica_signed: isICASigned ? 1 : 0,
      ica_signed_date: icaSignedDate ? (moment(icaSignedDate).unix()) : null,
      license_transferred_date: moment(licenseTransferredDate).unix() || null,
      other_past_brokerage_name: otherPastBrokerageName || other_past_brokerage_name || null,
      past_brokerage_name: previousBrokerage || past_brokerage_name || null,
      past_brokerage_production: null,
      production_tier: null,
      past_volume: pastVolume || null,
      dba: dba || null,
      //credit_card_pop_up_blocker_time: creditCardBlockerPopupDate ? (moment(creditCardBlockerPopupDate).unix()) : null,
      op_city_qualifications: op_city_qualifications || null,
      is_co_team_lead: isCoTeamLead || null,
    }
    changeStatesForAgent({
      ids: agentCurrentStates,
      agentId,
    })
    updateAgentBrokerageInfo({
      payload,
      agentId: parseInt(agentId, 10),
      callback: () => {
        getAgentBrokerageInfo({ agentId: parseInt(agentId, 10) })
        // if(isCoTeamLead){
        //   window.location.reload();
        // }
        window.location.reload();
      },
    })
  }

  const showLoader = fetchingBrokerageDetails || fetchingLicenseDate || fetchingAgentDetails
    || fetchingICASignedDate || fetchingUserAgentType || fetchingCAPResetDate
    || updatingAsmInfo || fetchingASMAndRecruiters || fetchingCurrentAgentASMAndRecruiters || isFetching

  return (
    <Wrap>
      <TitleWrap>
        <SidebarTitle>
          Licences
        </SidebarTitle>
        {showLoader ? (
          <LoaderWrap>
            <Loader />
          </LoaderWrap>
        )
          : (
          <>
            <LicenseTransWrap>
              <SidebarHeading>
                License transferred
              </SidebarHeading>
              {fetchingBrokerageDetails ? <ShimmerBox w="290px" h="39px" /> : (
                <SwitchWrap>
                  <Switch
                    onClick={() => {
                      handleClick("Transferring License", "Are you sure you want to change the status of license transfer?", () => setLicenseTransferred(isLicenseTransferred))
                    }}
                    size="small"
                    checked={isLicenseTransferred}
                    onChange={value => setLicenseTransferred(value)}
                  />
                  <SwitchText>
                    Yes
                  </SwitchText>
                </SwitchWrap>
              )}
            </LicenseTransWrap>
            <LicenseTransDateWrap>
              <SidebarHeading>
                License Transfer Date
              </SidebarHeading>
              {fetchingLicenseDate ? <ShimmerBox w="290px" h="39px" /> : (
                <CustomDatePicker
                  style={{
                    ...DatePickerStyle,
                  }}
                  allowClear={false}
                  value={!isEmpty(licenseTransferredDate) ? moment(licenseTransferredDate) : licenseTransferredDate}
                  onChange={(_date, dateString) => setLicenseTransferredDate(moment(dateString))}
                />
              )}
            </LicenseTransDateWrap>
            {selectedPlan && Object.keys(selectedPlan).length > 0 && (
              <LicenseTransDateWrap>
                <SidebarHeading>
                  Subscription Start Date
                </SidebarHeading>
                {fetchingCreditCardBlockerPopupDate ? <ShimmerBox w="290px" h="39px" /> : (
                  <CustomDatePicker
                    style={{
                      ...DatePickerStyle,
                    }}
                    allowClear={false}
                    disabledDate={date => disabledDate(date, disabledDates)}
                    value={!isEmpty(creditCardBlockerPopupDate) ? moment(creditCardBlockerPopupDate) : creditCardBlockerPopupDate}
                    onChange={(_date, dateString) => {
                      const dateStringMod = moment(dateString)
                      setCreditCardBlockerPopupDate(dateStringMod)
                      const payload = {
                        agentId: parseInt(agentId, 10),
                        credit_card_pop_up_blocker_time: moment(dateStringMod).unix(),
                      }

                      const warning = `Since a Subscription Start Date is selected, 
                      the user would be shown a popup to enter their credit card details. You can change 
                      the date from the side panel in the left under Subscription Start Date section.`

                      const confirmation = "Subscription Start Date selected, are you sure?"

                      const revert = () => {
                        setCreditCardBlockerPopupDate("")
                      }

                      const postAction = () => {
                        updateSubscriptionDate({
                          payload,
                        })
                      }

                      showConfirm(confirmation, postAction, warning, revert)
                    }}
                  />
                )}
              </LicenseTransDateWrap>
            )}
            <LicenseTransWrap>
              <SidebarHeading>
                ICA Signed
              </SidebarHeading>
              {fetchingBrokerageDetails ? <ShimmerBox w="290px" h="39px" /> : (
                <SwitchWrap>
                  <Switch
                    size="small"
                    checked={isICASigned}
                    onClick={() => handleClick("Is ICA Signed", "Are you sure you want to change the status of ICA Signed?", () => setIsICASigned(isICASigned))}
                    onChange={value => setIsICASigned(value)}
                  />
                  <SwitchText>
                    Yes
                  </SwitchText>
                </SwitchWrap>
              )}
            </LicenseTransWrap>
            <LicenseTransDateWrap>
              <SidebarHeading>
                ICA Signed Date
              </SidebarHeading>
              {fetchingICASignedDate ? <ShimmerBox w="290px" h="39px" /> : (
                <CustomDatePicker
                  style={{
                    ...DatePickerStyle,
                  }}
                  allowClear={false}
                  value={icaSignedDate && !isEmpty(icaSignedDate) && moment(icaSignedDate)}
                  onChange={(_date, dateString) => setICASgnedDate(moment(dateString))}
                />
              )}
            </LicenseTransDateWrap>
            <LicenseTransDateWrap>
              <SidebarHeading>
                Past Volume (last 12 months)
              </SidebarHeading>
              {fetchingBrokerageDetails ? <ShimmerBox w="290px" h="39px" /> : (
                <CustomInputNumber
                  style={{
                    height: "39px",
                  }}
                  formatter={value => value ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}
                  defaultValue={past_volume}
                  value={pastVolume}
                  onChange={value => setPastVolume(value)}
                  controls={false}
                />
              )}
            </LicenseTransDateWrap>
            <LicenseTransDateWrap>
              <SidebarHeading>
                CAP Start Date
              </SidebarHeading>
              {fetchingCAPResetDate ? <ShimmerBox w="290px" h="39px" /> : (
                <CustomDatePicker
                  style={{
                    ...DatePickerStyle,
                  }}
                  allowClear={false}
                  defaultValue={capResetDate ? moment(capResetDate) : ""}
                />
              )}
            </LicenseTransDateWrap>
            <LicenseTransDateWrap>
              <SidebarHeading>
                Role
              </SidebarHeading>
              {fetchingUserAgentType ? <ShimmerBox w="290px" h="39px" /> : (
                <Select
                  placeholder="Select"
                  options={AGENT_TYPE}
                  defaultValue={type}
                  value={agentRole}
                  onChange={(value) => {
                    handleRoleChange(value)
                  }}
                />
              )}
            </LicenseTransDateWrap>
            <div>
              <p style={{ fontSize: "15px", marginBottom: "8px" }}>Are you a co-team lead?</p>
              <Radio.Group onChange={handleCoLeadChange} value={isCoTeamLead}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
              <p style={{ marginTop: "10px" }}>
                Selected: {isCoTeamLead === null ? "None" : isCoTeamLead ? "Yes" : "No"}
              </p>
            </div>
            <LicenseTransDateWrap>
              <SidebarHeading>
                ASM Assignment
              </SidebarHeading>
              {updatingAsmInfo || fetchingASMAndRecruiters || fetchingCurrentAgentASMAndRecruiters ? <ShimmerBox w="290px" h="39px" /> : (
                <Select
                  placeholder="Select"
                  options={getASMOptions(asmAndReruiterData)}
                  defaultValue={currentASM}
                  value={currentASM}
                  onChange={(value) => {
                    updateRecruiterAndAsmInfo({
                      agentId,
                      payload: {
                        asm_assigned: value,
                      },
                    })
                  }}
                />
              )}
            </LicenseTransDateWrap>
            <LicenseTransDateWrap>
              <SidebarHeading>
                Contact Owner
              </SidebarHeading>
              {updatingAsmInfo || fetchingASMAndRecruiters || fetchingCurrentAgentASMAndRecruiters ? <ShimmerBox w="290px" h="39px" /> : (
                <Select
                  placeholder="Select"
                  options={getRecruiterOptions(asmAndReruiterData)}
                  defaultValue={currentRecruiter}
                  value={currentRecruiter}
                  onChange={(value) => {
                    updateRecruiterAndAsmInfo({
                      agentId,
                      payload: {
                        recruiter: value,
                      },
                    })
                  }}
                />
              )}
            </LicenseTransDateWrap>
            {(typeId == "2" || typeId == "3" || agentRole == "2" || agentRole == "3") && (
              <LicenseTransDateWrap>
                <SidebarHeading>
                  Team Name
                </SidebarHeading>
                {fetchingAgentDetails || fetchingTeams ? <ShimmerBox w="290px" h="39px" /> : (
                  <Select
                    placeholder="Select"
                    options={parsedList}
                    defaultValue={brokerage_team_id || brokerageTeamId}
                      onChange={value => (
                       !agentData?.isBrokerageCoTeamLead ? setTeamOrTeamLead(agentId, agentRole === "3" || agentRole === "Team Lead" ? "1" : "0", value) : null
                )}
                  />
                )}
                <CreateTeamButton
                  onClick={() => {
                    toggleTeamCreateModal(true)
                  }}
                >
                  Team doesn&apos;t exist? Create here.
                </CreateTeamButton>
              </LicenseTransDateWrap>
            )}
            <LicenseTransDateWrap>
              <SidebarHeading>
                Previous Brokerage
              </SidebarHeading>
              {fetchingBrokerageDetails ? <ShimmerBox w="290px" h="39px" /> : (
                <Select
                  placeholder="Select"
                  defaultValue={past_brokerage_name}
                  options={PAST_BROKERAGE_NAME_OPTIONS}
                  value={previousBrokerage}
                  onChange={value => setPreviousBrokerage(value)}
                />
              )}
            </LicenseTransDateWrap>
            {(previousBrokerage === "Other" || past_brokerage_name === "Other") && (
              <LicenseTransDateWrap>
                <SidebarHeading>
                  Other Previous Brokerage Name
                </SidebarHeading>
                {fetchingBrokerageDetails ? <ShimmerBox w="290px" h="39px" /> : (
                  <CustomInput
                    style={{
                      height: "39px",
                    }}
                    defaultValue={other_past_brokerage_name}
                    onChange={value => setOtherPastBrokerageName(value.target.value)}
                    value={otherPastBrokerageName}
                  />
                )}
              </LicenseTransDateWrap>
            )}
            <LicenseTransDateWrap>
              <SidebarHeading>
                Agent Location
              </SidebarHeading>
              {isFetching ? <ShimmerBox w="290px" h="39px" /> : (
                <CustomSelect
                  placeholder="Select"
                  options={USStates}
                  mode="multiple"
                  value={agentCurrentStates}
                  onChange={value => setAgentStates(value)}
                  maxTagCount={1}
                />
              )}
            </LicenseTransDateWrap>
            <Button
              onClick={() => {
                handleSubmit()
              }}
              type="primary"
            >
              Save
            </Button>
        </>
          )}
      </TitleWrap>
    </Wrap>
  )
}

export default withRouter(BrokerContainer(SidebarContainer(Container(Licences))))
