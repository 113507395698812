const Icons = {
  arrowsArrowMerge: "icon-arrows-Arroe-merge",
  arrowsArrowDiverge: "icon-arrows-Arrow-diverge",
  arrowsArrowDivert: "icon-arrows-Arrow-divert",
  arrowsArrowForward: "icon-arrows-Arrow-forward",
  arrowsArrowRelevance: "icon-arrows-Arrow-relevance",
  arrowsArrowRight: "icon-arrows-Arrow-right",
  arrowsArrowSendReceive: "icon-arrows-Arrow-send-receive",
  arrowsArrowUp: "icon-arrows-Arrow-up",
  arrowsClose: "icon-arrows--Close",
  arrowsCloseFill: "icon-arrows-Close-fill",
  arrowsCompress: "icon-icon-arrows-Compress",
  arrowsController: "icon-arrows-Controller",
  arrowsConverge: "icon-icon-arrows-Converge",
  arrowsDiverge: "icon-arrows-Diverge",
  arrowsDoubleSidedArrow: "icon-arrows-double-sided-arrow",
  arrowsDownload: "icon-arrows-Download",
  arrowsDrag: "icon-arrows-Drag",
  arrowsEject: "icon-arrows-Eject",
  arrowsEjectRight: "icon-arrows-Eject-right",
  arrowsEnlarge: "icon-arrows-Enlarge",
  arrowsExpand: "icon-arrows-Expand",
  arrowsExpandDown: "icon-icon-arrows-Expand-down",
  arrowsExpandUp: "icon-icon-arrows-Expand-up",
  arrowsExpandUp1: "icon-icon-arrows-Expand-up1",
  arrowsFastForward: "icon-arrows-Fast-forward",
  arrowsFileRedirect: "icon-icon-arrows-File-redirect",
  arrowsFileRefresh: "icon-arrows-File-refresh",
  arrowsFileRightTurn: "icon-arrows-File-right-turn",
  arrowsFullScreen: "icon-arrows-Full-screen",
  arrowsGamePad: "icon-arrows-game-pad",
  arrowsHorizontalScroll: "icon-icon-arrows-Horizontal-scroll",
  arrowsKeyboardLeft: "icon-icon-arrows-Keyboard-left",
  arrowsKeyboardRight: "icon-icon-arrows-Keyboard-right",
  arrowsLift: "icon-arrows-Lift",
  arrowsLogIn: "icon-arrows-Log-in",
  arrowsLogOut: "icon-arrows-Log-out",
  arrowsMerge: "icon-arrows-Merge",
  arrowsNext: "icon-arrows-Next",
  arrowsRefresh: "icon-arrows-Refresh",
  arrowsRightArrow: "icon-arrows-Right-arrow",
  arrowsRotation: "icon-arrows-Rotation",
  arrowsSCurved: "icon-arrows-S-curved",
  arrowsSShapedCurveArrow: "icon-arrows-S-shaped-curve-arrow",
  arrowsScreenMinimize: "icon-arrows-Screen-minimize",
  arrowsScrollUp: "icon-arrows-Scroll-up",
  arrowsScrollUpArrow: "icon-arrows-Scroll-up-arrow",
  arrowsSendReceive: "icon-icon-arrows-send-receive",
  arrowsShare: "icon-arrows-Share",
  arrowsShrink: "icon-arrows-Shrink",
  arrowsShuffleArrow: "icon-arrows-Shuffle-arrow",
  arrowsStats: "icon-icon-arrows-Stats",
  arrowsUTurnArrow: "icon-arrows-U-turn-arrow",
  arrowsUpload: "icon-arrows-Upload",
  businessAuction: "icon-business-Auction",
  businessBank: "icon-business-Bank",
  businessBarGraph: "icon-icon-business-Bar-graph",
  businessBasket: "icon-business-Basket",
  businessBill: "icon-business-Bill",
  businessBriefcase: "icon-business-Briefcase",
  businessCart: "icon-business-Cart",
  businessCash: "icon-business-Cash",
  businessCashBag: "icon-business-Cash-bag",
  businessCashOut: "icon-business-Cash-out",
  businessChart: "icon-business-Chart",
  businessCreditCard: "icon-business-Credit-card",
  businessDollar: "icon-business-Dollar",
  businessGiveMoney: "icon-business-Give-money",
  businessPieChart: "icon-business-Pie-chart",
  businessSafeBox: "icon-business-Safe-box",
  businessShop: "icon-business-Shop",
  businessShopping: "icon-business-Shopping",
  businessVisaCard: "icon-business-Visa-card",
  businessWallet: "icon-business-Wallet",
  communicationAntenna: "icon-communication-Antenna",
  communicationCall: "icon-communication-Call",
  communicationCallsNotAllowed: "icon-communication-Calls-not-allowed",
  communicationChatSMS: "icon-communication-Chat-SMS",
  communicationCloud: "icon-communication-Cloud",
  communicationContacts: "icon-communication-Contacts",
  communicationConversation: "icon-communication-Conversation",
  communicationDish: "icon-communication-Dish",
  communicationDownload: "icon-communication-Download",
  communicationFill41: "icon-communication-Fill-41",
  communicationHotspot: "icon-communication-Hotspot",
  communicationInbox: "icon-communication-Inbox",
  communicationMail: "icon-communication-Mail",
  communicationMessageBubble: "icon-communication-Message-bubble",
  communicationMic: "icon-communication-Mic",
  communicationMonitor: "icon-communication-Monitor",
  communicationNewsPaper: "icon-communication-News-paper",
  communicationPhone: "icon-communication-Phone",
  communicationPrinter: "icon-communication-Printer",
  communicationRadio: "icon-communication-Radio",
  communicationSatellite: "icon-communication-Satellite",
  communicationSpeaker: "icon-communication-Speaker",
  communicationSystem: "icon-communication-System",
  communicationUpload: "icon-communication-Upload",
  communicationWebCam: "icon-communication-Web-cam",
  electronicsAc: "icon-electronics-Ac",
  electronicsBedLamp: "icon-electronics-Bed-lamp",
  electronicsChimney: "icon-electronics-Chimney",
  electronicsFridge: "icon-electronics-Fridge",
  electronicsGamePad: "icon-electronics-Game-pad",
  electronicsHairDryer: "icon-electronics-Hair-dryer",
  electronicsIronBox: "icon-electronics-Iron-box",
  electronicsMousePointer: "icon-electronics-Mouse-pointer",
  electronicsOven: "icon-electronics-Oven",
  electronicsPlug: "icon-electronics-Plug",
  electronicsProcessor: "icon-electronics-Processor",
  electronicsServer: "icon-electronics-Server",
  electronicsSmartWatch: "icon-electronics-Smart-watch",
  electronicsSpeaker: "icon-electronics-Speaker",
  electronicsWashingMachine: "icon-electronics-Washing-machine",
  filesAddFile: "icon-files-Add-file",
  filesConfigFile: "icon-files-Config-file",
  filesCopyFile: "icon-files-Copy-file",
  filesCopyToClipboard: "icon-files-Copy-to-clipboard",
  filesDownloadFile: "icon-files-Download-file",
  filesDuplicateFile: "icon-files-Duplicate-file",
  filesEmptyFile: "icon-files-Empty-file",
  filesEncryptedFile: "icon-files-Encrypted-file",
  filesFIleImage: "icon-files-FIle-image",
  filesFIleLoading: "icon-files-FIle-Loading",
  filesFIleSettings: "icon-files-FIle-settings",
  filesFIleVolume: "icon-files-FIle-volume",
  filesFile: "icon-files-File",
  filesFileAdd: "icon-files-File-add",
  filesFileAttach: "icon-files-File-attach",
  filesFileBack: "icon-files-File-back",
  filesFileBill: "icon-files-File-bill",
  filesFileCamera: "icon-files-File-camera",
  filesFileCaution: "icon-files-File-caution",
  filesFileCheckMark: "icon-files-File-check-mark",
  filesFileChecked: "icon-files-File-checked",
  filesFileClose: "icon-files-File-close",
  filesFileCloud: "icon-files-File-cloud",
  filesFileCut: "icon-files-File-cut",
  filesFileDatabase: "icon-files-File-database",
  filesFileDisabled: "icon-files-File-disabled",
  filesFileDownload: "icon-files-File-download",
  filesFileDraw: "icon-files-File-draw",
  filesFileEdit: "icon-files-File-edit",
  filesFileError: "icon-files-File-error",
  filesFileFolder: "icon-files-File-folder",
  filesFileHeart: "icon-files-File-heart",
  filesFileLayers: "icon-files-File-layers",
  filesFileLink: "icon-files-File-link",
  filesFileLocked: "icon-files-File-locked",
  filesFileMinus: "icon-files-File-minus",
  filesFileMusic: "icon-files-File-music",
  filesFileNext: "icon-files-File-next",
  filesFileOptions: "icon-files-File-options",
  filesFilePlay: "icon-files-File-play",
  filesFileQuestionMark: "icon-files-File-question-mark",
  filesFileRefresh: "icon-files-File-refresh",
  filesFileReload: "icon-files-File-reload",
  filesFileSearch: "icon-files-File-search",
  filesFileSecured: "icon-files-File-secured",
  filesFileShare: "icon-files-File-share",
  filesFileStar: "icon-files-File-star",
  filesFileText: "icon-files-File-text",
  filesFileUnlock: "icon-files-File-unlock",
  filesFileUpload: "icon-files-File-upload",
  filesFileUser: "icon-files-File-user",
  filesFileVector: "icon-files-File-vector",
  filesFiles: "icon-files-Files",
  filesFolderChart: "icon-files-Folder-chart",
  filesFolderSearch: "icon-files-Folder-search",
  filesFolderSync: "icon-files-Folder-sync",
  filesGraphChartFile: "icon-files-Graph-chart-file",
  filesGraphFile: "icon-files-Graph-file",
  filesHistoryFile: "icon-files-History-file",
  filesKeyFile: "icon-files-Key-file",
  filesLockedFolder: "icon-files-Locked-folder",
  filesMoveFile: "icon-files-Move-file",
  filesNetworkFolder: "icon-files-Network-folder",
  filesRemoveFile: "icon-files-Remove-file",
  filesSettingsFile: "icon-files-Settings-file",
  filesSourceFile: "icon-files-Source-file",
  filesSpreadSheetFile: "icon-files-Spread-sheet-file",
  filesUpdateEditFile: "icon-files-Update-edit-file",
  filesUplaodFile: "icon-files-Uplaod-file",
  filesVectorFile: "icon-files-Vector-file",
  filesViewFile: "icon-files-View-file",
  filesZipFile: "icon-files-Zip-file",
  generic3dTriangle: "icon-generic-3d-triangle",
  genericAlert: "icon-generic-Alert",
  genericArchive: "icon-generic-Archive",
  genericArt: "icon-generic-Art",
  genericAttach: "icon-generic-Attach",
  genericBadge: "icon-generic-Badge",
  genericBadgeCircular: "icon-generic-Badge-Circular",
  genericBall: "icon-generic-Ball",
  genericBasket: "icon-generic-basket",
  genericBattery: "icon-generic-Battery",
  genericBell: "icon-generic-Bell",
  genericBlub: "icon-generic-Blub",
  genericBookmark: "icon-generic-Bookmark",
  genericBuilding: "icon-generic-Building",
  genericCamera: "icon-generic-Camera",
  genericCautionTriangle: "icon-generic-Caution-triangle",
  genericCertifiedBadge: "icon-generic-Certified-Badge",
  genericCheckMark: "icon-generic-Check-mark",
  genericCheckMarkRounded: "icon-generic-Check-mark-rounded",
  genericClose: "icon-generic-Close",
  genericCollaborate: "icon-generic-Collaborate",
  genericCommunity: "icon-generic-Community",
  genericCopy: "icon-generic-Copy",
  genericCube: "icon-generic-Cube",
  genericDashboard: "icon-generic-Dashboard",
  genericDiamond: "icon-generic-Diamond",
  genericDisabled: "icon-generic-Disabled",
  genericEdit: "icon-generic-Edit",
  genericFacebook: "icon-generic-Facebook",
  genericFactory: "icon-generic-Factory",
  genericFemale: "icon-generic-Female",
  genericFilter: "icon-generic-Filter",
  genericFire: "icon-generic-Fire",
  genericFlag: "icon-generic-Flag",
  genericFolder: "icon-generic-Folder",
  genericGiftBox: "icon-generic-Gift-box",
  genericHandGesturePointUp: "icon-generic-hand-gesture-point-up",
  genericHeart: "icon-generic-Heart",
  genericHexagon: "icon-generic-Hexagon",
  genericHidden: "icon-generic-Hidden",
  genericHome: "icon-generic-Home",
  genericHouse: "icon-generic-House",
  genericIG: "icon-generic-IG",
  genericInstant: "icon-generic-Instant",
  genericKey: "icon-generic-Key",
  genericLifeSaver: "icon-generic-Life-saver",
  genericLinkedIn: "icon-generic-LinkedIn",
  genericLock: "icon-generic-Lock",
  genericMale: "icon-generic-Male",
  genericMessageHeart: "icon-generic-Message-heart",
  genericMinus: "icon-generic-Minus",
  genericMoreMenu: "icon-icon-generic-More-menu",
  genericMousePointer: "icon-generic-Mouse-pointer",
  genericNewPost: "icon-generic--New-post",
  genericNotSecure: "icon-generic-Not-secure",
  genericNotification: "icon-generic-Notification",
  genericNotificationOff: "icon-generic-Notification-off",
  genericNuclear: "icon-generic-Nuclear",
  genericPaintBrush: "icon-generic-Paint-brush",
  genericPencil: "icon-generic-Pencil",
  genericPlus: "icon-generic-Plus",
  genericPostBox: "icon-generic-Post-box",
  genericPower: "icon-generic-Power",
  genericQuestionMark: "icon-generic-Question-mark",
  genericScan: "icon-generic-Scan",
  genericSearch: "icon-generic-Search",
  genericSecured: "icon-generic-Secured",
  genericSend: "icon-generic-Send",
  genericSettings: "icon-generic-Settings",
  genericShare: "icon-generic-Share",
  genericShare1: "icon-generic-Share1",
  genericShareRound: "icon-generic-Share-round",
  genericShareRound1: "icon-generic-Share-round1",
  genericShield: "icon-generic-Shield",
  genericStar: "icon-generic-Star",
  genericStar1: "icon-generic-Star1",
  genericStarActive: "icon-generic-Star-active",
  genericTag: "icon-generic-Tag",
  genericTelescope: "icon-generic-Telescope",
  genericThumbsDown: "icon-generic-Thumbs-down",
  genericThumbsUp: "icon-generic-Thumbs-up",
  genericTicket: "icon-generic-Ticket",
  genericTime: "icon-generic-Time",
  genericTrash: "icon-generic-Trash",
  genericTrophy: "icon-generic-Trophy",
  genericTwitter: "icon-generic-Twitter",
  genericUmbrella: "icon-generic-Umbrella",
  genericUnlock: "icon-generic-Unlock",
  genericUser: "icon-generic-User",
  genericUserAdd: "icon-generic-User-add",
  genericUserApprove: "icon-generic-User-approve",
  genericUserImportant: "icon-generic-User-important",
  genericUserRemove: "icon-generic-User-remove",
  genericVideoCamera: "icon-generic-Video-camera",
  genericVisible: "icon-icon-generic-Visible",
  genericZoomIn: "icon-generic-Zoom-in",
  genericZoomOut: "icon-generic-Zoom-out",
  interfaceAlarm: "icon-interface-Alarm",
  interfaceAutoRotateLocked: "icon-interface-Auto-rotate-locked",
  interfaceBluetooth: "icon-interface-Bluetooth",
  interfaceCalendar: "icon-interface-Calendar",
  interfaceCamera: "icon-interface-Camera",
  interfaceCart: "icon-interface-Cart",
  interfaceChat: "icon-interface-Chat",
  interfaceChatBubble: "icon-interface-Chat-bubble",
  interfaceCheckMark: "icon-interface-Check-mark",
  interfaceCircuitHub: "icon-interface-Circuit-hub",
  interfaceCircuitNodes: "icon-interface-Circuit-nodes",
  interfaceCircuitPath: "icon-interface-Circuit-path",
  interfaceColorPicker: "icon-interface-Color-picker",
  interfaceDoubleTick: "icon-icon-interface-Double-tick",
  interfaceEmptyFile: "icon-interface-Empty-file",
  interfaceEqualizer: "icon-interface-Equalizer",
  interfaceEvent: "icon-interface-Event",
  interfaceExitFullScreen: "icon-interface-Exit-full-screen",
  interfaceExpand: "icon-interface-Expand",
  interfaceExpandShare: "icon-interface-Expand-share",
  interfaceFill: "icon-interface-Fill",
  interfaceFlame: "icon-interface-Flame",
  interfaceFullScreen: "icon-interface-Full-screen",
  interfaceHistory: "icon-interface-History",
  interfaceHome: "icon-interface-Home",
  interfaceHorizontal: "icon-icon-interface-Horizontal",
  interfaceHorizontalSwipe: "icon-interface-Horizontal-swipe",
  interfaceHotspot: "icon-interface-Hotspot",
  interfaceInfinity: "icon-icon-interface-Infinity",
  interfaceLeftAlign: "icon-interface-Left-align",
  interfaceLoader: "icon-interface-Loader",
  interfaceLockOpen: "icon-interface-Lock-open",
  interfaceMessageBubble: "icon-interface-Message-bubble",
  interfaceMessageTyping: "icon-interface-Message-typing",
  interfaceMinimizeScreen: "icon-interface-Minimize-screen",
  interfaceMoonNight: "icon-interface-Moon-night",
  interfaceNetworkSignal: "icon-interface-Network-signal",
  interfaceNotification: "icon-interface-Notification",
  interfaceOffer: "icon-interface-Offer",
  interfacePaintBrush: "icon-interface-Paint-brush",
  interfaceParallelCircuitLines: "icon-interface-Parallel-circuit-lines",
  interfacePath: "icon-interface-Path",
  interfacePendrive: "icon-interface-Pendrive",
  interfacePhoneRotate: "icon-interface-Phone-rotate",
  interfacePhoneVibration: "icon-interface-Phone-vibration",
  interfacePulseMachine: "icon-interface-Pulse-machine",
  interfaceReuse: "icon-interface-Reuse",
  interfaceSave: "icon-interface-Save",
  interfaceScrollUp: "icon-interface-Scroll-up",
  interfaceSelection: "icon-interface-Selection",
  interfaceSettings: "icon-interface-Settings",
  interfaceSms: "icon-interface-Sms",
  interfaceSwipeLeft: "icon-interface-Swipe-left",
  interfaceSwitchButton: "icon-icon-interface-Switch-button",
  interfaceTouch: "icon-interface-Touch",
  interfaceTouchHandGesture: "icon-interface-Touch-hand-gesture",
  interfaceTyping: "icon-interface-Typing",
  interfaceTypingMessageBubble: "icon-interface-Typing-message-bubble",
  locationAddLocation: "icon-location-Add-location",
  locationDirections: "icon-location-Directions",
  locationDiscover: "icon-location-Discover",
  locationGPS: "icon-location-GPS",
  locationGPSTracking: "icon-location-GPS-tracking",
  locationGlobe: "icon-location-Globe",
  locationLocationPin: "icon-location-Location-pin",
  locationLocationPoint: "icon-location-Location-point",
  locationLocationRemove: "icon-location-Location-remove",
  locationMapChart: "icon-location-Map-chart",
  locationMapPin: "icon-location-Map-pin",
  locationNavigator: "icon-location-Navigator",
  locationNoGPS: "icon-location-No-GPS",
  locationPaperPin: "icon-location-Paper-pin",
  locationVerifiedLocation: "icon-location-Verified-location",
  targetArrow: "icon-target-arrow",
  transportationBoat: "icon-icon-transportation-Boat",
  transportationBus: "icon-transportation-Bus",
  transportationCab: "icon-transportation-Cab",
  transportationCar: "icon-icon-transportation-Car",
  transportationCargo: "icon-transportation-Cargo",
  transportationCycle: "icon-transportation-Cycle",
  transportationEstimateDelivery: "icon-transportation-Estimate-delivery",
  transportationFlight: "icon-transportation-Flight",
  transportationFreeDeliveryShipment: "icon-transportation-Free-delivery-shipment",
  transportationHarbour: "icon-transportation-Harbour",
  transportationHelmet: "icon-transportation-Helmet",
  transportationHookCrane: "icon-transportation-Hook-crane",
  transportationMetroTrain: "icon-transportation-Metro-train",
  transportationParachute: "icon-transportation-Parachute",
  transportationParcel: "icon-transportation-Parcel",
  transportationRoadBarrierCone: "icon-transportation-Road-barrier-cone",
  transportationRoadRoller: "icon-transportation-Road-roller",
  transportationRocket: "icon-transportation-Rocket",
  transportationScooter: "icon-transportation-Scooter",
  transportationShip: "icon-transportation-Ship",
  transportationSignBoard: "icon-transportation-Sign-board",
  transportationTrolley: "icon-transportation-Trolley",
  transportationTruck: "icon-transportation-Truck",
  weatherCloud: "icon-weather-Cloud",
  weatherCloudRain: "icon-weather-Cloud-rain",
  weatherLightning: "icon-weather-Lightning",
  weatherMoon: "icon-weather-Moon",
  weatherRain: "icon-weather-Rain",
  weatherRainCloud: "icon-weather-Rain-cloud",
  weatherRainDrop: "icon-weather-Rain-drop",
  weatherRainy: "icon-weather-Rainy",
  weatherSnowFlake: "icon-weather-Snow-flake",
  weatherSun: "icon-weather-Sun",
  weatherSunCloud: "icon-weather-Sun-Cloud",
  weatherSunny: "icon-weather-Sunny",
  weatherTemperature: "icon-weather-Temperature",
  weatherThunder: "icon-weather-Thunder",
  weatherUmbrellaRain: "icon-weather-Umbrella-rain",
  weatherWaveWindy: "icon-weather-Wave-windy",
  weatherWind: "icon-weather-Wind",
  weatherWindSock: "icon-weather-Wind-sock",
  weatherWindy: "icon-weather-Windy",
  weatherWinter: "icon-weather-Winter",
}

export default Icons