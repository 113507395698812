import React, { useState } from "react"
import subscriptionContainer from "container/BrokerDashboard/subscriptionContainer"
import { Modal } from "antd"
import {
  BlueButton,
} from "../../commonStyles"
import { showConfirm } from "../../ConfirmationModalUtil"

const SendOnboardingInvite = ({
  disabled,
  sendOnboardingEmail,
  currentAgentIDViewed,
  sendOnboardingEmailResponse,
}) => {
  const [mail, setMailStatus] = useState(false)

  const {
    data: mailSent,
    isFetching: sendingEmail,
  } = sendOnboardingEmailResponse || {}

  return (
    <BlueButton
      onClick={() => {

        const postAction = () => {
          const payload = {
            agentId: currentAgentIDViewed,
          }
          sendOnboardingEmail({
            payload,
          })

          setMailStatus(true)

          Modal.success({
            content: "Email Sent",
          })

          setTimeout(() => {
            setMailStatus(false)
          }, 2500)
        }

        const message = "Proceed with sending onboarding email for this agent?"
        const warning = "This would generate an email with onboarding link and will be sent to the agent!"

        showConfirm(message, postAction, warning)
      }}
      ATCFONT
      disabled={disabled || sendingEmail}
      borderRadius="33.5px"
    >

      {mail && mailSent && (
        <>
         Sent!
        </>
      )}

      {sendingEmail ? "Sending Email..." : !mail && !sendingEmail && (
        <>
          Send link to Hubspot
          <span>
            <img
              src="https://s3.amazonaws.com/cdn.agentdesks.com/images/ISA_ASSETS/ISA_SOUL_SUBMIT_Arrows.svg"
              alt="submit_isa"
            />
          </span>
        </>
      )}
    </BlueButton>
  )
}

export default subscriptionContainer(SendOnboardingInvite)

