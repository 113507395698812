import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  background: ${props => props.bg};
  margin: ${props => props.m};
  ${props => props.mobile && `
    ${props.mobile}
  `}
  ${props => (props.mt ? `margin-top: ${props.mt}px;` : "")}
  ${props => (props.mr ? `margin-right: ${props.mr}px;` : "")}
  ${props => (props.mb ? `margin-bottom: ${props.mb}px;` : "")}
  ${props => (props.ml ? `margin-left: ${props.ml}px;` : "")}
  padding: ${props => props.p || 0};
  ${props => (props.pt ? `padding-top: ${props.pt}px;` : "")}
  ${props => (props.pr ? `padding-right: ${props.pr}px;` : "")}
  ${props => (props.pb ? `padding-bottom: ${props.pb}px;` : "")}
  ${props => (props.pl ? `padding-left: ${props.pl}px;` : "")}
  ${props => (props.t ? `top: ${props.t}px;` : "")}
  ${props => (props.l ? `left: ${props.l}px;` : "")}
  ${props => (props.r ? `right: ${props.r}px;` : "")}
  ${props => (props.w ? `width: ${props.w}px;` : "")}
  ${props => (props.h ? `height: ${props.h}px;` : "")}
  ${props => (props.br ? `border-radius: ${props.br}px;` : "")}
  ${props => (props.fg ? `flex-grow: ${props.fg};` : "")}
  display: ${props => props.d};
  flex: ${props => props.flex};
  ${props => (props.wrap ? `flex-wrap: ${props.wrap};` : "")}
  flex-direction: ${props => props.fd};
  justify-content: ${props => props.jc};
  align-items: ${props => props.ai};
  text-align: ${props => props.ta};
  line-height: ${props => props.lh};
  color: ${props => props.c};
  font-weight: ${props => props.fw};
  font-size: ${props => props.fs};
  position: ${props => props.position};
  background-color: ${props => props.bgc};
  border: ${props => props.brd};
`

const Box = ({
  d,
  fd,
  jc,
  ai,
  m,
  mt,
  mr,
  mb,
  ml,
  p,
  pt,
  pr,
  pb,
  pl,
  flex,
  bg,
  ta,
  lh,
  c,
  fw,
  fs,
  w,
  t,
  wrap,
  style,
  position,
  children,
  id,
  br,
  fg,
  r,
  ...props
}) => (
  <Wrapper
    d={d}
    flex={flex}
    bg={bg}
    m={m}
    ml={ml}
    mr={mr}
    mb={mb}
    mt={mt}
    p={p}
    pt={pt}
    pr={pr}
    pb={pb}
    pl={pl}
    jc={jc}
    ai={ai}
    fd={fd}
    ta={ta}
    lh={lh}
    style={style}
    c={c}
    fw={fw}
    w={w}
    fs={fs}
    t={t}
    wrap={wrap}
    position={position}
    id={id}
    br={br}
    fg={fg}
    r={r}
    {...props}
  >
    {children}
  </Wrapper>
)

Box.defaultProps = {
  m: 0,
  p: 0,
  lh: 1,
}

export default Box
