import React from "react"

const CheckIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="10" height="8" viewBox="0 0 10 8" {...props}>
    <defs>
      <path id="a" d="M4 7.573a.921.921 0 0 1-1.333 0L.276 5.105a.998.998 0 0 1 0-1.378.923.923 0 0 1 1.333 0l1.725 1.781L8.392.286a.921.921 0 0 1 1.333 0 .995.995 0 0 1 0 1.376L4.001 7.573z" />
    </defs>
    <use fill={props.color} fillRule="evenodd" xlinkHref="#a" />
  </svg>
)



export default CheckIcon