/* eslint-disable import/no-unresolved */
import React from "react"
import styled from "styled-components"
import moment from "moment"
import { ShimmerBox } from "shared/styles/animation"
import AgentContainer from "container/BrokerDashboard/editAgentContainer"
import { USCurrencyFormat } from "shared/currencyUtils"
import { Collapse } from "antd"
import {
  TitleWrap,
  Container,
  ContainerDescription,
  ContainerMap,
  ContainerTitle,
} from "../../common"

const { Panel } = Collapse

const Wrap = styled.div``

const ICADetails = ({
  agentBrokerageInfoResponse,
}) => {
  const { data: agentBrokerageData, isFetching } = agentBrokerageInfoResponse || {}
  return (
    <Wrap>
      <TitleWrap>
        <Collapse expandIconPosition="end" ghost>
          <Panel header="ICA Details">
            <Container>
              {isFetching ? (
                <>
                  <ShimmerBox w="256px" h="86px" />
                  <ShimmerBox w="256px" h="86px" />
                  <ShimmerBox w="256px" h="86px" />
                  <ShimmerBox w="256px" h="86px" />
                </>
              ) : (
                <>
                  <ContainerMap>
                    <ContainerTitle>
                      {agentBrokerageData?.cap_reset_date ? moment(agentBrokerageData?.cap_reset_date).format("MM-DD-YYYY") : "N/A"}
                    </ContainerTitle>
                    <ContainerDescription>
                      CAP reset date
                    </ContainerDescription>
                  </ContainerMap>
                  <ContainerMap>
                    <ContainerTitle>
                      {agentBrokerageData?.ica_signed_date ? moment.unix(agentBrokerageData?.ica_signed_date).format("MM-DD-YYYY") : "N/A"}
                    </ContainerTitle>
                    <ContainerDescription>
                      ICA signed date
                    </ContainerDescription>
                  </ContainerMap>
                  <ContainerMap>
                    <ContainerTitle>
                      {agentBrokerageData?.license_transferred_date ? moment.unix(agentBrokerageData?.license_transferred_date).format("MM-DD-YYYY") : "N/A"}
                    </ContainerTitle>
                    <ContainerDescription>
                      Licences transfer date
                    </ContainerDescription>
                  </ContainerMap>
                  <ContainerMap>
                    <ContainerTitle>
                      {USCurrencyFormat(agentBrokerageData?.brokerage_fee_paid_current_cycle) || "N/A"}
                    </ContainerTitle>
                    <ContainerDescription>
                      Brokerage fee paid by the agent in current cycle
                    </ContainerDescription>
                  </ContainerMap>
                  <ContainerMap>
                    <ContainerTitle>
                      {`${agentBrokerageData?.cap === null ? "N/A" : `${USCurrencyFormat(agentBrokerageData?.cap)}`}`}
                    </ContainerTitle>
                    <ContainerDescription>
                      Off cap amount
                    </ContainerDescription>
                  </ContainerMap>
                </>
              )}
            </Container>
          </Panel>
        </Collapse>
      </TitleWrap>
    </Wrap>
  )
}

export default AgentContainer(ICADetails)
