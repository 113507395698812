import styled from "styled-components"

export const DashboardBusy = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  > img {
    max-width: 200px;
  }
  p {
    font-size: 28px;
  }
`

export const Title = styled.h1`
  color: #000;
  font-family: 'Roboto';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  padding: 16px;
`

export const SectionTitle = styled.p`
  color: #000;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 111.111% */
  margin: 0;
`

export const Card = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #F3F3F3;
  width: 100%;
  min-height: 120px;
  height: max-content;
`

export const BlueButton = styled.button`
  display: flex;
  padding: 10px 14px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  min-height: 24px;
  cursor: pointer;
  height: max-content;
  border-radius: 4px;
  background: var(--color-primary, #5A5FF2);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05); 
  border: none;
  width: 262px;
  border-radius: 33.5px;

  color: #FFF;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  justify-content: center;

  ${props => props.borderRadius && `
    border-radius: ${props.borderRadius};
  `}

  ${props => props.ATCFONT && `
    color: #FFF;
    font-family: ATC;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  `}

  ${props => props.disabled && `
    cursor: not-allowed;
    background: #52525B;
  `}

  transition: 0.7s ease-out;

  ${props => !props.disabled && `
    &:hover {
      transform: scale(1.1);
      color: #FFF;
    }
  `}
`

export const WhiteButton = styled.button`
  display: flex;
  padding: 10px 14px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  width: 262px;
  min-height: 24px;
  cursor: pointer;
  height: max-content;
  border-radius: 4px;
  background: white;
  border: 1px solid var(--color-primary, #5A5FF2);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border-radius: 33.5px;


  color: #5A5FF2;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  justify-content: center;

  ${props => props.borderRadius && `
    border-radius: ${props.borderRadius};
  `}

  ${props => props.disabled && `
    cursor: not-allowed;
    background: #52525B;
    color: white;
    border: none;
  `}

  ${props => props.ATCFONT && `
    font-family: ATC;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    min-height: 40px;
  `}

  transition: 0.7s ease-out;

  ${props => props.selected && `
    background: var(--color-primary, #5A5FF2);
    color: #FFF;
  `}


  ${props => !props.disabled && `
    &:hover {
      transform: scale(1.1);
      background: var(--color-primary, #5A5FF2);
      color: #FFF;
    }
  `}
  
`

export const SubTextDisclaimer = styled.div`
  p {
    overflow: hidden;
    color: #A7A7A7;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 10px;
    margin-bottom: -10px;
  }
`

export const CardV2 = styled.div`
  display: flex;
  width: ${props => props.cardWidth ? props.cardWidth : "100%"};
  padding: ${props => props.padding || "16px"};
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid #DFDDDD;

  ${props => props.cardMaxWidth && `
    max-width: ${props.cardMaxWidth};
  `}

  .ant-upload {
    background: white !important;
    padding: 50px;
    width: 456px;
  }

  .ant-upload-text {
    color: var(--neutral-800, #191D23);
    text-align: center;
    font-family: Manrope;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  .anticon-cloud-upload {
    color: black !important;
  }
`

export const Badge = styled.div`
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;

  color: var(--White, #FFF);
  text-align: center;

  /* Text xs/Medium */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */

  img {
    width: 16px
    height: 16px;
  }

  background-color: ${props => props.bgColor || "transparent"}
  border-radius: 16px;
`

export const CLOUDFRONT = "https://d2fedz0by71ckz.cloudfront.net/images"

export const StatusText = styled.p`
  color: var(--gray-900, #18181B);
  font-family: ATC;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 150% */
`

export const StatusDiv = styled.div`
  display: flex;
  width: max-content;
  padding: 4px 10px 4px 4px;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  background: #F5F5F5;
  mix-blend-mode: multiply;
`
export const StatusMessage = styled.p`
  color: var(--color-tertiary, #1C1C1F);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 141%; /* 16.92px */
  margin: 0;
`

export const BadgeText = styled.p`
  color: var(--White, #FFF);
  text-align: center;
  font-family: ATC;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 16.8px */
  margin: 0;
`
export const DocImage = styled.div`
  width: 167px;
  height: 186px;
  border-radius: 4px 4px 0px 0px;

  img {
    height: 100%;
    width: 100%;
  }
`
export const DocTypeCard = styled.div`
  border-top: 1px solid #F1F1F1;
  border-bottom: 1px solid #F1F1F1;
  display: flex;
  width: 166.519px;
  align-items: baseline;
  justify-content: space-between;
  gap: 8.123px;
  height: max-content;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 4px;

`

export const DocTypeText = styled.p`
  color: var(--typography-general-primary, #303030);
  font-family: ATC;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  margin: 0;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 80%;
`

export const ViewDocumentCTA = styled.button`
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 7px;
  align-self: stretch;
  cursor: pointer;
  background: transparent;
  border: none;

  &:hover {
    background: #e9f5ff;
  }

  ${props => props.disabled && `
    cursor: not-allowed;
  `}
`

export const DownloadDocumentCTA = styled.a`
  text-decoration: none;
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 7px;
  align-self: stretch;
  cursor: pointer;
  background: transparent;
  border: none;

  &:hover {
    background: #e9f5ff;
  }

  ${props => props.disabled && `
    cursor: not-allowed;
  `}
`

export const ViewDocumentText = styled.p`
  margin: 0;
  color: var(--typography-general-colored, #3B60E4);
  font-family: ATC;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

export const ViewDocumentImage = styled.div`
  width: 16px;
  height: 16px;
  position: relative;
  top: -3px;

  img {
    width: 100%;
    height: 100%;
  }
`

export const DocStatus = styled.div`
  width: 16px;
  height: 16px;

  img {
    width: 100%;
    height: 100%;
  }
`
export const UnderlinedCTA = styled.button`
  display: flex;
  padding: 10px 14px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  min-height: 24px;
  cursor: pointer;
  height: max-content;
  border-radius: 4px;
  background: transparent;
  border: none;
  width: 262px;

  color: #5A5FF2;
  text-align: right;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  justify-content: center;
  text-decoration: underline;

  ${props => props.ATCFONT && `
    color: #5A5FF2;
    font-family: ATC;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  `}

  ${props => props.disabled && `
    cursor: not-allowed;
    background: #52525B;
  `}

  transition: 0.7s ease-out;

  &:hover {
    transform: scale(1.1);
  }
`
