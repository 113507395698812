/* eslint-disable import/no-unresolved */
import React, { useEffect } from "react"
import styled from "styled-components"
import { withRouter } from "react-router-dom"
import { get, isEmpty } from "lodash"
import AgentContainer from "container/BrokerDashboard/editAgentContainer"
import BrokerDashboardContainer from "container/BrokerDashboard/sidebarContainer"
import SubscroptionContainer from "container/BrokerDashboard/subscriptionContainer"
import globalPagesContainer from "container/BrokerDashboard/globalPagesContainer"
import {
  AgentDetails,
  CurrentPlan,
  ICADetails,
  Licences,
  Profile,
  SalesRep,
  SidePanelSettings,
  CancelSubscription,
} from "./components"

const Wrap = styled.div`
  border: 1px solid #ccc;
`

const Container = styled.div`
  width: 338px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`

const SidePanelV2 = ({
  getAgentDetails,
  getAgentBrokerageInfo,
  userAgentType,
  getAgentStates,
  getAgentCardDetails,
  fetchSubscriptions,
  getSubsribedAddons,
  getRARTeamList,
  getUSStates,
  currentPlan,
  getTCAgentOpted,
  getTCListResponse,
  getTCList,
  getTentativeBrokerageSubscriptionDetails,
  addonsResponse,
  subscriptions,
  fetchRecruiterAndAsmInfo,
  toggleTeamModal,
  ...props
}) => {
  const agentId = get(props, "match.params.agentId", "")
  const { currentPlan: planExists } = currentPlan || {}
  useEffect(() => {
    getAgentDetails({
      agentId,
      isFetching: true,
    })
    getAgentBrokerageInfo({ agentId })
    userAgentType({
      agentId,
    })
    getAgentStates({
      agentId,
    })
    getAgentCardDetails({
      agentId,
    })
    fetchSubscriptions({
      agentId,
    })
    fetchRecruiterAndAsmInfo({
      agentId,
    })

    getSubsribedAddons({
      agentId,
    })

    getTCAgentOpted({
      agentId,
    })
    getTCList()
    getRARTeamList({ skip: 0, limit: 30 })
    getUSStates()
  }, [])

  useEffect(() => {
    const {
      data: addResponseData,
      isFetching: fetchingAddons,
    } = addonsResponse || {}

    const {
      data: subData,
      isFetching: fetchingSubscriptions,
    } = subscriptions || {}
    if (!fetchingSubscriptions && !fetchingAddons) {
      if (isEmpty(addResponseData)
        && isEmpty(currentPlan)) {
        if (!subscriptions?.length) {
          getTentativeBrokerageSubscriptionDetails({
            agentId,
          })
        }
      }
    }
  }, [addonsResponse, currentPlan, subscriptions])

  useEffect(() => {
    getRARTeamList({ skip: 0, limit: 30 })
  }, [toggleTeamModal])

  return (
    <Wrap>
      <Container>
        <Profile />
        <Licences />
        <AgentDetails />
        <CurrentPlan />
        <ICADetails />
        <SalesRep />
        <SidePanelSettings />
        {!isEmpty(planExists) && <CancelSubscription />}
      </Container>
    </Wrap>
  )
}

export default
withRouter((SubscroptionContainer(BrokerDashboardContainer(AgentContainer(globalPagesContainer(SidePanelV2))))))