import axios from "axios";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const fetchChecklistMetadataAPI = (state, type) =>
  axios.get(
    `${baseUrl}/v1.0/brokerage-checklist-docs/metadata?type=checklistTemplateMetadata&state=${state}&complianceType=${type}`
  );

export const fetchStatesAPI = () =>
  axios.get(
    `${baseUrl}/v1.0/brokerage-checklist-docs/metadata?type=stateMetadata`
  );

export const fetchTypeAPI = () =>
  axios.get(
    `${baseUrl}/v1.0/brokerage-checklist-docs/metadata?type=complianceTypeMetadata`
  );

export const fetchAllTemplatesAPI = (state, type, checklistTemplate) =>
  axios.get(
    `${baseUrl}/v1.0/brokerage-checklist-docs/templates?state=${state}&complianceType=${type}&checklistTemplate=${checklistTemplate}`
  );

export const fetchAllChecklistAPI = (state, type) =>
  axios.get(
    `${baseUrl}/v1.0/brokerage-checklist-docs/metadata?type=checklistTypeMetadata&complianceType=${type}`
  );

export const saveNewItemToDocumentAPI = (payload) =>
  axios.post(`${baseUrl}/v1.0/brokerage-checklist-docs/templates`, payload);

export const deleteTemplateAPI = (templateId) =>
  axios.delete(
    `${baseUrl}/v1.0/brokerage-checklist-docs/templates/${templateId}`
  );

export const deleteDocumentAPI = (documentItemId) =>
  axios.delete(
    `${baseUrl}/v1.0/brokerage-checklist-docs/document-item/${documentItemId}`
  );

export const saveDueDateAPI = (payload) =>
  axios.put(`${baseUrl}/v1.0/brokerage-checklist-docs/checklist-due`, payload);

export const addNewChecklistTemplate = (payload) =>
  axios.post(`${baseUrl}/v1.0/brokerage-checklist-docs/metadata`, payload);

export const updateOrderingTemplatesAPI = (payload) =>
  axios.post(
    `${baseUrl}/v1.0/brokerage-checklist-docs/checklist-ordering`,
    payload
  );
export const saveAddCriteriaAPI = (payload) =>
  axios.post(`${baseUrl}/v1.0/brokerage-checklist-docs/criteria`, payload);

export const fetchDocumentCriteria = (documentItemId) =>
  axios.get(
    `${baseUrl}/v1.0/brokerage-checklist-docs/criteria?documentItemId=${documentItemId}`
  );

export const fetchBrcbDocumentAPI = (payload) =>
  axios.get(`${baseUrl}/v1.0/brokerage-brbc-docs/brbc-template`, {params: payload});

export const addBrbcDocumentAPI = (payload) =>
  axios.post(`${baseUrl}/v1.0/brokerage-brbc-docs/brbc-template`, payload);
