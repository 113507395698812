import React from "react"
import subscriptionContainer from "container/BrokerDashboard/subscriptionContainer"
import { notification } from "antd"
import cookie from "react-cookies"
import {
  CardV2,
  DocImage,
  DocTypeCard,
  DocTypeText,
  DocStatus,
  ViewDocumentCTA,
  ViewDocumentImage,
  ViewDocumentText,
  DownloadDocumentCTA,
} from "./commonStyles"
import { GetSenderOrReciever } from "./mappings"

const DocCard = ({
  doc,
  icon,
  triggerSenderViewDocuSign,
  triggerRecieverViewDocuSign,
  triggerSenderViewDocuSignResponse,
  triggerRecieverViewDocuSignResponse,
  currentAgentIDViewed,
  download,
  noIcon,
  noView,
  editViewOnly,
  triggerTemplateEditView,
  triggerTemplateEditViewResponse,
  agentDetailsResponse,
  templateDownload,
  getTemplateDownloadURLResponse,
  getTemplateDownloadURL,
  agentBrokerageInfoResponse,
  rarTeamListResponse,
}) => {
  const envelopeId = doc.envelope_id
  const payload = {
    envelopeId,
  }

  const user = cookie.load("user")
  const adminId = cookie.load("admin_id")
  const id = adminId ? parseInt(adminId, 10) : parseInt(user.agent_id, 10)

  const recieverViewPayload = {
    agentId: parseInt(id, 10),
    envelopeId,
  }

  const {
    isFetching: gettingDownloadLink,
  } = getTemplateDownloadURLResponse || {}

  const {
    isFetching: triggeringSenderView,
  } = triggerSenderViewDocuSignResponse

  const {
    isFetching: triggeringReceiverView,
  } = triggerRecieverViewDocuSignResponse

  const {
    isFetching: triggeringTEmplateEditView,
  } = triggerTemplateEditViewResponse || {}

  const loading = triggeringReceiverView || triggeringSenderView

  const {
    data: agentData,
    isFetching: fetchingAgentResponse,
  } = agentBrokerageInfoResponse || {}

  // console.log("doc", doc)

  return (
    <CardV2
      cardWidth="max-content"
      padding="10px 6.1px 13px 5px"
    >
      <DocImage>
        <img src="https://s3.amazonaws.com/cdn.agentdesks.com/images/ISA_ASSETS/SOUL_SAMPLE_DOC.png" alt="soul_asset" />
      </DocImage>
      <DocTypeCard>
        <DocTypeText>{doc.document_name || doc.name || "Merged ICA"}</DocTypeText>
        {!noIcon && (
          <DocStatus>
            <img src={icon} alt="soul_asset" />
          </DocStatus>
        )}
      </DocTypeCard>
      {!noView && (
        <ViewDocumentCTA
          disabled={loading}
          onClick={() => {
            if (loading) return false

            const type = GetSenderOrReciever(doc.status)
            switch (type) {
              case "senderView":
                return triggerSenderViewDocuSign({ payload })
              case "recieverView":
                return triggerRecieverViewDocuSign({ payload: recieverViewPayload })
              default:
                return false
            }
          }}
        >
          {loading ? (
            <ViewDocumentText>
              Please wait...
            </ViewDocumentText>
          ) : (
            <>
              <ViewDocumentImage>
                <img
                  src="https://s3.amazonaws.com/cdn.agentdesks.com/images/ISA_ASSETS/SOUL_EYE_ICON.png"
                  alt="soul_assets"
                />
              </ViewDocumentImage>
              <ViewDocumentText>
                View Document
              </ViewDocumentText>
            </>
          )}
        </ViewDocumentCTA>
      )}
      {download && (
        <DownloadDocumentCTA href={doc.url} download target="_blank">
          <ViewDocumentText>
            View Document
          </ViewDocumentText>
        </DownloadDocumentCTA>
      )}
      {templateDownload && (
        <ViewDocumentCTA
          disabled={gettingDownloadLink}
          onClick={() => {
            if (gettingDownloadLink) return false

            const {
              data: teamsData,
            } = rarTeamListResponse || {}

            const {
              brokerage_team_id: brokerageTeamId,
            } = agentData || {}
            
            const currentTeam = teamsData && teamsData.filter(item => item.id === brokerageTeamId) || []
            const currentTeamMembers = currentTeam && currentTeam.length > 0 && currentTeam[0].members || null
            const currentTeamLead = currentTeamMembers && currentTeamMembers.length > 0 && currentTeamMembers.filter(item => item.is_brokerage_admin === 1) || []
            const currentTeamLeadId = currentTeamLead && currentTeamLead.length > 0 && currentTeamLead[0].id || ""

            if (doc && !doc.template_id) {
              notification.error({
                message: "Template ID Missing!",
                description: "Template cannot be download, contact backend",
                duration: 5,
                placement: "top",
              })
              return false
            }

            notification.info({
              message: "File is being downloaded...",
              description: "Please wait...",
              duration: 5,
              placement: "top",
            })

            getTemplateDownloadURL({
              templateId: doc.template_id,
              agentId: parseInt(currentTeamLeadId, 10),
              teamId: brokerageTeamId,
            })

            return true
          }}
        >
          {gettingDownloadLink ? (
            <ViewDocumentText>
              Please wait...
            </ViewDocumentText>
          ) : (
            <>
              <ViewDocumentImage>
                <img
                  src="https://s3.amazonaws.com/cdn.agentdesks.com/images/ISA_ASSETS/SOUL_EYE_ICON.png"
                  alt="soul_assets"
                />
              </ViewDocumentImage>
              <ViewDocumentText>
                Download
              </ViewDocumentText>
            </>
          )}
        </ViewDocumentCTA>
      )}
      {editViewOnly && (
        <ViewDocumentCTA
          disabled={loading}
          onClick={() => {
            if (loading || fetchingAgentResponse) return false

            const {
              brokerage_team_id: brokerageTeamId,
            } = agentData || {}

            const data = {
              agentId: parseInt(currentAgentIDViewed, 10),
              templateId: doc.template_id,
              teamId: brokerageTeamId,
            }

            triggerTemplateEditView({
              payload: data,
            })

            return true
          }}
        >
          {triggeringTEmplateEditView ? (
            <ViewDocumentText>
              Please wait...
            </ViewDocumentText>
          ) : (
            <>
              <ViewDocumentImage>
                <img
                  src="https://s3.amazonaws.com/cdn.agentdesks.com/images/ISA_ASSETS/SOUL_EYE_ICON.png"
                  alt="soul_assets"
                />
              </ViewDocumentImage>
              <ViewDocumentText>
                Edit Template
              </ViewDocumentText>
            </>
          )}
        </ViewDocumentCTA>
      )}
    </CardV2>
  )
}

export default subscriptionContainer(DocCard)
