import { message } from "antd";
import React from "react";
import { AlignLeftOutlined, ClockCircleFilled } from "@ant-design/icons";
import copyToClipBoard from "copy-to-clipboard";

const handleCopyToClipboard = (text) => {
  copyToClipBoard(text);
  message.success("Document Title ID copied to clipboard");
};

export const isEditing = (record, editingKeyMap) => {
  if (!record) {
    return false;
  }
  return record.key === editingKeyMap[record.checklist];
};

export const createPlaceholder = (dataIndex) => {
  let placeholder;
  if (dataIndex === "document_item") {
    placeholder = "Enter Title of the item";
  } else if (dataIndex === "template_name") {
    placeholder = "Enter Document Title";
  } else if (dataIndex === "template_templateId") {
    placeholder = "Enter Document Title ID";
  } else if (dataIndex === "mandatory") {
    placeholder = "Choose Status";
  }
  return placeholder;
};

export const isAnyItemEditing = (editingKeyMap) => {
  for (const key in editingKeyMap) {
    if (editingKeyMap[key] !== "") {
      return true;
    }
  }
  return false;
};

export const TitleColumn = {
  title: (
    <>
      <div className="">&nbsp; Title of the item</div>
    </>
  ),
  dataIndex: "document_item",
  key: "document_item",
  editable: true,
  width: "400px",
  render: (text, record) => (
    <div>
      {record.isBrbc ? <div className="brbc-tag">BRBC</div> : null}
      {text}
    </div>
  ),
};

export const DocumentTitleIDColumn = {
  title: (
    <>
      <div className="">
        <AlignLeftOutlined /> &nbsp; Document Title ID
      </div>
    </>
  ),
  dataIndex: "template_templateId",
  key: "template_templateId",
  editable: true,
  width: "400px",
  render: (text) =>
    text ? (
      <div className="copy-column">
        {text}
        <button
          className="copy-btn"
          onClick={() => handleCopyToClipboard(text)}
        >
          Copy
        </button>
      </div>
    ) : (
      <div> - </div>
    ),
};

export const DocumentTitleColumn = {
  title: (
    <>
      <div className="">
        <AlignLeftOutlined /> &nbsp; Document Title
      </div>
    </>
  ),
  width: "400px",
  dataIndex: "template_name",
  key: "template_name",
  editable: true,
  render: (text) => <div>{text ? text : " - "}</div>,
};

export const StatusColumn = {
  title: (
    <>
      <div className="">
        <ClockCircleFilled /> &nbsp; Status
      </div>
    </>
  ),
  dataIndex: "mandatory",
  key: "mandatory",
  editable: true,
  width: "300px",
  render: (mandatory) => <span>{mandatory}</span>,
};
