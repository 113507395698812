/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import _, { isEmpty, parseInt, get } from "lodash"
import root from "window-or-global"
import {
  Select, Button, Input, Switch, Modal, Spin,
} from "antd"
import Loader from "../../../common/UI/Loader"
import PlusSign from "./PlusOutlined.svg"
import container from "../../../../container/BrokerDashboard/editAgentContainer"

const Wrap = styled.div`
  margin: 20px;
`
const Title = styled.h1`
  margin-top: 34px;
  margin-left: 17px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  ${props => props.body && `
  margin-top: 0px;
  padding-left: 46px;
`}
`
const Content = styled.div`
  margin-top: 58px;
  margin-left: 17px;
  width: 1040px;
  height: 100%;
  .Content::-webkit-scrollbar {
    display: none;
  };
`
const HeaderWrap = styled.div`
  padding-left: 46px;
  padding-top: 48px;
  padding-right: 28px;
  display: flex;
  justify-content: space-between;
`

const TitleWrap = styled.div`
  padding-left: 46px;
  padding-top: 48px;
  padding-right: 28px;
  display: flex;
  justify-content: flex-start;
`

const AddNewDoc = styled.div`
  display: flex;
  margin-left: 48px;
  margin-top: 18px;
  cursor: pointer;
  ${props => props.colist && `
  margin-left: 0px;
`}
`
const Image = styled.img`
  margin-right: 16px;
`
const AddNew = styled.h3`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: #100063;
  margin: 0px;
`
const DropdownDiv = styled.div`
  display: flex;
`
const Subtitle = styled.h2`
  margin: 0px;
  margin-top: 6px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  ${props => props.body && `
  margin-top: 0px;
  padding-left: 63px;
  font-size: 16px;
`}
`
const SubHeading = styled.h2`
  margin: 0px;
  margin-top: 8px;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
`
const HR = styled.div`
  width: 100%;
  height: 0px;
  border: 1px solid #E6E6E6;
  margin-top: 32px;
  margin-left: 40px;
  ${props => props.colist && `
  margin-left: -9px;
  `}
  ${props => props.last && `
  margin-left: -9px;
  margin-bottom: 30px;
  `}
`
const Body = styled.div`
  // scroll: scroll;
  // overflow-x: hidden;
  height: 1000px;
  &::-webkit-scrollbar {
    display: none;
  }
`
const TitleDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
`
const InfoDiv = styled.div`
  width: 990px;
  height: 102px;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  margin-top: 87px;
  margin-left: 50px;
  display: flex;
  justify-content: space-around;
  ${props => props.body && `
  margin-top: 23px;
  margin-bottom: 44px;
`}
${props => props.stuck && `
  margin-top: 0px;
  margin-bottom: 44px;
  margin-left: 0px;
`}
${props => props.first && `
  margin-top: 0px;
  margin-bottom: 44px;
`}
${props => props.owner && `
  padding-left: 0px;
  margin-left: 0px;
  margin-top: 23px;
`}
`
const Section = styled.div`
  display: flex;
  flex-direction: column;
`
const SmallTitle = styled.h3`
  font-size: 14px;
  font-weight: 200;
  margin-top: 8px;
`
const AdditionDocuments = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 86px;
`
const Divider = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 35px;
`
const SmallHR = styled.div`
  width: 400.5px;
  height: 0px;
  border: 1px solid #E6E6E6;
  margin-top: 32px;
  margin-left: 40px;
`
const SmallText = styled.h3`
  width: 158px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 0px;
  margin-top: 0px;
  padding: 0px;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
`
const MLSDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 250px;
  ${props => props.body && `
  padding-left: 0px;
  `}
  ${props => props.contingency && `
    padding-left: 0px;
    width: 800px;
  `}
`
const Text = styled.p`
  margin: 0px;
  width: 553px;
  padding-left: 63px;
  margin-top: 20px;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  ${props => props.colist && `
  font-weight: 100;
  color: #8A8A8A;
  padding-left: 0;
  `}
  ${props => props.key && `
  font-weight: 100;
  padding-left: 0;
  `}
`
const InvalidText = styled.h3`
  margin-top: 5px;
  margin-bottom: 0px;
  color: red;
  font-size: 12px;
`

const TC_STATE = [
  { label: "California", value: "california" },
  { label: "Colorado", value: "colorado" },
  { label: "Texas", value: "texas" },
  { label: "Florida", value: "florida" },
  { label: "Washington", value: "washington" },
  { label: "Georgia", value: "georgia" },
  { label: "Oregon", value: "oregon" },
  { label: "Arizona", value: "arizona" },
  { label: "All State", value: "All State" },
]

const TitleAndTemplate = ({
  isEdit,
  valueName,
  valueTemplate,
  entityName,
  entityType,
  state,
  templateList,
  index,
  templateData,
  setTemplateInvalid,
  deleteTemplates,
  localTemplateData,
  setLocalTemplateData,
}) => {
  const [name, setName] = useState("")
  const [template, setTemplate] = useState("")
  const [isInvalidMessage, setIsInvalidMessage] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [invalidArray, setInvalidArray] = useState([])

  useEffect(() => {
    setName(valueName)
  }, [valueName])

  useEffect(() => {
    setTemplate(valueTemplate)
  }, [valueTemplate])

  const handleAddTemplate = (nameTarget, templateTarget) => {
    if (!isEmpty(templateList)) {
      templateList.map((x) => {
        if (x.idToAdd === index) {
          if (index.includes("old-temp")) {
            const id = parseInt((index.split("-").pop()), 10)
            x.name = nameTarget || ""
            x.id = id
            x.idToAdd = index
            x.templateId = templateTarget || ""
            x.isEdited = true
            x.entityName = entityName
            x.entityType = entityType
            x.state = state
          } else {
            x.name = nameTarget || ""
            x.idToAdd = index
            x.templateId = templateTarget || ""
            x.entityName = entityName
            x.entityType = entityType
            x.state = state
          }
        }
      })
    }
  }

  const handleDelete = (indexLocal) => {
    const checkId = indexLocal.split("-").pop()
    if (indexLocal.includes("old")) {
      templateData.map((x) => {
        if (x.id.toString() === checkId) {
          deleteTemplates.push(x.id)
          localTemplateData.splice(localTemplateData.indexOf(x), 1)
          setLocalTemplateData([...localTemplateData])
        }
      })
    }
    // setTemplateList([...templateList.filter(x => x.idToAdd !== indexLocal)])
    templateList.map((x) => {
      if (x.idToAdd === indexLocal) {
        templateList.splice(templateList.indexOf(x), 1)
      }
    })

    const checkIdInTemplateDataIndex = templateData.findIndex(x => x.id.toString() === checkId)

    if (checkIdInTemplateDataIndex !== -1) {
      templateData.map((x) => {
        if (x.id.toString() !== checkId) {
          setTemplateInvalid(false)
        }
      })
    } else {
      setTemplateInvalid(false)
    }

    setDeleteModal(false)
  }

  //Function to keep save button disabled if even one template ID is duplicate
  const checkDisabled = () => {
    if (templateList.length > 1) {
      setInvalidArray([])
      templateList.map((x) => {
        templateList.map((y) => {
          if (x.templateId === y.templateId && x.idToAdd !== y.idToAdd) {
            invalidArray.push(y)
          }
          // if (x.templateId === y.templateId && x.idToAdd !== y.idToAdd && x.state === y.state) {
          //   invalidArray.push(y)
          // }
        })
      })
      if (invalidArray.length > 0) {
        setTemplateInvalid(true)
      } else {
        setTemplateInvalid(false)
      }
    }
  }

  const checkIsDeleted = (indexLocal) => {
    const checkId = indexLocal.split("-").pop()
    deleteTemplates.map((x) => {
      if (x.toString() === checkId) {
        return true
      }
      return false
    })
  }

  const handleCancel = () => {
    setDeleteModal(false)
  }

  return (
      <>
        <InfoDiv
          owner
        >
          <Section>
            <SmallTitle>Title</SmallTitle>
            <Input
              placeholder="Enter the Document Name"
              disabled={!isEdit}
              style={{
                width: 400,
                height: 32,
                background: "#F9F8FF",
                border: 1,
                borderColor: "#D9D9D9",
                borderRadius: 2,
                color: "black",
              }}
              value={name}
              onChange={(e) => {
                setName(e.target.value)
                handleAddTemplate(e.target.value, template, index)
              }}
            />
          </Section>
          <Modal
            title="Delete Template"
            visible={deleteModal}
            onOk={() => handleDelete(index)}
            onCancel={handleCancel}
          >
              Are you sure you want to delete this template?
          </Modal>
          <Section>
            <SmallTitle>Template ID</SmallTitle>
            <Input
              placeholder="Enter the Template ID"
              disabled={!isEdit}
              required
              style={{
                width: 400,
                height: 32,
                background: "#F9F8FF",
                borderThickness: 1,
                borderColor: "#D9D9D9",
                borderRadius: 2,
                color: "black",
              }}
              value={template}
              onChange={(e) => {
                setTemplate(e.target.value)
                handleAddTemplate(name, e.target.value, index)
              }}
            />
            {isInvalidMessage === true ? (
              <InvalidText>* The Template ID is duplicate, please enter a different Template ID</InvalidText>
            ) : null}
          </Section>
          {isEdit ? (
              <>
                {checkIsDeleted === true ? (
                  <Button
                    type="primary"
                    style={{
                      alignSelf: "center",
                    }}
                    disabled
                  >
                    Deleted
                  </Button>
                ) : (
                  <Button
                    style={{
                      alignSelf: "center",
                    }}
                    disabled={!(index.includes("old"))}
                    onClick={() => setDeleteModal(true)}
                  >
                    Delete
                  </Button>
                )}
              </>
          ) : null}
        </InfoDiv>
      </>
  )
}

const ViewPage = ({
  getDocusignTemplate,
  getDocusignTemplateResponse,
  getListingTotalCommonTemplate,
  getListingTotalCountTemplateResponse,
  templateList,
  setTemplateInvalid,
  templateInvalid,
  setTemplateList,
  createDocusignTemplate,
  deleteTemplates,
  setDeleteTemplates,
  deleteDocusignTemplate,
}) => {
  const [isEdit, setIsEdit] = useState(false)

  const [addNewOG, setAddNewOG] = useState(false)

  const [addOGDoc, setAddOGDoc] = useState([1])
  const [state, setState] = useState("california")

  const [localTemplateData, setLocalTemplateData] = useState([])
  const [localList, setLocalList] = useState([])

  const [saveModal, setSaveModal] = useState(false)
  const [showSpin, setShowSpin] = useState(false)

  const {
    data: templateData,
    isFetching,
  } = getDocusignTemplateResponse || {}

  useEffect(() => {
    getDocusignTemplate()
    getListingTotalCommonTemplate()
  }, [])
  const {
    data: result,
  } = getListingTotalCountTemplateResponse || {}

  const {
    total: totalCount,
  } = result || {}

  useEffect(() => {
    getDocusignTemplate()
  }, [])

  useEffect(() => {
    console.log("---tempDate", templateData)
    if (templateData) {
      templateData.map((x) => {
        console.log("here")
        templateList.push({
          idToAdd: `old-temp-${x.id}`,
          name: x.name,
          templateId: x.template_id,
          entityName: x.entity_name,
          entityType: x.entity_type,
          state: x.state,
          isEdited: false,
        })
      })
      setLocalTemplateData(templateData)
    }
  }, [templateData])

  const getTotalCountByState = (givenState) => {
    const res = _.find(result, {'state': givenState})
    return res ? res.total : 0
  }

  const addOGDocFunc = () => {
    setAddOGDoc([...addOGDoc, addOGDoc.length])
  }

  const saveCancel = () => {
    setSaveModal(false)
  }

  const checkIfEmpty = () => {
    let isInvalid = null
    if (!isEmpty(templateList)) {
      templateList.find((x) => {
        if ((x.name === "") || (x.templateId === "")) {
          isInvalid = window.confirm("Some values are empty, please fill appropriate details in the empty fields")
          if (isInvalid) {
            setSaveModal(false)
          }
        }
      })
    }
    return isInvalid
  }

  const saveList = () => {
    const checking = checkIfEmpty() === true || false
    // return
    if (checking === true) {
      setIsEdit(true)
      return
    }
    if (checking === false) {
      if (!isEmpty(templateList)) {
        templateList.forEach((x) => {
          if (x.name !== "" && x.templateId !== "") {
            if (x.isEdited && x.isEdited === true) {
              localList.push(x)
            } else if (!("isEdited" in x)) {
              localList.push(x)
            }
          }
        })
        if (!isEmpty(localList)) {
          createDocusignTemplate({
            payload: localList,
            callback: () => {
              setShowSpin(false)
              root.location.reload()
            },
          })
          setShowSpin(true)
        }
      }
      if (!isEmpty(deleteTemplates)) {
        deleteTemplates.map((id) => {
          deleteDocusignTemplate({
            id,
            callback: () => {
              setShowSpin(false)
              root.location.reload()
            },
          })
        })
        setShowSpin(true)
      }
      if (isEmpty(deleteTemplates) && isEmpty(localList)) {
        root.location.reload()
      }
      setTemplateList([])
      setLocalList([])
      setIsEdit(false)
      setSaveModal(false)
    }
  }

  console.log("--local data---", localTemplateData)
  return (
    <Wrap>
      <TitleWrap>
        <Title body>Document Library</Title>
      </TitleWrap>

      {showSpin === true ? (
        <Spin
          size="large"
          style={{
            alignSelf: "center",
            marginLeft: "25%",
            marginTop: "25%",
          }}
        />
      ) : (
        <Content>
          <Modal
            title="Save Changes"
            visible={saveModal}
            onOk={saveList}
            onCancel={saveCancel}
          >
              Are you sure you want to save these changes?
          </Modal>
          <HeaderWrap>
          <DropdownDiv>
              <Subtitle>Saved Listing Templates  </Subtitle>
              {result && <SubHeading>  ( Total - {getTotalCountByState(state)} )</SubHeading> }
            </DropdownDiv>
          </HeaderWrap>
          <HeaderWrap>
          <DropdownDiv>
              <Subtitle>State</Subtitle>
              <Select
                defaultValue="California"
                style={{
                  width: 367,
                  marginLeft: 20,
                  marginTop: 0,
                }}
                disabled={isEdit}
                onChange={(value) => {
                  setState(value)
                }}
                options={TC_STATE}
              />
            </DropdownDiv>
            {isEdit === false ? (
              <Button
                style={{
                  width: 170,
                  background: "#100063",
                  color: "white",
                }}
                onClick={() => {
                  setIsEdit(true)
                }}
              >
                  Edit
              </Button>

            ) : (
                <>
                  <Button
                    style={{
                      width: 170,
                      background: "#24AE60",
                      color: "white",
                    }}
                    disabled={templateInvalid}
                    onClick={() => {
                      setSaveModal(true)
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    style={{
                      width: 170,
                      background: "red",
                      color: "white",
                    }}
                    disabled={templateInvalid}
                    onClick={() => {
                      setIsEdit(false)
                    }}
                  >
                    Cancel
                  </Button>
                </>
            )}
          </HeaderWrap>
          <HR />
          <Body>
            {isFetching === true ? (
              <Loader />
            ) : (
                <>
                  {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                    <>
                      {x.entity_type.includes("common_entity_type") && x.entity_name === "common_entity_name" ? (
                        <>
                          {x.state === state ? (
                            <TitleAndTemplate
                              index={`old-temp-${x.id}`}
                              deleteTemplates={deleteTemplates}
                              setDeleteTemplates={setDeleteTemplates}
                              templateData={templateData}
                              localTemplateData={localTemplateData}
                              setLocalTemplateData={setLocalTemplateData}
                              isShifted={false}
                              state={state}
                              entityName="common_entity_name"
                              entityType="common_entity_type"
                              templateList={templateList}
                              setTemplateList={setTemplateList}
                              isEdit={isEdit}
                              addArray={addOGDoc}
                              setAddArray={setAddOGDoc}
                              valueName={x.name}
                              setTemplateInvalid={setTemplateInvalid}
                              valueTemplate={x.template_id}
                            />
                          ) : null}
                        </>
                      ) : null}
                    </>
                  )) : (
                    <Text>No documents added for this category yet.</Text>
                  )}
                </>
            )}
            {addNewOG === true ? (
                <>
                  {addOGDoc.length && addOGDoc.map((x, i) => (
                    <>
                      {i > 0 && (
                        <TitleAndTemplate
                          key={x}
                          addArray={addOGDoc}
                          setAddArray={setAddOGDoc}
                          index={`new-temp-${i}`}
                          templateData={templateData}
                          isShifted={false}
                          state={state}
                          templateList={templateList}
                          setTemplateList={setTemplateList}
                          setTemplateInvalid={setTemplateInvalid}
                          entityName="common_entity_name"
                          entityType="common_entity_type"
                          isEdit={isEdit}
                        />
                      )}
                    </>
                  ))}
                </>
            ) : null}
            {isEdit === true ? (
              <AddNewDoc
                onClick={() => {
                  setAddNewOG(true)
                  addOGDocFunc()
                  templateList.push({
                    idToAdd: `new-temp-${addOGDoc.length}`,
                    name: "",
                    templateId: "",
                    entityName: "common_entity_name",
                    entityType: "common_entity_type",
                    state,
                  })
                }}
              >
                <Image src={PlusSign} />
                <AddNew>Add New Document </AddNew>
              </AddNewDoc>
            ) : null}
          </Body>
        </Content>
      )}
    </Wrap>
  )
}

export default container(ViewPage)
