import { createSignalAction, createSimpleCreator } from "shared/reduxUtils";

const BASE = 'CHECKLIST';

// Fetch Checklist Actions
export const fetchChecklistMetadataAction = createSignalAction(BASE, 'FETCH_CHECKLIST');
export const fetchStatesMetadataAction = createSignalAction(BASE, 'FETCH_STATES');
export const fetchTypeMetadataAction = createSignalAction(BASE, 'FETCH_TYPE');
export const fetchAllTemplatesAction = createSignalAction(BASE, 'FETCH_ALL_TEMPLATES');
export const fetchAllTemplatesForReplicationAction = createSignalAction(BASE, 'FETCH_ALL_TEMPLATES_FOR_REPLICATION');
export const fetchAllChecklistDataAction = createSignalAction(BASE, 'FETCH_ALL_CHECKLIST_DATA');
export const saveNewItemDocumentAction = createSignalAction(BASE, 'SAVE_NEW_ITEM_TO_DOCUMENT');
export const deleteTemplateAction = createSignalAction(BASE, 'DELETE_TEMPLATE')
export const deleteDocumentAction  = createSignalAction(BASE, 'DELETE_DOCUMENT')
export const saveDueDateAction = createSignalAction(BASE, 'SAVE_DUE_DATE');
export const updateOrderingTemplatesAction = createSignalAction(BASE, 'UPDATE_ORDERS_TEMPLATES');
export const addNewChecklistTemplateAction = createSignalAction(BASE, 'ADD_NEW_CHECKLIST_TEMPLATE')
export const fetchDocumentCriteriaAction = createSignalAction(BASE, 'FETCH_DOCUMENT_CRITERIA')
export const saveAddCriteriaAction = createSignalAction(BASE, 'SAVE_ADD_CRITERIA')
export const fetchBrbcDocumentsAction = createSignalAction(BASE, 'FETCH_BRBC_DOCUMENTS')
export const addBrbcDocAction = createSignalAction(BASE, 'ADD_BRBC_DOC')
//fetch helper functions data
export const dropdownDataAction = createSimpleCreator(BASE, "DROPDOWN_DATA")
