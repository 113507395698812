import React, {
  useEffect, useRef, useState,
} from "react"
import styled from "styled-components"
import { add, get, throttle, isEmpty } from "lodash"
import query from "query-string"
import DayPickerInput from "react-day-picker/DayPickerInput"
import DayPicker, { DateUtils } from "react-day-picker"
import InfiniteScroll from "react-infinite-scroller"
import { Link, withRouter } from "react-router-dom"
import Select from "react-select"
import { GoogleAutoComplete } from "dumbComponents/common/UI/antd"
import Button from "dumbComponents/common/UI/Button"
import container from "container/BrokerDashboard/globalPagesContainer"
import agentSearchContainer from "container/BrokerDashboard/agentSearchContainer"
import moment from "moment-timezone"
import Loader from "dumbComponents/common/UI/Loader"
import NumberFormat from "react-number-format"
import { USCurrencyFormat } from "shared/currencyUtils"
import Overview from "dumbComponents/BrokerDashboard/Comp/OverviewRoster"
import TextInput from "dumbComponents/BrokerDashboard/Comp/TextInput"
import { PAST_BROKERAGE_NAME_OPTIONS, PRODUCTION_TIER_OPTIONS, SALES_REPRESENTATIVE } from "./Resources/data"
import {
  Select as MultipleSelect,
  Dropdown, Menu, message, Upload, Modal, Col, Button as ClassicButton, Spin
} from "antd"
import { MoreOutlined, LoadingOutlined, UploadOutlined } from "@ant-design/icons"
import teamContainer from "container/BrokerDashboard/teamContainer.js"
const { Option } = MultipleSelect
const { Dragger } = Upload

const Wrap = styled.div`
  margin: 0px auto;
  max-width: 90%;
`

const WrapAgent = styled.div`
  position: relative;
  width: 300px;
`

const TableWrap = styled.div`
  min-height: 150vh;
  width: 2800px;
`

const Row = styled.div`
  
  display: flex;
  > div {
    flex-shrink: 0;
  }
  h5 {
    margin: 0px;
    font-weight: 400;
    color: #888;
    margin-top: 5px;
  }

  ${props => props.isFixed && `
    position: sticky;
    top: 0px;
    z-index: 1;
    background: #fff;
  `}
`

const PaginationWrap = styled.div`
  margin: 50px auto;
`

const HighlightWrap = styled.div`
  padding: 10px;
  width: 300px;
  background: #F9F8FF;
  position: relative;
  border: .2px solid #CCC
  display: flex;
  flex-direction: column;
  justify-content: center;
  > p {
    margin: 0px;
  }
  ${props => props.isHead && `
    background: #F0F0F0;
  `}
`

const StarWrap = styled.span`
  margin-left: 10px;
  cursor: pointer;
  i.fa.fa-star {
    color:  #FFD700;
  }
`

const PlusWrap = styled.span`
  margin-left: 10px;
  cursor: pointer;
  i.fa.fa-plus {
  color:  #FFD700;
  font-size: 20px;
  }
`

const GeneralWrap = styled.div`
  width: 240px;
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: .2px solid #CCC
  > p {
    margin: 0px;
  }
  ${props => props.isHead && `
    background: #F0F0F0;
  `}
`

const Header = styled.div`
  position: relative;
  align-items: center;
  margin: 30px 0px;
  > h1 {
    flex: 1;
  }
  > div {
    margin: 0px 10px;
    display: flex;
    align-items: flex-end;
    > input {
      margin-right: 10px;
    }
  }
`

const AgentList = styled.div`
  position: absolute;
  width: 300px;
  top: 100px;
  left: 0px;
  right: 0px;
  background: #fff;
  border-radius: 10px;
  z-index: 1000;
  height: max-content;

  ${props => props.hasAgents && `
     border: 1px solid #ccc;
   `}

   ${props => props.hideDropdown && `
     display: none;
    `}
`

const AgentItem = styled.div`
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  &:hover {
    background: #eee;
    transition-duration: 0.3s;
    cursor: pointer;
  }
  > div {
    &:nth-child(1) {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      overflow: hidden;
      background: #eee;
      > img {
        width: 100%;
      }
    }
    &:nth-child(2) {
      margin-left: 20px;
      p, h6 {
        padding: 0px;
        margin: 0px;
      }
      h6 {
        font-weight: 400;
        color: #999;
        font-size: 12px;
      }
      > input {
        color: #999;
        border: 10px;
        font-size: 12px;
        pointer-events: none;
        padding: 0px;
        background: transparent;
      }
    }
  }
`
const SearchInput = styled.input`
  flex: 1;
  font-size: 16px;
  padding: 8px 16px;
  border-radius: 6px;
  border: 1px solid #ccc;
  width: 100%;

  ${props => props.isError && `
    border: 1px solid red;
  `}
`

const IconWrap = styled.span`
  display: flex;
  position: absolute;
  top: 15px;
  right: 10px;
  color: #999;
  transition-duration: 0.3s;
  cursor: pointer;

  ${props => props.isBeingApplied && `
    color: blue;
  `}
  &:hover {
    color: #333;
  }
`

const Input = styled.input`
  width: 80px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`

const DateSelectorWrap = styled.div`
  z-index: 10;
  position: relative;
  width: 200px;
  > input {
    padding: 10px 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .Selectable {
    background: #fff;
    width: 570px;
    padding-bottom: 30px;
    position: absolute;
    top: 35px;
    left: -170px;
    box-shadow: rgb(0 0 0 / 5%) 0px 4px 10px 0px;
  }
`

const MaxMinWrapper = styled.div`
  margin: 0px 8px;
  h6 {
    margin: 0px 0px 6px 10px;
    font-size: 16px;
  }
  > div {
    display: flex;
    input {
      margin: 0px 5px;
      padding: 11px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  }

  div[class*="-container"] {
    width: 150px;
  }

  &.agent-type-container {
    div[class*="-container"] {
      width: 300px;
    }
    div[class*="-control"] {
      width: 300px;
    }
  }

  &.assist-plan-container {
    div[class*="-container"] {
      width: 200px;
    }
    div[class*="-control"] {
      width: 200px;
    }
  }

  .DayPickerInput {
    input {
      width: 100px;
    }
  }
`

const TeamFilterWrap = styled.div`
  background: #F9F8FE;
  border: 1px solid #888;
  display: inline-flex !important;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
  margin-top: 16px !important;
  p {
    margin: 0px;
  }
  a {
    margin-left: 20px;
  }
`
const ActionsWrap = styled.div`
display: flex;
width: 15%;
padding: 8px;
align-items: flex-start;
gap: 10px;
border-radius: 6px;
border: 1px solid var(--Neutral-200, #E5E7EB);
background: var(--Generic-White, #FFF);
`

const YearEndWrapContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 16px;
  margin-top: 16px;
  border-top: 1px solid #ccc;
`

const YearEndWrapStats = styled.div`
  border: 1px solid #ccc;
  padding: 16px;
  border-radius: 4px;
`

const YearEndWrapButton = styled.button`
  width: 100%;
  background: #3555da;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
  padding: 8px;
  outline: none;
  border: 0;

  &:hover {
    background: #17309A;
  }

  ${props => props.disabled && `
    background: #ccc;
    cursor: not-allowed;

    &:hover {
      background: #ccc;
    }
  `}
`

const getHeight = () => {
  const body = document.body

  const html = document.documentElement
  return Math.max(body.scrollHeight, body.offsetHeight,
    html.clientHeight, html.scrollHeight, html.offsetHeight)
}

const ButtonWrap = styled.div`
  margin: 50px 0px;
  display: flex;
  justify-content: flex-end;
`

const SIZE = 50
const ORDER_TYPE = {
  DESC: "DESC",
  ASC: "ASC",
}
const SORT_TYPES = {
  BY_CREATED_AT: "createdAtSortOrder",
  BY_DEALS_COUNT: "dealsSortOrder",
  BY_NAME: "nameSortOrder",
  BY_REQUEST: "requestedProductSortOrder",
  BY_LAST_CLOSED_DEALS: "lastClosedDealSortOrder",
  BY_DEALS_PER_MONTH: "dealsPerMonthSortOrder",
  BY_TOTAL_PRICE: "totalPriceSortOrder",
  BY_TOTAL_GROSS_COMMISION: "totalGrossCommissionSortOrder",
  BY_ANNUAL_GROSS_COMMISION: "annualGrossCommissionSortOrder",
  BY_TOTAL_RADIUS_FEE: "totalRadiusFeesSortOrder",
  BY_TOTAL_INCONTRACT: "totalInContractsSortOrder",
  BY_AGENT_TYPE: "agentTypeSortOrder",
  BY_ENDED_AT: "churnedSortOrder",
}

const AGENT_TYPES = [
  { label: "Individual Agent", value: "individual_agent" },
  { label: "Team Member", value: "team_member" },
  { label: "Team Lead", value: "team_lead" },
  { label: "Accelerator Agent", value: "accelerator_agent" },
  { label: "Internal", value: "internal" },
  { label: "Co Team Lead", value: "co_team_lead" },
]

const ASSIST_PLAN_TYPE = [
  { label: "Bulk", value: "blu" },
  { label: "RTL", value: "rtl" },
  { label: "Both", value: "both" },
]

const TC_STATE = [
  { label: "All States", value: "all-states" },
  { label: "California", value: "California" },
  { label: "Colorado", value: "Colorado" },
  { label: "Texas", value: "Texas" },
  { label: "Florida", value: "Florida" },
  { label: "Washington", value: "Washington" },
  { label: "Georgia", value: "Georgia" },
  { label: "Oregon", value: "Oregon" },
  { label: "Others", value: "others" },
]

const Roster = ({
  getAgentForRosterPage,
  getAgentYearEndWrap,
  agentYearEndWrapResponse,
  downloadAgentsRosterPage,
  rosterPageAgentsResponse,
  clearResults,
  agents,
  rosterFetchAgentSearch,
  onAgentSelect,
  resetSelections,
  clearAgent,
  match,
  location,
  isAgentListDropdownVisible,
  toggleAgentList,
  updateAgentSettings,
  getTCList,
  getTCListResponse,
  updateAgentOptedTC,
  getTCAgentAssignedResponse,
  getTCAgentOpted,
  TCListfinal,
  getRARTeamList,
  rarTeamListResponse,
  uploadW2FormResponse,
  uploadW2Form,
  deleteW2Form,
  ...props
}) => {
  const [churnedAgentCheck, setChurnedAgentCheck] = useState(false)
  const [churnedColumnVisible, setChurnedColumnVisible] = useState(false)
  const handleChange = () => {
    setChurnedAgentCheck(!churnedAgentCheck)
  }
  const [searchText, setSearchText] = useState("")
  const handleSearchType = (value) => {
    setSearchText(value)
    rosterFetchAgentSearch({ search: value })
  }
  const { isFetching: isFetchingName, data: dataAgent } = agents
  const { isFetching: isFetchingW2 } = uploadW2FormResponse
  const { isFetching: isFetchingAgentYearEndWrap } = agentYearEndWrapResponse
  const agentsLists = get(dataAgent, "agents", [])

  const [isShowDateSelector, setIsShowDateSelector] = useState(false)
  const [planFilter, setPlanFilter] = useState("")
  const [showYearEndWrapForAgent, setShowYearEndWrapForAgent] = useState(null)
  const planFilterRef = useRef()
  const [dateRange, setDateRange] = useState({
    from: undefined, to: undefined,
  })

  const fromDateRef = useRef()
  const toDateRef = useRef()
  const dealsRangeMinRef = useRef()
  const dealsRangeMaxRef = useRef()
  const dealsPerMonthMinRef = useRef()
  const dealsPerMonthMaxRef = useRef()
  const dealsClosedRangeFromRef = useRef()
  const dealsClosedRangeToRef = useRef()
  const capRangeMinRef = useRef()
  const capRangeMaxRef = useRef()
  const pprMinRef = useRef()
  const pprMaxRef = useRef()
  const [isSearchActive, setSearchActive] = useState(false)
  const [teamFilter, setTeamFilter] = useState()
  const [teamName, setTeamName] = useState()
  const [maxDeals, setMaxDeals] = useState(undefined)
  const [minDeals, setMinDeals] = useState(undefined)
  const [minDealsPerMonth, setMinDealsPerMonth] = useState(undefined)
  const [maxDealsPerMonth, setMaxDealsPerMonth] = useState(undefined)
  const [isRequestedCheckboxSelected, setCheckBox] = useState(false)
  const [sortBy, setSortBy] = useState(SORT_TYPES.BY_NAME)
  const [sortDir, setSortDir] = useState(ORDER_TYPE.ASC)
  const [assistPlan, setAssistPlan] = useState(undefined)
  const assistPlanRef = useRef()
  const [state, setState] = useState(undefined)
  const stateRef = useRef()

  const [isLicenseTransferred, setIsLicenseTransferred] = useState(null)
  const isLicenseTransferredRef = useRef()
  const [isIcaSigned, setIsIcaSigned] = useState(null)
  const isIcaSignedRef = useRef()
  const [primarySaleRepresentative, setPrimarySaleRepresentative] = useState("")
  const primarySaleRepresentativeRef = useRef()
  const [secondarySaleRepresentative, setSecondarySaleRepresentative] = useState("")
  const secondarySaleRepresentativeRef = useRef()
  const [agentType, setAgentType] = useState("")
  const [city, setCity] = useState("")
  const agentTypeRef = useRef()
  const [minPastBrokerageProduction, setMinPastBrokerageProduction] = useState(null)
  const [maxPastBrokerageProduction, setMaxPastBrokerageProduction] = useState(null)
  const [pastBrokerageName, setPastBrokerageName] = useState("")
  const pastBrokerageNameRef = useRef()
  const [otherPastBrokerageName, setOtherPastBrokerageName] = useState("")
  const [productionTier, setProductionTier] = useState("")
  const productionTierRef = useRef()
  const [minCap, setMinCap] = useState(null)
  const [maxCap, setMaxCap] = useState(null)
  const [teamId, setTeamId] = useState(null)
  const [isW2ModalOpen, setW2Modal] = useState(false)
  const [w2deletionModal, setW2deletionModal] = useState(false)
  const [selectedW2File, setW2File] = useState(null)
  const [agentIdForW2, setAgentIdForW2] = useState(null)

  const [tcAssigned, setTcAssigned] = useState(undefined)
  const tcAssignedRef = useRef()
  const [dealsDateRange, setDealsDateRange] = useState({
    from: undefined, to: undefined,
  })
  const [churnedDateRange, setChurnedDateRange] = useState({
    from: undefined, to: undefined,
  })

  useEffect(() => {
    const parsedSearch = query.parse(location.search)
    const payload = {
      limit: SIZE,
    }
    if (parsedSearch && parsedSearch.teamId && parsedSearch.teamName) {
      payload.teamId = parsedSearch.teamId
      setTeamFilter(parsedSearch.teamId)
      setTeamName(parsedSearch.teamName)
    }
    getAgentForRosterPage(payload)
    getRARTeamList({ skip: 0, limit: SIZE })
  }, [])

  useEffect(() => {
    getTCList()
  }, [])

  const handleSortByRequest = (type = "") => {
    clearResults()
    const newSortState = sortDir === ORDER_TYPE.DESC ? ORDER_TYPE.ASC : ORDER_TYPE.DESC
    setSearchText("")
    getAgentForRosterPage({
      ...createPayload(),
      sortBy: `${type}=${newSortState}`,
      skip: 0,
    })
    setSortDir(newSortState)
    setSortBy(type)
  }

  const fetchMore = () => {
    getAgentForRosterPage(createPayload({
      isFetchingMore: true,
    }))
  }

  const handleSearch = () => {
    setIsShowDateSelector(false)
    getAgentForRosterPage(
      createPayload({}, true)
    )
  }

  const handleDownload = () => {
    setIsShowDateSelector(false)
    downloadAgentsRosterPage(
      createPayload({}, true)
    )
  }

  const parseOnRadiusTime = (onRadiusFor) => {
    const currentTime = new Date()
    const month = moment(currentTime).diff(onRadiusFor * 1000, "months")
    const days = moment(currentTime).subtract(month, "month").diff(onRadiusFor * 1000, "days")
    let finalDate = ""
    if (month) {
      finalDate += `${month} month${month > 1 ? "s" : ""}, `
    }
    if (days) {
      finalDate += `${days} day${days > 1 ? "s" : ""}`
    }
    return finalDate
  }

  const onToggleStarred = (agentId, starred_for_lmp) => {
    updateAgentSettings({
      payload: {
        starred_for_lmp: starred_for_lmp === 0 ? 1 : 0,
      },
      agentId,
      pageType: "roster",
    })
  }

  const getAssistLeads = (assistPlus, assistCredit) => {
    if (assistPlus === 1) {
      return "∞"
    }
    if (assistCredit > 0) {
      return assistCredit
    }
    return 0
  }

  const getTeamSelectOptions = () => {
    return (rarTeamListResponse && !isEmpty(rarTeamListResponse.data)) ? rarTeamListResponse.data.map(dt => ({
      name: dt.name,
      id: dt.id,
    })) : []
  }

  const mapAgentType = (type) => {
    if (!type) {
      return "Not Assigned"
    }
    const selectedAgentType = AGENT_TYPES.find(x => x.value === type)
    if (selectedAgentType) {
      return selectedAgentType.label
    }
    return "Not Assigned"
  }

  const createPayload = (extra = {}, clearSkip = false) => {
    const payload = {
      limit: SIZE,
      ...extra,
    }
    const resultCount = get(rosterPageAgentsResponse, "data.result.length")
    if (resultCount && resultCount > 0) {
      payload.skip = resultCount
    }
    if (searchText) {
      payload.agentEmail = searchText
    }
    if (planFilter && !churnedAgentCheck) {
      payload.plan = planFilter
    }
    if (dateRange.from) {
      payload.fromDate = moment(dateRange.from.setHours(0, 0, 0)).unix()
    }
    if (dateRange.to) {
      payload.toDate = moment(dateRange.to.setHours(0, 0, 0)).add("1", "day").unix()
    }
    if (churnedDateRange.from) {
      payload.fromChurnedDate = moment(churnedDateRange.from.setHours(0, 0, 0)).unix()
    }
    if (churnedDateRange.to) {
      payload.toChurnedDate = moment(churnedDateRange.to.setHours(0, 0, 0)).add("1", "day").unix()
    }
    if (isRequestedCheckboxSelected) {
      payload.productRequest = isRequestedCheckboxSelected
    }
    if (sortBy) {
      payload.sortBy = `${sortBy}=${sortDir}`
    }
    if (minDealsPerMonth) {
      payload.minDealsPerMonth = minDealsPerMonth
    }
    if (maxDealsPerMonth) {
      payload.maxDealsPerMonth = maxDealsPerMonth
    }
    if (minDeals) {
      payload.minDeals = minDeals
    }
    if (maxDeals) {
      payload.maxDeals = maxDeals
    }
    if (state) {
      payload.state = state
    }
    if (city) {
      payload.city = city
    }
    if (teamFilter) {
      payload.teamId = teamFilter
    }
    if (clearSkip) {
      payload.skip = 0
    }
    if (churnedAgentCheck) {
      setChurnedColumnVisible(true)
      payload.churnedFilter = 1
    }
    if (!churnedAgentCheck) {
      setChurnedColumnVisible(false)
      payload.churnedFilter = 0
    }
    if (assistPlan) {
      payload.assistPlan = assistPlan
    }
    if (tcAssigned) {
      payload.tc_id = tcAssigned
    }
    if (dealsDateRange.from) {
      payload.dealsDateFrom = moment(dealsDateRange.from.setHours(0, 0, 0)).unix()
    }
    if (dealsDateRange.to) {
      payload.dealsDateTo = moment(dealsDateRange.to.setHours(0, 0, 0)).add("1", "day").unix()
    }
    if (isLicenseTransferred === 0 || isLicenseTransferred) {
      payload.isLicenseTransferred = isLicenseTransferred
    }
    if (isIcaSigned === 0 || isIcaSigned) {
      payload.isIcaSigned = isIcaSigned
    }
    if (pastBrokerageName) {
      payload.pastBrokerageName = pastBrokerageName
    }
    if (productionTier) {
      payload.productionTier = productionTier
    }
    if (primarySaleRepresentative) {
      payload.primarySaleRepresentative = primarySaleRepresentative
    }
    if (secondarySaleRepresentative) {
      payload.secondarySaleRepresentative = secondarySaleRepresentative
    }
    if (agentType) {
      payload.agentType = agentType
    }
    if (minCap) {
      payload.minCap = minCap
    }
    if (maxCap) {
      payload.maxCap = maxCap
    }
    if (teamId) {
      payload.teamId = teamId
    }
    if (minPastBrokerageProduction) {
      payload.minPastBrokerageProduction = minPastBrokerageProduction
    }
    if (maxPastBrokerageProduction) {
      payload.maxPastBrokerageProduction = maxPastBrokerageProduction
    }
    return payload
  }
  const { data, isFetching, isFetchingMore } = rosterPageAgentsResponse

  const assignTCtoAgent = async (agentId, tcId) => {
    updateAgentOptedTC({
      agentId,
      tcId,
    })
  }

  const getTCAgentOptInStatus = (item) => {
    if (item !== undefined) {
      if (item.tc_firstname === null && item.tc_lastname === null) { return { label: "TC not assigned", value: 0 } }
      return { label: `${item.tc_firstname} ${item.tc_lastname}`, value: item.assigned_tc_id }
    }
    return { label: "TC not assigned" }
  }

  const handleClearFilter = () => {
    const payload = {}
    handleSearchType("")
    setIsShowDateSelector(false)
    if (planFilterRef && planFilterRef.current) {
      planFilterRef.current.select.clearValue()
    }
    setPlanFilter("")
    setDateRange({ from: undefined, to: undefined })
    setSearchActive(false)
    setTeamFilter()
    setTeamName()
    setMaxDeals(undefined)
    setMinDeals(undefined)
    setMinDealsPerMonth(undefined)
    setMaxDealsPerMonth(undefined)
    setCheckBox(false)
    setSortBy(SORT_TYPES.BY_NAME)
    setSortDir(ORDER_TYPE.ASC)
    setAssistPlan(undefined)
    if (assistPlanRef.current) {
      assistPlanRef.current.select.clearValue()
    }
    setState(undefined)
    setCity(null)
    if (stateRef.current) {
      stateRef.current.select.clearValue()
    }
    setIsLicenseTransferred(null)
    if (isLicenseTransferredRef.current) {
      isLicenseTransferredRef.current.select.clearValue()
    }
    setIsIcaSigned(null)
    if (isIcaSignedRef.current) {
      isIcaSignedRef.current.select.clearValue()
    }
    setPrimarySaleRepresentative("")
    if (primarySaleRepresentativeRef.current) {
      primarySaleRepresentativeRef.current.select.clearValue()
    }
    setSecondarySaleRepresentative("")
    if (secondarySaleRepresentativeRef.current) {
      secondarySaleRepresentativeRef.current.select.clearValue()
    }
    setAgentType("")
    if (agentTypeRef.current) {
      agentTypeRef.current.select.clearValue()
    }
    setMinPastBrokerageProduction(null)
    setMaxPastBrokerageProduction(null)
    setPastBrokerageName("")
    if (pastBrokerageNameRef.current) {
      pastBrokerageNameRef.current.select.clearValue()
    }
    setOtherPastBrokerageName("")
    setProductionTier("")
    if (productionTierRef.current) {
      productionTierRef.current.select.clearValue()
    }
    setMinCap(null)
    setMaxCap(null)
    setTcAssigned(undefined)
    if (tcAssignedRef.current) {
      tcAssignedRef.current.select.clearValue()
    }
    setTeamId(null)
    setDealsDateRange({ from: undefined, to: undefined })
    setChurnedDateRange({ from: undefined, to: undefined })
    getAgentForRosterPage(payload)
    if (fromDateRef.current) {
      fromDateRef.current.state.value = ""
    }
    if (toDateRef.current) {
      toDateRef.current.state.value = ""
    }
    if (dealsRangeMinRef.current) {
      dealsRangeMinRef.current.children[0].value = ""
    }
    if (dealsRangeMaxRef.current) {
      dealsRangeMaxRef.current.children[0].value = ""
    }
    if (dealsPerMonthMinRef.current) {
      dealsPerMonthMinRef.current.children[0].value = ""
    }
    if (dealsPerMonthMaxRef.current) {
      dealsPerMonthMaxRef.current.children[0].value = ""
    }
    if (dealsClosedRangeFromRef.current) {
      dealsClosedRangeFromRef.current.state.value = ""
    }
    if (dealsClosedRangeToRef.current) {
      dealsClosedRangeToRef.current.state.value = ""
    }
    if (capRangeMinRef.current) {
      capRangeMinRef.current.children[0].value = ""
    }
    if (capRangeMaxRef.current) {
      capRangeMaxRef.current.children[0].value = ""
    }
    if (pprMinRef.current) {
      pprMinRef.current.children[0].value = ""
    }
    if (pprMaxRef.current) {
      pprMaxRef.current.children[0].value = ""
    }
    setChurnedAgentCheck(false)
    setChurnedColumnVisible(false)
  }

  const handleDeleteOk = () => {
    setW2deletionModal(false);
    deleteW2Form({
      agentId: agentIdForW2,
      callback: () => {
      window.location.reload()
      },
    })
  }

  const handleDeleteCancel = () => {
    setW2deletionModal(false);
  }


  const handleOk = () => {
    if (!selectedW2File) {
      message.error("Please select file to upload")
    } else {
      const bucketLocation = "w2-documents"
      const timestamp = Date.now()
      const file_name = `${timestamp}_${selectedW2File.name}`

      uploadW2Form({
        file: selectedW2File,
        objName: file_name,
        fileName: file_name,
        bucketName: process.env.NODE_ENV === "staging" ? `s-${bucketLocation}` : bucketLocation,
        agentId: agentIdForW2,
        callback: () => {
          setW2Modal(false)
          setW2File(null)
          window.location.reload()
        },
      })
    }
  }

  const handleCancel = () => {
    setW2Modal(false)
    setW2File(null)
    setAgentIdForW2(null)
  }

  const handleRemove = () => {
    setW2File(null)
  }
  const handleUpload = (file) => {
    const isPDF = file.type === 'application/pdf';

    if (!isPDF) {
      message.error(`${file.name} is not a PDF file`);
      return Upload.LIST_IGNORE;
    }

    setW2File(file);
    return true;
  };

  const handleMenuClick = (e, agentData) => {
    if (e.key === "w2_integration") {
      setW2Modal(true)
      setAgentIdForW2(agentData.id)
    }
    if (e.key === "w2_deletion") {
      setW2deletionModal(true)
      setAgentIdForW2(agentData.id)
    }
  }

  const actionItems = [
    {
      key: "w2_integration",
      label: "Add/Edit W2",
    },
    {
      key: "w2_deletion",
      label: "Delete W2",
    },
  ]

  const menu = agentData => (
    <Menu onClick={e => handleMenuClick(e, agentData)}>
      {actionItems.map(item => (
        <Menu.Item key={item.key}>
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  )
  return (
    <Wrap>
      {data && data.overview && (
        <Overview
          {...data.overview}
        />
      )}
      <Header style={{ zIndex: 4 }}>
        <h1>All Agents</h1>
        <div>
          <WrapAgent>
            <h4>Agent Name Or Email</h4>
            <div>
              <Input
                style={{ width: 300 }}
                value={searchText}
                placeholder="Search agent"
                onChange={(e) => {
                  toggleAgentList(true)
                  handleSearchType(e.target.value)
                }}
              />
              <AgentList
                hideDropdown={!isAgentListDropdownVisible}
                hasAgents={agentsLists && agentsLists.length > 0}
              >
                {isFetchingName ? (
                  <Loader width="25px" top={0} bottom={0} />
                ) : (
                  <React.Fragment>
                    {searchText && agentsLists && agentsLists.length > 0 && agentsLists.map(agent => (
                      <AgentItem
                        key={agent.id}
                        onClick={() => {
                          setSearchText(agent.email)
                          getAgentForRosterPage({
                            ...createPayload(),
                            agentEmail: agent.email,
                            skip: 0,
                            limit: 1,
                            churnedFilter: (churnedAgentCheck == true ? 1 : 0),
                          })
                          toggleAgentList(false)
                          setSearchActive(true)
                        }}
                      >
                        <div>
                          <img src={agent.agent_image} alt="" />
                        </div>
                        <div>
                          <p>
                            {`${agent.firstname} ${agent.lastname}`}
                          </p>
                          <h6>
                            {agent.email}
                          </h6>
                          <NumberFormat format="+1 (###) ###-####" mask="_" value={agent.phone} />
                        </div>
                      </AgentItem>
                    ))}
                  </React.Fragment>
                )}
              </AgentList>
            </div>
          </WrapAgent>
          {!churnedColumnVisible
            && (
              <MaxMinWrapper style={{ zIndex: 100 }}>
                <h6>Current Plan</h6>
                <div>
                  <Select
                    ref={planFilterRef}
                    width={200}
                    isMulti={false}
                    style={{ zIndex: 100, margin: "0px 20px" }}
                    options={[
                      { label: "Legacy", value: "legacy" },
                      { label: "Base", value: "base" },
                      { label: "Professional", value: "professional" },
                      { label: "Elite", value: "elite" },
                      { label: "90/10", value: "90/10" },
                      { label: "95/5", value: "95/5" },
                    ]}
                    placeholder="Select"
                    onChange={(value) => {
                      setPlanFilter(value?.value)
                    }}
                  />
                </div>
              </MaxMinWrapper>
            )
          }
          {!churnedColumnVisible
            && (
              <MaxMinWrapper style={{ marginRight: 20 }}>
                <h6>On Radius for</h6>
                <div>
                  <DayPickerInput
                    ref={fromDateRef}
                    type="text"
                    onDayChange={(day) => {
                      setDateRange({
                        ...dateRange,
                        from: day,
                      })
                    }}
                    placeholder="From"
                    style={{ width: 100, marginRight: 20 }}
                    className="lalala"
                  />
                  <DayPickerInput
                    ref={toDateRef}
                    type="text"
                    onDayChange={(day) => {
                      setDateRange({
                        ...dateRange,
                        to: day,
                      })
                    }}
                    style={{ width: 100 }}
                    placeholder="To"
                  />
                </div>
              </MaxMinWrapper>
            )
          }
          {churnedColumnVisible
            && (
              <MaxMinWrapper style={{ marginRight: 20 }}>
                <h6>Churned Date</h6>
                <div>
                  <DayPickerInput
                    type="text"
                    onDayChange={(day) => {
                      setChurnedDateRange({
                        ...churnedDateRange,
                        from: day,
                      })
                    }}
                    placeholder="From"
                    style={{ width: 100, marginRight: 20 }}
                    className="lalala"
                  />
                  <DayPickerInput
                    type="text"
                    onDayChange={(day) => {
                      setChurnedDateRange({
                        ...churnedDateRange,
                        to: day,
                      })
                    }}
                    style={{ width: 100 }}
                    placeholder="To"
                  />
                </div>
              </MaxMinWrapper>
            )
          }
          <MaxMinWrapper>
            <h6>Deals Range</h6>
            <div>
              <div
                ref={dealsRangeMinRef}
              >
                <Input
                  className="small"
                  type="text"
                  placeholder="Min"
                  value={minDeals}
                  onChange={(e) => { setMinDeals(e.target.value) }}
                />
              </div>
              <div
                ref={dealsRangeMaxRef}
              >
                <Input
                  className="small"
                  type="text"
                  placeholder="Max"
                  value={maxDeals}
                  onChange={(e) => { setMaxDeals(e.target.value) }}
                />
              </div>
            </div>
          </MaxMinWrapper>
          <MaxMinWrapper>
            <h6>Deals per month</h6>
            <div>
              <div
                ref={dealsPerMonthMinRef}
              >
                <Input
                  style={{ width: 70 }}
                  type="text"
                  placeholder="Min"
                  value={minDealsPerMonth}
                  onChange={(e) => { setMinDealsPerMonth(e.target.value) }}
                />
              </div>
              <div
                ref={dealsPerMonthMaxRef}
              >
                <Input
                  style={{ width: 70 }}
                  type="text"
                  placeholder="Max"
                  value={maxDealsPerMonth}
                  onChange={(e) => { setMaxDealsPerMonth(e.target.value) }}
                />
              </div>
            </div>
          </MaxMinWrapper>
          <MaxMinWrapper style={{ marginRight: 20 }}>
            <h6>Deals Closed Range</h6>
            <div>
              <DayPickerInput
                ref={dealsClosedRangeFromRef}
                type="text"
                onDayChange={(day) => {
                  setDealsDateRange({
                    ...dealsDateRange,
                    from: day,
                  })
                }}
                placeholder="From"
                style={{ width: 100, marginRight: 20 }}
                className="lalala"
              />
              <DayPickerInput
                ref={dealsClosedRangeToRef}
                type="text"
                onDayChange={(day) => {
                  setDealsDateRange({
                    ...dealsDateRange,
                    to: day,
                  })
                }}
                style={{ width: 100 }}
                placeholder="To"
              />
            </div>
          </MaxMinWrapper>
        </div>
        {teamFilter && (
          <TeamFilterWrap>
            <p>
              Team Filter Added:
              {" "}
              <strong>{teamName}</strong>
            </p>
            <a href="/broker/roster">Remove</a>
          </TeamFilterWrap>
        )}
      </Header>
      <Modal 
      title="Delete W2 Tax Document"
      onOk={handleDeleteOk}
      visible={w2deletionModal}
      onCancel={handleDeleteCancel}
      >
      <p>Are you sure you want to delete this W2 Tax Document?</p>
      </Modal>
      <Modal
        title="Upload W2 Tax Document"
        visible={isW2ModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Upload"
      >
        {isFetchingW2 ? (
          <Loader width="25px" top={0} bottom={0} />) : (<>
            <Upload
              maxCount={1}
              fileList={selectedW2File ? [selectedW2File] : []}
              onRemove={handleRemove}
              showUploadList={{
                showRemoveIcon: true,
              }}
              beforeUpload={handleUpload}
              action={file => handleUpload(file)}
            >
              <ClassicButton type="primary" icon={<UploadOutlined />}>Select file (Max: 1) </ClassicButton>

            </Upload>
          </>)}
      </Modal>
      <Header style={{ marginTop: 10, zIndex: 3 }}>
        <div>
          {!churnedColumnVisible
            && (
              <MaxMinWrapper style={{ zIndex: 100 }}>
                <h6>Is License Transferred?</h6>
                <div>
                  <Select
                    ref={isLicenseTransferredRef}
                    width={200}
                    isMulti={false}
                    style={{ zIndex: 100, margin: "0px 20px" }}
                    options={[
                      { label: "Yes", value: 1 },
                      { label: "No", value: 0 },
                    ]}
                    placeholder="Select"
                    onChange={(value) => {
                      setIsLicenseTransferred(value?.value)
                    }}
                  />
                </div>
              </MaxMinWrapper>
            )
          }
          {!churnedColumnVisible
            && (
              <MaxMinWrapper style={{ zIndex: 100 }}>
                <h6>Is ICA Signed?</h6>
                <div>
                  <Select
                    ref={isIcaSignedRef}
                    width={200}
                    isMulti={false}
                    style={{ zIndex: 100, margin: "0px 20px" }}
                    options={[
                      { label: "Yes", value: 1 },
                      { label: "No", value: 0 },
                    ]}
                    placeholder="Select"
                    onChange={(value) => {
                      setIsIcaSigned(value?.value)
                    }}
                  />
                </div>
              </MaxMinWrapper>
            )
          }
          {!churnedColumnVisible
            && (
              <MaxMinWrapper style={{ zIndex: 100 }}>
                <h6>Past Brokerage Name</h6>
                <div>
                  <Select
                    ref={pastBrokerageNameRef}
                    width={200}
                    isMulti={false}
                    style={{ zIndex: 100, margin: "0px 20px" }}
                    options={PAST_BROKERAGE_NAME_OPTIONS}
                    placeholder="Select"
                    onChange={(value) => {
                      setPastBrokerageName(value?.value)
                    }}
                  />
                </div>
              </MaxMinWrapper>
            )
          }
          {!churnedColumnVisible
            && (
              <MaxMinWrapper style={{ zIndex: 100 }}>
                <h6>Production Tier</h6>
                <div>
                  <Select
                    ref={productionTierRef}
                    width={200}
                    isMulti={false}
                    style={{ zIndex: 100, margin: "0px 20px" }}
                    options={PRODUCTION_TIER_OPTIONS}
                    placeholder="Select"
                    onChange={(value) => {
                      setProductionTier(value?.value)
                    }}
                  />
                </div>
              </MaxMinWrapper>
            )
          }
          {!churnedColumnVisible
            && (
              <MaxMinWrapper style={{ zIndex: 100 }}>
                <h6>Primary Sales Representative</h6>
                <div>
                  <Select
                    ref={primarySaleRepresentativeRef}
                    width={200}
                    isMulti={false}
                    style={{ zIndex: 100, margin: "0px 20px" }}
                    options={SALES_REPRESENTATIVE}
                    placeholder="Select"
                    onChange={(value) => {
                      setPrimarySaleRepresentative(value?.value)
                    }}
                  />
                </div>
              </MaxMinWrapper>
            )
          }
          {!churnedColumnVisible
            && (
              <MaxMinWrapper style={{ zIndex: 100 }}>
                <h6>Secondary Sales Representative</h6>
                <div>
                  <Select
                    ref={secondarySaleRepresentativeRef}
                    width={200}
                    isMulti={false}
                    style={{ zIndex: 100, margin: "0px 20px" }}
                    options={SALES_REPRESENTATIVE}
                    placeholder="Select"
                    onChange={(value) => {
                      setSecondarySaleRepresentative(value?.value)
                    }}
                  />
                </div>
              </MaxMinWrapper>
            )
          }
          {!churnedColumnVisible
            && (
              <MaxMinWrapper style={{ zIndex: 100 }}>
                <h6>Agent Type</h6>
                <div>
                  <Select
                    ref={agentTypeRef}
                    width={200}
                    isMulti={false}
                    style={{ zIndex: 100, margin: "0px 20px" }}
                    options={AGENT_TYPES}
                    placeholder="Select"
                    onChange={(value) => {
                      setAgentType(value?.value)
                    }}
                  />
                </div>
              </MaxMinWrapper>
            )
          }
          {!churnedColumnVisible
            && (
              <MaxMinWrapper>
                <h6>Cap Range</h6>
                <div>
                  <div
                    ref={capRangeMinRef}
                  >
                    <Input
                      className="small"
                      type="text"
                      placeholder="Min"
                      value={minCap}
                      onChange={(e) => { setMinCap(e.target.value) }}
                    />
                  </div>
                  <div
                    ref={capRangeMaxRef}
                  >
                    <Input
                      className="small"
                      type="text"
                      placeholder="Max"
                      value={maxCap}
                      onChange={(e) => { setMaxCap(e.target.value) }}
                    />
                  </div>
                </div>
              </MaxMinWrapper>
            )}
          {!churnedColumnVisible
            && (
              <MaxMinWrapper>
                <h6>Past Production Range</h6>
                <div>
                  <div
                    ref={pprMinRef}
                  >
                    <Input
                      className="small"
                      type="text"
                      placeholder="Min"
                      value={minPastBrokerageProduction}
                      onChange={(e) => { setMinPastBrokerageProduction(e.target.value) }}
                    />
                  </div>
                  <div
                    ref={pprMaxRef}
                  >
                    <Input
                      className="small"
                      type="text"
                      placeholder="Max"
                      value={maxPastBrokerageProduction}
                      onChange={(e) => { setMaxPastBrokerageProduction(e.target.value) }}
                    />
                  </div>
                </div>
              </MaxMinWrapper>
            )}
        </div>
      </Header>
      <Header style={{ marginTop: 10, zIndex: 2 }}>
        <div>
          <div>
            <MaxMinWrapper className="assist-plan-container">
              <h6>State</h6>
              <Select
                ref={stateRef}
                isMulti={false}
                style={{ zIndex: 100, margin: "0px 20px" }}
                options={TC_STATE}
                placeholder="Select State"
                onChange={(value) => {
                  setState(value?.value)
                }}
              />
            </MaxMinWrapper>
          </div>
          <div>
            <MaxMinWrapper className="assist-plan-container">
              <h6>Team Name</h6>
              <MultipleSelect
                style={{ width: 220 }}
                size="large"
                placeholder="Select Team"
                value={teamId}
                onChange={(value) => { setTeamId(value) }}
              >
                {getTeamSelectOptions().map(option => (
                  <Option value={option.id}>{option.name}</Option>
                ))}
              </MultipleSelect>
            </MaxMinWrapper>
          </div>
          <div>
            <MaxMinWrapper className="assist-plan-container">
              <h6>City</h6>
              <GoogleAutoComplete
                id="cities"
                types={["(cities)"]}
                name="cities"
                // formValue={cityFormValue[`city-${index}`]}
                //onChange={(e) => { }}
                onChange={(e) => { setCity(e.target.value) }}
                location={(location) => {
                  const selectedCity = {
                    city: get(location, "locality.long_name"),
                    state: get(location, "administrative_area_level_1.long_name"),
                  }
                  setCity(selectedCity.city)
                }}
                value={city}
                placeholder=""
                styles={{
                  height: "49px",
                  borderRadius: "0px",
                  background: "white",
                  fontSize: "16px",
                  marginTop: "10px",
                }}
                restrict={{
                  lat: 37.09024,
                  lng: -95.712891,
                  south_west: {
                    lat: 25.82,
                    lng: -124.39,
                  },
                  north_east: {
                    lat: 49.38,
                    lng: -66.94,
                  },
                  address: "United States",
                  country: {
                    long_name: "United States",
                    short_name: "US",
                    types: [
                      "country",
                      "political",
                    ],
                  },
                }}
              />
            </MaxMinWrapper>
          </div>
        </div>
      </Header>
      <ButtonWrap>
        <div style={{ marginLeft: 20 }}>
          <input
            type="checkbox"
            checked={churnedAgentCheck}
            onClick={handleChange}
          />
          <span>
            Show Churned Agents
          </span>
        </div>
        <Button success style={{ padding: 10, marginLeft: 20, marginRight: 20 }} onClick={handleSearch}>
          Search
        </Button>
        <Button danger style={{ padding: 10, marginLeft: 20, marginRight: 20 }} onClick={handleClearFilter}>
          Clear Filters
        </Button>
        <Button success style={{ padding: 10, marginLeft: 20, marginRight: 20 }} onClick={handleDownload}>
          Export Data to CSV
        </Button>
      </ButtonWrap>
      {isFetching ? (
        <h1>Loading..</h1>
      ) : (
        <React.Fragment>
          {data && data.result && data.result.length === 0 ? (
            <div>
              <h3>No results</h3>
            </div>
          ) : (
            <TableWrap>
              <Row isFixed>
                <HighlightWrap isHead>
                  <span>
                    Agent Details
                  </span>
                  <IconWrap
                    onClick={() => { handleSortByRequest(SORT_TYPES.BY_NAME) }}
                    isBeingApplied={sortBy === SORT_TYPES.BY_NAME}
                  >
                    <i className={`fa fa-arrow-${sortDir === ORDER_TYPE.DESC ? "down" : "up"}`}></i>
                  </IconWrap>
                </HighlightWrap>
                <HighlightWrap isHead>
                  <span>
                    Team Name
                  </span>
                </HighlightWrap>
                {/* <HighlightWrap isHead>
                  <span>
                  TC Assigned
                  </span>
                </HighlightWrap> */}
                {!churnedColumnVisible
                  && (
                    <HighlightWrap isHead>
                      <span>
                        Current Plan
                      </span>
                    </HighlightWrap>
                  )
                }
                {churnedColumnVisible
                  && (
                    <HighlightWrap isHead>
                      <span>
                        Churned Date
                      </span>
                      <IconWrap
                        onClick={() => { handleSortByRequest(SORT_TYPES.BY_ENDED_AT) }}
                        isBeingApplied={sortBy === SORT_TYPES.BY_ENDED_AT}
                      >
                        <i className={`fa fa-arrow-${sortDir === ORDER_TYPE.DESC ? "down" : "up"}`}></i>
                      </IconWrap>
                    </HighlightWrap>
                  )
                }
                {churnedColumnVisible
                  && (
                    <GeneralWrap isHead>
                      <span>
                        Subscription Date
                      </span>
                      <IconWrap
                        onClick={() => { handleSortByRequest(SORT_TYPES.BY_CREATED_AT) }}
                        isBeingApplied={sortBy === SORT_TYPES.BY_CREATED_AT}
                      >
                        <i className={`fa fa-arrow-${sortDir === ORDER_TYPE.DESC ? "down" : "up"}`}></i>
                      </IconWrap>
                    </GeneralWrap>
                  )
                }
                <GeneralWrap isHead>
                  <span>
                    Previous Brokerage
                  </span>
                </GeneralWrap>
                <GeneralWrap isHead>
                  <span>
                    Past Volume (Last 12 Months)
                  </span>
                </GeneralWrap>
                <GeneralWrap isHead>
                  <span>
                    Primary Sales Rep Split
                  </span>
                </GeneralWrap>
                <GeneralWrap isHead>
                  <span>
                    Secondary Sales Rep Split
                  </span>
                </GeneralWrap>
                <GeneralWrap isHead>
                  <span>
                    Agent Type
                  </span>
                  <IconWrap
                    onClick={() => { handleSortByRequest(SORT_TYPES.BY_AGENT_TYPE) }}
                    isBeingApplied={sortBy === SORT_TYPES.BY_AGENT_TYPE}
                  >
                    <i className={`fa fa-arrow-${sortDir === ORDER_TYPE.DESC ? "down" : "up"}`}></i>
                  </IconWrap>
                </GeneralWrap>
                {!churnedColumnVisible
                  && (
                    <GeneralWrap isHead>
                      <span>
                        On Radius for
                      </span>
                      <IconWrap
                        onClick={() => { handleSortByRequest(SORT_TYPES.BY_CREATED_AT) }}
                        isBeingApplied={sortBy === SORT_TYPES.BY_CREATED_AT}
                      >
                        <i className={`fa fa-arrow-${sortDir === ORDER_TYPE.DESC ? "down" : "up"}`}></i>
                      </IconWrap>
                    </GeneralWrap>
                  )
                }
                <GeneralWrap isHead>
                  <span>
                    No. of Deals
                  </span>
                  <IconWrap
                    onClick={() => { handleSortByRequest(SORT_TYPES.BY_DEALS_COUNT) }}
                    isBeingApplied={sortBy === SORT_TYPES.BY_DEALS_COUNT}
                  >
                    <i className={`fa fa-arrow-${sortDir === ORDER_TYPE.DESC ? "down" : "up"}`}></i>
                  </IconWrap>
                </GeneralWrap>
                <GeneralWrap isHead>
                  <span>
                    Last Deal Close
                  </span>
                  <IconWrap
                    onClick={() => { handleSortByRequest(SORT_TYPES.BY_LAST_CLOSED_DEALS) }}
                    isBeingApplied={sortBy === SORT_TYPES.BY_LAST_CLOSED_DEALS}
                  >
                    <i className={`fa fa-arrow-${sortDir === ORDER_TYPE.DESC ? "down" : "up"}`}></i>
                  </IconWrap>
                </GeneralWrap>
                <GeneralWrap isHead>
                  <span>
                    Deals per Month
                  </span>
                  <IconWrap
                    onClick={() => { handleSortByRequest(SORT_TYPES.BY_DEALS_PER_MONTH) }}
                    isBeingApplied={sortBy === SORT_TYPES.BY_DEALS_PER_MONTH}
                  >
                    <i className={`fa fa-arrow-${sortDir === ORDER_TYPE.DESC ? "down" : "up"}`}></i>
                  </IconWrap>
                </GeneralWrap>
                <GeneralWrap isHead>
                  <span>
                    Total Price
                  </span>
                  <IconWrap
                    onClick={() => { handleSortByRequest(SORT_TYPES.BY_TOTAL_PRICE) }}
                    isBeingApplied={sortBy === SORT_TYPES.BY_TOTAL_PRICE}
                  >
                    <i className={`fa fa-arrow-${sortDir === ORDER_TYPE.DESC ? "down" : "up"}`}></i>
                  </IconWrap>
                </GeneralWrap>
                <GeneralWrap isHead>
                  <span>
                    Total Gross Commission
                  </span>
                  <IconWrap
                    onClick={() => { handleSortByRequest(SORT_TYPES.BY_TOTAL_GROSS_COMMISION) }}
                    isBeingApplied={sortBy === SORT_TYPES.BY_TOTAL_GROSS_COMMISION}
                  >
                    <i className={`fa fa-arrow-${sortDir === ORDER_TYPE.DESC ? "down" : "up"}`}></i>
                  </IconWrap>
                </GeneralWrap>
                <GeneralWrap isHead>
                  <span>
                    Annual Gross Commission
                  </span>
                  <IconWrap
                    onClick={() => { handleSortByRequest(SORT_TYPES.BY_ANNUAL_GROSS_COMMISION) }}
                    isBeingApplied={sortBy === SORT_TYPES.BY_ANNUAL_GROSS_COMMISION}
                  >
                    <i className={`fa fa-arrow-${sortDir === ORDER_TYPE.DESC ? "down" : "up"}`}></i>
                  </IconWrap>
                </GeneralWrap>
                <GeneralWrap isHead>
                  <span>
                    Total Radius Fee
                  </span>
                  <IconWrap
                    onClick={() => { handleSortByRequest(SORT_TYPES.BY_TOTAL_RADIUS_FEE) }}
                    isBeingApplied={sortBy === SORT_TYPES.BY_TOTAL_RADIUS_FEE}
                  >
                    <i className={`fa fa-arrow-${sortDir === ORDER_TYPE.DESC ? "down" : "up"}`}></i>
                  </IconWrap>
                </GeneralWrap>
                <GeneralWrap isHead>
                  <span>
                    Total In-Contract
                  </span>
                  <IconWrap
                    onClick={() => { handleSortByRequest(SORT_TYPES.BY_TOTAL_INCONTRACT) }}
                    isBeingApplied={sortBy === SORT_TYPES.BY_TOTAL_INCONTRACT}
                  >
                    <i className={`fa fa-arrow-${sortDir === ORDER_TYPE.DESC ? "down" : "up"}`}></i>
                  </IconWrap>
                </GeneralWrap>
                <GeneralWrap isHead>
                  <span>
                    Any Creative Requests
                  </span>
                  <IconWrap
                    onClick={() => { handleSortByRequest(SORT_TYPES.BY_REQUEST) }}
                    isBeingApplied={sortBy === SORT_TYPES.BY_REQUEST}
                  >
                    <i className={`fa fa-arrow-${sortDir === ORDER_TYPE.DESC ? "down" : "up"}`}></i>
                  </IconWrap>
                </GeneralWrap>
                <GeneralWrap isHead>
                  <span>
                    Tax documents
                  </span>
                  {/* <IconWrap
                    onClick={() => { handleSortByRequest(SORT_TYPES.BY_REQUEST) }}
                    isBeingApplied={sortBy === SORT_TYPES.BY_REQUEST}
                  >
                    <i className={`fa fa-arrow-${sortDir === ORDER_TYPE.DESC ? "down" : "up"}`}></i>
                  </IconWrap> */}
                </GeneralWrap>
                <GeneralWrap isHead>
                  <span>
                    Actions
                  </span>
                  {/* <IconWrap
                    onClick={() => { handleSortByRequest(SORT_TYPES.BY_REQUEST) }}
                    isBeingApplied={sortBy === SORT_TYPES.BY_REQUEST}
                  >
                    <i className={`fa fa-arrow-${sortDir === ORDER_TYPE.DESC ? "down" : "up"}`}></i>
                  </IconWrap> */}
                </GeneralWrap>
              </Row>
              <InfiniteScroll
                hasMore={!isSearchActive && !isFetchingMore && data && !data.isDone}
                loadMore={throttle(() => { fetchMore() }, 4000)}
                threshold={250}
              >
                {data && data.result && data.result.map(agent => (
                  <Row key={agent.id}>
                    <HighlightWrap>
                      <div>
                        <Link to={`/broker/dashboard/${agent.id}/subscription`}>
                          {`${agent.firstname || ""} ${agent.lastname || ""}`}
                        </Link>
                        <StarWrap onClick={() => { onToggleStarred(agent.id, agent.starred_for_lmp) }}>
                          <i className={agent.starred_for_lmp ? "fa fa-star" : "fa fa-star-o"}></i>
                        </StarWrap>
                        <PlusWrap>
                          <i className={agent.is_assist_plus_user ? "fa fa-plus" : ""}></i>
                        </PlusWrap>
                      </div>
                      <h5>
                        {agent.email}
                      </h5>

                      <YearEndWrapContainer>
                        <YearEndWrapButton
                          onClick={() => {
                            setShowYearEndWrapForAgent(agent.id)
                            getAgentYearEndWrap({
                              agentId: agent.id,
                            })
                          }}
                          disabled={isFetchingAgentYearEndWrap}
                        >
                          Fetch year end wrap
                        </YearEndWrapButton>
                        {agent.id === showYearEndWrapForAgent ? (
                          <>
                            {isFetchingAgentYearEndWrap ? (
                              <YearEndWrapStats>Loading...</YearEndWrapStats>
                            ) : (
                              <>
                                {agentYearEndWrapResponse?.data?.length > 0 ? (
                                  <YearEndWrapStats>
                                    {agentYearEndWrapResponse.data.map((item, index) => (
                                      <div>
                                        {`${index + 1} - ${item.title_prefix || ""} ${item.formatted_value} ${
                                          item.title_suffix || ""
                                        }`}
                                      </div>
                                    ))}
                                  </YearEndWrapStats>
                                ) : (
                                  <YearEndWrapStats>
                                    No data found.
                                  </YearEndWrapStats>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </YearEndWrapContainer>
                    </HighlightWrap>
                    <HighlightWrap>
                      <p>
                        {" "}
                        {agent.team_name || "N/A"}
                      </p>
                    </HighlightWrap>
                    {/* <HighlightWrap>
                      <div>
                        <Select
                          isMulti={false}
                          style={{ margin: "0px 20px" }}
                          options={TCListfinal}
                          defaultValue={agent ? getTCAgentOptInStatus(agent) : { label: "TC Not Assinged", value: 0 }}
                          placeholder="Select"
                          onChange={(selectedItem) => {
                            assignTCtoAgent(agent.id, selectedItem.value)
                          }}
                        />
                      </div>
                    </HighlightWrap> */}
                    {!churnedColumnVisible
                      && (
                        <HighlightWrap>
                          <p>
                            {`${agent.planCategory}`}
                          </p>
                          <h5>{`(${USCurrencyFormat(agent.unitPrice)}/${agent.billing_cycle})`}</h5>
                          <h5>
                            {`Number of Addons: ${agent.addons_count}`}
                          </h5>
                        </HighlightWrap>
                      )
                    }
                    {churnedColumnVisible
                      && (
                        <HighlightWrap>
                          <p4>
                            {`Expired Since: ${parseOnRadiusTime(agent.ended_at)}`}
                          </p4>
                          <hr />
                          <p4>
                            {`Subscription Expired On : ${moment(agent.ended_at * 1000).format("D-MMM, YYYY")}`}
                          </p4>
                        </HighlightWrap>
                      )
                    }
                    {churnedColumnVisible
                      && (
                        <GeneralWrap>
                          <p4>
                            {`${moment(agent.created_at * 1000).format("D-MMM, YYYY")}`}
                          </p4>
                        </GeneralWrap>
                      )
                    }
                    <GeneralWrap>
                      <p>
                        {" "}
                        {agent.past_brokerage_name || "-"}
                      </p>
                    </GeneralWrap>
                    <GeneralWrap>
                      <p>
                        {" "}
                        {USCurrencyFormat(agent.past_volume) || "-"}
                      </p>
                    </GeneralWrap>
                    <GeneralWrap>
                      <p>
                        {" "}
                        {agent.primary_sale_representative_split_percentage ? `${agent.primary_sale_representative_split_percentage} %` : "-"}
                      </p>
                    </GeneralWrap>
                    <GeneralWrap>
                      <p>
                        {" "}
                        {agent.secondary_sale_representative_split_percentage ? `${agent.secondary_sale_representative_split_percentage} %` : "-"}
                      </p>
                    </GeneralWrap>
                    <GeneralWrap>
                      {mapAgentType(agent.agent_type)}
                    </GeneralWrap>
                    {!churnedColumnVisible
                      && (
                        <GeneralWrap>
                          <p>
                            {parseOnRadiusTime(agent.created_at)}
                          </p>
                          <h5>
                            {`${moment(agent.created_at * 1000).format("D-MMM, YYYY")}`}
                          </h5>
                        </GeneralWrap>
                      )
                    }
                    <GeneralWrap>
                      {agent.deals}
                    </GeneralWrap>
                    <GeneralWrap>
                      {agent.last_closed_deal ? `${moment(agent.last_closed_deal * 1000).fromNow()}` : "N/a"}
                    </GeneralWrap>
                    <GeneralWrap>
                      {agent.deals_per_month}
                    </GeneralWrap>
                    <GeneralWrap>
                      {USCurrencyFormat(agent.total_price)}
                    </GeneralWrap>
                    <GeneralWrap>
                      {USCurrencyFormat(agent.total_gross_commission)}
                    </GeneralWrap>
                    <GeneralWrap>
                      {USCurrencyFormat(agent.annual_gross_commission)}
                    </GeneralWrap>
                    <GeneralWrap>
                      {USCurrencyFormat(agent.total_radius_fees)}
                    </GeneralWrap>
                    <GeneralWrap>
                      {agent.total_in_contracts}
                    </GeneralWrap>
                    <GeneralWrap>
                      {agent.requested_product ? "Yes" : "No"}
                    </GeneralWrap>
                    <GeneralWrap>
                      {agent.wd_url ? <>
                        <a href={agent.wd_url} target="_blank">Link</a>
                        <div>
                          Uploaded on
                          {" "}
                          {new Date(agent.wd_updated).toLocaleDateString()}
                        </div>
                      </> : "-"}
                    </GeneralWrap>
                    <GeneralWrap>
                      <Dropdown
                        overlay={menu(agent)}
                        trigger={["click"]}
                      >
                        <ActionsWrap>
                          <MoreOutlined />
                        </ActionsWrap>
                      </Dropdown>
                    </GeneralWrap>
                  </Row>
                ))}
              </InfiniteScroll>
              {(!isSearchActive && data && !data.isDone) && (
                <h4>Loading more..</h4>
              )}
            </TableWrap>
          )}
        </React.Fragment>
      )}
      <PaginationWrap style={{ display: "none" }}>
        <Button onClick={() => { }} id="load-more-btn">
          Load More
        </Button>
      </PaginationWrap>
      <style>
        {`
          .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
            background-color: #f0f8ff !important;
            color: #4a90e2;
          }
          .Selectable .DayPicker-Day {
            border-radius: 0 !important;
          }
          .Selectable .DayPicker-Day--start {
            border-top-left-radius: 50% !important;
            border-bottom-left-radius: 50% !important;
          }
          .Selectable .DayPicker-Day--end {
            border-top-right-radius: 50% !important;
            border-bottom-right-radius: 50% !important;
          }

        `}
      </style>
    </Wrap>
  )
}

export default teamContainer(agentSearchContainer(container(Roster)))
