import React from "react"
import root from "window-or-global"
import { notification } from "antd"
import subscriptionContainer from "container/BrokerDashboard/subscriptionContainer"

function LoadScript(url, callback) {
  const script = document.createElement("script")
  script.type = "text/javascript"

  if (script.readyState) {
    //IE
    // eslint-disable-next-line func-names
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null
        callback()
      }
    }
  } else {
    //Others
    // eslint-disable-next-line func-names
    script.onload = function () {
      callback()
    }
  }

  script.src = url
  document.getElementsByTagName("head")[0].appendChild(script)
}

class PubnubForDocusign extends React.Component {
  componentDidMount() {
    this.loadScript()
  }

  loadScript = () => {
    const {
      docusignAgentId: currentAgentId,
      fetchICAListings,
      showUpdateLiveDot,
    } = this.props

    console.log("propsss", this.props)

    const docusignListingChannel = `docusign_ica_${currentAgentId}`
    console.log("docusignListingChannel", docusignListingChannel)
    LoadScript("https://cdn.pubnub.com/sdk/javascript/pubnub.4.29.8.min.js", () => {

      const PROD_PUB_KEY = process.env.PUBLISH_KEY || ""
      const PROD_SUB_KEY = process.env.SUBSCRIBE_KEY || ""

      console.log("PROD_PUB_KEY", PROD_PUB_KEY)
      console.log("PROD_SUB_KEY", PROD_SUB_KEY)

      //|| "pub-c-37fa6662-4400-4062-83d4-27ae1b0bec02" staging pub
      //"sub-c-3ef98a2e-3df8-11eb-a83f-7e8713e36938" staging sub

      const pubkey = "pub-c-32ccac5c-e28d-4c83-8b78-ce45c47dd495"
      const subkey = "sub-c-cfa64f6e-4909-11eb-9d3f-7e8713e36938"

      root.pubNubListingDocusign = new root.PubNub({
        publishKey: `${pubkey}`,
        subscribeKey: `${subkey}`,
        uuid: currentAgentId,
      })

      root.pubNubListingDocusign.subscribe({
        channels: [docusignListingChannel],
      })

      console.log("agentIdagentIdagentIdagentId currentAgentId", currentAgentId)

      root.pubNubListingDocusign.addListener({
        message: (data) => {
          console.log("Listening to..", docusignListingChannel, data)

          try {
            const { message, channel } = data
            console.log("check here->", data)
            const { event_type } = message
            console.log(message)
            console.log(event_type)

            if (data && message && message.length > 0) {
              showUpdateLiveDot(false)
              // notification.info({
              //   message: "ICA Status Updated",
              //   description:
              //     "The status of the ICA has been updated, kindly check.",
              // })
              fetchICAListings({
                agentId: currentAgentId,
              })
            }
          } catch (e) {
            console.log("exception", e)
          }
        },
      })
    })
  }

  componentWillUnmount() {
    const { docusignAgentId } = this.props
    const docusignListingChannel = `docusign_ica_${docusignAgentId}`
    if (root.pubNubListingDocusign) {
      root.pubNubListingDocusign.unsubscribe({
        channels: [docusignListingChannel],
      })
    }
  }

  render() {
    return null
  }
}

export default subscriptionContainer(PubnubForDocusign)
