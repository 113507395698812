import styled from "styled-components"
// import { ONBOARDING_BANNER_HEIGHT } from "@consts"
// import { ATC, INTER, MONTSERRAT } from "@fonts"
import { Input } from 'antd';
// import {
//   CardCvcElement,
// } from "react-stripe-elements"

export const CreditFormWrap = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 20px;
align-self: stretch;
width:100%;
`
export const CreditPlanButtonWrap = styled.div`
display: flex;
justify-content: flex-end;
align-items: center;
gap: 12px;
align-self: stretch;
`

export const CreditCancelButton = styled.button`
display: flex;
padding: 10px 16px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 8px;
border: 1px solid var(--Neutral-200, #E5E7EB);
background: var(--Generic-White, #FFF);
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04), 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
p {
color: var(--Neutral-700, #374151);
text-align: center;
/* Label/Medium/Medium */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 16px;
margin: 0px
}
`

export const CreditProceedButton = styled.button`
display: flex;
cursor: pointer;
padding: 10px 16px;
justify-content: center;
align-items: center;
gap: 6px;
border-radius: 8px;
border: 1px solid var(--primary-500-main, #6366F1);
background: var(--primary-500-main, #6366F1);
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04), 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
${props => props.width && `
  width: 100%
`}
p {
color: var(--Generic-White, #FFF);
text-align: justify;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 16px; 
margin: 0px
}
`

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  flex-direction: column;
`

export const CardLabel = styled.div`
align-self: stretch;
color: var(--Neutral-900, #111827);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 16px; /* 114.286% */
`

export const HorizontalBar = styled.div`
  width: 100%;
  height: 1px;
  background-color: #EFEFF4;
  ${props => props.marginTop && `
    margin-top: 16px;
 `}
`

export const CommonText = styled.div`
color: ${props => props.color ? props.color : "#6B7280"}
align-self: stretch;
font-family: Inter;
font-size: ${props => props.fontSize ? props.fontSize : "14px"}
font-style: normal;
font-weight: ${props => props.weight ? props.weight : 400}
${props => props.lineHeigth && `
    line-height: 24px; 
`}
${props => props.marginLeft && `
   margin-left: auto;
`}
`

export const CancelButtom = styled.button`
${props => props.width && `
    width: 50%
`}
display: flex;
padding: 10px 16px;
justify-content: center;
align-items: center;
gap: 6px;
border-radius: 8px;
border: 1px solid var(--Destructive-200, #FECACA);
background: var(--Generic-White, #FFF);
box-shadow: 0px 2px 6px 0px rgba(16, 24, 40, 0.06);
p {
color: #EF4444
text-align: justify;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 16px; 
margin: 0px
}
`

export const ProceedButtom = styled.button`
${props => props.width && `
    width: 50%
`}
cursor: pointer;
display: flex;
padding: 10px 16px;
justify-content: center;
align-items: center;
gap: 6px;
border-radius: 8px;
border: 1px solid var(--primary-500-main, #6366F1);
background: var(--primary-500-main, #6366F1);
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04), 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
p {
color: var(--Generic-White, #FFF);
text-align: justify;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 16px; 
margin: 0px
}
`

export const ACHInputDiv = styled(Input)`
display: flex;
height: 40px;
padding: 8px 12px;
align-items: center;
align-self: stretch;
border-radius: 8px;
border: 1px solid var(--Neutral-200, #E5E7EB);
background: var(--Generic-White, #FFF);
`

export const ACHWrapDiv = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 5px;
align-self: stretch;
`

