import React, { useEffect, useState } from "react";
import {
  Input,
  Upload,
  Button,
  Dropdown,
  Tag,
  message,
  Tabs,
  Menu,
  Modal,
} from "antd";
import {
  ExclamationCircleOutlined,
  DeleteOutlined,
  CloseOutlined,
  HomeOutlined,
  SearchOutlined,
  FileTextOutlined,
  MessageOutlined,
  UserOutlined,
  BellOutlined,
  DownOutlined
} from "@ant-design/icons";
import { ChromePicker } from "react-color";
import "./styles.css";
import ConsumerApp from "container/ConsumerApp/index";
const { TextArea } = Input;
const baseUrl = process.env.REACT_APP_API_BASE_URL;
const token = process.env.REACT_APP_SECRET_ID;

const CustomiseApp = ({
  updateAppCustomisationResponse,
  updateAppCustomisations,
  fetchAppCustomisations,
  fetchAppCustomisationResponse,
  selectedAppObject,
  saveWaitlistStatus,
  setOpenCustomiseApp,
  statusMapping,
}) => {
  const [primaryColor, setPrimaryColor] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("");
  const [images, setImages] = useState({
    bg: { imageLoading: false, image: null, imageUrl: null },
    splash: { imageLoading: false, image: null, imageUrl: null },
    home: { imageLoading: false, image: null, imageUrl: null },
    logo: { imageLoading: false, image: null, imageUrl: null },
  });
  const [hoveredImage, setHoveredImage] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [appIconIos, setAppIconIos] = useState({ url: null, loading: false });
  const [appIconAndroid, setAppIconAndroid] = useState({
    url: null,
    loading: false,
  });
  const [appPreviewIos, setAppPreviewIos] = useState({
    url: null,
    loading: false,
  });
  const [appPreviewAndroid, setAppPreviewAndroid] = useState({
    url: null,
    loading: false,
  });

  const [featureGraphicIos, setFeatureGraphicIos] = useState([]);
  const [featureGraphicAndroid, setFeatureGraphicAndroid] = useState([]);
  const [headerText, setHeaderText] = useState("");
  const [appName, setAppName] = useState("");
  const [description, setDescription] = useState("");
  const [subtitleIos, setSubtitleIos] = useState("");
  const [subtitleAndroid, setSubtitleAndroid] = useState("");
  const [promotionalTextIos, setPromotionalTextIos] = useState("");
  const [promotionalTextAndroid, setPromotionalTextAndroid] = useState("");
  const [whatsnewAndroid, setWhatsNewAndroid] = useState("");
  const [whatsnewIos, setWhatsNewIos] = useState("");
  const [url, setUrl] = useState("");
  const [screenshots, setScreenshots] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [showPrimaryPicker, setShowPrimaryPicker] = useState(false);
  const [showBackgroundPicker, setShowBackgroundPicker] = useState(false);
  const [previewSplashScreen, setPreviewSplashScreen] = useState(false);
  const [previewWelcomeScreen, setPreviewWelcomeScreen] = useState(true);
  const [previewHomeScreen, setPreviewHomeScreen] = useState(false);
  const [keywordInputValue, setKeywordInputValue] = useState("");
  const [featureGraphicUpload, setFeatureGraphicUpload] = useState(false);
  const [confirmDisabled, setConfirmDisabled] = useState(true);
  const [reason, setReason] = useState("");
  const [selectedAppStatus, setSelectedAppStatus] = useState("");
  const [selectedAppStatusData, setSelectedAppStatusData] = useState(
    selectedAppObject?.status || ""
  );
  useEffect(() => {
    if (fetchAppCustomisationResponse?.data) {
      setPrimaryColor(
        fetchAppCustomisationResponse?.data?.primary_color
          ? fetchAppCustomisationResponse.data.primary_color
          : ""
      );
      setBackgroundColor(
        fetchAppCustomisationResponse?.data?.background_color
          ? fetchAppCustomisationResponse.data.background_color
          : ""
      );
      setSubtitleIos(
        fetchAppCustomisationResponse?.data?.ios_settings?.subtitle
      );
      setSubtitleAndroid(
        fetchAppCustomisationResponse?.data?.android_settings?.subtitle
      );
      setWhatsNewIos(
        fetchAppCustomisationResponse?.data?.ios_settings?.whats_new_text
      );
      setWhatsNewAndroid(
        fetchAppCustomisationResponse?.data?.android_settings?.whats_new_text
      );
      setPromotionalTextIos(
        fetchAppCustomisationResponse?.data?.ios_settings?.promotional_text
      );
      setPromotionalTextAndroid(
        fetchAppCustomisationResponse?.data?.android_settings?.promotional_text
      );

      setDescription(fetchAppCustomisationResponse?.data?.description);
      setAppName(fetchAppCustomisationResponse?.data?.app_name);
      setHeaderText(fetchAppCustomisationResponse?.data?.header_text);
      setKeywords(
        fetchAppCustomisationResponse?.data?.keywords?.length
          ? fetchAppCustomisationResponse?.data?.keywords
          : []
      );
      setWhatsNewAndroid(
        fetchAppCustomisationResponse?.data?.android_settings?.whats_new_text
      );
      setWhatsNewIos(
        fetchAppCustomisationResponse?.data?.ios_settings?.whats_new_text
      );

      setFeatureGraphicAndroid(
        fetchAppCustomisationResponse?.data?.android_settings?.feature_graphic
      );
      setFeatureGraphicIos(
        fetchAppCustomisationResponse?.data?.ios_settings?.feature_graphic
      );
      setScreenshots(fetchAppCustomisationResponse?.data?.screenshots);
      setUrl(fetchAppCustomisationResponse?.data?.support_url);
      setAppPreviewIos({
        url: fetchAppCustomisationResponse?.data?.ios_settings?.app_preview,
        loading: false,
      });
      setAppPreviewAndroid({
        url: fetchAppCustomisationResponse?.data?.android_settings?.app_preview,
        loading: false,
      });
      setAppIconIos({
        url: fetchAppCustomisationResponse?.data?.ios_settings?.app_icon,
        loading: false,
      });
      setAppIconAndroid({
        url: fetchAppCustomisationResponse?.data?.android_settings?.app_icon,
        loading: false,
      });
      setPromotionalTextIos(
        fetchAppCustomisationResponse?.data?.ios_settings?.promotional_text
      );
      setPromotionalTextAndroid(
        fetchAppCustomisationResponse?.data?.android_settings?.promotional_text
      );
      setImages({
        bg: {
          imageLoading: false,
          image: null,
          imageUrl: fetchAppCustomisationResponse?.data?.background_image,
        },
        splash: {
          imageLoading: false,
          image: null,
          imageUrl: fetchAppCustomisationResponse.data.splash_screen_logo,
        },
        home: {
          imageLoading: false,
          image: null,
          imageUrl: fetchAppCustomisationResponse.data.home_screen_logo,
        },
        logo: {
          imageLoading: false,
          image: null,
          imageUrl: fetchAppCustomisationResponse.data.app_logo,
        },
      });
    }
  }, [fetchAppCustomisationResponse]);

  useEffect(() => {
    fetchAppCustomisations(selectedAppObject.app_id);
  }, []);

  const handlePrimaryColorChange = (color) => {
    setPrimaryColor(color.hex);
  };

  const handleBackgroundColorChange = (color) => {
    setBackgroundColor(color.hex);
  };

  const handleKeywordInput = (e) => {
    if (e.key === "Enter" && e.target.value) {
      setKeywords([...keywords, e.target.value]);
      e.target.value = "";
      setKeywordInputValue("");
    }
  };

  const handleChange = (e) => {
    setKeywordInputValue(e.target.value);
  };

  const removeKeyword = (index) => {
    const newKeywords = keywords.filter((_, i) => i !== index);
    setKeywords(newKeywords);
  };

  const { TabPane } = Tabs;
  const tabChange = (key) => {
    // this.setState({
    //   activeKey: key,
    // })
  };

  const handleAppIconAndroidUpload = async (file) => {
    if (!file) return;

    setAppIconAndroid({ ...appIconIos, loading: true });

    if (!isValidFileType(file)) {
      message.error("Invalid file type. Only JPEG and PNG files are allowed.");
      setAppIconAndroid({ ...appIconIos, loading: false });
      return;
    }

    const maxSizeMB = 1;
    if (file.size > maxSizeMB * 1024 * 1024) {
      message.error("File size exceeds 1 MB.");
      setAppIconAndroid({ ...appIconIos, loading: false });
      return;
    }

    const imageUrl = await uploadImage(file, token);

    setAppIconAndroid({ url: imageUrl || null, loading: false });
  };

  const handleRemoveFeatureGraphicIos = (index) => {
    setFeatureGraphicIos((prevGraphics) =>
      prevGraphics.filter((_, i) => i !== index)
    );
  };
  const handleRemoveFeatureGraphicAndroid = (index) => {
    setFeatureGraphicAndroid((prevGraphics) =>
      prevGraphics.filter((_, i) => i !== index)
    );
  };

  const handleAppIconIosUpload = async (file) => {
    if (!file) return;

    setAppIconIos({ ...appIconIos, loading: true });

    if (!isValidFileType(file)) {
      message.error("Invalid file type. Only JPEG and PNG files are allowed.");
      setAppIconIos({ ...appIconIos, loading: false });
      return;
    }

    const maxSizeMB = 1;
    if (file.size > maxSizeMB * 1024 * 1024) {
      message.error("File size exceeds 1 MB.");
      setAppIconIos({ ...appIconIos, loading: false });
      return;
    }

    const imageUrl = await uploadImage(file, token);

    setAppIconIos({ url: imageUrl || null, loading: false });
  };

  const isValidFileType = (file) => {
    const validTypes = ["image/jpeg", "image/png"];
    return validTypes.includes(file.type);
  };

  const handleFeatureGraphicAndroidUpload = async (file) => {
    if (!file) return;
    setFeatureGraphicUpload(true);
    // if (!isValidFileType(file)) {
    //   message.error("Invalid file type. Only JPEG and PNG files are allowed.");
    //   return;
    // }
    // if (isValidFileType(file)) {
    const imageUrl = await uploadImage(file, token);

    if (imageUrl) {
      setFeatureGraphicUpload(false);
      setFeatureGraphicAndroid((prevGraphics) => {
        if (!Array.isArray(prevGraphics)) {
          return [imageUrl];
        }
        return [...prevGraphics, imageUrl];
      });
    }
    // }
  };

  const statusMenu = (
    <Menu>
      {Object.keys(statusMapping).map((statusKey) => (
        <Menu.Item
          key={statusKey}
          onClick={() => handleStatusChange(statusKey)}
        >
          {statusMapping[statusKey]}
        </Menu.Item>
      ))}
    </Menu>
  );

  const handleFeatureGraphicIosUpload = async (file) => {
    if (!file) return;
    setFeatureGraphicUpload(true);
    // if (!isValidFileType(file)) {
    //   message.error("Invalid file type. Only JPEG and PNG files are allowed.");
    //   return;
    // }
    // if (isValidFileType(file)) {
    const imageUrl = await uploadImage(file, token);
    if (imageUrl) {
      setFeatureGraphicUpload(false);
      setFeatureGraphicIos((prevGraphics) => {
        if (!Array.isArray(prevGraphics)) {
          return [imageUrl];
        }
        return [...prevGraphics, imageUrl];
      });
    }
    // }
  };

  const handleAppPreviewsAndroidUpload = async (file) => {
    if (!file) return;
    setAppPreviewAndroid({ ...appPreviewAndroid, loading: true });
    const validTypes = ["video/mp4"];
    if (!validTypes.includes(file.type)) {
      message.error("Invalid file type. Only MP4 files are allowed.");
      setAppPreviewAndroid({ ...appPreviewAndroid, loading: false });
      return;
    }
    const maxSizeMB = 50;
    if (file.size > maxSizeMB * 1024 * 1024) {
      message.error("File size exceeds 50 MB.");
      setAppPreviewAndroid({ ...appPreviewAndroid, loading: false });
      return;
    }
    const videoUrl = await uploadImage(file, token);
    setAppPreviewAndroid({ url: videoUrl || null, loading: false });
  };

  const handleAppPreviewsIosUpload = async (file) => {
    if (!file) return;
    setAppPreviewIos({ ...appPreviewIos, loading: true });
    const validTypes = ["video/mp4"];
    if (!validTypes.includes(file.type)) {
      message.error("Invalid file type. Only MP4 files are allowed.");
      setAppPreviewIos({ ...appPreviewIos, loading: false });
      return;
    }
    const maxSizeMB = 50;
    if (file.size > maxSizeMB * 1024 * 1024) {
      message.error("File size exceeds 50 MB.");
      setAppPreviewIos({ ...appPreviewIos, loading: false });
      return;
    }
    const videoUrl = await uploadImage(file, token);
    setAppPreviewIos({ url: videoUrl || null, loading: false });
  };

  const uploadImage = async (file, token) => {
    if (!file) return null;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("teamName", selectedAppObject.team_name);

    try {
      let response = await fetch(
        `${baseUrl}/v1.0/consumer-app/uploads/app-assets`,
        {
          method: "POST",
          headers: {
            "internal-id": token,
            "X-User-Agent": "agent-php",
          },
          body: formData,
        }
      );

      const data = await response.json();
      return data.fileUrl;
    } catch (error) {
      return null;
    }
  };
  const handleImageUpload = async (type, file) => {
    if (!file) return;

    // Initialize loading state
    setImages((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        imageLoading: true,
      },
    }));

    // Validate file type
    if (!isValidFileType(file)) {
      message.error("Invalid file type. Only JPEG and PNG files are allowed.");
      setImages((prevState) => ({
        ...prevState,
        [type]: {
          ...prevState[type],
          imageLoading: false,
        },
      }));
      return;
    }
    // const maxSizeMB = 1;
    // if (file.size > maxSizeMB * 1024 * 1024) {
    //   message.error("File size exceeds 1 MB.");
    //   setImages((prevState) => ({
    //     ...prevState,
    //     [type]: {
    //       ...prevState[type],
    //       imageLoading: false,
    //     },
    //   }));
    //   return;
    // }
    const objectUrl = URL.createObjectURL(file);
    setImages((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        image: { file, url: objectUrl },
      },
    }));

    const imageUrl = await uploadImage(file, token);

    setImages((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        imageLoading: false,
        imageUrl: imageUrl || prevState[type].imageUrl,
      },
    }));
  };

  const updateEntries = () => {
    // if (!isValidated()) return;
    const payload = {
      id: selectedAppObject.app_id,
      payload: {
        primary_color: primaryColor || null,
        background_color: backgroundColor || null,
        background_image: images.bg?.imageUrl || null,
        splash_screen_logo: images.splash?.imageUrl || null,
        home_screen_logo: images.home?.imageUrl || null,
        app_logo: images.logo?.imageUrl || null,
        app_name: appName || null,
        header_text: headerText || null,
        screenshots: screenshots || null,
        description: description || null,
        keywords: keywords || null,
        support_url: url || null,
        ios_platform_settings: {
          app_icon: appIconIos?.url || null,
          app_preview: appPreviewIos?.url || null,
          subtitle: subtitleIos || null,
          feature_graphic: featureGraphicIos || null,
          promotional_text: promotionalTextIos || null,
          whats_new_text: whatsnewIos || null,
        },
        android_platform_settings: {
          app_icon: appIconAndroid?.url || null,
          app_preview: appPreviewAndroid?.url || null,
          promotional_text: promotionalTextAndroid || null,
          feature_graphic: featureGraphicAndroid || null,
          subtitle: subtitleAndroid || null,
          whats_new_text: whatsnewAndroid || null,
        },
      },
    };

    updateAppCustomisations({
      payload,
      callback: () => {
        setOpenCustomiseApp(false);
      },
    });
  };

  const handleImageRemove = (type) => {
    setImages({
      ...images,
      [type]: { imageLoading: false, image: null, imageUrl: null },
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setConfirmDisabled(true);
    setReason("");
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setConfirmDisabled(value.trim() === "");
    setReason(value);
  };

  const handleOk = () => {
    const payload = {
      status: selectedAppStatus,
      reason: reason,
      id: selectedAppObject.app_id,
    };

    saveWaitlistStatus({
      payload,
      callback: () => {
        setReason("");
        setIsModalVisible(false);
        setConfirmDisabled(true);
        setSelectedAppStatusData(selectedAppStatus);
      },
    });
  };

  const isValidated = () => {
    if (
      images?.bg?.imageUrl &&
      images?.splash?.imageUrl &&
      images?.home?.imageUrl &&
      images?.logo?.imageUrl &&
      appIconIos?.url &&
      appIconAndroid?.url &&
      primaryColor &&
      backgroundColor &&
      headerText &&
      appName &&
      description &&
      subtitleIos &&
      subtitleAndroid &&
      promotionalTextIos &&
      promotionalTextAndroid &&
      whatsnewAndroid &&
      whatsnewIos &&
      screenshots?.length > 0 &&
      keywords?.length > 0 &&
      url
    ) {
      return true;
    } else {
      return false;
    }
  };
  const goBack = () => {
    setOpenCustomiseApp(false);
  };

  const handleStatusChange = (value) => {
    setIsModalVisible(true);

    setSelectedAppStatus(value);
  };

  return (
    <>
         <div className="save-btn-wrapper">
          <a className="back-btn" onClick={goBack}>
            {" "}
            {"< "}Back to waitlist
          </a>
          <Button
            type="primary"
            // disabled={!isValidated()}
            onClick={updateEntries}
            className="save-button"
          >
            Save Customisation
          </Button>
        </div>
      <div className="container">
      <h2 style={{fontSize: '24px',
    fontWeight: 600}}>Customisation</h2>

        <div className="waitlist-data-wrapper">
          <h4 style={{ fontSize: "18px", color: "#111827" }}>
            Request Details:
          </h4>
          <div className="waitlist-team-data">
            <div className="details-waitlist">
              Agent Name:{" "}
              {selectedAppObject.agent_name
                ? selectedAppObject.agent_name
                : "-"}{" "}
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;|
            </div>
            <div className="details-waitlist">
              Request Date:{" "}
              {selectedAppObject.created_at
                ? selectedAppObject.created_at
                : "-"}{" "}
              &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;|
            </div>
            <div className="details-waitlist">
              Company:{" "}
              {selectedAppObject.team_name ? selectedAppObject.team_name : "-"}{" "}
              &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;|
            </div>
            <div className="details-waitlist" style={{ display: "flex" }}>
              Status:{" "} &nbsp; &nbsp;
              <Dropdown overlay={statusMenu} trigger={["click"]}>
                <Button style={{ border: "none", marginTop: '-5px', color: 'blue' }}>
                  {selectedAppStatusData
                    ? statusMapping[selectedAppStatusData]
                    : "Select Status"}
                  <DownOutlined/>
                </Button>
              </Dropdown>
            </div>
          </div>
        </div>

        <div style={{ display: "flex" }}>
          <div className="left-section">
            <h2 className="heading">Common Parameters</h2>
            <div className="color-picker">
              <label className="label-color">Primary Color: &nbsp;</label>
              <Input
                style={{ fontSize: "14px" }}
                addonBefore={
                  <div
                    style={{
                      width: "20px",
                      height: "20px",
                      backgroundColor: primaryColor,
                    }}
                  />
                }
                placeholder="Enter the hex or pick color"
                value={primaryColor}
                onChange={(e) => setPrimaryColor(e.target.value)}
                onClick={() => setShowPrimaryPicker(!showPrimaryPicker)}
                className="color-picker-input"
              />
              {showPrimaryPicker && (
                <div className="chrome-picker-wrapper">
                  <button
                    className="close-btn-picker"
                    onClick={() => setShowPrimaryPicker(false)}
                  >
                    <CloseOutlined style={{ fontSize: "12px" }} />
                  </button>
                  <ChromePicker
                    color={primaryColor}
                    onChange={handlePrimaryColorChange}
                  />
                </div>
              )}
              <div className="stroke"></div>

              <label className="label-color">Background Color:</label>
              <Input
                addonBefore={
                  <div
                    style={{
                      width: "20px",
                      height: "20px",
                      backgroundColor: backgroundColor,
                    }}
                  />
                }
                placeholder="Enter the hex or pick color"
                value={backgroundColor}
                style={{ fontSize: "14px" }}
                onChange={(e) => setBackgroundColor(e.target.value)}
                onClick={() => setShowBackgroundPicker(!showBackgroundPicker)}
                className="color-picker-input"
              />
              {showBackgroundPicker && (
                <div className="chrome-picker-wrapper">
                  <button
                    className="close-btn-picker"
                    onClick={() => setShowBackgroundPicker(false)}
                  >
                    <CloseOutlined style={{ fontSize: "12px" }} />
                  </button>
                  <ChromePicker
                    color={backgroundColor}
                    onChange={handleBackgroundColorChange}
                  />
                </div>
              )}
            </div>
            <div className="stroke"></div>

            {/* Image Upload Fields */}
            <div className="upload-wrapper">
              <label className="label-color">
                Background Image (Welcome Screen):&nbsp;
              </label>
              <div className="preview-btn-wrapper">
                <Upload
                  beforeUpload={(file) => handleImageUpload("bg", file)}
                  onRemove={() => handleImageRemove("bg")}
                  fileList={
                    images?.bg?.image
                      ? [images.bg.image.file]
                      : images.bg.imageUrl
                      ? [
                          {
                            uid: "-1",
                            name: images.bg.imageUrl,
                            status: "done",
                            url: images.bg.imageUrl,
                          },
                        ]
                      : []
                  }
                  showUploadList={{
                    showRemoveIcon: true,
                    showPreviewIcon: true,
                  }}
                >
                  <Button
                    className="upload-button"
                    disabled={images.bg.imageLoading}
                  >
                    {images.bg.imageLoading ? "Uploading..." : "+ Upload Image"}
                  </Button>
                </Upload>

                {images.bg.imageUrl && (
                  <Button
                    className="preview-button"
                    onClick={() => {
                      setPreviewWelcomeScreen(true);
                      setPreviewSplashScreen(false);
                      setPreviewHomeScreen(false);
                    }}
                    disabled={images.bg.imageLoading}
                  >
                    {images.bg.imageLoading ? (
                      "Loading Preview..."
                    ) : (
                      <span style={{ display: "flex" }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="21"
                          height="20"
                          viewBox="0 0 21 20"
                          fill="none"
                        >
                          <path
                            d="M5.9165 4.16669L14.9906 10L5.9165 15.8334V4.16669Z"
                            stroke="#5A5FF2"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        Preview
                      </span>
                    )}
                  </Button>
                )}
              </div>
              <p className="upload-info">
                <ExclamationCircleOutlined
                  style={{ width: "24px", height: "24px" }}
                />
                Background image must be a PNG or JPEG
              </p>
            </div>

            <div className="stroke"></div>
            <div className="upload-wrapper">
              <label className="label-color">Logo (Splash Screen):&nbsp;</label>
              <div className="preview-btn-wrapper">
                <Upload
                  beforeUpload={(file) => handleImageUpload("splash", file)}
                  onRemove={() => handleImageRemove("splash")}
                  fileList={
                    images.splash.image
                      ? [images.splash.image.file]
                      : images.splash.imageUrl
                      ? [
                          {
                            uid: "-1",
                            name: images.splash.imageUrl,
                            status: "done",
                            url: images.splash.imageUrl,
                          },
                        ]
                      : []
                  }
                  showUploadList={{
                    showRemoveIcon: true,
                    showPreviewIcon: true,
                  }}
                >
                  <Button
                    className="upload-button"
                    disabled={images.splash.imageLoading}
                  >
                    {images.splash.imageLoading
                      ? "Uploading..."
                      : "+ Upload Image"}
                  </Button>
                </Upload>

                {images?.splash?.imageUrl && (
                  <Button
                    className="preview-button"
                    onClick={() => {
                      setPreviewWelcomeScreen(false);
                      setPreviewSplashScreen(true);
                      setPreviewHomeScreen(false);
                    }}
                    disabled={images.splash.imageLoading}
                  >
                    {images.splash.imageLoading ? (
                      "Loading Preview..."
                    ) : (
                      <span style={{ display: "flex" }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="21"
                          height="20"
                          viewBox="0 0 21 20"
                          fill="none"
                        >
                          <path
                            d="M5.9165 4.16669L14.9906 10L5.9165 15.8334V4.16669Z"
                            stroke="#5A5FF2"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        Preview
                      </span>
                    )}
                  </Button>
                )}
              </div>
              <p className="upload-info">
                <ExclamationCircleOutlined
                  style={{ width: "24px", height: "24px" }}
                />
                Logo must be a PNG or JPEG, up to 1 MB, 300 px by 300 px
              </p>
            </div>

            <div className="stroke"></div>

            <div className="upload-wrapper">
              <label className="label-color">Logo (Home Screen):&nbsp;</label>
              <div className="preview-btn-wrapper">
                <Upload
                  beforeUpload={(file) => handleImageUpload("home", file)}
                  onRemove={() => handleImageRemove("home")}
                  fileList={
                    images.home.image
                      ? [images.home.image.file]
                      : images.home.imageUrl
                      ? [
                          {
                            uid: "-1",
                            name: images.home.imageUrl,
                            status: "done",
                            url: images.home.imageUrl,
                          },
                        ]
                      : []
                  }
                  showUploadList={{
                    showRemoveIcon: true,
                    showPreviewIcon: true,
                  }}
                >
                  <Button
                    className="upload-button"
                    disabled={images.home.imageLoading}
                  >
                    {images.home.imageLoading
                      ? "Uploading..."
                      : "+ Upload Image"}
                  </Button>
                </Upload>

                {images?.home?.imageUrl && (
                  <Button
                    className="preview-button"
                    onClick={() => {
                      setPreviewWelcomeScreen(false);
                      setPreviewSplashScreen(false);
                      setPreviewHomeScreen(true);
                    }}
                    disabled={images.home.imageLoading}
                  >
                    {images.home.imageLoading ? (
                      "Loading Preview..."
                    ) : (
                      <span style={{ display: "flex" }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="21"
                          height="20"
                          viewBox="0 0 21 20"
                          fill="none"
                        >
                          <path
                            d="M5.9165 4.16669L14.9906 10L5.9165 15.8334V4.16669Z"
                            stroke="#5A5FF2"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        Preview
                      </span>
                    )}
                  </Button>
                )}
              </div>
              <p className="upload-info">
                <ExclamationCircleOutlined
                  style={{ width: "24px", height: "24px" }}
                />
                Logo must be a PNG or JPEG, up to 1 MB, 150 px by 150 px
              </p>
            </div>

            <div className="stroke"></div>

            <div className="upload-wrapper">
              <label className="label-color">Logo of the app:&nbsp;</label>
              <Upload
                beforeUpload={(file) => handleImageUpload("logo", file)}
                onRemove={() => handleImageRemove("logo")}
                fileList={
                  images.logo.image
                    ? [images.logo.image.file]
                    : images.logo.imageUrl
                    ? [
                        {
                          uid: "-1",
                          name: images.logo.imageUrl,
                          status: "done",
                          url: images.logo.imageUrl,
                        },
                      ]
                    : []
                }
                showUploadList={{
                  showRemoveIcon: true,
                  showPreviewIcon: true,
                }}
              >
                <Button
                  className="upload-button"
                  disabled={images.logo.imageLoading}
                >
                  {images.logo.imageLoading ? "Uploading..." : "+ Upload Image"}
                </Button>
              </Upload>

              <p className="upload-info">
                <ExclamationCircleOutlined
                  style={{ width: "24px", height: "24px" }}
                />
                App icon must be a PNG or JPEG, up to 1 MB, 1024 px by 1024 px
              </p>
            </div>

            <div className="stroke"></div>

            {/* Text Fields */}
            <div className="text-fields">
              <label className="label-color">Header Text:</label>
              <div style={{ position: "relative" }}>
                <Input
                  placeholder="Header Text"
                  maxLength={50}
                  value={headerText}
                  onChange={(e) => setHeaderText(e.target.value)}
                  style={{ paddingRight: "40px", height: "100px !important" }}
                />
                <span
                  style={{
                    position: "absolute",
                    top: "5px",
                    right: "10px",
                    fontSize: "12px",
                    color: "#888",
                  }}
                >
                  {headerText?.length ? headerText?.length : 0}/50
                </span>
              </div>

              <p className="upload-info">
                <ExclamationCircleOutlined
                  style={{ width: "24px", height: "24px" }}
                />
                Maximum of 50 characters allowed
              </p>

              <div className="stroke"></div>

              <label className="label-color">App Name:</label>

              <div style={{ position: "relative" }}>
                <Input
                  placeholder="App Name"
                  maxLength={30}
                  value={appName}
                  onChange={(e) => setAppName(e.target.value)}
                  style={{ paddingRight: "40px" }}
                />
                <span
                  style={{
                    position: "absolute",
                    top: "5px",
                    right: "10px",
                    fontSize: "12px",
                    color: "#888",
                  }}
                >
                  {appName?.length ? appName?.length : 0}/30
                </span>
              </div>
              <p className="upload-info">
                <ExclamationCircleOutlined
                  style={{ width: "24px", height: "24px" }}
                />
                Maximum of 30 characters allowed
              </p>

              <div className="stroke"></div>

              <label className="label-color">Description:</label>
              <div style={{ position: "relative" }}>
                <TextArea
                  placeholder="Description"
                  maxLength={4000}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  rows={4}
                  suffix={`${
                    4000 - description?.length ? description.length : 0
                  } characters left`}
                />
                <span
                  style={{
                    position: "absolute",
                    top: "5px",
                    right: "10px",
                    fontSize: "12px",
                    color: "#888",
                  }}
                >
                  {description?.length ? description?.length : 0}/4000
                </span>
              </div>
              <p className="upload-info">
                <ExclamationCircleOutlined
                  style={{ width: "24px", height: "24px" }}
                />
                Maximum of 4000 characters allowed
              </p>

              <div className="stroke"></div>

              <label className="label-color">Support URL:</label>
              <Input
                placeholder="Support URL"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              />
            </div>

            <div className="stroke"></div>

            <label className="label-color">Screenshots</label>
            <Input
              placeholder="Screenshots"
              value={screenshots}
              onChange={(e) => setScreenshots(e.target.value)}
            />

            <div className="stroke"></div>

            <div className="keywords">
              <label className="label-color">Keywords:</label>
              <Input
                placeholder="Enter a keyword and press Enter"
                onKeyDown={handleKeywordInput}
                className="keyword-input"
                value={keywordInputValue}
                onChange={handleChange}
              />
              <div>
                {keywords?.length > 0 &&
                  keywords.map((keyword, index) => (
                    <Tag
                      key={index}
                      closable
                      onClose={() => removeKeyword(index)}
                    >
                      {keyword}
                    </Tag>
                  ))}
              </div>
              <p className="upload-info">
                <ExclamationCircleOutlined
                  style={{ width: "24px", height: "24px" }}
                />
                Maximum Keywords: '100' Add Multiple Keywords by pressing enter
                key
              </p>
            </div>
            <div className="stroke"></div>

            <h2 className="heading">Operating System Specific Paramters</h2>

            <Tabs defaultActiveKey="1" onChange={tabChange}>
              <TabPane tab="For Apple Devices" key="1">
                <div className="upload-wrapper">
                  <label className="label-color">App Icon:&nbsp;</label>
                  <div className="preview-btn-wrapper">
                    <Upload
                      beforeUpload={(file) => handleAppIconIosUpload(file)}
                      onRemove={() =>
                        setAppIconIos({ url: null, loading: false })
                      }
                      fileList={
                        appIconIos?.url
                          ? [
                              {
                                uid: "1",
                                name: "App Icon",
                                status: "done",
                                url: appIconIos.url,
                              },
                            ]
                          : []
                      }
                      // customRequest={({ file, onSuccess }) => {
                      //   handleAppIconIosUpload(file).then(() => onSuccess());
                      // }}
                      showUploadList={{ showRemoveIcon: true }}
                    >
                      {appIconIos?.loading ? (
                        <Button className="upload-button" disabled>
                          Loading...
                        </Button>
                      ) : (
                        <Button className="upload-button">
                          + Upload Image
                        </Button>
                      )}
                    </Upload>
                  </div>
                  <p className="upload-info">
                    <ExclamationCircleOutlined
                      style={{ width: "24px", height: "24px" }}
                    />
                    App icon must be a JPEG or PNG, up to 1 MB, 1024 px by 1024
                    px; 2048 * 2048 px
                  </p>
                </div>

                <div className="stroke"></div>
                <div className="upload-wrapper">
                  <label className="label-color">
                    Feature Graphic (Optional):&nbsp;
                  </label>
                  <div className="preview-btn-wrapper">
                    <Upload
                      multiple
                      showUploadList={false}
                      beforeUpload={(file) => {
                        handleFeatureGraphicIosUpload(file);
                      }}
                      onRemove={(file) => {
                        setFeatureGraphicIos((prevGraphics) =>
                          prevGraphics.filter((url) => url !== file.url)
                        );
                      }}
                    >
                      <Button
                        className="upload-button"
                        disabled={featureGraphicUpload}
                      >
                        {featureGraphicUpload
                          ? "Uploading ..."
                          : "+ Upload Image"}
                      </Button>
                    </Upload>
                  </div>

                  <div className="feature-graphic-display">
                    {featureGraphicIos && featureGraphicIos.length > 0 ? (
                      featureGraphicIos.map((imageUrl, index) => (
                        <div
                          key={index}
                          style={{
                            position: "relative",
                            marginBottom: "10px",
                            width: "116px",
                            marginRight: "20px",
                          }}
                          onMouseEnter={() => setHoveredImage(index)}
                          onMouseLeave={() => setHoveredImage(null)}
                        >
                          <img
                            className="img-feature"
                            src={imageUrl}
                            alt={`Feature Graphic ${index + 1}`}
                            style={{ width: "100%", height: "auto" }}
                          />
                          {hoveredImage === index && (
                            <DeleteOutlined
                              onClick={() =>
                                handleRemoveFeatureGraphicIos(index)
                              }
                              style={{
                                position: "absolute",
                                top: "5px",
                                right: "5px",
                                color: "#5a5ff2",
                                fontSize: "15px",
                                cursor: "pointer",
                                background: "white",
                                borderRadius: "50%",
                                padding: "2px",
                              }}
                            />
                          )}
                        </div>
                      ))
                    ) : (
                      <p>
                        {featureGraphicUpload
                          ? "Loading..."
                          : "No feature graphics available"}
                      </p>
                    )}
                  </div>

                  <p className="upload-info">
                    <ExclamationCircleOutlined
                      style={{ width: "24px", height: "24px" }}
                    />
                    Feature graphic must be a PNG or JPEG, up to 15 MB and 1,024
                    px by 500 px
                  </p>
                </div>

                <div className="stroke"></div>

                <div className="upload-wrapper">
                  <label className="label-color">
                    App Previews (optional):&nbsp;
                  </label>
                  <div className="preview-btn-wrapper">
                    <Upload
                      beforeUpload={(file) => handleAppPreviewsIosUpload(file)}
                      onRemove={() =>
                        setAppPreviewIos({ url: null, loading: false })
                      }
                      fileList={
                        appPreviewIos.url && !appPreviewIos?.loading
                          ? [
                              {
                                uid: "1",
                                name: "App Preview",
                                status: "done",
                                url: appPreviewIos.url,
                              },
                            ]
                          : []
                      }
                      customRequest={({ file, onSuccess }) => {
                        handleAppPreviewsIosUpload(file).then(() =>
                          onSuccess()
                        );
                      }}
                      showUploadList={{ showRemoveIcon: true }}
                    >
                      {appPreviewIos.loading ? (
                        <Button className="upload-button" disabled>
                          Loading...
                        </Button>
                      ) : (
                        <Button className="upload-button">
                          + Upload Video
                        </Button>
                      )}
                    </Upload>
                  </div>
                  <a
                    className="upload-info"
                    href="https://developer.apple.com/app-store/app-previews/"
                  >
                    <ExclamationCircleOutlined
                      style={{ width: "24px", height: "24px" }}
                    />
                    Creation Guidelines
                  </a>
                </div>

                <div className="stroke"></div>

                <div className="text-fields">
                  <label className="label-color">
                    Subtitle (App Short Description):
                  </label>
                  <div style={{ position: "relative" }}>
                    <Input
                      placeholder="Subtitle"
                      maxLength={30}
                      value={subtitleIos}
                      onChange={(e) => setSubtitleIos(e.target.value)}
                      style={{
                        paddingRight: "40px",
                        height: "100px !important",
                      }} // Ensure space for the suffix
                    />
                    <span
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "10px",
                        fontSize: "12px",
                        color: "#888",
                      }}
                    >
                      {subtitleIos?.length ? subtitleIos?.length : 0}/30
                    </span>
                  </div>

                  <p className="upload-info">
                    <ExclamationCircleOutlined
                      style={{ width: "24px", height: "24px" }}
                    />
                    Maximum of 30 characters allowed
                  </p>
                </div>
                <div className="stroke"></div>

                <div className="text-fields">
                  <label className="label-color">Promotional Text:</label>
                  <div style={{ position: "relative" }}>
                    <TextArea
                      placeholder="Promotional Text"
                      maxLength={170}
                      value={promotionalTextIos}
                      onChange={(e) => setPromotionalTextIos(e.target.value)}
                      rows={4}
                      suffix={`${
                        170 - promotionalTextIos?.length
                          ? promotionalTextIos?.length
                          : 0
                      } characters left`}
                    />

                    <span
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "10px",
                        fontSize: "12px",
                        color: "#888",
                      }}
                    >
                      {promotionalTextIos?.length
                        ? promotionalTextIos?.length
                        : 0}
                      /170
                    </span>
                  </div>

                  <p className="upload-info">
                    <ExclamationCircleOutlined
                      style={{ width: "24px", height: "24px" }}
                    />
                    Maximum of 170 characters allowed
                  </p>
                </div>

                <div className="stroke"></div>

                <div className="text-fields">
                  <label className="label-color">
                    What's New Text (Optional):
                  </label>
                  <div style={{ position: "relative" }}>
                    <TextArea
                      placeholder="What's new"
                      maxLength={4000}
                      value={whatsnewIos}
                      onChange={(e) => setWhatsNewIos(e.target.value)}
                      rows={4}
                      suffix={`${
                        4000 - whatsnewIos?.length ? whatsnewIos?.length : 0
                      } characters left`}
                    />
                    <span
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "10px",
                        fontSize: "12px",
                        color: "#888",
                      }}
                    >
                      {whatsnewIos?.length ? whatsnewIos?.length : 0}/4000
                    </span>
                  </div>

                  <p className="upload-info">
                    <ExclamationCircleOutlined
                      style={{ width: "24px", height: "24px" }}
                    />
                    Maximum of 4000 characters allowed
                  </p>
                </div>
              </TabPane>

              <TabPane tab="For Android Devices" key="2">
                <div className="upload-wrapper">
                  <label className="label-color">App Icon:&nbsp;</label>
                  <div className="preview-btn-wrapper">
                    <Upload
                      beforeUpload={(file) => handleAppIconAndroidUpload(file)}
                      onRemove={() =>
                        setAppIconAndroid({ url: null, loading: false })
                      }
                      fileList={
                        appIconAndroid?.url && !appIconAndroid.loading
                          ? [
                              {
                                uid: "1",
                                name: "App Icon",
                                status: "done",
                                url: appIconAndroid.url,
                              },
                            ]
                          : []
                      }
                      // customRequest={({ file, onSuccess }) => {
                      //   handleAppIconAndroidUpload(file).then(() => onSuccess());
                      // }}
                      showUploadList={{ showRemoveIcon: true }}
                    >
                      {appIconAndroid.loading ? (
                        <Button className="upload-button" disabled>
                          Loading...
                        </Button>
                      ) : (
                        <Button className="upload-button">
                          + Upload Image
                        </Button>
                      )}
                    </Upload>
                  </div>
                  <p className="upload-info">
                    <ExclamationCircleOutlined
                      style={{ width: "24px", height: "24px" }}
                    />
                    App icon must be a JPEG or PNG, up to 1 MB, 1024 px by 1024
                    px; 2048 * 2048 px
                  </p>
                </div>

                <div className="stroke"></div>
                <div className="upload-wrapper">
                  <label className="label-color">
                    Feature Graphic (Optional):&nbsp;
                  </label>
                  <div className="preview-btn-wrapper">
                    <Upload
                      multiple
                      showUploadList={false}
                      beforeUpload={(file) => {
                        handleFeatureGraphicAndroidUpload(file);
                      }}
                    >
                      <Button
                        className="upload-button"
                        disabled={featureGraphicUpload}
                      >
                        {" "}
                        {featureGraphicUpload
                          ? "Uploading ..."
                          : "+ Upload Image"}
                      </Button>
                    </Upload>
                  </div>

                  <div className="feature-graphic-display">
                    {featureGraphicAndroid &&
                    featureGraphicAndroid.length > 0 ? (
                      featureGraphicAndroid.map((imageUrl, index) => (
                        <div
                          key={index}
                          style={{
                            position: "relative",
                            marginBottom: "10px",
                            width: "116px",
                            marginRight: "20px",
                          }}
                          onMouseEnter={() => setHoveredImage(index)}
                          onMouseLeave={() => setHoveredImage(null)}
                        >
                          <img
                            className="img-feature"
                            src={imageUrl}
                            alt={`Feature Graphic ${index + 1}`}
                            style={{ width: "100%", height: "auto" }}
                          />
                          {hoveredImage === index && (
                            <DeleteOutlined
                              onClick={() =>
                                handleRemoveFeatureGraphicAndroid(index)
                              }
                              style={{
                                position: "absolute",
                                top: "5px",
                                right: "5px",
                                color: "#5a5ff2",
                                fontSize: "15px",
                                cursor: "pointer",
                                background: "white",
                                borderRadius: "50%",
                                padding: "2px",
                              }}
                            />
                          )}
                        </div>
                      ))
                    ) : (
                      <p>
                        {featureGraphicUpload
                          ? "Loading..."
                          : "No feature graphics available"}
                      </p>
                    )}
                  </div>

                  <p className="upload-info">
                    <ExclamationCircleOutlined
                      style={{ width: "24px", height: "24px" }}
                    />
                    Feature graphic must be a PNG or JPEG, up to 15 MB and 1,024
                    px by 500 px
                  </p>
                </div>

                <div className="stroke"></div>

                <div className="upload-wrapper">
                  <label className="label-color">
                    App Previews (optional):&nbsp;
                  </label>
                  <div className="preview-btn-wrapper">
                    <Upload
                      beforeUpload={(file) =>
                        handleAppPreviewsAndroidUpload(file)
                      }
                      onRemove={() =>
                        setAppPreviewAndroid({ url: null, loading: false })
                      }
                      fileList={
                        appPreviewAndroid.url && !appPreviewAndroid?.loading
                          ? [
                              {
                                uid: "1",
                                name: appPreviewAndroid.url,
                                status: "done",
                                url: appPreviewAndroid.url,
                              },
                            ]
                          : []
                      }
                      customRequest={({ file, onSuccess }) => {
                        handleAppPreviewsAndroidUpload(file).then(() =>
                          onSuccess()
                        );
                      }}
                      showUploadList={{ showRemoveIcon: true }}
                    >
                      {appPreviewAndroid.loading ? (
                        <Button className="upload-button" disabled>
                          Loading...
                        </Button>
                      ) : (
                        <Button className="upload-button">
                          + Upload Video
                        </Button>
                      )}
                    </Upload>
                  </div>
                  <a
                    className="upload-info"
                    href="https://developer.apple.com/app-store/app-previews/"
                  >
                    <ExclamationCircleOutlined
                      style={{ width: "24px", height: "24px" }}
                    />
                    Creation Guidelines
                  </a>
                </div>
                <div className="stroke"></div>
                <div className="text-fields">
                  <label className="label-color">
                    Subtitle (App Short Description):
                  </label>
                  <div style={{ position: "relative" }}>
                    <Input
                      placeholder="Subtitle"
                      maxLength={30}
                      value={subtitleAndroid}
                      onChange={(e) => setSubtitleAndroid(e.target.value)}
                      style={{
                        paddingRight: "40px",
                        height: "100px !important",
                      }} // Ensure space for the suffix
                    />
                    <span
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "10px",
                        fontSize: "12px",
                        color: "#888",
                      }}
                    >
                      {subtitleAndroid?.length ? subtitleAndroid?.length : 0}/30
                    </span>
                  </div>

                  <p className="upload-info">
                    <ExclamationCircleOutlined
                      style={{ width: "24px", height: "24px" }}
                    />
                    Maximum of 30 characters allowed
                  </p>
                </div>
                <div className="stroke"></div>

                <div className="text-fields">
                  <label className="label-color">Promotional Text:</label>
                  <div style={{ position: "relative" }}>
                    <TextArea
                      placeholder="Promotional Text"
                      maxLength={170}
                      value={promotionalTextAndroid}
                      onChange={(e) =>
                        setPromotionalTextAndroid(e.target.value)
                      }
                      rows={4}
                      suffix={`${
                        170 - promotionalTextAndroid?.length
                          ? promotionalTextAndroid?.length
                          : 0
                      } characters left`}
                    />

                    <span
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "10px",
                        fontSize: "12px",
                        color: "#888",
                      }}
                    >
                      {promotionalTextAndroid?.length
                        ? promotionalTextAndroid?.length
                        : 0}
                      /170
                    </span>
                  </div>

                  <p className="upload-info">
                    <ExclamationCircleOutlined
                      style={{ width: "24px", height: "24px" }}
                    />
                    Maximum of 170 characters allowed
                  </p>
                </div>
                <div className="stroke"></div>
                <div className="text-fields">
                  <label className="label-color">
                    What's New Text (Optional):
                  </label>
                  <div style={{ position: "relative" }}>
                    <TextArea
                      placeholder="What's new"
                      maxLength={4000}
                      value={whatsnewAndroid}
                      onChange={(e) => setWhatsNewAndroid(e.target.value)}
                      rows={4}
                      suffix={`${
                        4000 - whatsnewAndroid?.length
                          ? whatsnewAndroid?.length
                          : 0
                      } characters left`}
                    />
                    <span
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "10px",
                        fontSize: "12px",
                        color: "#888",
                      }}
                    >
                      {whatsnewAndroid?.length ? whatsnewAndroid?.length : 0}
                      /4000
                    </span>
                  </div>

                  <p className="upload-info">
                    <ExclamationCircleOutlined
                      style={{ width: "24px", height: "24px" }}
                    />
                    Maximum of 4000 characters allowed
                  </p>
                </div>
              </TabPane>
            </Tabs>
          </div>

          <div className="right-section">
            {previewWelcomeScreen && (
              <div
                className="preview"
                style={{
                  backgroundImage: images.bg.imageUrl
                    ? `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.80) 97.5%), url("${images.bg.imageUrl}")`
                    : "none",
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <h3 className="headerText">
                  {headerText || "Please enter your app header text here"}
                </h3>
                <Button
                  style={{
                    color: "white",
                    backgroundColor: primaryColor ? primaryColor : "#fff",
                    borderRadius: "26px",
                    border: "none",
                    fontSize: "10px",
                    width: "100%",
                  }}
                >
                  Get Started
                </Button>
              </div>
            )}

            {previewSplashScreen && (
              <div className="preview" style={{ padding: "10px", backgroundColor: 'white' }}>
                <div className="">
                  <img
                    src={images.splash.imageUrl ? images.splash.imageUrl : ""}
                    alt=""
                    className="splash-logo-img"
                  />
                </div>
              </div>
            )}

            {previewHomeScreen && (
              <div className="preview" style={{ backgroundColor: "white" }}>
                <div className="header-demo-screen">
                  <div className="user-icon">
                    <UserOutlined className="app-icon-header " />
                  </div>
                  {images?.home?.imageUrl ? (
                    <img
                      src={images.home.imageUrl ? images.home.imageUrl : ""}
                      alt=""
                      className="home-screen-logo"
                    />
                  ) : (
                    <div className="home-screen-logo"></div>
                  )}
                  <BellOutlined className="app-icon-header" />
                </div>

                <div className="content-home-screen"></div>
                <div className="shimmer-ui-one"></div>
                <div className="main-content-screen">
                  <div className="shimmer-div">
                    <div className="shimmer-ui-two"></div>
                    <div className="shimmer-div-two">
                      <div className="shimmer-ui-three"></div>
                      <div className="shimmer-ui-four"></div>
                    </div>
                  </div>
                  <div className="shimmer-ui-one"></div>
                </div>

                <div className="content-home-screen"></div>

                <div className="footer-screen-demo">
                  <HomeOutlined
                    className="app-icon-footer"
                    style={{ color: primaryColor ? primaryColor : "#9eb1c8" }}
                  />
                  <SearchOutlined className="app-icon-footer" />
                  <FileTextOutlined className="app-icon-footer" />
                  <MessageOutlined className="app-icon-footer" />
                </div>
              </div>
            )}

            <div className="footer-preview">
              {previewWelcomeScreen && "Previewing Welcome Screen"}
              {previewSplashScreen && "Previewing Splash Screen"}
              {previewHomeScreen && "Previewing Home Screen"}
            </div>
          </div>
        </div>
      </div>
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        okText="Confirm"
        onCancel={handleCancel}
        okButtonProps={{ disabled: confirmDisabled }}
      >
        <div>
          <h3>Please enter your reason to change the status</h3>
          <Input.TextArea
            placeholder="Enter reason for status change"
            value={reason}
            onChange={handleInputChange}
          />
        </div>
      </Modal>
    </>
  );
};

export default ConsumerApp(CustomiseApp);
