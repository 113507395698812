import React, { useEffect } from "react"
import styled from "styled-components"
import SubContainer from "container/BrokerDashboard/subscriptionContainer"
import { get } from "lodash"
import { withRouter } from "react-router-dom"
import root from "window-or-global"
import {
  Header,
  ICAButtonBlock,
  MainBlock,
  Modal,
  TeamAgreement,
  PubnubForDocusign,
  SubmitControl,
  TeamAgreementSubmitControl,
  FileUpload,
  FullySignedICAUpload,
  Busy,
  ReasonConfirmationModal,
} from "./components"

const ComponentWrap = styled.div`
  padding: 25px;
`

const ICAFlow = ({
  isICAModalVisible,
  toggleICAModal,
  fetchICAListings,
  getAgentBrokerageMetadataInfo,
  fetchAgentICADetails,
  fetchAgentICADetailsResponse,
  setCurrentAgentViewed,
  currentAgentIDViewed,
  fetchICAListingsResponse,
  getPlanbenefits,
  currentPlan,
  fetchStripePlans,
  toggleUploadModeForICA,
  icaUploadModeVisible,
  uploadICAFile,
  fullySignedICAUploadVisible,
  toggleFullySignedICAUploadUI,
  fetchFullySignedICA,
  fetchFullySignedICAResponse,
  uploadFullySignedICA,
  disableICAModes,
  setICAFlowType,
  triggerSenderViewDocuSignResponse,
  triggerRecieverViewDocuSignResponse,
  mergeReviewAndSendDocumentsResponse,
  sendOnboardingEmailResponse,
  teamAgreementViewType,
  history,
  isLiveLoaderShown,
  isTriggerVoidAndDeleteModalOpen,
  toggleVoidAndDeleteModal,
  ...props
}) => {
  const agentId = get(props, "match.params.agentId", "")
  const currentMonthlySupportFee = get(currentPlan, "currentPlan")
  useEffect(() => {

    //scroll top top
    root.scrollTo(0, 0)

    //set currently viewed agent's id
    setCurrentAgentViewed(agentId)

    //fetch fully signed ICA
    fetchFullySignedICA({
      agentId,
    })

    //fetch all plans
    fetchStripePlans(agentId)

    //fetch agent meta data
    getAgentBrokerageMetadataInfo()

    //Fetches the Subscription Details for this agent for ICA
    fetchAgentICADetails({
      agentId,
    })
  }, [])

  useEffect(() => {
    //fetch benefits
    if (currentMonthlySupportFee && currentMonthlySupportFee.id) {
      getPlanbenefits({
        planId: currentMonthlySupportFee.id,
        agentId,
      })
    }
  }, [currentMonthlySupportFee])

  const openModal = () => {
    toggleUploadModeForICA(false)
    toggleFullySignedICAUploadUI(false)
    toggleICAModal(true)
    setICAFlowType("load_ica")
  }

  const closeModal = () => {
    toggleICAModal(false)
    setICAFlowType("")
  }

  const {
    isFetching: triggeringReviewMode,
  } = triggerRecieverViewDocuSignResponse || {}

  const {
    data: icaDetails,
    isFetching: fetchingDetails,
  } = fetchAgentICADetailsResponse || {}

  const {
    data: isaDocuments,
    isFetching: fetchingICADocuments,
  } = fetchICAListingsResponse || {}

  const {
    data: fullSignedICADocument,
    isFetching: fetchingFullySignedICA,
  } = fetchFullySignedICAResponse || {}

  const {
    isFetching: triggeringSenderView,
  } = triggerSenderViewDocuSignResponse || {}

  const {
    isFetching: triggeringReceiverView,
  } = triggerRecieverViewDocuSignResponse || {}

  const {
    isFetching: mergingDocuments,
  } = mergeReviewAndSendDocumentsResponse || {}

  const {
    isFetching: sendingEmail,
  } = sendOnboardingEmailResponse || {}

  const data = {
    agentId: currentAgentIDViewed,
  }

  const handleICAUpload = (url, name) => {
    const payload = {
      agentId: currentAgentIDViewed,
      document: {
        url,
        name,
        extension: "pdf",
      },
    }

    uploadICAFile({
      agentId: currentAgentIDViewed || agentId,
      payload,
    })
  }

  const handleFullySignedICAUpload = (obj, name) => {
    uploadFullySignedICA({
      agentId: currentAgentIDViewed || agentId,
      payload: {
        agentId: currentAgentIDViewed || agentId,
        url: obj,
        name,
      },
    })
  }

  const busyStates = triggeringReviewMode
  || triggeringSenderView
  || triggeringReceiverView
  || mergingDocuments
  || sendingEmail

  console.log("isTriggerVoidAndDeleteModalOpen", isTriggerVoidAndDeleteModalOpen)

  return (
    <>
      {mergingDocuments || triggeringReviewMode && (
        <Busy />
      )}
      <PubnubForDocusign
        docusignAgentId={agentId || currentAgentIDViewed}
        fetchICAListings={fetchICAListings}
      />
      <ComponentWrap>
        <Header />
        <ICAButtonBlock
          openICAModal={openModal}
          toggleUploadModeForICA={toggleUploadModeForICA}
          toggleFullySignedICAUploadUI={toggleFullySignedICAUploadUI}
          disableICAModes={disableICAModes}
        />

        {icaUploadModeVisible && (
          <FileUpload
            uploadFunction={handleICAUpload}
            data={data}
          />
        )}

        {fullySignedICAUploadVisible && (
          <FullySignedICAUpload
            uploadFunction={handleFullySignedICAUpload}
            data={data}
          />
        )}

        {!icaUploadModeVisible && !fullySignedICAUploadVisible && (
          <MainBlock
            isaDocuments={isaDocuments}
            fetchingICADocuments={fetchingICADocuments}
            fullySignedDocument={fullSignedICADocument}
            fetchingFullySignedICA={fetchingFullySignedICA}
            isLiveLoaderShown={isLiveLoaderShown}
          />
        )}

        {!fetchingICADocuments
        && !icaUploadModeVisible
        && !fullySignedICAUploadVisible && (
          <SubmitControl
            isaDocuments={isaDocuments}
            fullSignedICADocument={fullSignedICADocument}
          />
        )}

        {/*TEAM LEAD & MEMBERS ONLY*/}
        {!fetchingDetails
        && icaDetails
        && (icaDetails.role.value === "Team Lead"
        || icaDetails.role.value === "Co Team Lead"
        || icaDetails.role.value === "Team Member")
        && (
          <>
            <TeamAgreement
              history={history}
              isTeamMemberDashboard={icaDetails.role.value === "Team Member"}
            />
            <>
              {(icaDetails.role.value === "Team Lead"
                || icaDetails.role.value === "Co Team Lead") && (
                <TeamAgreementSubmitControl />
              )}
            </>
          </>
        )}
        {/*TEAM LEADER ONLY*/}

      </ComponentWrap>
      {isICAModalVisible && !fetchingDetails && (
        <Modal
          toClose={closeModal}
          type={icaDetails.role.value}
        />
      )}
      {isTriggerVoidAndDeleteModalOpen && (
        <ReasonConfirmationModal
          toClose={() => {
            toggleVoidAndDeleteModal(false)
          }}
        />
      )}
    </>
  )
}

export default withRouter(SubContainer(ICAFlow))
