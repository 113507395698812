import React, { useEffect, useState } from "react"
import moment from "moment"
import styled from "styled-components"
// import {
//   CardNumberElement, CardExpiryElement, CardCvcElement,
// } from "react-stripe-elements"
import {
  useStripe, useElements, CardElement,
} from "@stripe/react-stripe-js"
import queryString from "query-string"
// import SettingsContainer from "container/Settings"
// import OnboardingContainer from "container/OnboardingSetup"
// import Container from "container/Settings/SubscriptionsContainer"
import SidebarContainer from "container/BrokerDashboard/sidebarContainer"
import EditAgentContainer from "container/BrokerDashboard/editAgentContainer"
import SubContainer from "container/BrokerDashboard/subscriptionContainer"
// import AppContainer from "container/App"
import { withRouter } from "react-router-dom"
// import ThreeDotLoader from "@ui/ThreeDotLoader"
// import Navbar from "container/Navbar"
import {
  CreditFormWrap,
  CreditPlanButtonWrap,
  CreditCancelButton,
  CreditProceedButton,
  ButtonWrap,
  CardLabel,
  HorizontalBar,
  CommonText,
  CancelButtom,
  ProceedButtom,
} from "./InjectedFormStyles"

const InjectedCardForm = ({
  isOpen,
  verifyAuthToken,
  history,
  getStripeSecretKey,
  getStripeSecretResponse,
  verifyAuthTokenResponse,
  saveStripeSubscription,
  getCardDetails,
  saveStripeSubscriptionResponse,
  fetchUserInfo,
  handleClickForCancel,
  handleClick,
  getClientSecret,
  getClientSecretResponse,
  setLoadingCard,
  isLoadingCard,
  custom = false,
  planDetail,
  agentId,
  ...props
}) => {
  const [clientSecret, setClientSecret] = useState("sk_test_tR3PYbcVNZZ796tH88S4VQ2u")
  const stripe = useStripe()
  const elements = useElements()
  const { isFetching } = saveStripeSubscriptionResponse || {}
  const [addingCard, setAddingCard] = useState(false);
//   const parsedQuery = queryString.parse(history.location.search)
//   const { authcode } = parsedQuery
//   const payload = {
//     authCode: authcode,
//   }
  console.log("isOpen", isOpen)
  useEffect(() => {
    setAddingCard(false);
    console.log("isClearing", elements)
    if (isOpen && elements) {
      console.log("isClearing")
      const cardElement = elements.getElement(CardElement)
      if (cardElement) {
        cardElement.clear() 
      }
    }
  }, [])

  const { data, isFetching: clientSecretFetching } = getClientSecretResponse || {}
  const { client_secret } = data || {}
//   useEffect(() => {
//     // verifyAuthToken({ payload })
//   }, [authcode])

  useEffect(() => {
    setClientSecret(client_secret)
  }, [getClientSecretResponse])

  const handleSubmit = async (e) => {
    e.preventDefault()
    // setLoadingCard(true)
    setAddingCard(true);

    if (!stripe || !elements) {
      console.error("Stripe.js has not loaded yet.")
      return
    }

    // Prepare payload for clientSecret generation
    const clientSecretPayload = {
      intent_type: "card",
    }

    // Call API to fetch client_secret
    console.log("here before api")
    getClientSecret({
      //payload: clientSecretPayload,
      payload: { 
        intent_type: "card",
        agentId: agentId,
        },
      callback: async (response) => {
        console.log("here in callback")
        const clientSecretkey = response?.client_secret
        console.log(clientSecretkey)
        if (!clientSecretkey) {
          console.error("Client secret not returned from API.")
          return
        }

        //Get the CardElement instance
        const cardElement = elements.getElement(CardElement)
        if (!cardElement) {
          console.error("CardElement not found.")
          return
        }

        try {
          // Use confirmCardPayment with the clientSecret
          const result = await stripe.confirmCardSetup(clientSecretkey, {
            payment_method: {
              card: cardElement,
            },
          })

          if (result.error) {
            console.log("result", result)
            console.error(result.error.message)
            handleClick(result.error.code)
            setAddingCard(false);
          } else {
            console.log("Setup succeeded:", result.setupIntent)
            handleClick("success")
          }
        } catch (error) {
          setAddingCard(false);
          console.error("Error during payment confirmation:", error)
        }
      },
    })
  }
  return (
    <CreditFormWrap>
      <form
        onSubmit={handleSubmit}
        style={{
          width: "100%",
        }}
      >
        <div
          style={{
            marginTop: "16px", marginBottom: "16px", gap: "18px", display: "flex", flexDirection: "column",
          }}
        >
          <CardLabel>Card details</CardLabel>
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: "16px",
                  color: "#424770",
                  "::placeholder": { color: "#aab7c4" },
                },
                invalid: { color: "#9e2146" },
              },
              hidePostalCode: true,
            }}
          />
        </div>
        {
          custom ? (
            <>
              <HorizontalBar />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  alignSelf: "stretch",
                  marginTop: "16px",
                }}
              >
                <CommonText
                  color="#232338"
                  fontSize="14px"
                  weight="400"
                  lineHeigth
                >
                  Total amount:
                </CommonText>
                <CommonText
                  color="#0C9F6E"
                  fontSize="14px"
                  weight="400"
                  lineHeigth
                >
                  $
                  {planDetail?.unit_price ? (planDetail.unit_price) : "N/A"}
                  /
                  {planDetail.billing_cycle === "MONTHLY" ? "month" : "year"}
                </CommonText>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  alignSelf: "stretch",
                  gap: "32px",
                  marginTop: "30px",
                }}
              >
                <CancelButtom onClick={handleClickForCancel} width><p>Cancel</p></CancelButtom>
                <ProceedButtom width>
                  {addingCard ? (
                    <p>Adding...</p>
                  ) : (
                    <p>Proceed</p>
                  )}
                </ProceedButtom>

              </div>
            </>
          ) : (
            <>
              <ButtonWrap>
                <CreditPlanButtonWrap>
                  <CreditCancelButton onClick={handleClickForCancel} type="button">
                    <p>
                      Cancel
                    </p>
                  </CreditCancelButton>
                  <CreditProceedButton type="submit">
                    {/* <p>Proceed123</p> */}
                    {addingCard ? (
                    //   <ThreeDotLoader color="#fff" />
                    <p>Adding...</p>
                    ) : (
                      <p>Proceed</p>
                    )}
                  </CreditProceedButton>
                </CreditPlanButtonWrap>
              </ButtonWrap>
            </>
          )
        }
      </form>
    </CreditFormWrap>
  )
}

export default SidebarContainer(EditAgentContainer(SubContainer(InjectedCardForm)))
