import React, { useState, useEffect } from "react"
import {
  Modal, Select,
} from "antd"
import moment from "moment"
// import ThreeDotLoader from "@ui/ThreeDotLoader"
// import Navbar from "container/Navbar"
import {
  ModalWrapper,
  CreditHeadingWrap,
  CreditHeading,
  CreditImage,
  CommonText,
  ButtonWrap,
  CancelButton,
  DeleteButton,
} from "./styles"

const DeleteCardModal = ({
  toClose,
  isOpen,
  handleProceed,
  handleClickForCancel,
  isLoadingCard,
  setLoadingCard,
}) => {
  const handleSubmit = () => {
    // setLoadingCard(true)
    handleProceed()
  }

  return (
    <Modal visible={isOpen} onCancel={toClose} footer={null} width={500}>
      <ModalWrapper>
        {/* <CreditHeadingWrap>
          <CreditImage>
            <img src={`${CLOUDFRONT}/brokeragePage/settingsModeule.svg`} width="19px" />
          </CreditImage>
        </CreditHeadingWrap> */}
        <CreditHeadingWrap>
          <CreditHeading>
            Remove payment method
          </CreditHeading>
        </CreditHeadingWrap>
        <CommonText
          color="#6B7280"
          fontSize="12px"
          weight="400"
          lineHeigth
        >
          Are you sure you want to remove this payment method? This action cannot be undone. Any recurring payments or subscriptions using this payment method will need to be updated.
        </CommonText>
        <ButtonWrap>
          <CancelButton onClick={handleClickForCancel}>Cancel</CancelButton>
          <DeleteButton onClick={handleSubmit}>
            {isLoadingCard ? (
            //   <ThreeDotLoader color="#fff" />
              <p>Loading...</p>
            ) : (
              <>Delete</>
            )}
          </DeleteButton>
        </ButtonWrap>
      </ModalWrapper>
    </Modal>
  )
}

export default DeleteCardModal
