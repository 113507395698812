/* eslint-disable no-param-reassign */
import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils"
import { get, isEmpty, uniq } from "lodash"
import * as BrokerDashboardActions from "./actions"
import { alert } from "window-or-global"

const initState = {
  isBusy: false,
  selectedDuration: "MONTHLY",
  selectedAddons: [],
  isTimelineDrawerOpen: false,
  assetForm: {},
  tcSortBy: {},
  showQueryMoreForDeals: true,
  fileUploading: null,
  yardSign: {},
  businessCard: {},
  allDealsListingFilter: "",
  tcLoader: false,
  dealToView: 2282,
  totalListingCount: 0,
  teamAgreementViewType: 2,
  // dealToView: null,
  // dealAgentId: 10027946,
  dealAgentId: null,
  // loaderOnAgentInfo: true,
  loaderOnAgentInfo: false,
  escrowIdToEdit: null,
  isEscrowDrawerOpen: false,
  isEditEscrow: null,
  selectedId: null,
  allVendors: [],
  isUploadImage: false,
  dealIdToUpload: null,
  allListingImages: [],
  templateList: [],
  templateInvalid: false,
  deleteTemplates: [],
  isOpenTimelineChecklist: false,
  dealIdTimeline: null,
  radiusFee: 0,
  selectedAgentPlans: [],
  addOnPlanIds: [],
  addOnsSelected: [],
  addOnsRemoved: [],
  currentAgentIDViewed: "",
  isICAModalVisible: false,
  icaUploadModeVisible: false,
  fullySignedICAUploadVisible: false,
  disableICAModes: false,
  currentTypeICAFlow: "",
  missingTeamMessage: "",
  teamBasedCurrentTeamAgreementStatus: null,
  isLiveLoaderShown: false,
  isModalViewOpen: false,
  urlBasedTeamId: null,
  isTriggerVoidAndDeleteModalOpen: false,
  toggleTeamModal: false,
  inviteWithoutSubscriptionPayload: null,
  isInviteWithoutSubscriptionModalOpen: false,
  currentCouponText: "",
  listingState: "California",
  isCreatingDeal: false,
  ...createDeltaReducer("updateSubscriptionDateResponse"),
  ...createDeltaReducer("pendingRequestsResponse"),
  ...createDeltaReducer("rarTeamListResponse"),
  ...createDeltaReducer("getBrokerTeamsOverviewResponse"),
  ...createDeltaReducer("teamsDownloadDataResponse"),
  ...createDeltaReducer("teamDetailsResponse"),
  ...createDeltaReducer("dealsListingResponse"),
  ...createDeltaReducer("agentDetailsResponse"),
  ...createDeltaReducer("planBenefitsResponse"),
  ...createDeltaReducer("couponCodeResponse"),
  ...createDeltaReducer("globalAssetsResponse"),
  ...createDeltaReducer("agentAssetsResponse"),
  ...createDeltaReducer("fileUploadResResponse"),
  ...createDeltaReducer("rosterPageAgentsResponse"),
  ...createDeltaReducer("agentPipelinePageResponse"),
  ...createDeltaReducer("referralSourcesResponse"),
  ...createDeltaReducer("addonsResponse"),
  ...createDeltaReducer("saveCardResponse"),
  ...createDeltaReducer("tcFilesResponse"),
  ...createDeltaReducer("getAgentTypesResponse"),
  ...createDeltaReducer("getUserAgentTypeResponse"),
  ...createDeltaReducer("changeUserAgentTypeResponse"),
  ...createDeltaReducer("getCitiesServedResponse"),
  ...createDeltaReducer("getNeighbourHoodFromCitiesResponse"),
  ...createDeltaReducer("deleteNeighbourhoodResponse"),
  ...createDeltaReducer("deleteRARTeamResponse"),
  ...createDeltaReducer("resourcesListResponse"),
  ...createDeltaReducer("tcDataDownloadResponse"),
  ...createDeltaReducer("getTCListResponse"),
  ...createDeltaReducer("getDealStatusTimelineResponse"),
  ...createDeltaReducer("getTCAgentAssignedResponse"),
  ...createDeltaReducer("setupProfileResponse"),
  ...createDeltaReducer("rosterPageDataDownloadResponse"),
  ...createDeltaReducer("getOrientationInfoResponse"),
  ...createDeltaReducer("getBrandingInfoResponse"),
  ...createDeltaReducer("uploadDocsToBucketResponse"),
  ...createDeltaReducer("uploadDocsForListingResponse"),
  ...createDeltaReducer("uploadDocsToBucketBrandingResponse"),
  ...createDeltaReducer("uploadSellerProfileImageResponse"),
  ...createDeltaReducer("getBrandingAssetsResponse"),
  ...createDeltaReducer("getListingCoverSheetResponse"),
  ...createDeltaReducer("getContractsDetailsResponse"),
  ...createDeltaReducer("createListingCoverSheetResponse"),
  ...createDeltaReducer("updateListingCoverSheetResponse"),
  ...createDeltaReducer("getListingTypeResponse"),
  ...createDeltaReducer("getVendorDetailsResponse"),
  ...createDeltaReducer("getContractStatusResponse"),
  ...createDeltaReducer("getDocusignTemplateResponse"),
  ...createDeltaReducer("getTotalCountTemplateResponse"),
  ...createDeltaReducer("getListingTotalCountTemplateResponse"),
  ...createDeltaReducer("fetchDealTimelineResponse"),
  ...createDeltaReducer("agentBrokerageInfoResponse"),
  ...createDeltaReducer("addCreditCardDetailsResponse"),
  ...createDeltaReducer("getBrokerageInfoMetabaseResponse"),
  ...createDeltaReducer("updateSubscriptionV2Response"),
  ...createDeltaReducer("checkoutBrokerPlanV2Response"),
  ...createDeltaReducer("cancelSubscriptionResponse"),
  ...createDeltaReducer("fetchICAListingsResponse"),
  ...createDeltaReducer("fetchAgentICADetailsResponse"),
  ...createDeltaReducer("triggerSenderViewDocuSignResponse"),
  ...createDeltaReducer("triggerRecieverViewDocuSignResponse"),
  ...createDeltaReducer("updateICADocumentStatusResponse"),
  ...createDeltaReducer("inviteWithoutSubscriptionResponse"),
  //tc overview
  ...createDeltaReducer("tcOverviewListingResponse"),
  ...createDeltaReducer("saveListingStepsResponse"),
  // ...createDeltaReducer("AssignTeamOrTeamLeadResponse"),
  ...createDeltaReducer("mergeReviewAndSendDocumentsResponse"),
  ...createDeltaReducer("uploadICAResponse"),
  ...createDeltaReducer("uploadTeamAgreementResponse"),
  ...createDeltaReducer("fetchTeamAgreementsResponse"),
  ...createDeltaReducer("saveStripeTokenResponse"),
  ...createDeltaReducer("getTentativeBrokerageSubscriptionDetailsResponse"),
  ...createDeltaReducer("fetchRecruiterAndAsmInfoResponse"),
  ...createDeltaReducer("updateRecruiterAndAsmInfoResponse"),
  ...createDeltaReducer("fetchAgentAsmRecruiterInfoResponse"),
  ...createDeltaReducer("uploadICAFileReponse"),
  ...createDeltaReducer("sendOnboardingEmailResponse"),
  ...createDeltaReducer("copyOnboardingURLResponse"),
  ...createDeltaReducer("fetchFullySignedICAResponse"),
  ...createDeltaReducer("uploadFullySignedICAResponse"),
  ...createDeltaReducer("saveTeamAgreementResponse"),
  ...createDeltaReducer("triggerTemplateEditViewResponse"),
  ...createDeltaReducer("getTemplateDownloadURLResponse"),
  ...createDeltaReducer("triggerVoidAndDeleteResponse"),
  ...createDeltaReducer("uploadCSVToS3Response"),
  ...createDeltaReducer("uploadS3LocationResponse"),
}

export default function brokerDashboardReducer(state = initState, action) {
  switch (action.type) {
    case BrokerDashboardActions.getPendingRequestsAction.REQUEST:
    case BrokerDashboardActions.getPendingRequestsAction.SUCCESS:
    case BrokerDashboardActions.getPendingRequestsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.getPendingRequestsAction, "pendingRequestsResponse"),
      }
    }

    case BrokerDashboardActions.getResourcesListAction.REQUEST:
    case BrokerDashboardActions.getResourcesListAction.SUCCESS:
    case BrokerDashboardActions.getResourcesListAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.getResourcesListAction, "resourcesListResponse"),
      }
    }

    case BrokerDashboardActions.getAgentTypesAction.REQUEST:
    case BrokerDashboardActions.getAgentTypesAction.FAILURE:
    case BrokerDashboardActions.getAgentTypesAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.getAgentTypesAction, "getAgentTypesResponse"),
      }
    }

    case BrokerDashboardActions.deleteRARTeamAction.REQUEST:
    case BrokerDashboardActions.deleteRARTeamAction.FAILURE:
    case BrokerDashboardActions.deleteRARTeamAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.deleteRARTeamAction, "deleteRARTeamResponse"),
      }
    }

    case BrokerDashboardActions.getUserAgentTypeAction.REQUEST:
    case BrokerDashboardActions.getUserAgentTypeAction.FAILURE:
    case BrokerDashboardActions.getUserAgentTypeAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.getUserAgentTypeAction, "getUserAgentTypeResponse"),
      }
    }

    case BrokerDashboardActions.getOrientationInfoAction.REQUEST:
    case BrokerDashboardActions.getOrientationInfoAction.FAILURE:
    case BrokerDashboardActions.getOrientationInfoAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.getOrientationInfoAction, "getOrientationInfoResponse"),
      }
    }

    case BrokerDashboardActions.getBrandingInfoAction.REQUEST:
    case BrokerDashboardActions.getBrandingInfoAction.FAILURE:
    case BrokerDashboardActions.getBrandingInfoAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.getBrandingInfoAction, "getBrandingInfoResponse"),
      }
    }

    case BrokerDashboardActions.getBrandingAssetsAction.REQUEST:
    case BrokerDashboardActions.getBrandingAssetsAction.FAILURE:
    case BrokerDashboardActions.getBrandingAssetsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.getBrandingAssetsAction, "getBrandingAssetsResponse"),
      }
    }

    case BrokerDashboardActions.getVendorDetailsAction.REQUEST:
    case BrokerDashboardActions.getVendorDetailsAction.FAILURE:
    case BrokerDashboardActions.getVendorDetailsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.getVendorDetailsAction, "getVendorDetailsResponse"),
      }
    }

    case BrokerDashboardActions.changeUserAgentTypeAction.REQUEST:
    case BrokerDashboardActions.changeUserAgentTypeAction.FAILURE:
    case BrokerDashboardActions.changeUserAgentTypeAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.changeUserAgentTypeAction, "changeUserAgentTypeResponse"),
      }
    }

    // case BrokerDashboardActions.handleAssignTeamOrTeamLeadAction.REQUEST:
    // case BrokerDashboardActions.handleAssignTeamOrTeamLeadAction.FAILURE:
    // case BrokerDashboardActions.handleAssignTeamOrTeamLeadAction.SUCCESS: {
    //   return {
    //     ...state,
    //     ...handleDeltaReducer(state, action, BrokerDashboardActions.handleAssignTeamOrTeamLeadAction, "AssignTeamOrTeamLeadResponse"),
    //   }
    // }

    case BrokerDashboardActions.getAgentCardDetailsAction.REQUEST:
    case BrokerDashboardActions.getAgentCardDetailsAction.FAILURE:
    case BrokerDashboardActions.getAgentCardDetailsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.getAgentCardDetailsAction, "saveCardResponse"),
      }
    }

    case BrokerDashboardActions.tcOverviewAction.REQUEST:
    case BrokerDashboardActions.tcOverviewAction.FAILURE:
    case BrokerDashboardActions.tcOverviewAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.tcOverviewAction, "tcOverviewResponse"),
      }
    }

    //tcOverview
    case BrokerDashboardActions.tcOverviewListingAction.REQUEST:
    case BrokerDashboardActions.tcOverviewListingAction.FAILURE:
    case BrokerDashboardActions.tcOverviewListingAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.tcOverviewListingAction, "tcOverviewListingResponse"),
      }
    }

    case BrokerDashboardActions.tcDataDownloadAction.REQUEST:
    case BrokerDashboardActions.tcDataDownloadAction.FAILURE:
    case BrokerDashboardActions.tcDataDownloadAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.tcDataDownloadAction, "tcDataDownloadResponse"),
      }
    }

    case BrokerDashboardActions.getSecureIdForTCAction.SUCCESS: {
      const { secureId } = action.data
      return {
        ...state,
        secureIdForTC: secureId,
      }
    }

    case BrokerDashboardActions.sortTCListAction.type: {
      return {
        ...state,
        tcSortBy: {
          ...action.data,
        },
      }
    }

    case BrokerDashboardActions.getPlanbenefitsAction.REQUEST:
    case BrokerDashboardActions.getPlanbenefitsAction.SUCCESS:
    case BrokerDashboardActions.getPlanbenefitsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.getPlanbenefitsAction, "planBenefitsResponse"),
      }
    }

    case BrokerDashboardActions.getAgentForRosterPageAction.REQUEST:
    case BrokerDashboardActions.getAgentForRosterPageAction.SUCCESS:
    case BrokerDashboardActions.getAgentForRosterPageAction.CLEAR:
    case BrokerDashboardActions.getAgentForRosterPageAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.getAgentForRosterPageAction, "rosterPageAgentsResponse"),
      }
    }

    case BrokerDashboardActions.rosterPageDataDownloadAction.REQUEST:
    case BrokerDashboardActions.rosterPageDataDownloadAction.SUCCESS:
    case BrokerDashboardActions.rosterPageDataDownloadAction.CLEAR:
    case BrokerDashboardActions.rosterPageDataDownloadAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.rosterPageDataDownloadAction, "rosterPageDataDownloadResponse"),
      }
    }

    case BrokerDashboardActions.getAgentPipelinePageAction.REQUEST:
    case BrokerDashboardActions.getAgentPipelinePageAction.SUCCESS:
    case BrokerDashboardActions.getAgentPipelinePageAction.CLEAR:
    case BrokerDashboardActions.getAgentPipelinePageAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.getAgentPipelinePageAction, "agentPipelinePageResponse"),
      }
    }

    case BrokerDashboardActions.getDocusignTemplateAction.REQUEST:
    case BrokerDashboardActions.getDocusignTemplateAction.SUCCESS:
    case BrokerDashboardActions.getDocusignTemplateAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.getDocusignTemplateAction, "getDocusignTemplateResponse"),
      }
    }

    case BrokerDashboardActions.getOffersDocusignTemplate.REQUEST:
    case BrokerDashboardActions.getOffersDocusignTemplate.SUCCESS:
    case BrokerDashboardActions.getOffersDocusignTemplate.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.getOffersDocusignTemplate, "getDocusignTemplateResponse"),
      }
    }

    case BrokerDashboardActions.getOffersTotalCommonTemplate.REQUEST:
    case BrokerDashboardActions.getOffersTotalCommonTemplate.SUCCESS:
    case BrokerDashboardActions.getOffersTotalCommonTemplate.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.getOffersTotalCommonTemplate, "getTotalCountTemplateResponse"),
      }
    } 
    case BrokerDashboardActions.getListingTotalCommonTemplate.REQUEST:
    case BrokerDashboardActions.getListingTotalCommonTemplate.SUCCESS:
    case BrokerDashboardActions.getListingTotalCommonTemplate.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.getListingTotalCommonTemplate, "getListingTotalCountTemplateResponse"),
      }
    }

    case BrokerDashboardActions.getReferralSourcesAction.REQUEST:
    case BrokerDashboardActions.getReferralSourcesAction.SUCCESS:
    case BrokerDashboardActions.getReferralSourcesAction.CLEAR:
    case BrokerDashboardActions.getReferralSourcesAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.getReferralSourcesAction, "referralSourcesResponse"),
      }
    }

    case BrokerDashboardActions.getFileForTCAction.REQUEST:
    case BrokerDashboardActions.getFileForTCAction.SUCCESS:
    case BrokerDashboardActions.getFileForTCAction.CLEAR:
    case BrokerDashboardActions.getFileForTCAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.getFileForTCAction, "tcFilesResponse"),
      }
    }

    case BrokerDashboardActions.uploadFilesAction.REQUEST:
    case BrokerDashboardActions.uploadFilesAction.SUCCESS:
    case BrokerDashboardActions.uploadFilesAction.CLEAR:
    case BrokerDashboardActions.uploadFilesAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.uploadFilesAction, "fileUploadResResponse"),
      }
    }

    case BrokerDashboardActions.uploadDocsToBucketAction.REQUEST:
    case BrokerDashboardActions.uploadDocsToBucketAction.FAILURE:
    case BrokerDashboardActions.uploadDocsToBucketAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.uploadDocsToBucketAction,
          "uploadDocsToBucketResponse"
        ),
      }
    }

    case BrokerDashboardActions.uploadDocsForListingAction.REQUEST:
    case BrokerDashboardActions.uploadDocsForListingAction.FAILURE:
    case BrokerDashboardActions.uploadDocsForListingAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.uploadDocsForListingAction,
          "uploadDocsForListingResponse"
        ),
      }
    }

    case BrokerDashboardActions.getAgentBrokerageInfoAction.REQUEST:
    case BrokerDashboardActions.getAgentBrokerageInfoAction.FAILURE:
    case BrokerDashboardActions.getAgentBrokerageInfoAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.getAgentBrokerageInfoAction,
          "agentBrokerageInfoResponse"
        ),
      }
    }

    case BrokerDashboardActions.resetListingImages.type: {
      return {
        ...state,
        uploadDocsForListingResponse: { data: null },
      }
    }

    case BrokerDashboardActions.resetDealId.type: {
      return {
        ...state,
        dealIdToUpload: null,
        dealIdTimeline: null,
      }
    }

    case BrokerDashboardActions.uploadDocsToBucketBrandingAction.REQUEST:
    case BrokerDashboardActions.uploadDocsToBucketBrandingAction.FAILURE:
    case BrokerDashboardActions.uploadDocsToBucketBrandingAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.uploadDocsToBucketBrandingAction,
          "uploadDocsToBucketBrandingResponse"
        ),
      }
    }

    case BrokerDashboardActions.uploadSellerProfileImageAction.REQUEST:
    case BrokerDashboardActions.uploadSellerProfileImageAction.FAILURE:
    case BrokerDashboardActions.uploadSellerProfileImageAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.uploadSellerProfileImageAction,
          "uploadSellerProfileImageResponse"
        ),
      }
    }

    case BrokerDashboardActions.setFileUploading.type: {
      return {
        ...state,
        fileUploading: action.data,
      }
    }

    case BrokerDashboardActions.getAgentAssetsActions.REQUEST:
    case BrokerDashboardActions.getAgentAssetsActions.SUCCESS:
    case BrokerDashboardActions.getAgentAssetsActions.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.getAgentAssetsActions, "agentAssetsResponse"),
      }
    }

    case BrokerDashboardActions.getSetupProfileAction.REQUEST:
    case BrokerDashboardActions.getSetupProfileAction.SUCCESS:
    case BrokerDashboardActions.getSetupProfileAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.getSetupProfileAction, "setupProfileResponse"),
      }
    }

    case BrokerDashboardActions.getRARTeamListAction.REQUEST:
    case BrokerDashboardActions.getRARTeamListAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.getRARTeamListAction, "rarTeamListResponse"),
      }
    }

    case BrokerDashboardActions.getRARTeamListAction.SUCCESS: {
      const fetchedTeams = action.data
      let isCustomTeamAgreement = 3
      let filteredTeam = []
      const currentTeamId = state
      && state.agentBrokerageInfoResponse.data
      && state.agentBrokerageInfoResponse.data.brokerage_team_id
      let dbValueTeamAgreement = null
      if (currentTeamId) {
        filteredTeam = fetchedTeams.filter(item => item.id === currentTeamId)
        console.log("filteredTeam", filteredTeam)
        dbValueTeamAgreement = filteredTeam && filteredTeam[0].is_custom_team_agreement


        console.log("dbValueTeamAgreement", dbValueTeamAgreement)

        if (dbValueTeamAgreement === 0) {
          isCustomTeamAgreement = 2
        } else if (dbValueTeamAgreement === 1) {
          isCustomTeamAgreement = 1
        } else if (dbValueTeamAgreement === null) {
          isCustomTeamAgreement = 3
        }
      }
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.getRARTeamListAction, "rarTeamListResponse"),
        teamAgreementViewType: isCustomTeamAgreement,
        teamBasedCurrentTeamAgreementStatus: dbValueTeamAgreement,
      }
    }

    case BrokerDashboardActions.getBrokerTeamsOverviewAction.REQUEST:
    case BrokerDashboardActions.getBrokerTeamsOverviewAction.SUCCESS:
    case BrokerDashboardActions.getBrokerTeamsOverviewAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.getBrokerTeamsOverviewAction, "getBrokerTeamsOverviewResponse"),
      }
    }

    case BrokerDashboardActions.getBrokerageTeamDownloadAction.REQUEST:
    case BrokerDashboardActions.getBrokerageTeamDownloadAction.FAILURE:
    case BrokerDashboardActions.getBrokerageTeamDownloadAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.getBrokerageTeamDownloadAction, "teamsDownloadDataResponse"),
      }
    }

    case BrokerDashboardActions.getAssetsDashboardAction.REQUEST:
    case BrokerDashboardActions.getAssetsDashboardAction.SUCCESS:
    case BrokerDashboardActions.getAssetsDashboardAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.getAssetsDashboardAction, "globalAssetsResponse"),
      }
    }

    case BrokerDashboardActions.getAgentStatesActions.REQUEST:
    case BrokerDashboardActions.getAgentStatesActions.SUCCESS:
    case BrokerDashboardActions.getAgentStatesActions.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.getAgentStatesActions, "agentStates"),
      }
    }

    case BrokerDashboardActions.getTeamDetailsActions.REQUEST:
    case BrokerDashboardActions.getTeamDetailsActions.SUCCESS:
    case BrokerDashboardActions.getTeamDetailsActions.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.getTeamDetailsActions, "teamDetailsResponse"),
      }
    }

    case BrokerDashboardActions.getUSStatesAction.REQUEST:
    case BrokerDashboardActions.getUSStatesAction.SUCCESS:
    case BrokerDashboardActions.getUSStatesAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.getUSStatesAction, "statesResponse"),
      }
    }

    case BrokerDashboardActions.getSubsribedAddonsAction.REQUEST:
    case BrokerDashboardActions.getSubsribedAddonsAction.SUCCESS:
    case BrokerDashboardActions.getSubsribedAddonsAction.FAILURE: {
      console.log("lala", action.data)
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.getSubsribedAddonsAction, "addonsResponse"),
      }
    }

    case BrokerDashboardActions.getAllDealsListingActions.REQUEST:
    case BrokerDashboardActions.getAllDealsListingActions.SUCCESS:
    case BrokerDashboardActions.getAllDealsListingActions.CLEAR:
    case BrokerDashboardActions.getAllDealsListingActions.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.getAllDealsListingActions, "dealsListingResponse"),
      }
    }

    case BrokerDashboardActions.allDealsListingFilterActions.type: {
      return {
        ...state,
        allDealsListingFilter: action.data,
      }
    }

    case BrokerDashboardActions.showQueryMoreForDealsActions.type: {
      return {
        ...state,
        showQueryMoreForDeals: action.data,
      }
    }

    case BrokerDashboardActions.showTCLoaderActions.type: {
      return {
        ...state,
        tcLoader: action.data,
      }
    }

    case BrokerDashboardActions.getAgentDetailsAction.REQUEST:
    case BrokerDashboardActions.getAgentDetailsAction.SUCCESS:
    case BrokerDashboardActions.getAgentDetailsAction.CLEAR:
    case BrokerDashboardActions.getAgentDetailsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.getAgentDetailsAction, "agentDetailsResponse"),
      }
    }

    case BrokerDashboardActions.checkCouponAction.REQUEST:
    case BrokerDashboardActions.checkCouponAction.SUCCESS:
    case BrokerDashboardActions.checkCouponAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.checkCouponAction, "couponCodeResponse"),
      }
    }

    case BrokerDashboardActions.getTCAgentAssignedAction.REQUEST:
    case BrokerDashboardActions.getTCAgentAssignedAction.FAILURE:
    case BrokerDashboardActions.getTCAgentAssignedAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.getTCAgentAssignedAction,
          "getTCAgentAssignedResponse"
        ),
      }
    }

    case BrokerDashboardActions.changePlanDurationAction.type: {
      return {
        ...state,
        selectedDuration: action.data,
      }
    }

    case BrokerDashboardActions.toggleIsBusyAction.type: {
      return {
        ...state,
        isBusy: action.data,
      }
    }

    case BrokerDashboardActions.resetSelectionsAction.type: {
      return {
        ...state,
        selectedPlan: {},
      }
    }

    case BrokerDashboardActions.selectPlanAction.type: {
      return {
        ...state,
        selectedPlan: action.data,
      }
    }

    case BrokerDashboardActions.setRadiusFeeAction.type: {
      return {
        ...state,
        radiusFee: action.data,
      }
    }

    case BrokerDashboardActions.updateAssetsFormLinksAction.type: {
      return {
        ...state,
        assetForm: action.data,
      }
    }

    case BrokerDashboardActions.clearAddonsAction.type: {
      return {
        ...state,
        selectedAddons: [],
      }
    }

    case BrokerDashboardActions.setTemplateListAction.type: {
      return {
        ...state,
        templateList: [],
      }
    }

    case BrokerDashboardActions.setTemplateInvalidAction.type: {
      return {
        ...state,
        templateInvalid: action.data,
      }
    }

    case BrokerDashboardActions.deleteTemplateListAction.type: {
      return {
        ...state,
        deleteTemplates: [],
      }
    }

    case BrokerDashboardActions.removeCouponCodeAction.type: {
      return {
        ...state,
        ...createDeltaReducer("couponCodeResponse"),
        currentCouponText: "",
      }
    }

    case BrokerDashboardActions.selectAddonAction.type: {
      if (action.data === "removeKVCore") {
        const removeMoxi = state.selectedAddons.findIndex(x => x.name === "Moxi/Active Pipe")
        state.selectedAddons.splice(removeMoxi, 1)
        const indexToBeRemoved = state.selectedAddons.findIndex(x => x.name === "kvCORE/Follow Up Boss")
        state.selectedAddons.splice(indexToBeRemoved, 1)
        return {
          ...state,
          selectedAddons: [...state.selectedAddons],
        }
      }
      // const { id } = action.data
      // const found = state.selectedAddons.some(el => el.id === id)
      // if (found) {
      //   const indexToBeRemove = state.selectedAddons.findIndex(x => x.id === id)
      //   state.selectedAddons.splice(indexToBeRemove, 1)
      // } else {
      //   state.selectedAddons.push(action.data)
      // }
      return {
        ...state,
        selectedAddons: [...state.selectedAddons],
      }
    }
    case BrokerDashboardActions.getCurrentCitiesServedAction.REQUEST:
    case BrokerDashboardActions.getCurrentCitiesServedAction.SUCCESS:
    case BrokerDashboardActions.getCurrentCitiesServedAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.getCurrentCitiesServedAction, "getCitiesServedResponse"),
      }
    }

    case BrokerDashboardActions.getContractStatusAction.REQUEST:
    case BrokerDashboardActions.getContractStatusAction.SUCCESS:
    case BrokerDashboardActions.getContractStatusAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.getContractStatusAction, "getContractStatusResponse"),
      }
    }

    case BrokerDashboardActions.getNeighbourHoodFromCitiesAction.REQUEST:
    case BrokerDashboardActions.getNeighbourHoodFromCitiesAction.SUCCESS:
    case BrokerDashboardActions.getNeighbourHoodFromCitiesAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.getNeighbourHoodFromCitiesAction,
          "getNeighbourHoodFromCitiesResponse"
        ),
      }
    }

    case BrokerDashboardActions.saveAgentServedNeighbourhoodAction.REQUEST:
    case BrokerDashboardActions.saveAgentServedNeighbourhoodAction.SUCCESS:
    case BrokerDashboardActions.saveAgentServedNeighbourhoodAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.saveAgentServedNeighbourhoodAction,
          "saveAgentServedNeighbourhoodResponse"
        ),
      }
    }

    case BrokerDashboardActions.deleteNeighbourhoodAction.REQUEST:
    case BrokerDashboardActions.deleteNeighbourhoodAction.SUCCESS:
    case BrokerDashboardActions.deleteNeighbourhoodAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.deleteNeighbourhoodAction,
          "deleteNeighbourhoodResponse"
        ),
      }
    }

    case BrokerDashboardActions.getTCListAction.REQUEST:
    case BrokerDashboardActions.getTCListAction.SUCCESS:
    case BrokerDashboardActions.getTCListAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.getTCListAction, "getTCListResponse"),
      }
    }

    case BrokerDashboardActions.getDealStatusTimelineAction.REQUEST:
    case BrokerDashboardActions.getDealStatusTimelineAction.SUCCESS:
    case BrokerDashboardActions.getDealStatusTimelineAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.getDealStatusTimelineAction, "getDealStatusTimelineResponse"),
      }
    }

    case BrokerDashboardActions.toggleTimelineDrawerAction.type: {
      return {
        ...state,
        isTimelineDrawerOpen: action.data,
      }
    }

    case BrokerDashboardActions.toggleEscrowDrawerAction.type: {
      return {
        ...state,
        isEscrowDrawerOpen: action.data,
      }
    }

    case BrokerDashboardActions.toggleUploadImageDrawerAction.type: {
      return {
        ...state,
        isUploadImage: action.data,
      }
    }

    case BrokerDashboardActions.setDealIdTimelineAction.type: {
      return {
        ...state,
        dealIdTimeline: action.data,
      }
    }

    case BrokerDashboardActions.setDealIdToUploadAction.type: {
      return {
        ...state,
        dealIdToUpload: action.data,
      }
    }

    case BrokerDashboardActions.setBusinessCard.type: {
      return {
        ...state,
        businessCard: action.data,
      }
    }

    case BrokerDashboardActions.setYardSign.type: {
      return {
        ...state,
        yardSign: action.data,
      }
    }

    case BrokerDashboardActions.getListingCoverSheet.REQUEST:
    case BrokerDashboardActions.getListingCoverSheet.SUCCESS:
    case BrokerDashboardActions.getListingCoverSheet.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.getListingCoverSheet, "getListingCoverSheetResponse"),
      }
    }

    case BrokerDashboardActions.getContractsDetails.REQUEST:
    case BrokerDashboardActions.getContractsDetails.SUCCESS:
    case BrokerDashboardActions.getContractsDetails.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.getContractsDetails, "getContractsDetailsResponse"),
      }
    }

    case BrokerDashboardActions.createListingCoverSheet.REQUEST:
    case BrokerDashboardActions.createListingCoverSheet.SUCCESS:
    case BrokerDashboardActions.createListingCoverSheet.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.createListingCoverSheet, "createListingCoverSheetResponse"),
      }
    }

    case BrokerDashboardActions.updateListingCoverSheet.REQUEST:
    case BrokerDashboardActions.updateListingCoverSheet.SUCCESS:
    case BrokerDashboardActions.updateListingCoverSheet.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.updateListingCoverSheet, "updateListingCoverSheetResponse"),
      }
    }

    case BrokerDashboardActions.getListingTypeAction.REQUEST:
    case BrokerDashboardActions.getListingTypeAction.SUCCESS:
    case BrokerDashboardActions.getListingTypeAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, BrokerDashboardActions.getListingTypeAction, "getListingTypeResponse"),
      }
    }

    case BrokerDashboardActions.setDealToView.type: {
      return {
        ...state,
        dealToView: action.data,
      }
    }

    case BrokerDashboardActions.setTotalListingCount.type: {
      return {
        ...state,
        totalListingCount: action.data,
      }
    }

    case BrokerDashboardActions.setLoaderOnAgentInfo.type: {
      return {
        ...state,
        loaderOnAgentInfo: action.data,
      }
    }

    case BrokerDashboardActions.setEscrowIdToEdit.type: {
      return {
        ...state,
        escrowIdToEdit: action.data,
      }
    }

    case BrokerDashboardActions.setDealAgentId.type: {
      return {
        ...state,
        dealAgentId: action.data,
      }
    }

    case BrokerDashboardActions.setEscrowEdit.type: {
      return {
        ...state,
        isEditEscrow: action.data,
      }
    }

    case BrokerDashboardActions.setAllVendors.type: {
      return {
        ...state,
        allVendors: action.data,
      }
    }

    case BrokerDashboardActions.setAllListingImagesAction.type: {
      return {
        ...state,
        allListingImages: action.data,
      }
    }

    case BrokerDashboardActions.setSelectedId.type: {
      return {
        ...state,
        selectedId: action.data,
      }
    }

    case BrokerDashboardActions.toggleTimelineChecklistAction.type: {
      return {
        ...state,
        isOpenTimelineChecklist: action.data,
      }
    }

    case BrokerDashboardActions.resetListings.type: {
      return {
        ...state,
        getListingCoverSheetResponse: { data: null },
      }
    }

    case BrokerDashboardActions.fetchDealTimelineAction.REQUEST:
    case BrokerDashboardActions.fetchDealTimelineAction.FAILURE:
    case BrokerDashboardActions.fetchDealTimelineAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.fetchDealTimelineAction,
          "fetchDealTimelineResponse"
        ),
      }
    }

    case BrokerDashboardActions.saveListingStepsAction.REQUEST:
    case BrokerDashboardActions.saveListingStepsAction.FAILURE:
    case BrokerDashboardActions.saveListingStepsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.saveListingStepsAction,
          "saveListingStepsResponse"
        ),
      }
    }

    case BrokerDashboardActions.addCreditCardDetailsAction.REQUEST:
    case BrokerDashboardActions.addCreditCardDetailsAction.FAILURE:
    case BrokerDashboardActions.addCreditCardDetailsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.addCreditCardDetailsAction,
          "addCreditCardDetailsResponse"
        ),
      }
    }

    case BrokerDashboardActions.getAgentBrokerageInfoMetadataAction.REQUEST:
    case BrokerDashboardActions.getAgentBrokerageInfoMetadataAction.FAILURE:
    case BrokerDashboardActions.getAgentBrokerageInfoMetadataAction.SUCCESS: {
      console.log("??? bruh", action.data)
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.getAgentBrokerageInfoMetadataAction,
          "getBrokerageInfoMetabaseResponse"
        ),
      }
    }

    case BrokerDashboardActions.addOnPlanIdsAction.type: {
      return {
        ...state,
        addOnPlanIds: [action.data],
      }
    }

    case BrokerDashboardActions.selectedSubscriptionPlansAction.type: {
      const { key } = action.data
      const found = state.selectedAgentPlans.some(el => el.key === key)
      if (found && !isEmpty(state.selectedAgentPlans)) {
        const indexToBeRemoved = state.selectedAgentPlans.findIndex(x => x.key === key)
        state.selectedAgentPlans.splice(indexToBeRemoved, 1)
        state.selectedAgentPlans.push(action.data)
      } else {
        state.selectedAgentPlans.push(action.data)
      }
      return {
        ...state,
        selectedAgentPlans: [...state.selectedAgentPlans],
      }
    }

    case BrokerDashboardActions.updateSubscriptionV2Action.REQUEST:
    case BrokerDashboardActions.updateSubscriptionV2Action.SUCCESS:
    case BrokerDashboardActions.updateSubscriptionV2Action.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.updateSubscriptionV2Action,
          "updateSubscriptionV2Response"
        ),
      }
    }

    case BrokerDashboardActions.checkoutBrokerPlanV2Action.REQUEST:
    case BrokerDashboardActions.checkoutBrokerPlanV2Action.SUCCESS:
    case BrokerDashboardActions.checkoutBrokerPlanV2Action.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.checkoutBrokerPlanV2Action,
          "checkoutBrokerPlanV2Response"
        ),
      }
    }

    case BrokerDashboardActions.cancelSubscriptionAction.REQUEST:
    case BrokerDashboardActions.cancelSubscriptionAction.SUCCESS:
    case BrokerDashboardActions.cancelSubscriptionAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.cancelSubscriptionAction,
          "cancelSubscriptionResponse"
        ),
      }
    }

    case BrokerDashboardActions.toggleICADetailsModalAction.type: {
      return {
        ...state,
        isICAModalVisible: action.data,
      }
    }

    case BrokerDashboardActions.fetchICAListingsAction.REQUEST:
    case BrokerDashboardActions.fetchICAListingsAction.SUCCESS:
    case BrokerDashboardActions.fetchICAListingsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.fetchICAListingsAction,
          "fetchICAListingsResponse"
        ),
      }
    }

    case BrokerDashboardActions.fetchAgentICADetailsAction.REQUEST:
    case BrokerDashboardActions.fetchAgentICADetailsAction.SUCCESS:
    case BrokerDashboardActions.fetchAgentICADetailsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.fetchAgentICADetailsAction,
          "fetchAgentICADetailsResponse"
        ),
      }
    }

    case BrokerDashboardActions.triggerSenderViewDocuSignAction.REQUEST:
    case BrokerDashboardActions.triggerSenderViewDocuSignAction.SUCCESS:
    case BrokerDashboardActions.triggerSenderViewDocuSignAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.triggerSenderViewDocuSignAction,
          "triggerSenderViewDocuSignResponse"
        ),
      }
    }

    case BrokerDashboardActions.triggerRecieverViewDocuSignAction.REQUEST:
    case BrokerDashboardActions.triggerRecieverViewDocuSignAction.SUCCESS:
    case BrokerDashboardActions.triggerRecieverViewDocuSignAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.triggerRecieverViewDocuSignAction,
          "triggerRecieverViewDocuSignResponse"
        ),
      }
    }
    case BrokerDashboardActions.inviteWithoutSubscriptionAction.REQUEST:
    case BrokerDashboardActions.inviteWithoutSubscriptionAction.SUCCESS:
    case BrokerDashboardActions.inviteWithoutSubscriptionAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.inviteWithoutSubscriptionAction,
          "inviteWithoutSubscriptionResponse"
        ),
      }
    }

    case BrokerDashboardActions.updateICADocumentStatusAction.REQUEST:
    case BrokerDashboardActions.updateICADocumentStatusAction.SUCCESS:
    case BrokerDashboardActions.updateICADocumentStatusAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.updateICADocumentStatusAction,
          "updateICADocumentStatusResponse"
        ),
      }
    }

    case BrokerDashboardActions.mergeReviewAndSendDocumentsAction.REQUEST:
    case BrokerDashboardActions.mergeReviewAndSendDocumentsAction.SUCCESS:
    case BrokerDashboardActions.mergeReviewAndSendDocumentsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.mergeReviewAndSendDocumentsAction,
          "mergeReviewAndSendDocumentsResponse"
        ),
      }
    }

    case BrokerDashboardActions.uploadICAAction.REQUEST:
    case BrokerDashboardActions.uploadICAAction.SUCCESS:
    case BrokerDashboardActions.uploadICAAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.uploadICAAction,
          "uploadICAResponse"
        ),
      }
    }

    case BrokerDashboardActions.uploadTeamAgreementAction.REQUEST:
    case BrokerDashboardActions.uploadTeamAgreementAction.SUCCESS:
    case BrokerDashboardActions.uploadTeamAgreementAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.uploadTeamAgreementAction,
          "uploadTeamAgreementResponse"
        ),
      }
    }

    case BrokerDashboardActions.toggleTeamAgreementTypeAction.type: {
      return {
        ...state,
        teamAgreementViewType: action.data,
      }
    }

    case BrokerDashboardActions.setCurrentAgentViewedAction.type: {
      return {
        ...state,
        currentAgentIDViewed: action.data,
      }
    }

    case BrokerDashboardActions.fetchTeamAgreementsAction.REQUEST:
    case BrokerDashboardActions.fetchTeamAgreementsAction.SUCCESS:
    case BrokerDashboardActions.fetchTeamAgreementsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.fetchTeamAgreementsAction,
          "fetchTeamAgreementsResponse"
        ),
      }
    }

    case BrokerDashboardActions.saveStripeTokenAction.REQUEST:
    case BrokerDashboardActions.saveStripeTokenAction.SUCCESS:
    case BrokerDashboardActions.saveStripeTokenAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.saveStripeTokenAction,
          "saveStripeTokenResponse"
        ),
      }
    }

    case BrokerDashboardActions.getTentativeBrokerageSubscriptionDetailsAction.REQUEST:
    case BrokerDashboardActions.getTentativeBrokerageSubscriptionDetailsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.getTentativeBrokerageSubscriptionDetailsAction,
          "getTentativeBrokerageSubscriptionDetailsResponse",
        ),
      }
    }

    case BrokerDashboardActions.getTentativeBrokerageSubscriptionDetailsAction.SUCCESS: {

      const response = action.data

      const {
        addOnBenefits,
        basePlan,
        cap: commissionCap,
        riskManagementPlan,
        split,
        production,
      } = response || {}

      const selectedPlan = {
        planId: basePlan.plan_id,
        plan: {
          ...basePlan,
        },
      }

      const addOnPlanIds = [{
        id: riskManagementPlan.id,
        plan_id: riskManagementPlan.stripe_plan_id,
        price: riskManagementPlan.unit_price,
      }]

      const selectedAgentPlans = [{
        key: "production",
        production,
      },
      {
        key: "split",
        split,
      },
      {
        key: "commission_cap",
        commission_cap: commissionCap,
      }]
      
      const selectedAddonsResponse = addOnBenefits && addOnBenefits.length > 0 && addOnBenefits[0]

      const selectedAddons = selectedAddonsResponse && [...selectedAddonsResponse] || []

      console.log("selectedAgentPlans", addOnPlanIds)

      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.getTentativeBrokerageSubscriptionDetailsAction,
          "getTentativeBrokerageSubscriptionDetailsResponse",
        ),
        selectedPlan,
        addOnPlanIds,
        selectedAgentPlans,
        selectedAddons,
      }
    }

    case BrokerDashboardActions.fetchRecruiterAndAsmInfoAction.REQUEST:
    case BrokerDashboardActions.fetchRecruiterAndAsmInfoAction.SUCCESS:
    case BrokerDashboardActions.fetchRecruiterAndAsmInfoAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.fetchRecruiterAndAsmInfoAction,
          "fetchRecruiterAndAsmInfoResponse"
        ),
      }
    }

    case BrokerDashboardActions.updateRecruiterAndAsmInfoAction.REQUEST:
    case BrokerDashboardActions.updateRecruiterAndAsmInfoAction.SUCCESS:
    case BrokerDashboardActions.updateRecruiterAndAsmInfoAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.updateRecruiterAndAsmInfoAction,
          "updateRecruiterAndAsmInfoResponse"
        ),
      }
    }

    case BrokerDashboardActions.fetchAgentAsmRecruiterInfoAction.REQUEST:
    case BrokerDashboardActions.fetchAgentAsmRecruiterInfoAction.SUCCESS:
    case BrokerDashboardActions.fetchAgentAsmRecruiterInfoAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.fetchAgentAsmRecruiterInfoAction,
          "fetchAgentAsmRecruiterInfoResponse"
        ),
      }
    }

    case BrokerDashboardActions.uploadICAFileAction.REQUEST:
    case BrokerDashboardActions.uploadICAFileAction.SUCCESS:
    case BrokerDashboardActions.uploadICAFileAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.uploadICAFileAction,
          "uploadICAFileResponse"
        ),
      }
    }

    case BrokerDashboardActions.sendOnboardingEmailAction.REQUEST:
    case BrokerDashboardActions.sendOnboardingEmailAction.SUCCESS:
    case BrokerDashboardActions.sendOnboardingEmailAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.sendOnboardingEmailAction,
          "sendOnboardingEmailResponse"
        ),
      }
    }

    case BrokerDashboardActions.copyOnboardingURLAction.REQUEST:
    case BrokerDashboardActions.copyOnboardingURLAction.SUCCESS:
    case BrokerDashboardActions.copyOnboardingURLAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.copyOnboardingURLAction,
          "copyOnboardingURLResponse"
        ),
      }
    }

    case BrokerDashboardActions.fetchFullySignedICAAction.REQUEST:
    case BrokerDashboardActions.fetchFullySignedICAAction.SUCCESS:
    case BrokerDashboardActions.fetchFullySignedICAAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.fetchFullySignedICAAction,
          "fetchFullySignedICAResponse"
        ),
      }
    }

    case BrokerDashboardActions.uploadFullySignedICAAction.REQUEST:
    case BrokerDashboardActions.uploadFullySignedICAAction.SUCCESS:
    case BrokerDashboardActions.uploadFullySignedICAAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.uploadFullySignedICAAction,
          "uploadFullySignedICAResponse"
        ),
      }
    }

    case BrokerDashboardActions.saveTeamAgreementAction.REQUEST:
    case BrokerDashboardActions.saveTeamAgreementAction.SUCCESS:
    case BrokerDashboardActions.saveTeamAgreementAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.saveTeamAgreementAction,
          "saveTeamAgreementResponse"
        ),
      }
    }

    case BrokerDashboardActions.triggerTemplateEditViewAction.REQUEST:
    case BrokerDashboardActions.triggerTemplateEditViewAction.SUCCESS:
    case BrokerDashboardActions.triggerTemplateEditViewAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.triggerTemplateEditViewAction,
          "triggerTemplateEditViewResponse"
        ),
      }
    }

    case BrokerDashboardActions.getTemplateDownloadURLAction.REQUEST:
    case BrokerDashboardActions.getTemplateDownloadURLAction.SUCCESS:
    case BrokerDashboardActions.getTemplateDownloadURLAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.getTemplateDownloadURLAction,
          "getTemplateDownloadURLResponse"
        ),
      }
    }

    case BrokerDashboardActions.toggleUploadModeForICAAction.type: {
      return {
        ...state,
        icaUploadModeVisible: action.data,
      }
    }

    case BrokerDashboardActions.toggleFullySignedICAUploadUIAction.type: {
      return {
        ...state,
        fullySignedICAUploadVisible: action.data,
      }
    }

    case BrokerDashboardActions.disableOtherOptionsICAActions.type: {
      return {
        ...state,
        disableICAModes: action.data,
      }
    }

    case BrokerDashboardActions.currentlySelectedTypeAction.type: {
      return {
        ...state,
        currentTypeICAFlow: action.data,
      }
    }

    case BrokerDashboardActions.toggleMissingTeamMessageAction.type: {
      return {
        ...state,
        missingTeamMessage: action.data,
      }
    }

    case BrokerDashboardActions.showUpdateLiveDotAction.type: {
      return {
        ...state,
        isLiveLoaderShown: action.data,
      }
    }

    case BrokerDashboardActions.setTeamIDFromURLAction.type: {
      return {
        ...state,
        urlBasedTeamId: action.data,
      }
    }

    case BrokerDashboardActions.uploadCSVToS3Action.REQUEST:
    case BrokerDashboardActions.uploadCSVToS3Action.FAILURE:
    case BrokerDashboardActions.uploadCSVToS3Action.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.uploadCSVToS3Action,
          "uploadCSVToS3Response",
        ),
      }
    }

    case BrokerDashboardActions.uploadS3LocationAction.REQUEST:
    case BrokerDashboardActions.uploadS3LocationAction.FAILURE:
    case BrokerDashboardActions.uploadS3LocationAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.uploadS3LocationAction,
          "uploadS3LocationResponse",
        ),
      }
    }

    //triggerVoidAndDeleteAction
    case BrokerDashboardActions.triggerVoidAndDeleteAction.REQUEST:
    case BrokerDashboardActions.triggerVoidAndDeleteAction.FAILURE:
    case BrokerDashboardActions.triggerVoidAndDeleteAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.triggerVoidAndDeleteAction,
          "triggerVoidAndDeleteResponse",
        ),
      }
    }

    //updateSubscriptionDateAction
    case BrokerDashboardActions.updateSubscriptionDateAction.REQUEST:
    case BrokerDashboardActions.updateSubscriptionDateAction.FAILURE:
    case BrokerDashboardActions.updateSubscriptionDateAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          BrokerDashboardActions.updateSubscriptionDateAction,
          "updateSubscriptionDateResponse",
        ),
      }
    }

    case BrokerDashboardActions.toggleModalViewAction.type: {
      return {
        ...state,
        isModalViewOpen: action.data,
      }
    }

    case BrokerDashboardActions.highlightSelectedAddonsAction.type: {
      if (action.data.name === "E&O Insurance") {
        return {
          ...state,
          addOnsSelected: [...state.addOnsSelected],
          addOnsRemoved: [...state.addOnsRemoved],
        }
      }
      if (action.data.removeAddOn) {
        const indexToBeRemoved = state.addOnsSelected.findIndex(x => x.id === action.data.planBenefits.id)
        state.addOnsSelected.splice(indexToBeRemoved, 1)
        const removeIndex = state.addOnsRemoved.findIndex(x => x.id === action.data.planBenefits.id)
        const { planBenefits } = action.data
        if (removeIndex !== -1) {
          return {
            ...state,
            addOnsSelected: [...state.addOnsSelected],
            addOnsRemoved: [...state.addOnsRemoved],
          }
        }
        return {
          ...state,
          addOnsSelected: [...state.addOnsSelected],
          addOnsRemoved: [...state.addOnsRemoved, planBenefits],
        }
      }
      const indexToBeRemoved = state.addOnsRemoved.findIndex(x => x.id === action.data.id)
      if (indexToBeRemoved !== -1) {
        state.addOnsRemoved.splice(indexToBeRemoved, 1)
      }
      return {
        ...state,
        addOnsSelected: [...state.addOnsSelected, action.data],
        addOnsRemoved: [...state.addOnsRemoved],
      }
    }

    case BrokerDashboardActions.toggleVoidAndDeleteModalAction.type: {
      return {
        ...state,
        isTriggerVoidAndDeleteModalOpen: action.data,
      }
    }

    case BrokerDashboardActions.toggleTeamCreateModalAction.type: {
      return {
        ...state,
        toggleTeamModal: action.data,
      }
    }

    case BrokerDashboardActions.inviteWithoutSubscriptionPayloadCreationAction.type: {
      return {
        ...state,
        inviteWithoutSubscriptionPayload: action.data,
      }
    }

    case BrokerDashboardActions.toggleInviteWithoutSubscriptionModalAction.type: {
      const {
        data,
        bool,
      } = action.data || {}

      return {
        ...state,
        isInviteWithoutSubscriptionModalOpen: bool,
        inviteWithoutSubscriptionPayload: data,
      }
    }

    case BrokerDashboardActions.handleCouponFieldChangeAction.type: {
      return {
        ...state,
        currentCouponText: action.data,
      }
    }

    case BrokerDashboardActions.setListingStateAction.type: {
      return {
        ...state,
        listingState: action.data,
      }
    }

    case BrokerDashboardActions.setIsCreatingDealAction.type: {
      return {
        ...state,
        isCreatingDeal: action.data,
      }
    }

    default:
      return state
  }
}
