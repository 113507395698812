/* eslint-disable import/no-unresolved */
import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components"
import SubContainer from "container/BrokerDashboard/subscriptionContainer"
import { get } from "lodash"
import {
  Container,
  PlanClickBar,
  PlanTitle,
  PlanWrap,
  PlanPriceWrap,
  PlanPrice,
  Title,
  TitleWrap,
} from "../../common"
import BrokerDashboardContainer from "container/BrokerDashboard/sidebarContainer"
import AgentContainer from "container/BrokerDashboard/editAgentContainer"

const Wrap = styled.div``

const AddOns = ({
  planBenefitsResponse,
  highlightSelectedAddon,
  selectedAddons,
  addonsResponse,
  addOnsSelected,
  getTentativeBrokerageSubscriptionDetailsResponse,
  selectedAgentPlans,
  selectedPlan,
}) => {
  const selectedAddonsRef = useRef(new Set());
  
  const planBenefitsData = get(planBenefitsResponse, "data");
  const filteredBenefits = planBenefitsData ? planBenefitsData.filter(filteredData => filteredData.name !== "E&O Insurance") : []
  const getUnitPrice = planBenefits => planBenefits.name === "Branding" || planBenefits.name === "Custom Logo" || planBenefits.type === "REQUEST"
    ? `$${planBenefits.unit_price}`
    : (planBenefits.plan_details && planBenefits?.plan_details[0]?.billing_cycle === "YEARLY")
      ? planBenefits?.plan_details && `$${planBenefits?.plan_details[0]?.unit_price}/yearly` : planBenefits?.plan_details && `$${planBenefits.plan_details[0]?.unit_price}/month`
      const addOnBenefits = get(getTentativeBrokerageSubscriptionDetailsResponse, 'data.addOnBenefits', [])

      useEffect(() => {
        if(addOnBenefits && addOnBenefits.length > 0) {
          addOnBenefits.forEach(benefit => {
            if (typeof benefit === 'object' && benefit.add_on_id && !selectedAddonsRef.current.has(benefit.add_on_id)) {
              const index = filteredBenefits.findIndex(filteredData => filteredData.id === benefit.add_on_id);
              if(index !== -1) {
                selectedAddonsRef.current.add(benefit.add_on_id);
                console.log("payload", filteredBenefits[index])
                highlightSelectedAddon(filteredBenefits[index]);
              }
            }
          });
        }
      }, [addOnBenefits, filteredBenefits, highlightSelectedAddon]);


  return (
    <Wrap>
      <Container>
        <TitleWrap>
          <Title>
          Add Ons
          </Title>
        </TitleWrap>
        <PlanWrap>
          {filteredBenefits.length > 0 && filteredBenefits.map(planBenefits => (
          <>
            <PlanClickBar
              onClick={() => {
                const isSelected = addOnsSelected.some(el => el.id === planBenefits.id)
                console.log("isSelected ikea", isSelected,planBenefits,addOnsSelected)
                if (isSelected) {
                  const payload = {
                    removeAddOn: true,
                    planBenefits,
                  }
                  highlightSelectedAddon(payload)
                } else {
                  highlightSelectedAddon(planBenefits)
                }
              }}
              isSelected={addOnsSelected.some(el => el.id === planBenefits.id) }
              key={planBenefits.id}
              width={215}
            >
              <PlanTitle
                height="48px"
              >
                {planBenefits.name}
              </PlanTitle>
              <PlanPriceWrap
                isSelected={addOnsSelected.some(el => el.id === planBenefits.id)}
                key={planBenefits.id}
              >
                <PlanPrice
                  isSelected={addOnsSelected.some(el => el.id === planBenefits.id) }
                  key={planBenefits.id}
                >
                  {getUnitPrice(planBenefits)}
                </PlanPrice>
              </PlanPriceWrap>
            </PlanClickBar>
          </>
          ))}
        </PlanWrap>
      </Container>
    </Wrap>
  )
}

export default BrokerDashboardContainer(AgentContainer(SubContainer(AddOns)))
