export const getSource = (value) => {
  switch (value) {
    case "radius_marketplace":
      return "Radius Marketplace"
    case "zillow_agent_personal_account":
      return "Zillow (Agent's Personal Account)"
    case "zillow_radius_provided":
      return "Zillow (Radius Provided)"
    case "zillow_flex":
      return "Zillow Flex"
    case "opcity_realtor_com":
      return "OpCity/Realtor.com"
    case "team_mentor_lead":
      return "Team/Mentor Lead"
    case "self":
      return "Self"
    case "sphere_of_influence":
      return "Sphere of Influence/Personal"
    case "referrals_agent_Lender":
      return "Referrals (Agent/Lender)"
    case "fsbo":
      return "FSBO"
    case "mail":
      return "Mail"
    case "networking":
      return "Networking"
    case "new_construction":
      return "New Construction"
    case "open_house":
      return "Open House"
    case "other_agency":
      return "Other - Agency"
    case "other_builder":
      return "Other - Builder"
    case "paid_marketing_farming_direct":
      return "Paid Marketing - Farming/Direct"
    case "paid_marketing_other":
      return "Paid Marketing - Other"
    case "paid_marketing_zillow":
      return "Paid Marketing - Zillow"
    case "personal_transaction":
      return "Personal Transaction"
    case "property_management":
      return "Property Management"
    case "floor_call":
      return "Floor Call"
    case "referral_attorney":
      return "Referral - Attorney"
    case "referral_from_past_client":
      return "Referral - From Past Client"
    case "referral_lendor":
      return "Referral - Lendor"
    case "referral_other":
      return "Referral - Other"
    case "referral_real_estate_agent_external":
      return "Referral - Real Estate Agent(External)"
    case "referral_sphere_of_influence":
      return "Referral - Sphere of Influence"
    case "referral_title":
      return "Referral - Title"
    case "sign_call":
      return "Sign Call"
    case "social_profile_facebook":
      return "Social Profile - Facebook"
    case "social_profile_instagram":
      return "Social Profile - Instagram"
    case "social_profile_other":
      return "Social Profile - Other"
    case "events_other":
      return "Events - Other"
    default:
      return "Other"
  }
}
