// /* eslint-disable import/no-unresolved */
// import React, { useEffect } from "react"
// import styled from "styled-components"
// import { ShimmerBox } from "shared/styles/animation"
// import SubDeetsContainer from "container/Agent"
// import AgentContainer from "container/BrokerDashboard/editAgentContainer"
// import { get } from "lodash"
// import {
//   Container,
//   PlanClickBar,
//   PlanWrap,
//   PlanPriceWrap,
//   PlanPrice,
//   Title,
//   TitleWrap,
//   ShimmerWrap,
// } from "../../common"

// const Wrap = styled.div``

// const Split = ({
//   getBrokerageInfoMetabaseResponse,
//   selectAgentPlan,
//   selectedAgentPlans,
//   subscriptions
// }) => {
//   const isSplitAlready = get(subscriptions, "data.additional_info.split", "")
//   const isFetching = get(getBrokerageInfoMetabaseResponse, "isFetching")
//   const split = get(getBrokerageInfoMetabaseResponse, "data.split")

//   useEffect(() => {
//     if (isSplitAlready) {
//       const payload = {
//         key: "split",
//         split: isSplitAlready,
//       }
//       selectAgentPlan(payload)
//     }
//   }, [isSplitAlready])

//   const handleClick = (value) => {
//     const payload = {
//       key: "split",
//       split: value.value,
//     }
//     selectAgentPlan(payload)
//   }
//   return (
//     <Wrap>
//       <Container>
//         <TitleWrap>
//           <Title>
//             Split
//           </Title>
//         </TitleWrap>
//         {isFetching ? (
//           <ShimmerWrap>
//             <ShimmerBox w="129px" h="102px" />
//             <ShimmerBox w="129px" h="102px" />
//           </ShimmerWrap>
//         ) : (
//           <PlanWrap>
//             {split && split.map(plans => (
//             <>
//               <PlanClickBar
//                 onClick={() => {
//                   handleClick(plans)
//                 }}
//                 isSelected={selectedAgentPlans.some(el => el.split === plans.value)}
//                 key={plans.id}
//                 width={129}
//               >
//                 <PlanPriceWrap
//                   isSelected={selectedAgentPlans.some(el => el.split === plans.value)}
//                   key={plans.id}
//                 >
//                   <PlanPrice
//                     isSelected={selectedAgentPlans.some(el => el.split === plans.value)}
//                     key={plans.id}
//                   >
//                     {plans.value}
//                   </PlanPrice>
//                 </PlanPriceWrap>
//               </PlanClickBar>
//             </>
//             ))}
//           </PlanWrap>
//         )}
//       </Container>
//     </Wrap>
//   )
// }

// export default SubDeetsContainer(AgentContainer(Split))

import React, { useEffect } from "react";
import styled from "styled-components";
import { ShimmerBox } from "shared/styles/animation";
import SubDeetsContainer from "container/Agent";
import AgentContainer from "container/BrokerDashboard/editAgentContainer";
import { get } from "lodash";
import { Select } from "antd";
import {
  Container,
  Title,
  TitleWrap,
  ShimmerWrap,
} from "../../common";

const Wrap = styled.div``;

const Split = ({
  getBrokerageInfoMetabaseResponse,
  selectAgentPlan,
  selectedAgentPlans,
  subscriptions
}) => {
  const isSplitAlready = get(subscriptions, "data.additional_info.split", "");
  const isFetching = get(getBrokerageInfoMetabaseResponse, "isFetching");
  const split = get(getBrokerageInfoMetabaseResponse, "data.split", []);

  useEffect(() => {
    if (isSplitAlready) {
      selectAgentPlan({ key: "split", split: isSplitAlready });
    }
  }, [isSplitAlready]);

  const handleChange = (value) => {
    const selectedSplit = split.find((plan) => plan.value === value);
    if (selectedSplit) {
      selectAgentPlan({ key: "split", split: selectedSplit.value });
    }
  };

  const prioritizedPlans = [
    "Flagship partner plan (95/5 until $500K, 97/3 after)",
    "Partner plan (90/10 with cap)",
  ];
  
  const sortedSplit = [
    ...split.filter((obj) => obj.value === "No split"),
    ...split
      .filter((obj) => obj.value !== "No split")
      .sort((a, b) => {
        const aPriority = prioritizedPlans.indexOf(a.value);
        const bPriority = prioritizedPlans.indexOf(b.value);
  
        if (aPriority === -1 && bPriority === -1) return 0;
  
        if (aPriority === -1) return 1;
        if (bPriority === -1) return -1;
  
        return aPriority - bPriority; 
  }),
];
  

  return (
    <Wrap>
      <Container>
        <TitleWrap>
          <Title>Commission split</Title>
        </TitleWrap>
        {isFetching ? (
          <ShimmerWrap>
            {/* {[...Array(2)].map((_, index) => (
              <ShimmerBox key={index} w="129px" h="102px" />
            ))} */}
            <ShimmerBox w="1000px" h="50px" />
          </ShimmerWrap>
        ) : (
          // <Select
          //   placeholder="Select Split"
          //   onChange={handleChange}
          //   value={selectedAgentPlans.find((el) => split.some((plan) => plan.value === el.split))?.split || undefined}
          // >
          //   {split.map((plan) => (
          //     <Select.Option key={plan.id} value={plan.value}>
          //       {plan.value}
          //     </Select.Option>
          //   ))}
          // </Select>
          <Select
            placeholder="Select Split"
            onChange={handleChange}
            value={selectedAgentPlans.find((el) => split.some((plan) => plan.value === el.split))?.split || undefined}
          >
            {sortedSplit.map((plan) => (
              <Select.Option key={plan.id} value={plan.value}>
                {plan.value}
              </Select.Option>
            ))}
          </Select>

        )}
      </Container>
    </Wrap>
  );
};

export default SubDeetsContainer(AgentContainer(Split));