// /* eslint-disable import/no-unresolved */
// import React, { useEffect } from "react"
// import styled from "styled-components"
// import { get, isEmpty } from "lodash"
// import SubContainer from "container/BrokerDashboard/subscriptionContainer"
// import {
//   Container,
//   PlanClickBar,
//   PlanWrap,
//   PlanPriceWrap,
//   PlanPrice,
//   Title,
//   TitleWrap,
// } from "../../common"
// import { Exclamation } from "../../icons"
// import AgentContainer from "container/BrokerDashboard/editAgentContainer"

// const Wrap = styled.div``

// const Advisory = styled.div`
//   display: flex;
//   gap: 4px;
// `

// const AdvisoryText = styled.p`
//   color: #52525B;
//   font-family: 'DM Sans', sans-serif;
//   font-size: 16px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: 22px;
//   margin: 0;
// `

// const RiskManagement = ({
//   planBenefitsResponse,
//   addOnPlanIdsToAdd,
//   addOnPlanIds,
//   addonsResponse,
//   subscriptions,
// }) => {
//   const planBenefitsData = get(planBenefitsResponse, "data")
//   const insuranceData = planBenefitsData.filter(filteredData => filteredData.name === "E&O Insurance")
//   const planDetails = get(insuranceData, "[0].plan_details", []) || []

//   const selectedENOData = get(addonsResponse, "data")
//   const selectedENO = !isEmpty(selectedENOData) && selectedENOData?.filter(filterData => filterData.name === "E&O Insurance")

//   useEffect(() => {
//     console.log("CHECK HERE ==>", selectedENO, addonsResponse)
//     if (selectedENO && selectedENO.length > 0) {
//       addOnPlanIdsToAdd({ plan_id: selectedENO[0]?.pricing?.plan_id, id: selectedENO[0]?.id, price: selectedENO[0]?.pricing?.amount })
//     }
//   }, [addonsResponse])

//   const getUnitPrice = value => value.billing_cycle === "YEARLY"
//     ? `$${value.unit_price}/yearly` : `$${value.unit_price}/monthly`

//   const checkIsSelected = (planId) => {
//     console.log("dhruvik subscriptions", subscriptions)
//     const isInAddon = addOnPlanIds.some(el => el.plan_id === planId)
//     console.log("dhruvik isInAddon", isInAddon)
//     console.log("dhruvik addOnPlanIds", addOnPlanIds)
//     console.log("dhruvik addOnPlanIds.some(el => el.plan_id === planId)", addOnPlanIds.some(el => el.plan_id === planId))

//     console.log("dhruvik subscriptions?.length > 0", subscriptions?.length > 0)
//     if (subscriptions?.length > 0) {
//       console.log("dhruvik if (subscriptions?.length > 0) {")
//       return false
//     }
//     console.log("dhruvik isInAddon", isInAddon)

//     return isInAddon
//   }

//   return (
//     <Wrap>
//       <Container>
//         <TitleWrap>
//           <Title>
//           Risk Management (E&O)
//           </Title>
//         </TitleWrap>
//         <PlanWrap>
//           {planDetails && planDetails.length > 0 && planDetails.map((planBenefits) => {
//             const isSelected = checkIsSelected(planBenefits.plan_id)
//             console.log("dhruvik isSelected", isSelected)
//             console.log("dhruvik planBenefits", planBenefits)

//             return (
//               <>
//                 <PlanClickBar
//                   onClick={() => {
//                     addOnPlanIdsToAdd({ plan_id: planBenefits.plan_id, id: planBenefits.id, price: planBenefits.unit_price })
//                   }}
//                   isSelected={isSelected}
//                   key={planBenefits.id}
//                   width={223.67}
//                 >
//                   <PlanPriceWrap
//                     isSelected={isSelected}
//                     key={planBenefits.id}
//                   >
//                     <PlanPrice
//                       isSelected={isSelected}
//                       key={planBenefits.id}
//                     >
//                       {getUnitPrice(planBenefits)}
//                     </PlanPrice>
//                   </PlanPriceWrap>
//                 </PlanClickBar>
//               </>
//             )
//           })}
//         </PlanWrap>
//         <Advisory>
//           <Exclamation />
//           <AdvisoryText>
//             Upon enrollment and annually on enrollment anniversary
//           </AdvisoryText>
//         </Advisory>
//       </Container>
//     </Wrap>
//   )
// }

// export default AgentContainer(SubContainer(RiskManagement))

/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { get, isEmpty } from "lodash";
import { Select } from "antd";
import SubContainer from "container/BrokerDashboard/subscriptionContainer";
import AgentContainer from "container/BrokerDashboard/editAgentContainer";
import { Container, Title, TitleWrap } from "../../common";
import { Exclamation } from "../../icons";

const Wrap = styled.div``;

const Advisory = styled.div`
  display: flex;
  gap: 4px;
  margin-top: 10px;
`;

const AdvisoryText = styled.p`
  color: #52525b;
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin: 0;
`;

const RiskManagement = ({
  planBenefitsResponse,
  addOnPlanIdsToAdd,
  addOnPlanIds,
  addonsResponse,
  subscriptions,
  durationValue,
}) => {
  const [selectedPlan, setSelectedPlan] = useState(null);

  const planBenefitsData = get(planBenefitsResponse, "data", []);
  const insuranceData = planBenefitsData.filter(
    (filteredData) => filteredData.name === "E&O Insurance"
  );
  const planDetails = get(insuranceData, "[0].plan_details", []);

  const selectedENOData = get(addonsResponse, "data", []);
  const selectedENO =
    !isEmpty(selectedENOData) &&
    selectedENOData?.filter((filterData) => filterData.name === "E&O Insurance");

  useEffect(() => {
    if (selectedENO && selectedENO.length > 0) {
      addOnPlanIdsToAdd({
        plan_id: selectedENO[0]?.pricing?.plan_id,
        id: selectedENO[0]?.id,
        price: selectedENO[0]?.pricing?.amount,
      });
      setSelectedPlan(selectedENO[0]?.pricing?.plan_id);
    }
  }, [addonsResponse]);

  const getUnitPrice = (value) =>
    value.billing_cycle === "YEARLY"
      ? `$${value.unit_price}/year`
      : `$${value.unit_price}/month`;

  // const getUnitPrice = (value) => {
  //   if (durationValue === "yearly") {
  //     return value.billing_cycle === "YEARLY" ? `$${value.unit_price}/yearly` : "";
  //   } else {
  //     return value.billing_cycle != "YEARLY" ? `$${value.unit_price}/monthly` : "";
  //   }
  // };

  const handleChange = (planId) => {
    const selectedPlan = planDetails.find((plan) => plan.plan_id === planId);
    if (selectedPlan) {
      addOnPlanIdsToAdd({
        plan_id: selectedPlan.plan_id,
        id: selectedPlan.id,
        price: selectedPlan.unit_price,
      });
      setSelectedPlan(planId);
    }
  };

  return (
    <Wrap>
      <Container>
        <TitleWrap>
          <Title>Risk Management (E&O)</Title>
        </TitleWrap>
        <Select
          style={{ width: "100%" }}
          placeholder="Select a plan"
          value={selectedPlan || (addOnPlanIds && addOnPlanIds[0]?.plan_id)}
          onChange={handleChange}
        >
          {planDetails.map((plan) => (
            <Select.Option key={plan.plan_id} value={plan.plan_id}>
              {getUnitPrice(plan)}
            </Select.Option>
          ))}
          {/* {planDetails
            .filter(plan => 
              (durationValue === "yearly" && plan.billing_cycle === "YEARLY") || 
              (durationValue !== "yearly" && plan.billing_cycle === "MONTHLY")
            )
            .map((plan) => (
              <Select.Option key={plan.plan_id} value={plan.plan_id}>
                {getUnitPrice(plan)}
              </Select.Option>
            ))} */}
        </Select>
        {/* <Advisory>
          <Exclamation />
          <AdvisoryText>Upon enrollment and annually on enrollment anniversary</AdvisoryText>
        </Advisory> */}
      </Container>
    </Wrap>
  );
};

export default AgentContainer(SubContainer(RiskManagement));
