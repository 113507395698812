import React, { useState, useEffect } from "react"
import {
  Modal, Input, Button, Select,
} from "antd"
import styled from "styled-components"
import moment from "moment"
// import ThreeDotLoader from "@ui/ThreeDotLoader"
// import Navbar from "container/Navbar"
// import {
//   SelectField,
// } from "dumbComponents/common/InputFields"
import {
  ModalWrapper,
  CreditHeadingWrap,
  CreditHeading,
  CreditImage,
  ButtonWrap,
  CancelButton,
  CreditProceedButton,
  CommonText,
  CreditDetailsWrap,
  CreditDetailsLabel,
  CreditExpCVVWrap,
} from "./styles"
const { Option } = Select

const EditCardModal = ({
  toClose,
  isOpen,
  handleProceed,
  handleClickForCancel,
  isLoadingCard,
  setLoadingCard,
}) => {
  useEffect(() => {
    if (isOpen) {
      setExpiryMonth("") // Reset expiry month
      setExpiryYear("") // Reset expiry year
    }
  }, [isOpen])
  const monthOptions = Array.from({ length: 12 }, (_, i) => ({
    label: moment().month(i).format("MM"), // Format as "01", "02", ..., "12"
    value: moment().month(i).format("MM"),
  }))

  // Generate expiry year options (Current year + next 10 years)
  const yearOptions = Array.from({ length: 10 }, (_, i) => {
    const year = moment().year() + i
    return { label: String(year), value: String(year) }
  })


  const [expiryMonth, setExpiryMonth] = useState(null)
  const [expiryYear, setExpiryYear] = useState(null)
  const [cvv, setCvv] = useState("")
  const handleSubmit = () => {
    if (!expiryMonth || !expiryYear) {
      alert("Please enter valid card details.")
      return
    }

    // setLoadingCard(true)
    console.log(expiryMonth)
    console.log(expiryYear)
    handleProceed({
      expiryMonth,
      expiryYear,
    })
    // toClose()
  }

  return (
    <Modal visible={isOpen} onCancel={toClose} footer={null} width={500}>
      <ModalWrapper>
        <CreditHeadingWrap>
          <CreditImage>
            {/* <img src={`${CLOUDFRONT}/brokeragePage/settingsModeule.svg`} width="19px" /> */}
          </CreditImage>
        </CreditHeadingWrap>
        <CreditHeadingWrap>
          <CreditHeading>
            Edit details
          </CreditHeading>
        </CreditHeadingWrap>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            alignSelf: "stretch",
            gap: "10px",
          }}
        >
          <CreditHeadingWrap width>
            <CommonText
              color="#111827"
              fontSize="14px"
              weight="500"
              lineHeigth
            >
              Expiry month
            </CommonText>
            <Select
              name="expiry_month"
              placeholder="MM"
              text="Expiry month"
              options={monthOptions}
              value={expiryMonth}
              onChange={value => setExpiryMonth(value)}
              style={{width: "100%"}}
            />
          </CreditHeadingWrap>
          <CreditHeadingWrap width>
            <CommonText
              color="#111827"
              fontSize="14px"
              weight="500"
              lineHeigth
            >
              Expiry year
            </CommonText>
            <Select
              name="expiry_year"
              placeholder="YYYY"
              value={expiryYear}
              options={yearOptions}
              text="Expiry year"
              onChange={value => setExpiryYear(value)}
              style={{width: "100%"}}
            />
          </CreditHeadingWrap>

        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "12px",
            alignSelf: "stretch",
            marginTop: "10px",
          }}
        >
          <CancelButton onClick={handleClickForCancel}>Cancel</CancelButton>
          <CreditProceedButton onClick={handleSubmit}>
            {isLoadingCard ? (
            //   <ThreeDotLoader color="#fff" />
              <p>Updating...</p>
            ) : (
              <p>Proceed</p>
            )}
          </CreditProceedButton>
        </div>
        {/* <DropdownWrapper>
          <DropdownItem>
            <label>Expiry Month</label>
            <Select
              placeholder="MM"
              value={expiryMonth}
              onChange={value => setExpiryMonth(value)}
              getPopupContainer={trigger => trigger.parentNode}
              style={{ width: "100%" }}
            >
              {Array.from({ length: 12 }, (_, i) => (
                <Option key={i + 1} value={String(i + 1).padStart(2, "0")}>
                  {String(i + 1).padStart(2, "0")}
                </Option>
              ))}
            </Select>
          </DropdownItem>
          <DropdownItem>
            <label>Expiry Year</label>
            <Select
              placeholder="YYYY"
              value={expiryYear}
              onChange={value => setExpiryYear(value)}
              getPopupContainer={trigger => trigger.parentNode}
              style={{ width: "100%" }}
            >
              {Array.from({ length: 10 }, (_, i) => (
                <Option key={i} value={String(new Date().getFullYear() + i)}>
                  {String(new Date().getFullYear() + i)}
                </Option>
              ))}
            </Select>
          </DropdownItem>
        </DropdownWrapper> */}
        {/* <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
          <Button onClick={handleClickForCancel}>Cancel</Button>
          <Button type="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </div> */}
      </ModalWrapper>
    </Modal>
  )
}

export default EditCardModal
