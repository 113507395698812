import { call, put, takeLatest } from "redux-saga/effects"; //takevery , take
import {
  fetchAllTemplatesAction,
  fetchChecklistMetadataAction,
  fetchAllTemplatesForReplicationAction,
  fetchStatesMetadataAction,
  fetchTypeMetadataAction,
  fetchAllChecklistDataAction,
  saveNewItemDocumentAction,
  deleteTemplateAction,
  deleteDocumentAction,
  saveDueDateAction,
  addNewChecklistTemplateAction,
  updateOrderingTemplatesAction,
  fetchBrbcDocumentsAction,
  fetchDocumentCriteriaAction,
  saveAddCriteriaAction,
  addBrbcDocAction,
} from "./actions";
import {
  fetchAllTemplatesAPI,
  fetchChecklistMetadataAPI,
  fetchStatesAPI,
  fetchTypeAPI,
  fetchAllChecklistAPI,
  saveNewItemToDocumentAPI,
  deleteTemplateAPI,
  deleteDocumentAPI,
  saveDueDateAPI,
  addNewChecklistTemplate,
  updateOrderingTemplatesAPI,
  fetchDocumentCriteria,
  fetchBrcbDocumentAPI,
  saveAddCriteriaAPI,
  addBrbcDocumentAPI,
} from "./api";
import { isSuccess } from "services/Utils";
import { toast } from "react-toastify";
import { message } from "antd";
function* fetchChecklistSaga(action) {
  try {
    const { state, type, callback } = action.data;
    const data = yield call(fetchChecklistMetadataAPI, state, type);
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(fetchChecklistMetadataAction.success(response));
      if (callback) callback();
    }
  } catch (error) {
    const { payload, callback } = action.data;
    message.error("Something went again.. Try again later");
    yield put(fetchChecklistMetadataAction.failure(error.message));
    if (callback) callback();
  }
}

function* fetchStatesSaga(action) {
  try {
    const data = yield call(fetchStatesAPI);
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(fetchStatesMetadataAction.success(response));
    }
  } catch (error) {
    message.error("Something went again.. Try again later");
    yield put(fetchStatesMetadataAction.failure(error.message));
  }
}

function* fetchTypeSaga(action) {
  try {
    const data = yield call(fetchTypeAPI);
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(fetchTypeMetadataAction.success(response));
    }
  } catch (error) {
    message.error("Something went again.. Try again later");
    yield put(fetchTypeMetadataAction.failure(error.message));
  }
}

function* fetchAllTemplatesSaga(action) {
  try {
    const { state, type, checklistTemplate } = action.data;
    const data = yield call(
      fetchAllTemplatesAPI,
      state,
      type,
      checklistTemplate
    );
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(fetchAllTemplatesAction.success(response));
    }
  } catch (error) {
    message.error("Something went again.. Try again later");
    yield put(fetchAllTemplatesAction.failure(error.message));
  }
}

function* addNewChecklistTemplateSaga(action) {
  try {
    const { payload, callback } = action.data;
    const data = yield call(addNewChecklistTemplate, payload);
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(addNewChecklistTemplateAction.success(response));
      callback();
      message.success("Checklist Template added.");
    }
  } catch (error) {
    const { payload, callback } = action.data;

    message.error("Something went again.. Try again later");
    yield put(addNewChecklistTemplateAction.failure(error.message));
    callback();
  }
}

function* fetchAllTemplatesForReplicationSaga(action) {
  try {
    const { state, type, checklistTemplate } = action.data;
    const data = yield call(
      fetchAllTemplatesAPI,
      state,
      type,
      checklistTemplate
    );
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(fetchAllTemplatesForReplicationAction.success(response));
    }
  } catch (error) {
    message.error("Something went again.. Try again later");
    yield put(fetchAllTemplatesForReplicationAction.failure(error.message));
  }
}

function* fetchAllChecklistDataSaga(action) {
  try {
    const { state, type, checklistTemplate } = action.data;
    const data = yield call(
      fetchAllChecklistAPI,
      state,
      type,
      checklistTemplate
    );
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(fetchAllChecklistDataAction.success(response));
    }
  } catch (error) {
    message.error("Something went again.. Try again later");
    yield put(fetchAllChecklistDataAction.failure(error.message));
  }
}

function* fetchDocumentCriteriaSaga(action) {
  console.log(action, "pragya");

  try {
    const documentItemId = action.data;
    console.log(documentItemId, "pragya");

    const data = yield call(fetchDocumentCriteria, documentItemId);
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(fetchDocumentCriteriaAction.success(response));
    }
  } catch (error) {
    message.error("Something went again.. Try again later");
    yield put(fetchDocumentCriteriaAction.failure(error.message));
  }
}

function* saveNewItemDocumentSaga(action) {
  try {
    const { payload, callback } = action.data;
    const res = yield call(saveNewItemToDocumentAPI, payload);
    if (isSuccess(res)) {
      const { response } = res.data;
      message.success("Saved Successfully");
      yield put(saveNewItemDocumentAction.success(response));
      if (callback) callback();
    }
  } catch (error) {
    const { payload, callback } = action.data;
    message.error(error?.data?.error?.dev_message);
    if (callback) callback();
    yield put(saveNewItemDocumentAction.failure(error.message));
  }
}

function* saveAddCriteriaSaga(action) {
  try {
    const { payload, callback } = action.data;
    const res = yield call(saveAddCriteriaAPI, payload);
    if (isSuccess(res)) {
      const { response } = res.data;
      message.success("Saved Successfully");
      if (callback) callback();
      yield put(saveAddCriteriaAction.success(response));
    }
  } catch (error) {
    const { payload, callback } = action.data;
    message.error(error?.data?.error?.dev_message);
    if (callback) callback();
    yield put(saveAddCriteriaAction.failure(error.message));
  }
}

function* deleteTemplateSaga(action) {
  try {
    const { payload, callback } = action.data;
    const res = yield call(deleteTemplateAPI, payload);
    if (isSuccess(res)) {
      message.success("Deleted Successfully");
      const { response } = res.data;

      yield put(deleteTemplateAction.success(response));
      callback();
    }
  } catch (error) {
    message.error("Something went again.. Try again later");
    yield put(deleteTemplateAction.failure(error.message));
  }
}

function* deleteDocumentSaga(action) {
  try {
    const { payload, callback } = action.data;
    const res = yield call(deleteDocumentAPI, payload);
    if (isSuccess(res)) {
      const { response } = res.data;

      message.success("Deleted Successfully");
      yield put(deleteDocumentAction.success(response));
      callback();
    }
  } catch (error) {
    message.error("Something went again.. Try again later");
    yield put(deleteDocumentAction.failure(error.message));
  }
}

function* saveDueDateSaga(action) {
  try {
    const { payload, callback } = action.data;
    const res = yield call(saveDueDateAPI, payload);
    if (isSuccess(res)) {
      const { response } = res.data;

      message.success("Saved Due Date Successfully");
      yield put(saveDueDateAction.success(response));
      callback();
    }
  } catch (error) {
    const { payload, callback } = action.data;
    message.error("Something went again.. Try again later");
    callback();
    yield put(saveDueDateAction.failure(error.message));
  }
}

function* updateOrderingTemplatesSaga(action) {
  try {
    const { payload, callback } = action.data;
    const res = yield call(updateOrderingTemplatesAPI, payload);
    if (isSuccess(res)) {
      const { response } = res.data;

      message.success("Saved Successfully");
      yield put(updateOrderingTemplatesAction.success(response));
      callback();
    }
  } catch (error) {
    const { payload, callback } = action.data;
    message.error("Something went again.. Try again later");
    callback();
    yield put(updateOrderingTemplatesAction.failure(error.message));
  }
}

function* fetchBrbcDocumentsSaga(action) {
  try {
    const { state, callback } = action.data;
    const payload = {
      state: state
    };
    const data = yield call(fetchBrcbDocumentAPI, payload);
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(fetchBrbcDocumentsAction.success(response));
      if(callback){
        callback();
        return;
      }
    }
  } catch (error) {
    message.error("Not able to fetch BRBC documents.");
    yield put(fetchBrbcDocumentsAction.failure(error.message));
  }
}

function* addBrbcDocumentSaga(action) {
  try {
    const { state, id, callback } = action.data;
    const payload = {
      state: state,
      id: id,
    };
    const res = yield call(addBrbcDocumentAPI, payload);
    if (isSuccess(res)) {
      const { response } = res.data;
      message.success("Brbc Added");
      yield put(addBrbcDocAction.success(response));
      callback();
    }
  } catch (error) {
    message.error(error?.data?.error?.dev_message);
    yield put(addBrbcDocAction.failure(error.message));
  }
}

function* ChecklistSaga() {
  yield takeLatest(fetchChecklistMetadataAction.REQUEST, fetchChecklistSaga);
  yield takeLatest(fetchStatesMetadataAction.REQUEST, fetchStatesSaga);
  yield takeLatest(fetchTypeMetadataAction.REQUEST, fetchTypeSaga);
  yield takeLatest(fetchAllTemplatesAction.REQUEST, fetchAllTemplatesSaga);
  yield takeLatest(
    fetchAllChecklistDataAction.REQUEST,
    fetchAllChecklistDataSaga
  );
  yield takeLatest(
    fetchAllTemplatesForReplicationAction.REQUEST,
    fetchAllTemplatesForReplicationSaga
  );
  yield takeLatest(saveNewItemDocumentAction.REQUEST, saveNewItemDocumentSaga);
  yield takeLatest(deleteTemplateAction.REQUEST, deleteTemplateSaga);
  yield takeLatest(deleteDocumentAction.REQUEST, deleteDocumentSaga);
  yield takeLatest(saveDueDateAction.REQUEST, saveDueDateSaga);
  yield takeLatest(
    updateOrderingTemplatesAction.REQUEST,
    updateOrderingTemplatesSaga
  );
  yield takeLatest(
    fetchDocumentCriteriaAction.REQUEST,
    fetchDocumentCriteriaSaga
  );
  yield takeLatest(saveAddCriteriaAction.REQUEST, saveAddCriteriaSaga);
  yield takeLatest(
    addNewChecklistTemplateAction.REQUEST,
    addNewChecklistTemplateSaga
  );
  yield takeLatest(fetchBrbcDocumentsAction.REQUEST, fetchBrbcDocumentsSaga);
  yield takeLatest(addBrbcDocAction.REQUEST, addBrbcDocumentSaga);
}

export default ChecklistSaga;
