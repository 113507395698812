import axios from "axios";

const baseUrl = process.env.REACT_APP_API_BASE_URL;
console.log("pragya");

export const fetchWaitlistAPI = (startDate, endDate, status, searchText, limit, skip) =>
  axios.get(
    `${baseUrl}/v1.0/consumerapp/soul/get-app-requests?start_date=${startDate}&end_date=${endDate}&status=${status}&searchText=${searchText}&limit=${limit}&skip=${skip}`
  );

export const saveWaitlistStatusAPI = (id, requestPayload) =>
  axios.post(`${baseUrl}/v1.0/consumerapp/update-app-request/${id}`, requestPayload);

export const uploadAppAssetsAPI = (payload) =>
  axios.post(`${baseUrl}/v1.0/consumer-app/uploads/app-assets`, payload);

export const updateAppCustomisationsAPI = (payload) =>
  axios.post(`${baseUrl}/v1.0/consumerapp/update-app-customizations/${payload.id}`, payload.payload);

export const fetchAppCustomisationsAPI = (id) =>
  axios.get(`${baseUrl}/v1.0/consumerapp/soul/get-app-settings/${id}`);

export const fetchConsumersAPI = (limit, skip, name, location, teamId) =>
  axios.get(
    `${baseUrl}/v1.0/consumerapp/soul/get-all-consumers?limit=${limit}&skip=${skip}&name=${name}&location=${location}&teamId=${teamId}`
  );

export const fetchSearchBoardAPI = (limit, skip, consumerUserId, email, location, teamId) =>
  axios.get(
    `${baseUrl}/v1.0/consumerapp/soul/get-all-search-boards?consumerUserId=${consumerUserId}&limit=${limit}&skip=${skip}&email=${email}&location=${location}&teamId=${teamId}`
  );

export const fetchPropertiesAPI = (limit, skip, consumerId, like_status) =>
  axios.get(
    `${baseUrl}/v1.0/consumerapp/soul/get-all-properties/${consumerId}?limit=${limit}&skip=${skip}&like_status=${like_status}`
  );

export const fetchFamilyMembersAPI = (limit, skip, consumerId) =>
  axios.get(
    `${baseUrl}/v1.0/consumerapp/soul/get-all-family-members/${consumerId}?limit=${limit}&skip=${skip}`
  );

export const joinTheWaitlistAPI = (payload) =>
  axios.post(`${baseUrl}/v1.0/consumerapp/request-consumerapp`, payload);

export const fetchConsumerTeamsAPI = () =>
  axios.get(
    `${baseUrl}/v1.0/consumerapp/soul/get-all-consumer-apps`
  );