import React, { useState, useEffect } from "react"
import {
  Modal, Input, Button, Select,
} from "antd"
import moment from "moment"
// import ThreeDotLoader from "@ui/ThreeDotLoader"
// import Navbar from "container/Navbar"
import {
  ModalWrapper,
  CreditHeadingWrap,
  CreditHeading,
  CreditImage,
  CommonText,
  ButtonWrap,
  CancelButton,
  DeleteButton,
  CreditProceedButton,
} from "./styles"
const { Option } = Select

const DefaultCard = ({
  toClose,
  isOpen,
  handleProceed,
  handleClickForCancel,
  isLoadingCard,
  setLoadingCard,
}) => {
  const handleSubmit = () => {
    // setLoadingCard(true)
    handleProceed()
  }

  return (
    <Modal visible={isOpen} onCancel={toClose} footer={null} width={500}>
      <ModalWrapper>
        <CreditHeadingWrap>
          <CreditImage>
            {/* <img src={`${CLOUDFRONT}/brokeragePage/settingsModeule.svg`} width="19px" /> */}
          </CreditImage>
        </CreditHeadingWrap>
        <CreditHeadingWrap>
          <CreditHeading>
            Update primary payment method
          </CreditHeading>
        </CreditHeadingWrap>
        <CommonText
          color="#6B7280"
          fontSize="12px"
          weight="400"
          lineHeigth
        >
          Are you sure you want to make this as your primary method of payment?
        </CommonText>
        <ButtonWrap>
          <CancelButton onClick={handleClickForCancel}>Cancel</CancelButton>
          <CreditProceedButton onClick={handleSubmit}>
            {isLoadingCard ? (
            //   <ThreeDotLoader color="#fff" />
              <p>Loading...</p>
            ) : (
              <p>Proceed</p>
            )}
          </CreditProceedButton>
        </ButtonWrap>
      </ModalWrapper>
    </Modal>
  )
}

export default DefaultCard
