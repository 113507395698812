/* eslint-disable import/no-unresolved */
import React from "react"
import styled from "styled-components"
import moment from "moment"
import { getInterval } from "dumbComponents/BrokerDashboard/helper"
import { USCurrencyFormat } from "shared/currencyUtils"
import sidebarContainer from "container/BrokerDashboard/sidebarContainer"
import { ShimmerBox } from "shared/styles/animation"
import { Collapse } from "antd"
import { get, isEmpty } from "lodash"
import {
  TitleWrap,
  Container,
  ContainerDescription,
  ContainerMap,
  ContainerTitle,
  SidebarTitle,
} from "../../common"

const { Panel } = Collapse

const Wrap = styled.div``

const CurrentPlan = ({
  currentPlan,
  addonsResponse,
  subscriptions,
}) => {
  const { isFetching } = subscriptions || {}
  const { subscriptionDetails, currentPlan: activePlan } = currentPlan || {}
  const addOns = get(addonsResponse, "data")
  const fetchingAddOns = get(addonsResponse, "isFetching")

  const getPlanListPrice = planList => planList.interval === "month"
    ? `$${planList.amount / 100}/monthly` : `$${planList.amount / 100}/yearly`

  return (
    <Wrap>
      <TitleWrap>
        <Collapse expandIconPosition="end" ghost>
          <Panel header="Current Plans">
            <Container>
              <SidebarTitle
                fontSize="16px"
              >
                Brokerage Plan
              </SidebarTitle>
              {activePlan ? (
                <>
                  {isFetching ? <ShimmerBox w="256px" h="110px" /> : (
                    <ContainerMap>
                      <ContainerTitle>
                        {getPlanListPrice(activePlan)}
                        {/* {getPlanListPrice(activePlan?.amount / 100)} */}
                      </ContainerTitle>
                      <ContainerDescription>
                        {`Last Billed: ${moment(subscriptionDetails?.current_period_start * 1000).format("DD MMM, YYYY")}`}
                      </ContainerDescription>
                      <ContainerDescription>
                        {`Next Billing: ${moment(subscriptionDetails?.current_period_end * 1000).format("DD MMM, YYYY")}`}
                      </ContainerDescription>
                      {subscriptionDetails?.cancel_at && (
                        <ContainerDescription
                          color="red"
                        >
                          {`Expires On: ${moment(subscriptionDetails?.cancel_at * 1000).format("DD MMM, YYYY")}`}
                        </ContainerDescription>
                      )}
                    </ContainerMap>
                  )}
                </>
              ) : (
                <ContainerMap>
                  <ContainerDescription>
                    No Active Plans
                  </ContainerDescription>
                </ContainerMap>
              )}
              {!isEmpty(addOns) && (
                <>
                  <SidebarTitle
                    fontSize="16px"
                  >
                    Add Ons
                  </SidebarTitle>
                  {fetchingAddOns ? <ShimmerBox w="256px" h="110px" /> : (
                    <>
                      {!isEmpty(addOns) && addOns.map(addOn => (
                        <ContainerMap>
                          <ContainerTitle
                            fontSize="20px"
                          >
                            {`${get(addOn, "name", "")}`}
                          </ContainerTitle>
                          <ContainerTitle>
                            {`${USCurrencyFormat(get(addOn, "pricing.amount", ""))}${getInterval(addOn.pricing)}`}
                          </ContainerTitle>
                          <ContainerDescription>
                            {`Last Billed: ${moment(get(addOn, "pricing.last_billed_at", 0) * 1000).format("DD MMM, YYYY")}`}
                          </ContainerDescription>
                          {addOn.ends_at && (
                            <ContainerDescription
                              color="red"
                            >
                              {`Expiring On: ${moment(get(addOn, "ends_at", 0) * 1000).format("DD MMM, YYYY")}`}
                            </ContainerDescription>
                          )}
                        </ContainerMap>
                      ))}
                    </>
                  )}
                </>
              )}
            </Container>
          </Panel>
        </Collapse>
      </TitleWrap>
    </Wrap>
  )
}

export default sidebarContainer(CurrentPlan)
