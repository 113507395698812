import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as Actions from "./actions";

function mapStateToProps(state) {
  const { checklist } = state;
  return {
    ...checklist,
  };
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      fetchChecklistMetadata: Actions.fetchChecklistMetadataAction.request,
      fetchStatesMetadata: Actions.fetchStatesMetadataAction.request,
      fetchTypeMetadata: Actions.fetchTypeMetadataAction.request,
      fetchAllTemplates: Actions.fetchAllTemplatesAction.request,
      fetchAllTemplatesForReplication:
        Actions.fetchAllTemplatesForReplicationAction.request,
      fetchAllChecklistData: Actions.fetchAllChecklistDataAction.request,
      saveNewItemToDocument: Actions.saveNewItemDocumentAction.request,
      deleteTemplate: Actions.deleteTemplateAction.request,
      deleteDocument: Actions.deleteDocumentAction.request,
      saveDueDate: Actions.saveDueDateAction.request,
      dropdownData: Actions.dropdownDataAction.call,
      addNewChecklistTemplate: Actions.addNewChecklistTemplateAction.request,
      updateOrderingTemplates : Actions.updateOrderingTemplatesAction.request,
      fetchDocumentCriteria: Actions.fetchDocumentCriteriaAction.request,
      saveAddCriteria: Actions.saveAddCriteriaAction.request,
      fetchBrbcDocuments: Actions.fetchBrbcDocumentsAction.request,
      addBrbcDocument: Actions.addBrbcDocAction.request,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
