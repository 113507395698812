import { createSignalAction, createSimpleCreator } from "shared/reduxUtils";

const BASE = 'CONSUMER';

export const fetchWaitlistAction = createSignalAction(BASE, 'FETCH_WAITLIST');
export const saveWaitlistStatusAction = createSignalAction(BASE, 'SAVE_WAITLIST_STATUS');
export const uploadAppAssetsAction = createSignalAction(BASE, 'UPLOAD_APP_ASSETS');
export const updateAppCustomisationsAction = createSignalAction(BASE, 'UPDATE_CUSTOMIZATION');
export const fetchAppCustomisationsAction = createSignalAction(BASE, 'FETCH_CUSTOMIZATION');
export const selectedAppAction = createSimpleCreator(BASE, "SELECTED_APP");
export const fetchConsumerAction = createSignalAction(BASE, 'FETCH_CONSUMER');
export const fetchSearchBoardAction = createSignalAction(BASE, 'FETCH_SEARCH_BOARD');
export const fetchPropertiesAction = createSignalAction(BASE, 'FETCH_PROPERTIES');
export const fetchFamilyMembersAction = createSignalAction(BASE, 'FETCH_FAMILY_MEMBERS');
export const fetchConsumerTeamsAction = createSignalAction(BASE, 'FETCH_CONSUMER_TEAMS');
export const joinTheWaitlistAction = createSignalAction(BASE,"JOIN_THE_WAITLIST");