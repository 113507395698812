/* eslint-disable import/no-unresolved */
import React, {useState, useEffect} from "react"
import styled from "styled-components"
import ThreeDotLoader from "dumbComponents/common/UI/ThreeLoader"
import { isEmpty, get } from "lodash"
import { USCurrencyFormat } from "shared/currencyUtils"
import StripeCheckout from "react-stripe-checkout"
import EditAgentContainer from "container/BrokerDashboard/editAgentContainer"
import SubContainer from "container/BrokerDashboard/subscriptionContainer"
import SidebarContainer from "container/BrokerDashboard/sidebarContainer"
import BrokerContainer from "container/BrokerDashboard/subscriptionContainer"
import CouponCode from "../CouponCode/index"
import SubDeetsContainer from "container/Agent"
import { withRouter } from "react-router-dom"
import { toast } from "react-toastify"
import { notify } from 'react-notify-toast';
import { message } from 'antd';
import {
  ShowErrorToast,
  ShowSuccessToast,
  ShowErrorToastFromText,
} from "lib/ErrorUtils"
import {
  Container,
} from "../../common"

const STRIPE_PUBLISH_KEY = process.env.REACT_APP_STRIPE_PUBLISH_KEY

const productionMap = [
  { key: ">$5MM GP", name: "Indie High Producer", value: ">$5MM GP IHP" },
  { key: "$3-$5MM GP", name: "Indie Low Producer", value: "$3-$5MM GP ILP" },
  { key: "<$3MM GP", name: "Indie Zero Producer", value: "<$3MM GP IZP" },
  { key: ">$5MM GP", name: "Team Leader", value: ">$5MM GP TL" },
  { key: "Member", name: "Producing Team Member", value: "Member" },
  { key: "$0-$3MM GP", name: "Non Producing Team", value: "$0-$3MM GP NPT" }
]

const Wrap = styled.div`
`

const CouponWrap = styled.div``

const ContainerWrap = styled.div`
  max-width: 694px;
`

const TitleWrap = styled.div`
  display: flex;
  // justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px solid #E4E4E7;
  padding: 12px 0px;
`

const HeadingTitle = styled.p `
  color: var(--Neutral-900, #111827);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.5px;
  margin: 0;
`

const Title = styled.h3`
  // color: ${props => props.color};
  // font-family: ${props => props.font || "Plus Jakarta Sans"};
  // font-size: 14px;
  // font-style: normal;
  // font-weight: 500;
  // line-height: 21px;
  // text-transform: capitalize;
  // margin: 0;
  color: var(--Neutral-900, #111827);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`

const AmountTitle = styled.p `
  color: var(--Neutral-500, #6B7280);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`

const TeamTitle = styled.p `
  color: var(--Neutral-500, #6B7280);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`

const CheckoutButton = styled.button`
  width: 100%;
  // border-radius: 6px;
  // background: #06F;
  height: 36px;
  // border: none;
  cursor: pointer;

  border-radius: 8px;
  border: 1px solid var(--primary-500-main, #6366F1);
  background: var(--primary-500-main, #6366F1);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04), 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  color: var(--Generic-White, #FFF);
  text-align: justify;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;

  ${props => props.disabled && `
    background: #CBCBCB;
  `}
`

const CheckoutButtonText = styled.p`
  color: #FFF;
  text-align: center;
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
`

const AddOnsWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const TotalWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const AddOnsMapWrap = styled.div`
  display: flex;
  justify-content: space-between;
`

const CTAWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 15px;
`

const ButtonRow = styled.div`
  display: flex;
  gap: ${props => props.gap || "30px"};
  flex-direction: ${props => props.fd || "row"};

  span {
    width: 100%;
  }

`

const ButtonText = styled.p`
  color: ${props => props.color || "#06F"};
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;

  ${props => props.inverted && `
    color: white;
  `}
`

const BlueButton = styled.button`
  border: 1px solid var(--Primary-100, #E0E7FF);
  cursor: pointer;
  width: ${props => props.width || "332px"};
  height: 48px;
  // background: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;

  background-color: #EEF2FF;
  border-radius: 8px;

  img {
    width: 20px;
    height: 20px;
  }
  
  p {
    color: #6366F1;
    ${props => props.inverted && `
      color: white;
    `}
  }

  ${props => props.inverted && `
    background-color: #6366F1;
    color: white;
    border: none;
  `}

  ${props => props.disabled && `
    cursor: not-allowed;
  `}

  &:hover {
  //    background-color: #06F;
  //    border: 1px solid grey;
  //    p {
  //     color: #fff;
  //    }

  //    img {
  //      filter: invert(1) contrast(50) saturate(0);
  //    }

     ${props => props.disabled && `
        background: #e3dede;
        p {
          color: #303030;
        }
     `}
  }
`

const WhiteButton = styled.button`
  border-radius: 6px;
  border: 1px solid #000;
  cursor: pointer;
  width: 100%;
  height: 48px;
  background: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;

  p {
    color: #000;
  }

  img {
    width: 20px;
    height: 20px;
  }

  &:hover {
    border: none;
    background-color: #000;

    p {
      color: #fff;
    }
  }
  
  transition: all 0.3s ease-out;

  ${props => props.disabled && `
    cursor: not-allowed;
  `}
`

const OrText = styled.p`
  color: var(--color-tertiary, #1C1C1F);
  text-align: center;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
`

const CardDetailsDiv = styled.div `
  display: flex;
  justify-content: space-between;
  height: 40px;
  padding: 8px 12px;
  align-items: center;
  gap: 12px;
  width: 100%;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Neutral-200, #E5E7EB);
  background: var(--Generic-White, #FFF);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04), 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  color: var(--Neutral-400, #9CA3AF);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`

const Summary = ({
  selectedAgentPlans,
  selectedPlan,
  selectedAddons = [],
  checkoutBrokerPlanV2,
  addOnPlanIds,
  currentPlan,
  updateSubscriptionV2,
  saveCardResponse,
  subscriptions,
  planBenefitsResponse,
  addonsResponse,
  checkoutBrokerPlanV2Response,
  updateSubscriptionV2Response,
  saveStripeToken,
  addCreditCardDetails,
  agentId,
  addCreditCardDetailsResponse,
  inviteWithoutSubscription,
  history,
  fetchAgentAsmRecruiterInfoResponse,
  addOnsSelected,
  couponData,
  addOnsRemoved,
  agentBrokerageInfoResponse,
  urlBasedTeamId,
  toggleInviteWithoutSubscriptionModal,
  inviteWithoutSubscriptionPayloadCreation,
  paymentByLead,
  rarTeamListResponse,
  agentDetailsResponse,
  paymentByLeadAmount,
  durationValue,
  getTentativeBrokerageSubscriptionDetailsResponse,
}) => {
  const [associateAmount, setAssociateAmount] = useState(paymentByLeadAmount)
  const paymentAmount = get(subscriptions, "data.team_pricing_info.team_member_pricing.amount", "");
  const addOnBenefits = get(getTentativeBrokerageSubscriptionDetailsResponse, 'data.addOnBenefits', [])
  useEffect(() => {
    if(paymentByLeadAmount) {
      setAssociateAmount(paymentByLeadAmount)
    }
  }, [paymentByLeadAmount])

  useEffect(() => {
    if(paymentAmount) {
      setAssociateAmount(paymentAmount);
      console.log("in this")
    }
  }, [subscriptions])

  const { data: teams = [], isFetching: fetchingTeams } = rarTeamListResponse || {}
  const { data: agentData, isFetching: fetchingAgentDetails } = agentDetailsResponse || {}
  const { data: brokerageData, isFetching: fetchingBrokerageDetails } = agentBrokerageInfoResponse || {}
  const {
    team_name,
    brokerageTeamId,
  } = agentData || {}
  const {
    brokerage_team_id,
  } = brokerageData || {}
  const team = teams && teams.find(team => team.id === brokerageTeamId || team.id === brokerage_team_id);
  const teamSize = team ? team.team_size : 0;
  const { data: userInfo } = agentBrokerageInfoResponse || {}
  const isSubmitting = get(checkoutBrokerPlanV2Response, "isFetching")
  const isUpdating = get(updateSubscriptionV2Response, "isFetching")
  const { currentPlan: planExists } = currentPlan || {}
  const planBenefitsData = get(planBenefitsResponse, "data")
  const addOnsData = planBenefitsData?.length>0 && planBenefitsData?.filter(filteredData => filteredData.name !== "E&O Insurance")
  const currentAddOns = get(addonsResponse, "data")
  const ENOData = !isEmpty(currentAddOns) && currentAddOns?.filter(filteredData => filteredData.name === "E&O Insurance")
  const isCreditCardPresent = !isEmpty(saveCardResponse.data?.payment_sources)
  const isPaymentDefault = !isEmpty(saveCardResponse.data?.payment_sources) && saveCardResponse.data.payment_sources.some(source => source.is_default_source === true);
  const subscriptionIds = get(subscriptions, "data.subscriptions")
  const { currentPlan: subscribedPlan } = currentPlan || {}
  const checkifPlanSelected = !isEmpty(selectedPlan)
  const totalItemsInCart = selectedAgentPlans.length
    + (selectedAddons && selectedAddons.length) + (checkifPlanSelected ? 1 : 0)
      + addOnPlanIds.length
  const isDisabled = totalItemsInCart === 0
  const {
    isFetching: sendingCardDetailsEmail,
  } = addCreditCardDetailsResponse || {}

  const zeroDollarIDs = ["broker_zero_monthly_0", "broker_test_rar_zero_dollar_qa"]

  const isZeroDollarPlanSelected = (addOnPlanIds && addOnPlanIds.length > 0
    && addOnPlanIds[0].plan_id === "eoi_monthly_0")
    && (!isEmpty(selectedPlan) && zeroDollarIDs.includes(selectedPlan.planId))
      && (isEmpty(addOnsSelected) || (addOnsSelected && addOnsSelected.length === 1 && addOnsSelected[0].name === "Branding"))

  const disabled = (selectedAgentPlans.length <= 2) 

  const selectedAddonsPrice = addOnsSelected
    .map((x) => {
      console.log("X ===>", x, x.pricing)
      return x.pricing?.amount ? x.pricing?.amount : ((x.unit_price || x.unit_price === 0) ? x.unit_price : x.plan_details && x.plan_details[0]?.unit_price || 0)
    })
  // const teamSubscriptionPrice = paymentByLead ? (100*teamSize) : 0
  const totalAddOnPrice = selectedAddonsPrice.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
  const total = (addOnPlanIds[0]?.price === undefined ? 0 : addOnPlanIds[0]?.price)
    + (totalAddOnPrice)
    + ((selectedPlan?.plan?.amount === undefined
      ? (selectedPlan?.plan?.unit_price === undefined
        ? 0 : selectedPlan?.plan?.unit_price)
      : selectedPlan?.plan?.amount / 100))

  const getAgentPlanName = (value) => {
    if (value.key === "production") {
      return value.name
    }
    return value.split("_").join(" ")
  }

  const getUnitPrice = (planBenefits) => {
    if ((planBenefits.unit_price === undefined || !planBenefits.unit_price) && (
      !planBenefits.pricing || planBenefits.pricing === undefined) && (
      !planBenefits.plan_details || planBenefits.plan_details === undefined
    )) {
      return "$0"
    }

    return planBenefits.name === "Branding" || planBenefits.type == "REQUEST"
      ? `$${planBenefits.unit_price}` : planBenefits.unit_price === null
        ? `$${planBenefits.plan_details[0]?.unit_price}` : `$${planBenefits.pricing?.amount}`
  }

  const getENOAmount = (data) => {
    return data === 0 ? "$0/month" : data?.plan_id?.includes("yearly") ? `${USCurrencyFormat(data?.price || data?.amount)}/year` : `${USCurrencyFormat(data?.price || data?.amount)}/month`
  }

  const getProductionName = (plans) => {
    let temp
    productionMap.forEach((item) => {
      if (item.value === plans.production) {
        temp = item.key
      }
    })
    return temp
  }

  const getCapPrice = plans => plans.key === "commission_cap" ? USCurrencyFormat(plans[plans.key])
    : (plans.key === "production" ? getProductionName(plans) : plans[plans.key])

  const getMonthlyAmount = (plan) => {
    if (plan && (plan?.plan?.amount === 0 || plan?.plan?.unit_price === 0)) {
      return plan?.plan?.billing_cycle === "YEARLY" ? "$0/year" : "$0/month"
    }

    return plan?.plan?.billing_cycle === "YEARLY"
      ? `${USCurrencyFormat(plan?.plan?.amount / 100 || plan?.plan?.unit_price)}/year`
      : `${USCurrencyFormat(plan?.plan?.amount / 100 || plan?.plan?.unit_price)}/month`
  }

  const handleCheckout = (data) => {
    console.log("shrungi selectedPlan", selectedPlan)
    let payload = {}
    if (urlBasedTeamId) {
      payload.team_id = parseInt(urlBasedTeamId, 10)
    }

    const { id: tokenId } = data || {}
    if ((subscribedPlan?.id === selectedPlan?.planId)) {
      const currentAddOnIds = currentAddOns && currentAddOns.length > 0 && currentAddOns?.filter(filterAddOns => filterAddOns.name !== "E&O Insurance").map(x => x.id)
      const brandingId = addOnsData && addOnsData.filter(filterAddOns => filterAddOns.name === "Branding").map(x => x.id) || []
      const selectedAddOnIds = selectedAddons.filter(filterAddOns => filterAddOns.name !== "E&O Insurance").map(x => x.id)
      payload.benefitIdsToAdd = [...addOnsSelected.map(x => x.id)]
      payload.benefitIdsToRemove = [...addOnsRemoved.filter(filterAddOns => brandingId !== filterAddOns.id).map(x => x.id)]
      payload.add_on_plan_ids_to_add = addOnPlanIds
      payload.add_on_plan_ids_to_remove = []
      payload.subscriptionIds = subscriptionIds && subscriptionIds.map(x => x.id)
      selectedAgentPlans.map((agentPlans) => {
        payload = {
          ...payload,
          [agentPlans.key]: agentPlans[agentPlans.key],
        }
      })
      console.log("paymentByLead", paymentByLead)

      console.log("Payload shrungi => -> ", payload)
      if (tokenId) {
        saveStripeToken({
          token: tokenId,
          callback: () => {
            updateSubscriptionV2({
              payload,
              callback: () => window.location.reload(),
            })
          },
        })
        return
      }

      updateSubscriptionV2({
        payload,
        callback: () => window.location.reload(),
      })
    } else {
      const cCode = get(couponData, "trialInfo.couponCode")

      payload.plansMap = [{
        planId: selectedPlan?.planId,
        couponCode: cCode || "",
      }]
      // payload.benefitIds = []
      // addOnsSelected.map((addOns) => {
      //   payload = {
      //     ...payload,
      //     benefitIds: [...payload.benefitIds, addOns.id, addOnPlanIds[0].id],
      //   }
      // })
      payload.benefitIds = [addOnPlanIds[0].id, ...addOnsSelected.map(addOn => addOn.id)];
      const { id, plan_id } = addOnPlanIds[0]
      payload.add_on_plan_ids = [{ plan_id, id }]
      selectedAgentPlans.map((agentPlans) => {
        payload = {
          ...payload,
          [agentPlans.key]: agentPlans[agentPlans.key],
        }
      })
      if (paymentByLead != null) {
        payload.team_payment_option = {
          is_paid_by_lead: paymentByLead,
          team_id: brokerageTeamId || brokerage_team_id,
          team_member_pricing: {
            amount: parseInt(paymentByLeadAmount, 10),
            billing_interval: selectedPlan.plan?.billing_interval,
            billing_cycle: selectedPlan.plan?.billing_cycle,
          },
        }
      }
      console.log("paymentByLead", paymentByLead)

      console.log("Payload shrungi else => -> ", payload)
      if (tokenId) {
        payload.tokenId = tokenId
      }

      checkoutBrokerPlanV2({
        payload,
        callback: () => {
          if (history) {
            history.push(`/broker/dashboard/${agentId}/ica`)
          }
        },
      })
    }
  }

  const handleCheckoutV2 = (data) => {
    console.log("shrungi selectedPlan", selectedPlan)
    let payload = {}
    if (urlBasedTeamId) {
      payload.team_id = parseInt(urlBasedTeamId, 10)
    }
    console.log("isPaymentDefault", isPaymentDefault)

    if(!isPaymentDefault && total > 0){
      message.error("Please add a default payment method!");
      return
    }
    console.log("hello")



    const { id: tokenId } = data || {}
    if ((subscribedPlan?.id === selectedPlan?.planId)) {
      const currentAddOnIds = currentAddOns && currentAddOns.length > 0 && currentAddOns?.filter(filterAddOns => filterAddOns.name !== "E&O Insurance").map(x => x.id)
      const brandingId = addOnsData && addOnsData.filter(filterAddOns => filterAddOns.name === "Branding").map(x => x.id) || []
      const selectedAddOnIds = selectedAddons.filter(filterAddOns => filterAddOns.name !== "E&O Insurance").map(x => x.id)
      payload.benefitIdsToAdd = [...addOnsSelected.map(x => x.id)]
      payload.benefitIdsToRemove = [...addOnsRemoved.filter(filterAddOns => brandingId !== filterAddOns.id).map(x => x.id)]
      payload.add_on_plan_ids_to_add = addOnPlanIds
      payload.add_on_plan_ids_to_remove = []
      payload.subscriptionIds = subscriptionIds && subscriptionIds.map(x => x.id)
      selectedAgentPlans.map((agentPlans) => {
        payload = {
          ...payload,
          [agentPlans.key]: agentPlans[agentPlans.key],
        }
      })
      console.log("paymentByLead", paymentByLead)
      console.log("id check", brokerageTeamId, brokerage_team_id, parseInt(urlBasedTeamId, 10))
      if (paymentByLead != null) {
        payload.team_payment_option = {
          is_paid_by_lead: paymentByLead,
          team_id: brokerageTeamId || brokerage_team_id,
          team_member_pricing: {
            amount: parseInt(paymentByLeadAmount, 10),
            billing_interval: selectedPlan.plan?.billing_interval,
            billing_cycle: selectedPlan.plan?.billing_cycle,
          },
        }
      }

      console.log("Payload shrungi => -> ", payload)
      updateSubscriptionV2({
        payload,
        callback: () => window.location.reload(),
      })
    } else {
      const cCode = get(couponData, "trialInfo.couponCode")

      payload.plansMap = [{
        planId: selectedPlan?.planId,
        couponCode: cCode || "",
      }]
      payload.benefitIds = []
      if (addOnsSelected && addOnsSelected.length > 0) {
        payload.benefitIds = [];
        addOnsSelected.forEach((addOns) => {
          payload.benefitIds.push(addOns.id);
        });
      
        // if (addOnPlanIds.length > 0) {
        //   const { id, plan_id } = addOnPlanIds[0];
        //   payload.add_on_plan_ids = [{ plan_id, id }];
        // } else {
        //   payload.add_on_plan_ids = [];
        // }
      } else {
        payload.add_on_plan_ids = [];
      }
      if (addOnPlanIds.length > 0) {
        const { id, plan_id } = addOnPlanIds[0];
        payload.add_on_plan_ids = [{ plan_id, id }];
      } else {
        payload.add_on_plan_ids = [];
      }
      // payload.add_on_plan_ids = []
      selectedAgentPlans.map((agentPlans) => {
        payload = {
          ...payload,
          [agentPlans.key]: agentPlans[agentPlans.key],
        }
      })
      console.log("id check", brokerageTeamId, brokerage_team_id, parseInt(urlBasedTeamId, 10))
      if (paymentByLead != null) {
        payload.team_payment_option = {
          is_paid_by_lead: paymentByLead,
          team_id: brokerageTeamId || brokerage_team_id || parseInt(urlBasedTeamId, 10),
          team_member_pricing: {
            amount: parseInt(paymentByLeadAmount, 10),
            billing_interval: selectedPlan.plan?.billing_interval,
            billing_cycle: selectedPlan.plan?.billing_cycle,
          },
        }
      }
      console.log("paymentByLead", paymentByLead)

      console.log("Payload shrungi else => -> ", payload)

      checkoutBrokerPlanV2({
        payload,
        callback: () => {
          if (history) {
            history.push(`/broker/dashboard/${agentId}/ica`)
          }
        },
      })
    }
  }

  const handleToken = (data) => {
    const { id: token } = data
    saveStripeToken({
      token,
      // callback: () => {
      //   getCardDetails()
      // },
    })
  }

  addOnPlanIds && addOnPlanIds.length > 0 && console.log("addOnPlanIdai ikea ", addOnPlanIds)
  selectedAddons && console.log("selectedAddons ikea", selectedAddons)

  const handleInviteWithoutSubscription = (values) => {
    const {
      data: recruiterData,
    } = fetchAgentAsmRecruiterInfoResponse || {}
    let payload = {}

    const cCode = get(couponData, "trialInfo.couponCode")

    payload.plansMap = [{
      planId: selectedPlan?.planId,
      couponCode: cCode || "",
    }]
    payload.benefitIds = []
    
    if (addOnsSelected && addOnsSelected.length > 0) {
      payload.benefitIds = [];
      addOnsSelected.forEach((addOns) => {
        payload.benefitIds.push(addOns.id);
      });
    
      // if (addOnPlanIds?.length > 0) {
      //   const { id, plan_id } = addOnPlanIds[0];
      //   payload.add_on_plan_ids = [{ plan_id, id }];
      // } else {
      //   payload.add_on_plan_ids = [];
      // }
    } else {
      payload.add_on_plan_ids = [];
    }
    selectedAddons.map((addOns) => {
      payload = {
        ...payload,
        benefitIds: [...payload.benefitIds, addOns.id],
      }
    })
      if (addOnPlanIds?.length > 0) {
        const { id, plan_id } = addOnPlanIds[0];
        payload.add_on_plan_ids = [{ plan_id, id }];
      } else {
        payload.add_on_plan_ids = [];
      }
      // console.log("selectedAddons", selectedAddons)
      // console.log("addOnPlanIds", addOnPlanIds)
    // const { id, plan_id } = addOnPlanIds[0]
    // payload.add_on_plan_ids = [{ plan_id, id }]
    selectedAgentPlans.map((agentPlans) => {
      payload = {
        ...payload,
        [agentPlans.key]: agentPlans[agentPlans.key],
      }
    })

    const {
      add_on_plan_ids,
      benefitIds,
      plansMap,
      production,
      split,
      commission_cap,
    } = payload

    const teamPaymentOption =
      paymentByLead != null
        ? {
            is_paid_by_lead: paymentByLead,
            team_id: brokerageTeamId || brokerage_team_id,
            team_member_pricing: {
              amount: parseInt(paymentByLeadAmount, 10),
              billing_interval: selectedPlan.plan?.billing_interval,
              billing_cycle: selectedPlan.plan?.billing_cycle,
            },
          }
        : undefined;

    const finalPayload = {
      plansMap,
      benefitIds,
      add_on_plan_ids,
      production,
      split,
      commission_cap,
      asm_assigned: recruiterData && recruiterData.asm_assigned || null,
      // generate_ica_for_state: state,
      recruiter: recruiterData && recruiterData.recruiter || null,
      ...(teamPaymentOption && { team_payment_option: teamPaymentOption }),
    }
    if (urlBasedTeamId) {
      finalPayload.team_id = parseInt(urlBasedTeamId, 10)
      if (finalPayload?.team_payment_option) {
        finalPayload.team_payment_option.team_id = parseInt(urlBasedTeamId, 10);
      }

    } else if (finalPayload?.team_payment_option) 
{ 
  finalPayload.team_payment_option.team_id = brokerageTeamId || brokerage_team_id;
    }

    // inviteWithoutSubscription({
    //   payload: finalPayload,
    //   agentId,
    //   fetchIca: false,
    //   history,
    // })

    const inviteWithoutSubPayload = {
      payload: finalPayload,
      agentId,
      fetchIca: false,
      history,
    }
    // inviteWithoutSubscriptionPayloadCreation(inviteWithoutSubPayload)
    toggleInviteWithoutSubscriptionModal({
      bool: true,
      data: inviteWithoutSubPayload,
    })
  }

  const cCode = couponData && get(couponData, "trialInfo.couponCode", "") || ""

  console.log("addOnBenefits summary", addOnBenefits)
  return (
    <Wrap>
      <Container
        padding="24px 16px"
      >
        <ContainerWrap>
          <TitleWrap style={{padding: "0px", paddingBottom: "12px"}}>
            <HeadingTitle>
              Summary
            </HeadingTitle>
            {/* <Title
              color="var(--gray-500, #71717A)"
            >
              {`${totalItemsInCart} items in cart`}
            </Title> */}
          </TitleWrap>
          {selectedAgentPlans && selectedAgentPlans.map(agentPlans => (
            <TitleWrap>
              <Title
                color="var(--gray-900, #18181B)"
              >
                {getAgentPlanName(agentPlans.key)}
              </Title>
              <AmountTitle>
                {getCapPrice(agentPlans)}
              </AmountTitle>
            </TitleWrap>
          ))}
          {selectedPlan && selectedPlan.plan && (
            <TitleWrap>
              <Title
                color="var(--gray-900, #18181B)"
              >
                Software fee
              </Title>
              <AmountTitle>
                {getMonthlyAmount(selectedPlan)}
              </AmountTitle>
            </TitleWrap>
          )}
          {(addOnPlanIds && addOnPlanIds[0]?.plan_id || ENOData && ENOData.length > 0) && (
            <TitleWrap>
              <Title
                color="var(--gray-900, #18181B)"
              >
                E & O - Risk management
              </Title>
              <AmountTitle>
                {getENOAmount(addOnPlanIds[0]?.price === 0 ? 0 : (addOnPlanIds[0] || ENOData[0]?.pricing))}
              </AmountTitle>
            </TitleWrap>
          )}
          {addOnsSelected && addOnsSelected.length > 0 && (
            <TitleWrap>
              <AddOnsWrap>
                <Title
                  color="var(--gray-900, #18181B)"
                >
                  Add-ons
                </Title>
                {addOnsSelected.map(addOns => (
                  <AddOnsMapWrap>
                    <AmountTitle>
                      {addOns.name ? addOns.name : (addOns.plan_id === "broker_crm_bw_monthly_50" ? "kvCORE/Follow Up Boss" : (addOns.plan_id === "broker_map_monthly_75" ? "Moxi/Active Pipe" : "Branding"))}
                    </AmountTitle>
                    <AmountTitle>
                      {getUnitPrice(addOns)}
                    </AmountTitle>
                  </AddOnsMapWrap>
                ))}
              </AddOnsWrap>
            </TitleWrap>
          )}
          <CouponWrap>
            {cCode && (
              <p>{`${cCode} is currently applied!`}</p>
            )}
          </CouponWrap>
          <TitleWrap>
            <TotalWrap>
              <Title>
                Total
              </Title>
              <Title>
                {USCurrencyFormat(total)}
              </Title>
            </TotalWrap>
            <CouponCode/>
            {isCreditCardPresent && saveCardResponse.data?.payment_sources.some(source => source.is_default_source) && (
              <div style={{width: "100%", marginTop: "5px"}}>
                <Title>Payment mode</Title>
                {saveCardResponse.data?.payment_sources
                  .filter(payment => payment.is_default_source)
                  .map((payment) => (
                    <CardDetailsDiv key={payment.id}>
                      <p>**** **** **** {payment.last_four_digits}</p>
                      <p>{payment.brand}</p>
                    </CardDetailsDiv>
                  ))}
              </div>
            )}
          </TitleWrap>
          {(paymentByLead != null) && (
            <TitleWrap>
              <Title
                color="var(--gray-900, #18181B)"
              >
                Team subscription
              </Title>
              <>
              {
                paymentByLead ? (
                  <>
                  <TeamTitle>
                   Team leader pays
                  </TeamTitle>
                  <TeamTitle>
                   Price per agent : {durationValue === "monthly" ? `$${associateAmount}/monthly` : `$${associateAmount}/yearly`}
                  </TeamTitle>
                  <TeamTitle>
                   Price per partner : {getMonthlyAmount(selectedPlan)}
                  </TeamTitle>
                  {/* <TeamTitle>
                   Team size : {teamSize}
                  </TeamTitle> */}
                  </>
                ) : (
                  <>
                  <TeamTitle>
                   Associate pays
                  </TeamTitle>
                  <TeamTitle>
                   Price per agent : {durationValue === "monthly" ? `$${associateAmount}/monthly` : `${associateAmount}/yearly`}
                  </TeamTitle>
                  </>
                )
              }
              </>
              
            </TitleWrap>
          )}
          <CTAWrap>
            <ButtonRow>
              {/* {!isCreditCardPresent && (
                <BlueButton
                  onClick={() => {
                    addCreditCardDetails({ agentId })
                  }}
                  disabled={disabled}
                >
                  {sendingCardDetailsEmail ? (
                    <ThreeDotLoader color="#06F" />
                  ) : (
                    <>
                      <img src="https://s3.amazonaws.com/cdn.agentdesks.com/images/SUBSCRIPTION/SUBSCRIPTION_ENVELOPE.svg" alt="soul_img" />
                      <ButtonText disabled={disabled}>Email the payment link</ButtonText>
                    </>
                  )}
                </BlueButton>
              )} */}

              {/* {!isZeroDollarPlanSelected && (
                <StripeCheckout
                  stripeKey={STRIPE_PUBLISH_KEY}
                  token={handleToken}
                  style={{
                    width: "100%",
                  }}
                  label="Subscribe"
                >
                  <BlueButton
                    width="100%"
                  >
                    <ButtonText>Update Card Details</ButtonText>
                  </BlueButton>
                </StripeCheckout>
              )} */}
            </ButtonRow>
            {/* <CheckoutButton
                    onClick={handleCheckoutV2}
                    //disabled={isDisabled || isUpdating || disabled}
                    type="primary"
                  >
                    <CheckoutButtonText>
                      {isUpdating ? <ThreeDotLoader /> : "Subscribe"}
                    </CheckoutButtonText>
            </CheckoutButton> */}
            {isCreditCardPresent ? (
              <>
                {!isEmpty(planExists) ? (
                  <CheckoutButton
                    onClick={handleCheckout}
                    disabled={isDisabled || isUpdating || disabled}
                    type="primary"
                  >
                    <CheckoutButtonText>
                      {isUpdating ? <ThreeDotLoader /> : "Update"}
                    </CheckoutButtonText>
                  </CheckoutButton>
                ) : (
                  <>
                    <CheckoutButton
                      // onClick={handleCheckout}
                      onClick={handleCheckoutV2}
                      disabled={isDisabled || isSubmitting || disabled}
                      type="primary"
                    >
                      <CheckoutButtonText>
                        {isSubmitting ? <ThreeDotLoader /> : "Subscribe"}
                      </CheckoutButtonText>
                    </CheckoutButton>
                  </>
                )}
              </>
            ) : (
              <ButtonRow
                fd="column"
                gap="10px"
              >
                {isZeroDollarPlanSelected ? (
                  <CheckoutButton
                    // onClick={handleCheckout}
                    onClick={handleCheckoutV2}
                    disabled={isDisabled || isUpdating}
                    type="primary"
                  >
                    <CheckoutButtonText>
                      {isUpdating ? <ThreeDotLoader /> : "Subscribe"}
                    </CheckoutButtonText>
                  </CheckoutButton>
                ) : (
                  // <StripeCheckout
                  //   stripeKey={STRIPE_PUBLISH_KEY}
                  //   token={handleCheckout}
                  //   style={{
                  //     width: "100%",
                  //   }}
                  //   label="Subscribe"
                  // >
                  //   <BlueButton
                  //     width="100%"
                  //     disabled={disabled}
                  //     inverted
                  //   >
                  //     <ButtonText inverted disabled={disabled}>Subscribe</ButtonText>
                  //   </BlueButton>
                  // </StripeCheckout>
                  <CheckoutButton
                    onClick={handleCheckoutV2}
                    //disabled={isDisabled || isUpdating || disabled}
                    type="primary"
                  >
                    <CheckoutButtonText>
                      {isUpdating ? <ThreeDotLoader /> : "Subscribe"}
                    </CheckoutButtonText>
                  </CheckoutButton>
                )}
                <OrText>
                  OR
                </OrText>
                <WhiteButton
                  onClick={handleInviteWithoutSubscription}
                  disabled={ disabled || isZeroDollarPlanSelected }
                >
                  <ButtonText
                    color="#DEDCDC"
                    disabled={ disabled || isZeroDollarPlanSelected }
                  >
                    Invite without Subscription
                  </ButtonText>
                </WhiteButton>
              </ButtonRow>
            )}
          </CTAWrap>
        </ContainerWrap>
      </Container>
    </Wrap>
  )
}

export default withRouter(BrokerContainer(SubDeetsContainer(SidebarContainer(SubContainer(EditAgentContainer(Summary))))))
