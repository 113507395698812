import styled from "styled-components"

export const Container = styled.div`
  margin: 16px;
  padding: ${props => props.padding || "16px"};
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px solid #F3F3F3;
`

export const TeamContainer = styled.div`
  margin: 16px;
  padding: ${props => props.padding || "16px"};
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px solid #F3F3F3;
`

export const PlanWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`

export const PlanClickBar = styled.div`
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #E5E7EB;
  // background: #FAF9FF;
  width: ${props => `${props.width}px` || "auto"};
  display: flex;
  flex-direction: column;
  cursor: pointer;

  ${props => props.isSelected && `
    border: 1px solid var(--Global-Mapping-Primary, #5A5FF2);
  `}
`

export const PlanTitle = styled.p`
  color: var(--Neutral-500, #6B7280);
  text-align: justify;

  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin: 0;
  height: ${props => props.height || "auto"};
`
export const PlanPriceWrap = styled.div`
  display: flex;
  // padding: 8px 12px;
  // justify-content: center;
  // align-items: center;
  border-radius: 11px;
  // border: 1px solid #A1A1AA;
  // background: #FFF;

  // ${props => props.isSelected && `
  //   background: var(--fill, #100063);
  // `}
`

export const PlanPrice = styled.p`
  color: var(--Neutral-900, #111827);
  text-align: justify;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.5px;
  margin: 0;

  ${props => props.isSelected && `
    color: var(--Global-Mapping-Primary, #5A5FF2);
  `}
`

export const TitleWrap = styled.div``

export const Title = styled.h1`
  color: #000;
  font-family: 'Roboto';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 2px;
  margin: 0;
`

export const ShimmerWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`
