import React, { PureComponent, useState } from "react"
import styled from "styled-components"
import Loader from "dumbComponents/common/UI/Loader"
import ListingList from "dumbComponents/Listings/components/ListingList"
import TechAssetsContainer from "container/BrokerDashboard/globalPagesContainer"
import { get, zip, isEmpty } from "lodash"
import root, { alert, setTimeout } from "window-or-global"
import PlusIcon from "images/icons/WhiteIconPlus.png"
import { PlusOutlined, PlusCircleOutlined, CloseCircleOutlined, UploadOutlined,} from "@ant-design/icons"
// import Loader from "@ui/Loader"
import Sidebar from "dumbComponents/Listings/components/Sidebar"
import ProgressBarComponent from "dumbComponents/Listings/components/ProgressBar"
import {
  Input, InputNumber, Select, Form, Checkbox,
  Divider, Button,
  GoogleAutoComplete,
  Upload,
} from "dumbComponents/common/UI/antd"
import US_STATES from "shared/US_STATES.json"
import { normalizePhone } from "../../../../services/formUtils"
// import "antd/dist/antd.css"

const Wrap = styled.div`
  margin-top: 52px;
  background: #F9F9F7;
  padding: 24px;
`

const HeaderNav = styled.div`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
`

const ProgressBarContainer = styled.div`
  padding: 30px 0px;
  display: flex;
`

const ProgressBar = styled.div`
  background: #E5E5E5;
  ${props => (props.completed || props.ongoing) && `
    background: #3B60E4;
  `}
  height: 6px;
  margin: 0 4px;
  width: 100%;
`

const Container = styled.div`
  display: flex;
`

const LeftSideBar = styled.div`
  width: 25%;
`

const RightSideBar = styled.div`
  width: 70%;
`

const LabelText = styled.h2`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  margin: 0px;
`

const Required = styled.span`
  color: #E34C4C
`

const CheckboxLabelText = styled.h2`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0px;
  text-transform: uppercase;
`

const FlexBox = styled.div`
  display: flex;
  ${props => props.wd && `
    width: ${props.wd};
  `}
`

const ProfileImageWrapper = styled.div`
  display: flex;
  padding: 5px;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  border-radius: 5px;
  margin-bottom: 32px;
  border: 1px dashed var(--Color-Secondary-Secondary, #c0c0c0);
`;
const ContentWrap = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
`;

const UploadText = styled.div`
  color: #000;
  align-self: stretch;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 10px
`;
const CheckboxContainer = styled.div`
  padding: 0 20px 0 0;
`

const StyledDivider = styled(Divider)`
  min-width: 180px !important;
  width: 180px !important;
  margin: 18px 0 !important;
`

const AddForm = styled.div`
  padding: 0 0 34px 0;
  text-align: right;
`

const HeaderInfo = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 22px;
    margin: 0px;
  }
`

const STATE_OPTIONS = Object.keys(US_STATES).map(key => ({ label: US_STATES[key], value: US_STATES[key] }))

const LabelComponent = ({ text, required }) => (
  <>
    <LabelText>{text}</LabelText>
    {required && (<Required>*</Required>)}
  </>
)

class CoverSheet extends PureComponent {
  state = {
    showCoListingform: [1],
    cityFormValue: {},
    typeOfOwnership: {},
    selectedFile:{},
    source:{},
  }

  formRef = React.createRef();

  onFinish = (fieldValues, isDraft) => {
    const {
      updateListingCoverSheet, history, dealToView, dealAgentId,uploadSellerProfileImage
    } = this.props
    const { cityFormValue } = this.state
    console.log("==fieldValues==", fieldValues)
    console.log("==cityFormValue==", cityFormValue)
    Object.keys(fieldValues).forEach((key) => {
      if (fieldValues[key] === undefined) {
        // eslint-disable-next-line no-param-reassign
        fieldValues[key] = null
      }
    })
    const localFieldValues = {
      ...fieldValues,
    }
    const payload = []
    Object.keys(localFieldValues).forEach((fvKey) => {
      const index = parseInt(fvKey.split("-")[1], 10)
      const keyName = fvKey.split("-")[0]
      if (!payload[index]) {
        payload[index] = {
          // is_primary: index === 0 ? "Y" : "N",
          is_primary: index === 0 ? 1 : 0,
          ownership_name: "",
          ownership_authorizer: "",
          ownership_email: "",
          ownership_mobile: "",
        }
      }
      payload[index] = {
        ...payload[index],
        [keyName]: localFieldValues[fvKey] || "",
      }
    })
    updateListingCoverSheet({
      payload: {
        sellers: payload,
        current_listing_step: "seller_info",
        draft: isDraft ? "Y" : "N",
      },
      dealAgentId,
      dealId: dealToView,
      // callAPI: ["agent_info", "seller_info", "source_time"],
      // nextRoute: isDraft ? "/broker/tc/add-listings" : "/broker/tc/add-listings/coversheet/listing-details",
      callAPI: ["agent_info", "seller_info", "source_time", "property_info"],
      nextRoute: isDraft ? "/broker/tc/add-listings" : "/broker/tc/add-listings/coversheet/property-info",
      history,
    })
  }

  onSaveAsDraft = (values) => {
    console.log("===this.formRef.current.getFieldsValue===", this.formRef.current.getFieldsValue())
    this.onFinish(this.formRef.current.getFieldsValue(), true)
    // this.formRef.current.setFieldsValue({
    //   "name-0": "Hello world!",
    // })
    // alert("onFinishFailed")
  }

  addAdditionalSeller = () => {
    const { showCoListingform } = this.state
    const colisting = showCoListingform
    colisting.push(showCoListingform.length + 1)
    this.setState({ showCoListingform: [...colisting] })
    setTimeout(() => {
      root.scrollTo(0, 3100)
    }, 300)
  }

  setCities = (location, cityFormName, stateFormName) => {
    const { cityFormValue } = this.state
    const locationDetails = {
      city: get(location, "locality.long_name"),
      state: get(location, "administrative_area_level_1.long_name"),
      stateShortName: get(location, "administrative_area_level_1.short_name"),
      country: get(location, "country.long_name"),
      latitude: get(location, "lat"),
      longitude: get(location, "lng"),
    }
    this.formRef.current.setFieldsValue({
      [cityFormName]: `${locationDetails.city || ""}`,
      [stateFormName]: `${locationDetails.state || ""}`,
    })
    this.setState({
      cityFormValue: {
        ...cityFormValue,
        [cityFormName]: `${locationDetails.city}`,
      },
    })
  }

  setStreet = (location, index) => {
    console.log("==payload=values=location", location)
    const locationDetails = {
      city: get(location, "locality.long_name"),
      state: get(location, "administrative_area_level_1.long_name"),
      stateShortName: get(location, "administrative_area_level_1.short_name"),
      country: get(location, "country.long_name"),
      latitude: get(location, "lat"),
      longitude: get(location, "lng"),
    }
    let neighborhood = ""
    let zipcode = ""
    if (location.street_number) {
      neighborhood += `${location.street_number.long_name} `
    }
    if (location.route) {
      neighborhood += location.route.long_name
    }
    if (location.postal_code) {
      zipcode = location.postal_code.long_name
    }

    this.formRef.current.setFieldsValue({
      [`city-${index}`]: `${locationDetails.city || ""}`,
      [`state-${index}`]: `${locationDetails.state || ""}`,
      [`country-${index}`]: `${locationDetails.country}`,
      [`zipcode-${index}`]: zipcode,
    })
    if (neighborhood) {
      this.formRef.current.setFieldsValue({
        [`address-${index}`]: neighborhood || "",
      })
    }
  }

  setLocaCity = (value, cityFormName) => {
    const { cityFormValue } = this.state
    this.setState({
      cityFormValue: {
        ...cityFormValue,
        [cityFormName]: value,
      },
    })
  }

  getInitialValues = () => {
    const { getListingCoverSheetResponse } = this.props
    const { cityFormValue, showCoListingform, typeOfOwnership, source } = this.state
    const sellerInfo = getListingCoverSheetResponse.data?.sellerInfo
    const localShowCoListingform = showCoListingform
    console.log("==getListingCoverSheetResponse.data==", getListingCoverSheetResponse.data)
    console.log("==sellerInfo.data==", sellerInfo)
    const result = {}
    if (sellerInfo) {
      sellerInfo.forEach((info, index) => {
        Object.keys(info).forEach((key) => {
          result[`${key}-${index}`] = info[key]
          if (key === "city") {
            // need to check this logic
            cityFormValue[`${key}-${index}`] = info[key]
          }
          if (!typeOfOwnership[index] && key === "type_of_ownership" && info[key]) {
            const type = typeOfOwnership
            type[index] = info[key]
            this.setState({
              typeOfOwnership: type,
            })
          }
          if (!source[index] && key==="source" && info[key]){
            const typeSource = source
            typeSource[index] = info[key]
            this.setState({
              source: typeSource,
            })
          }
        })
        // need to check this logic
        if (index > 0 && !localShowCoListingform.includes(index + 1)) localShowCoListingform.push(index + 1)
      })
    }
    console.log("==result.data==", result)
    return result
  }

  setTypeOfOwnership = (value, index) => {
    const { typeOfOwnership } = this.state
    typeOfOwnership[index] = value
    this.setState({
      typeOfOwnership: { ...typeOfOwnership },
    })
  }

  setClientSource = (value, index) => {
    const {source} = this.state
    source[index] = value
    this.setState({
      source: {...source}
    })
  }

  handleChange = (file, index,name) => {
    const { selectedFile } = this.state
    const { uploadSellerProfileImage } = this.props
    selectedFile[index] = file;
    this.setState({
      selectedFile: {...selectedFile},
    })
    uploadSellerProfileImage({
      file: selectedFile[index],
      objName: "logo",
      fileName: "here",
      bucketName: "scheduled_event_rooms",
      callback : (res) => {
        this.formRef.current.setFieldsValue({
          [name]: res,
        })
      }
    })
  }
  getFile = (e,index,name) =>{
    const file = e.file.originFileObj;
    this.handleChange(file, index,name);
  }
  

  removeColistingAgent = (index) => {
    const { showCoListingform } = this.state
    const colisting = showCoListingform.filter((sc, ind) => ind !== index)
    // colisting.push(showCoListingform.length + 1)
    this.setState({ showCoListingform: [...colisting] })
  }

  getFieldName = (typeOfOwnership) => {
    if (typeOfOwnership === "trust") {
      return ["Full Name of Trust"]
    }
    if (typeOfOwnership === "successor_trust") {
      return ["Full Name of Trust"]
    }
    if (typeOfOwnership === "entity") {
      return ["Full Name of Entity"]
    }
    if (typeOfOwnership === "probate") {
      return ["Full Name of Executer"]
    }
    if (typeOfOwnership === "estate") {
      return ["Full Name of Estate"]
    }
    if (typeOfOwnership === "power_of_attorney") {
      return ["Full Name of Attorney-in-fact"]
    }
    if (isEmpty(typeOfOwnership)) {
      return ["Full Name"]
    }
    return ["Name", "Full Name"]
  }

  getClientSource = (source) => {
    if (source === "radius_marketplace") {
      return ["Radius Marketplace"]
    }
    if (source === "zillow_agent_personal_account") {
      return ["Zillow Agent Personal Account"]
    }
    if (source === "zillow_radius_provided") {
      return ["Zillow Radius Provided"]
    }
    if (source === "zillow_flex") {
      return ["Zillow Flex"]
    }
    if (source === "opcity_realtor_com") {
      return ["Opcity Realtor Com"]
    }
    if (source === "team_mentor_lead") {
      return ["Team Mentor Lead"]
    }
    if (source === "self") {
      return ["Self"]
    }
    if (source === "referrals_agent_lender") {
      return ["Referrals Agent Lender"]
    }
    if (source === "other") {
      return ["Other"]
    }
    if (source === "fsbo") {
      return ["FSBO"]
    }
    if (source === "mail") {
      return ["Mail"]
    }
    if (source === "networking") {
      return ["Networking"]
    }
    if (source === "new_construction") {
      return ["New Construction"]
    }
    if (source === "other_agency") {
      return ["Other - Agency"]
    }
    if (source === "other_builder") {
      return ["Other - Builder"]
    }
    if (source === "paid_marketing_farming_direct") {
      return ["Paid Marketing - Farming/Direct"]
    }
    if (source === "paid_marketing_other") {
      return ["Paid Marketing - Other"]
    }
    if (source === "open_house") {
      return ["Open House"]
    }
    if (source === "paid_marketing_zillow") {
      return ["Paid Marketing - Zillow"]
    }
    if (source === "personal_transaction") {
      return ["Personal Transaction"]
    }
    if (source === "property_management") {
      return ["Property Management"]
    }
    if (source === "floor_call") {
      return ["Floor Call"]
    }
    if (source === "referral_attorney") {
      return ["Referral - Attorney"]
    }
    if (source === "referral_from_past_client") {
      return ["Referral - From Past Client"]
    }
    if (source === "referral_lendor") {
      return ["Referral - Lendor"]
    }
    if (source === "referral_other") {
      return ["Referral - Other"]
    }
    if (source === "referral_real_estate_agent_external") {
      return ["Referral - Real Estate Agent(External)"]
    }
    if (source === "referral_sphere_of_influence") {
      return ["Referral - Sphere of Influence"]
    }
    if (source === "referral_title") {
      return ["Referral - Title"]
    }
    if (source === "sign_call") {
      return ["Sign Call"]
    }
    if (source === "social_profile_facebook") {
      return ["Social Profile - Facebook"]
    }
    if (source === "social_profile_instagram") {
      return ["Social Profile - Instagram"]
    }
    if (source === "social_profile_other") {
      return ["Social Profile - Other"]
    }
    if (source === "events_other") {
      return ["Events - Other"]
    }
  }

  render() {
    const { showCoListingform, cityFormValue, typeOfOwnership,source } = this.state
    const {
      history, createListingCoverSheetResponse, updateListingCoverSheetResponse, getListingCoverSheetResponse,
      loaderOnAgentInfo,
      uploadSellerProfileImage,
    } = this.props
    const commonRules = [
      {
        required: true,
        message: "Required",
      },
    ]
    return (
      <Wrap>
        <HeaderNav>
          Cover Sheet: Seller Information
        </HeaderNav>
        <ProgressBarComponent total={[1, 2, 3, 4]} ongoingOrCompleted={2} />
        <Container>
          <LeftSideBar>
            <Sidebar />
          </LeftSideBar>
          <RightSideBar>
            <AddForm>
              <Button
                ghost
                style={{
                  float: "right",
                  borderColor: "#3B60E4",
                  color: "#3B60E4",
                  display: "flex",
                  alignItems: "center",
                }}
                type="primary"
                shape="round"
                size="large"
                icon={<PlusCircleOutlined />}
                onClick={() => { this.addAdditionalSeller() }}
              >
                Add additional seller
              </Button>
            </AddForm>
            {loaderOnAgentInfo ? (<Loader />) : (
              <Form name="seller_information" colon={false} layout="vertical" requiredMark={false} onFinish={this.onFinish} initialValues={this.getInitialValues()} ref={this.formRef}>
                {showCoListingform.map((lst, index) => (
                  <>
                    {index > 0 && (<Divider />)}
                    {index > 0 && (
                      <HeaderInfo>
                        <h2>
                          Co-Listing Seller Information
                        </h2>
                        <div onClick={() => { this.removeColistingAgent(index) }}>
                          <CloseCircleOutlined style={{ fontSize: "22px" }} />
                        </div>

                      </HeaderInfo>
                    )}
                    <FlexBox wd="100">
                      <ProfileImageWrapper>
                        <ContentWrap>
                          <UploadText>
                          Please upload image with format of jpeg.
                          </UploadText>
                          <Form.Item 
                            name={`seller_image_url-${index}`} 
                            getValueFromEvent={(e) => this.getFile(e,index,`seller_image_url-${index}`)}
                          >
                            
                            <Upload 
                            accept=".png"
                            //action={(value) => {this.handleChange(value,index)}}
                            >
                              <Button icon={<UploadOutlined />}>
                                Click to Upload
                              </Button>
                            </Upload>
                          </Form.Item>
                        </ContentWrap>
                      </ProfileImageWrapper>
                    </FlexBox>
                    <FlexBox wd="100%">
                      <Form.Item name={`firstname-${index}`} label={<LabelComponent text="First Name" required />} rules={commonRules}>
                        <Input />
                      </Form.Item>
                      <Form.Item name={`lastname-${index}`} label={<LabelComponent text="Last Name" required />} rules={commonRules}>
                        <Input />
                      </Form.Item>
                    </FlexBox>
                    <FlexBox>
                      <Form.Item
                        name={`email-${index}`}
                        label={<LabelComponent text="Email" required />}
                        required
                        rules={[
                          {
                            type: "email",
                            required: true,
                            message: "Required",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </FlexBox>
                    <FlexBox>
                      <Form.Item name={`landline-${index}`} label={<LabelComponent text="Home Phone" />}>
                        <Input />
                      </Form.Item>
                      <Form.Item name={`phone-${index}`} label={<LabelComponent text="Cell Phone" />} normalize={normalizePhone}>
                        <Input />
                      </Form.Item>
                    </FlexBox>
                    <FlexBox>
                      <Form.Item name={`address-${index}`} label={<LabelComponent text="Address" />}>
                        {/* <Input /> */}
                        <GoogleAutoComplete
                          id="cities"
                          types={["address"]}
                          name="cities"
                          // formValue={cityFormValue}
                          onChange={(e) => { }}
                          location={(loc) => { this.setStreet(loc, index) }}
                          placeholder=""
                          styles={{
                            height: "49px",
                            borderRadius: "0px",
                            background: "white",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                        />
                      </Form.Item>

                      <Form.Item name={`apt-${index}`} label={<LabelComponent text="Apt/Unit/Suite No." />}>
                        <Input />
                      </Form.Item>
                    </FlexBox>
                    <FlexBox>
                      <Form.Item name={`city-${index}`} label={<LabelComponent text="City" />} >
                        <GoogleAutoComplete
                          id="cities"
                          types={["(cities)"]}
                          name="cities"
                          // formValue={cityFormValue[`city-${index}`]}
                          onChange={(e) => { this.setLocaCity(e.target.value, `city-${index}`) }}
                          location={(loc) => { this.setCities(loc, `city-${index}`, `state-${index}`) }}
                          placeholder=""
                          styles={{
                            height: "49px",
                            borderRadius: "0px",
                            background: "white",
                            fontSize: "16px",
                            marginTop: "10px",
                          }}
                          restrict={{
                            lat: 37.09024,
                            lng: -95.712891,
                            south_west: {
                              lat: 25.82,
                              lng: -124.39,
                            },
                            north_east: {
                              lat: 49.38,
                              lng: -66.94,
                            },
                            address: "United States",
                            country: {
                              long_name: "United States",
                              short_name: "US",
                              types: [
                                "country",
                                "political",
                              ],
                            },
                          }}
                        />
                      </Form.Item>

                      <Form.Item name={`state-${index}`} label={<LabelComponent text="State"  />} >
                        {/* <Select>
                          {STATE_OPTIONS.map(st => (
                            <Select.Option value={st.value}>{st.label}</Select.Option>
                          ))}
                        </Select> */}
                        <Input />
                      </Form.Item>

                      <Form.Item
                        name={`zipcode-${index}`}
                        label={<LabelComponent text="Zip Code"  />}
                        rules={[
                          {
                            type: "string",
                            pattern: new RegExp(/\d+/g),
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </FlexBox>
                    <Divider />
                    <Form.Item name={`source-${index}`} label={<LabelComponent text="Lead Source" required />} rules={commonRules}>
                      <Select onChange={(value) => { this.setClientSource(value, index) }}>
                        <Select.Option value="radius_marketplace">Radius Marketplace </Select.Option>
                        <Select.Option value="zillow_agent_personal_account">Zillow Agent Personal Account</Select.Option>
                        <Select.Option value="zillow_radius_provided">Zillow Radius Provided</Select.Option>
                        <Select.Option value="zillow_flex">Zillow Flex</Select.Option>
                        <Select.Option value="opcity_realtor_com">Opcity Realtor Com</Select.Option>
                        <Select.Option value="team_mentor_lead">Team Mentor Lead</Select.Option>
                        <Select.Option value="self">Self</Select.Option>
                        <Select.Option value="referrals_agent_Lender">Referrals Agent Lender</Select.Option>
                        <Select.Option value="other">Other</Select.Option>
                        <Select.Option value="fsbo">FSBO</Select.Option>
                        <Select.Option value="mail">Mail</Select.Option>
                        <Select.Option value="networking">Networking</Select.Option>
                        <Select.Option value="new_construction">New Construction</Select.Option>
                        <Select.Option value="open_house">Open House</Select.Option>
                        <Select.Option value="other_agency">Other - Agency</Select.Option>
                        <Select.Option value="other_builder">Other - Builder</Select.Option>
                        <Select.Option value="paid_marketing_farming_direct">Paid Marketing - Farming/Direct</Select.Option>
                        <Select.Option value="paid_marketing_other">Paid Marketing - Other</Select.Option>
                        <Select.Option value="paid_marketing_zillow">Paid Marketing - Zillow</Select.Option>
                        <Select.Option value="personal_transaction">Personal Transaction</Select.Option>
                        <Select.Option value="property_management">Property Management</Select.Option>
                        <Select.Option value="floor_call">Floor Call</Select.Option>
                        <Select.Option value="referral_attorney">Referral - Attorney</Select.Option>
                        <Select.Option value="referral_from_past_client">Referral - From Past Client</Select.Option>
                        <Select.Option value="referral_lendor">Referral - Lendor</Select.Option>
                        <Select.Option value="referral_other">Referral - Other</Select.Option>
                        <Select.Option value="referral_real_estate_agent_external">Referral - Real Estate Agent(External)</Select.Option>
                        <Select.Option value="referral_sphere_of_influence">Referral - Sphere of Influence</Select.Option>
                        <Select.Option value="referral_title">Referral - Title</Select.Option>
                        <Select.Option value="referrals_agent_Lender">Referrals (Agent/Lender)</Select.Option>
                        <Select.Option value="sign_call">Sign Call</Select.Option>
                        <Select.Option value="social_profile_facebook">Social Profile - Facebook</Select.Option>
                        <Select.Option value="social_profile_instagram">Social Profile - Instagram</Select.Option>
                        <Select.Option value="social_profile_other">Social Profile - Other</Select.Option>
                        <Select.Option value="events_other">Events - Other</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item name={`type_of_ownership-${index}`} label={<LabelComponent text="Type Of Ownership" />}>
                      <Select onChange={(value) => { this.setTypeOfOwnership(value, index) }}>
                        <Select.Option value="individual">Individual </Select.Option>
                        <Select.Option value="trust">Trust </Select.Option>
                        <Select.Option value="successor_trust">Successor Trust </Select.Option>
                        <Select.Option value="entity">Entity (LLC, Corp, Partnership,Other) </Select.Option>
                        <Select.Option value="probate">Probate/Court Administration </Select.Option>
                        <Select.Option value="power_of_attorney">Power of Attorney </Select.Option>
                        <Select.Option value="estate">Estate (Estate,Conservatorship, Guardianship, Other)</Select.Option>
                      </Select>
                    </Form.Item>
                    {(!typeOfOwnership[index] || ["trust", "entity", "successor_trust", "probate", "power_of_attorney", "estate"].includes(typeOfOwnership[index])) && (
                      <Form.Item name={`ownership_name-${index}`} label={<LabelComponent text={this.getFieldName(typeOfOwnership[index])[0]} />}>
                        <Input />
                      </Form.Item>
                    )}
                  </>
                ))}
                <FlexBox>
                  <FlexBox wd="100%">
                    <Form.Item>
                      {/* <Button
                        style={{
                          width: 119,
                          height: 48,
                          color: "#849DF8",
                          padding: 0,
                          marginLeft: "-10px",
                        }}
                        size="large"
                        type="link"
                        onClick={this.onSaveAsDraft}
                      >
                        Save for Later
                      </Button> */}
                    </Form.Item>
                  </FlexBox>
                  <FlexBox>
                    <Form.Item>
                      <Button
                        style={{
                          width: 119,
                          height: 48,
                          background: "white",
                          float: "right",
                          borderColor: "#3B60E4",
                          color: "#3B60E4",
                        }}
                        type="primary"
                        shape="round"
                        size="large"
                        // htmlType="submit"
                        onClick={() => { history.push("/broker/tc/add-listings/coversheet/listing-agent-info") }}
                      >
                        Back
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        style={{
                          width: 119,
                          height: 48,
                          background: "#3B60E4",
                          float: "right",
                          borderColor: "white",
                        }}
                        type="primary"
                        shape="round"
                        size="large"
                        loading={updateListingCoverSheetResponse.isFetching || getListingCoverSheetResponse.isFetching}
                        htmlType="submit"
                      // onClick={() => { history.push("/broker/tc/add-listings/coversheet/listing-details") }}
                      >
                        Next
                      </Button>
                    </Form.Item>
                  </FlexBox>
                </FlexBox>
              </Form>
            )}
          </RightSideBar>
        </Container>
      </Wrap>
    )
  }
}

export default TechAssetsContainer(CoverSheet)

