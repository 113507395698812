import React, { useState, useEffect } from "react"
import subscriptionContainer from "container/BrokerDashboard/subscriptionContainer"
import { withRouter } from "react-router-dom"
import {
  Wrap,
  CouponInput,
  InputWrap,
  StyledLabel,
  SubmitCTA,
  FlexWrap,
  ErrMessage,
  SuccessMessage,
} from "./styles"

const CouponCode = ({
  checkCoupon,
  selectedPlan,
  couponCodeResponse,
  currentAgentIDViewed,
  currentCouponText,
  handleCouponFieldChange,
  ...props
}) => {
  const [status, setStatus] = useState("")

  const {
    data: couponData,
    isFetching: verifyingCoupon,
  } = couponCodeResponse || {}

  const handleChange = (event) => {
    handleCouponFieldChange(event.target.value)
  }

  const verifyCoupon = () => {
    const planId = selectedPlan && selectedPlan.planId
    // const agentId = get(props, "match.params.agentId", "")
    const {
      match: {
        params: {
          agentId,
        },
      },
    } = props || {}

    checkCoupon({
      couponCode: currentCouponText,
      agentId,
      planId,
    })
  }

  useEffect(() => {
    if (!verifyingCoupon && couponData) {
      if (couponData.trialInfo === null) {
        setStatus("error")
      } else if (couponData.trialInfo) {
        setStatus("success")
      }
    }
  }, [couponData, verifyingCoupon, currentCouponText])

  useEffect(() => {
    if (!currentCouponText || currentCouponText.length <= 0) {
      setStatus("")
    }
  }, [currentCouponText])

  return (
    <Wrap>
      <InputWrap>
        <StyledLabel>Coupon Code</StyledLabel>
        <FlexWrap isErrored={status === "error"}>
          <CouponInput
            value={currentCouponText}
            name="coupon_code"
            onChange={handleChange}
            placeholder="Enter coupon"
          />
          {!status || status === "success" ? (
            <SubmitCTA
              onClick={verifyCoupon}
              loading={verifyingCoupon}
            >
                Apply
            </SubmitCTA>
          ) : status === "error" && (
            <SubmitCTA
              type="primary"
              onClick={() => {
                setStatus("")
                handleCouponFieldChange("")
              }}
              loading={verifyingCoupon}
            >
                Clear
            </SubmitCTA>
          )}
        </FlexWrap>
        <FlexWrap>
          {status === "error" && (
            <ErrMessage>
              Invalid Coupon
            </ErrMessage>
          )}
          {status === "success" && (
            <SuccessMessage>
              {`"${currentCouponText}" applied!`}
            </SuccessMessage>
          )}
        </FlexWrap>
      </InputWrap>
    </Wrap>
  )
}

export default withRouter(subscriptionContainer(CouponCode))
