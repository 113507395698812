import React from "react"
import {
  ResendInvitation,
  ReviewAndSend,
  ReviewDocuments,
  SendOnboardingInvite,
} from "./CTAS"

export const GetCTA = (status, containsInactiveDocument, isFullySignedICAShown, selectedPlan) => {

  if (isFullySignedICAShown) {
    return (<SendOnboardingInvite />)
  }

  if (selectedPlan && selectedPlan.planId.startsWith("rn_")) {
    return (<SendOnboardingInvite />)
  }

  switch (status) {
    case "created":
      return (<ReviewAndSend disabled />)
    case "active":
      return (<ReviewAndSend disabled={containsInactiveDocument} />)
    case "sales_review_pending":
      return (<ReviewDocuments disabled={containsInactiveDocument} />)
    case "agent_signature_pending":
      return (<SendOnboardingInvite disabled={containsInactiveDocument} />)
    case "sent":
    case "completed":
      return (<ResendInvitation disabled={containsInactiveDocument} />)
    default:
      return (<></>)
  }
}

export const enabledStates = ["active", "sales_review_pending", "agent_signature_pending", "sent", "completed"]
