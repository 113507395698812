import React, { useState, useEffect } from "react"
import {
  Flex, Radio, Checkbox, Button, Modal,
} from "antd"
// import { ShimmerBox } from "shared/styles/animation"
import {
  CreditCardOutlined, BankOutlined, PlusOutlined, DeleteOutlined,
} from "@ant-design/icons"
import { ShimmerBox } from "shared/styles/animation"
import { ShimmerWrap } from "../../common";
// import Container from "container/Settings/SubscriptionsContainer"
// import SettingsContainer from "container/Settings"
// import Navbar from "container/Navbar"
// import Loader from "@ui/Loader"
import { isEmpty } from "lodash"
// import VisaImg from "images/settings/visa.png"
// import MasterCardImg from "images/settings/master-card.png"
import EditCardModal from "./Modals/EditCardModal"
import DeleteCardModal from "./Modals/DeleteCard"
import DefaultCard from "./Modals/DefaultCard"
import AddPayment from "./Modals/AddPayment"
// import DeclineMethod from "./Modals/DeclinedMethod"
import SidebarContainer from "container/BrokerDashboard/sidebarContainer"
import EditAgentContainer from "container/BrokerDashboard/editAgentContainer"
import SubContainer from "container/BrokerDashboard/subscriptionContainer"
import {
  CommonText,
  CurrentPlanDiv,
  CardDiv,
  CardNumberDiv,
  BillingEdit,
  CardLogo,
  AddNewCard,
  EditDetails,
} from "./styles"

const PaymentMethod = ({
  editPayment,
  deletePayment,
  defaultPayment,
  agentId,
//   isFetching,
//   data,
  saveCardResponse,
  fetchPayment,
  getAgentCardDetails,
  setLoadingCard,
  makeDefaultMethod,
}) => {
  console.log("data", data)
  const {
    data,
    isFetching,
   } = saveCardResponse || {}
  const [sortedPaymentData, setSortedPaymentData] = useState([])
  const [isEdit, setEdit] = useState(false)
  const [isEditDetails, setEditDetails] = useState(false)
  const [isMakePrimary, setMakePrimary] = useState(false)
  const [isDeleteOpen, setDeleteOpen] = useState(false)
  const [stripeId, setStripeId] = useState(null)
  const [isAddCard, setAddCard] = useState(false)
  const [errorValue, setErrorValue] = useState(null)
  const [declineCard, setDeclineCard] = useState(false)
  useEffect(() => {
    if (data?.payment_sources) {
      const sortedData = [...data.payment_sources].sort((a, b) => {
        if (a.bank_name && !b.bank_name) return -1
        if (!a.bank_name && b.bank_name) return 1
        return 0
      })
      setSortedPaymentData(sortedData)
    }

    if(data?.payment_sources?.length === 1) {
      if(data?.payment_sources[0]?.is_default_source === false) {
        handleOnLoadDefaultPayment(data?.payment_sources[0]?.id);
      }
    }
  }, [data])

//   const getCardBrandLogo = (brand) => {
//     switch (brand?.toLowerCase()) {
//       case "visa":
//         return <CardLogo src={VisaImg} alt="Visa" />
//       case "mastercard":
//         return <CardLogo src={MasterCardImg} alt="MasterCard" />
//       case "amex":
//         return <CardLogo src={`${CLOUDFRONT}/brokeragePage/American Express Icon.svg`} alt="American Express" />
//       default:
//         return <CreditCardOutlined style={{ fontSize: 16 }} />
//     }
//   }

  const onEditClick = () => {
    setEdit(!isEdit)
  }

  const onEditDetailsClick = (value) => {
    setStripeId(value.id)
    setEditDetails(true)
  }

  const onMakePrimaryMethod = (value) => {
    setStripeId(value.id)
    setMakePrimary(true)
  }

  const onClickAddCard = () => {
    setAddCard(true)
  }

  const closeAddCard = () => {
    console.log("here closing")
    setAddCard(false)
  }

  const closeMakePrimaryMethod = () => {
    setStripeId(null)
    setMakePrimary(false)
  }

  const onDeleteClick = (value) => {
    setStripeId(value.id)
    setDeleteOpen(true)
  }

  const closeEditDetails = () => {
    setStripeId(null)
    setEditDetails(false)
  }

  const closeDeleteModal = () => {
    setStripeId(null)
    setDeleteOpen(false)
  }

  const closeDeclineModal = () => {
    console.log("here close")
    setDeclineCard(false)
    setStripeId(null)
  }

  const handleDeclineCard = () => {
    console.log("here reached")
    setDeclineCard(false)
    setLoadingCard(false)
    setAddCard(true)
  }

  const handleEditDetails = ({ expiryMonth, expiryYear }) => {
    const payload = {
      payment_method_id: stripeId,
      exp_month: parseInt(expiryMonth, 10),
      exp_year: parseInt(expiryYear, 10),
      agentId: agentId,
    }
    editPayment({
      payload,
      callback: () => {
        setStripeId(null)
        setEditDetails(false)
        // fetchPayment()
        getAgentCardDetails({
            agentId
        })
      },
    })
  }

  const handleDeleteMethod = () => {
    console.log("stripeId", stripeId)
    const payload = {
      payment_method_id: stripeId,
      agentId: agentId,
    }
    deletePayment({
      payload,
      callback: () => {
        setStripeId(null)
        setEditDetails(false)
        setDeleteOpen(false)
        // fetchPayment()
        getAgentCardDetails({
            agentId
        })
      },
    })
  }
  const handleDefaultPayment = () => {
    const payload = {
      payment_method_id: stripeId,
      agentId: agentId,
    }
    defaultPayment({
      payload,
      callback: () => {
        setStripeId(null)
        setEditDetails(false)
        setMakePrimary(false)
        // fetchPayment()
        getAgentCardDetails({
            agentId
        })
      },
    })
  }

  const handleOnLoadDefaultPayment = (payment_id) => {
    const payload = {
      payment_method_id: payment_id,
      agentId: agentId,
    }
    defaultPayment({
      payload,
      callback: () => {
        setStripeId(null)
        setEditDetails(false)
        setMakePrimary(false)
        // fetchPayment()
        getAgentCardDetails({
            agentId
        })
      },
    })
  }

  const handleAddCardDetails = (value = "success") => {
    console.log("aman", value);
    if (value == "success") {
        const payload = {
            agentId: agentId,
        }
      makeDefaultMethod({
        payload,
        callback: () => {
          setAddCard(false)
        //   fetchPayment()
        getAgentCardDetails({
            agentId
        })
        },
      })
    } else {
      setLoadingCard(false)
      setErrorValue(value)
      setAddCard(false)
      setDeclineCard(true)
    }
  }

  return (
    <>
      {isFetching ? (
        // <ShimmerBox w="100%" h="200px" />
        // <p>Loading...</p>
          <ShimmerWrap style={{padding: "12px"}}>
            <ShimmerBox w="800px" h="200px" />
          </ShimmerWrap>
      ) : (
        <>
          <EditCardModal isOpen={isEditDetails} toClose={closeEditDetails} handleProceed={handleEditDetails} handleClickForCancel={closeEditDetails} />
          <DeleteCardModal isOpen={isDeleteOpen} toClose={closeDeleteModal} handleProceed={handleDeleteMethod} handleClickForCancel={closeDeleteModal} />
          <DefaultCard isOpen={isMakePrimary} toClose={closeMakePrimaryMethod} handleProceed={handleDefaultPayment} handleClickForCancel={closeMakePrimaryMethod} />
          {isAddCard && <AddPayment isOpen={isAddCard} toClose={closeAddCard} handleProceed={handleAddCardDetails} handleClickForCancel={closeAddCard} agentId={agentId} />}
          {/* <DeclineMethod isOpen={declineCard} toClose={closeDeclineModal} handleProceed={handleDeclineCard} handleClickForCancel={closeDeclineModal} errorValue={errorValue} /> */}
          <CurrentPlanDiv style={{margin: "15px"}}>
            <div
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <CommonText
                  color="#111827"
                  fontSize="16px"
                  weight="600"
                  lineHeigth
                >
                  Payment methods
                </CommonText>
                <CommonText
                  color="#6B7280"
                  fontSize="14px"
                  weight="400"
                  lineHeigth
                >
                  Manage your payment options
                </CommonText>
              </div>
              {isEdit ? (
                <BillingEdit marginLeft onClick={onEditClick}>
                  Save
                </BillingEdit>
              ) : (
                <BillingEdit marginLeft onClick={onEditClick}>
                  Edit payment methods
                </BillingEdit>
              )

              }

            </div>
            {data
                    && data.payment_sources
                    && data.payment_sources.length > 0 && sortedPaymentData.length > 0 && sortedPaymentData
                    && sortedPaymentData.map(card => (
                      <CardDiv>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                          }}
                        >
                          <CommonText
                            color="#111827"
                            fontSize="14px"
                            weight="400"
                          >
                            {card.bank_name
                              ? "ACH payment"
                              : "Card payment"}
                          </CommonText>
                          {isEdit ? (
                            <>
                              {card.bank_name ? (<></>) : (
                                <EditDetails
                                  onClick={() => onEditDetailsClick(card)}
                                >
                                  Edit
                                </EditDetails>
                              )}
                            </>

                          ) : (
                            <>
                            </>
                          )

                          }

                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              flexDirection: "column",
                              gap: "5px",
                            }}
                          >
                            <CardNumberDiv>
                              <span>
                                Connected to
                                {" "}
                                {card.bank_name
                                  ? `${card.bank_name} ****${card.last_four_digits}`
                                  : `**** ${card.last_four_digits}`}
                              </span>
                              {card.bank_name ? (
                                <BankOutlined style={{ marginLeft: "8px", fontSize: "16px" }} />
                              ) : (
                                // getCardBrandLogo(card.brand)
                                <p>{card.brand}</p>
                              )}
                            </CardNumberDiv>
                            {
                              (!isEdit && card.is_default_source) ? (
                                <CommonText
                                  color="#6B7280"
                                  fontSize="14px"
                                  weight="400"
                                >
                                  Primary method
                                </CommonText>
                              ) : (
                                <></>
                              )
                            }
                          </div>
                          {(isEdit && !card.is_default_source) ? (
                            <Button
                              type="text"
                              icon={(
                                <DeleteOutlined
                                  style={{ fontSize: "16px", color: "#F05152" }}
                                  onClick={() => onDeleteClick(card)}
                                />
                              )}
                            />

                          ) : (
                            <>
                            </>
                          )

                          }

                        </div>
                        <>
                          {
                            isEdit ? (
                              <>
                                {
                                  card.is_default_source ? (
                                    <CommonText
                                      color="#6B7280"
                                      fontSize="14px"
                                      weight="400"
                                    >
                                      Primary method
                                    </CommonText>
                                  ) : (
                                    <BillingEdit onClick={() => onMakePrimaryMethod(card)}>
                                      Make this primary method
                                    </BillingEdit>
                                  )
                                }
                              </>
                            ) : (<></>)
                          }
                        </>

                      </CardDiv>
                    ))}
            {
              isEdit ? (
                <AddNewCard onClick={() => onClickAddCard()}>
                  <PlusOutlined style={{ color: "#5A5FF2" }} />
                  {" "}
                  Add new method
                </AddNewCard>
              ) : (<></>)
            }

          </CurrentPlanDiv>
        </>
      ) }
    </>

  )
}

export default SubContainer(EditAgentContainer(SidebarContainer(PaymentMethod)))
