import React, { useEffect, useState } from "react"
import Container from "container/BrokerDashboard/editAgentContainer"
import SidebarContainer from "container/BrokerDashboard/sidebarContainer"
import BrokerContainer from "container/BrokerDashboard/subscriptionContainer"
import moment from "moment"
import { Form } from "antd"
import {
  FormWrap,
  FlexRow,
  CustomDatePicker,
} from "./commonStyles"
import {
  SubmitFormButton,
  CancelFormButton,
} from "./commonComponents"

const DatePickerStyle = {
  width: "300px",
  height: "39px",
  padding: "10px 14px",
  borderRadius: "4px !important",
  background: "var(--White, #FFF)",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  border: "1px solid #D9D9D9 !important",
}

const TeamCreationForm = ({
  onFinishHandle,
  isSending: submitting,
  toClose,
  setDateSelected,
  isDateSelected,
  agentBrokerageInfoResponse,
}) => {
  const { data: brokerageData, isFetching: fetchingBrokerageDetails } = agentBrokerageInfoResponse || {}
  const [form] = Form.useForm();

  useEffect(() => {
    if (brokerageData?.credit_card_pop_up_blocker_time) {
      const momentObj = moment.unix(brokerageData.credit_card_pop_up_blocker_time);
      form.setFieldsValue({ credit_card_pop_up_blocker_time: momentObj });
    }
  }, [brokerageData, form]);

  return (
    <FormWrap>
      <Form
        form={form}
        name="invite_without_subscription_form"
        layout="vertical"
        size="large"
        onFinish={onFinishHandle}
        autoComplete="off"
      >
        <FlexRow>
          <Form.Item
            label="Subscription Start Date"
            name="credit_card_pop_up_blocker_time"
          >
            {!fetchingBrokerageDetails && (
              <CustomDatePicker
                style={DatePickerStyle}
                allowClear={false}
                onChange={(date) => {
                  form.setFieldsValue({ credit_card_pop_up_blocker_time: date });
                  setDateSelected(true);
                }}
                disabled={brokerageData?.credit_card_pop_up_blocker_time}
              />
            )}
          </Form.Item>
        </FlexRow>
        <FlexRow gap="10px">
          <SubmitFormButton
            submitting={submitting}
            title={isDateSelected ? "Submit" : "Submit/Skip"}
          />
          <CancelFormButton onClick={toClose} />
        </FlexRow>
      </Form>
    </FormWrap>
  )
}

export default Container(SidebarContainer(BrokerContainer(TeamCreationForm)))
