/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import _, { isEmpty, parseInt, get } from "lodash"
import root from "window-or-global"
import {
  Select, Button, Input, Switch, Modal, Spin,
} from "antd"
import Loader from "../../../common/UI/Loader"
import PlusSign from "./PlusOutlined.svg"
import container from "../../../../container/BrokerDashboard/editAgentContainer"

const Wrap = styled.div`
  margin: 20px;
`
const Title = styled.h1`
  margin-top: 34px;
  margin-left: 17px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  ${props => props.body && `
  margin-top: 0px;
  padding-left: 46px;
`}
`
const Content = styled.div`
  margin-top: 58px;
  margin-left: 17px;
  width: 1040px;
  height: 100%;
  .Content::-webkit-scrollbar {
    display: none;
  };
`
const HeaderWrap = styled.div`
  padding-left: 46px;
  padding-top: 48px;
  padding-right: 28px;
  display: flex;
  justify-content: space-between;
`
const AddNewDoc = styled.div`
  display: flex;
  margin-left: 48px;
  margin-top: 18px;
  cursor: pointer;
  ${props => props.colist && `
  margin-left: 0px;
`}

`
const Image = styled.img`
  margin-right: 16px;
`
const AddNew = styled.h3`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: #100063;
  margin: 0px;
`
const DropdownDiv = styled.div`
  display: flex;
`
const Subtitle = styled.h2`
  margin: 0px;
  margin-top: 6px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  ${props => props.body && `
  margin-top: 0px;
  padding-left: 63px;
  font-size: 16px;
`}
`
const HR = styled.div`
  width: 100%;
  height: 0px;
  border: 1px solid #E6E6E6;
  margin-top: 32px;
  margin-left: 40px;
  ${props => props.colist && `
  margin-left: -9px;
  `}
  ${props => props.last && `
  margin-left: -9px;
  margin-bottom: 30px;
  `}
`
const Body = styled.div`
  // scroll: scroll;
  // overflow-x: hidden;
  height: 1000px;
  &::-webkit-scrollbar {
    display: none;
  }
`
const TitleDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
`
const InfoDiv = styled.div`
  width: 990px;
  height: 102px;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  margin-top: 87px;
  margin-left: 50px;
  display: flex;
  justify-content: space-around;
  ${props => props.body && `
  margin-top: 23px;
  margin-bottom: 44px;
`}
${props => props.stuck && `
  margin-top: 0px;
  margin-bottom: 44px;
  margin-left: 0px;
`}
${props => props.first && `
  margin-top: 0px;
  margin-bottom: 44px;
`}
${props => props.owner && `
  padding-left: 0px;
  margin-left: 0px;
  margin-top: 23px;
`}
`
const Section = styled.div`
  display: flex;
  flex-direction: column;
`
const SmallTitle = styled.h3`
  font-size: 14px;
  font-weight: 200;
  margin-top: 8px;
`
const AdditionDocuments = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 86px;
`
const Divider = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 35px;
`
const SmallHR = styled.div`
  width: 400.5px;
  height: 0px;
  border: 1px solid #E6E6E6;
  margin-top: 32px;
  margin-left: 40px;
`
const SmallText = styled.h3`
  width: 158px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 0px;
  margin-top: 0px;
  padding: 0px;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
`
const MLSDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 250px;
  ${props => props.body && `
  padding-left: 0px;
  `}
  ${props => props.contingency && `
    padding-left: 0px;
    width: 800px;
  `}
`
const Text = styled.p`
  margin: 0px;
  width: 553px;
  padding-left: 63px;
  margin-top: 20px;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  ${props => props.colist && `
  font-weight: 100;
  color: #8A8A8A;
  padding-left: 0;
  `}
  ${props => props.key && `
  font-weight: 100;
  padding-left: 0;
  `}
`
const CoListingDiv = styled.div`
  margin-top: 41px;
  padding-left: 46px;
`
const OwnershipDiv = styled.div`
  margin-top: 41px;
  padding-left: 46px;
`
const KeysafeDiv = styled.div`
  padding-left: 50px;
  margin-top: 35px;
`
const InvalidText = styled.h3`
  margin-top: 5px;
  margin-bottom: 0px;
  color: red;
  font-size: 12px;
`

const TitleWrap = styled.div`
  padding-left: 46px;
  padding-top: 48px;
  padding-right: 28px;
  display: flex;
  justify-content: flex-start;
`

const TitleAndTemplate = ({
  isEdit,
  valueName,
  valueTemplate,
  entityName,
  entityType,
  addArray,
  setAddArray,
  templateList,
  index,
  state,
  isShifted,
  templateData,
  setTemplateInvalid,
  deleteTemplates,
  localTemplateData,
  setLocalTemplateData,
}) => {
  const [name, setName] = useState("")
  const [template, setTemplate] = useState("")
  const [isInvalidMessage, setIsInvalidMessage] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [invalidArray, setInvalidArray] = useState([])

  useEffect(() => {
    setName(valueName)
  }, [valueName])

  useEffect(() => {
    setTemplate(valueTemplate)
  }, [valueTemplate])

  const handleAddTemplate = (nameTarget, templateTarget) => {
    if (!isEmpty(templateList)) {
      templateList.map((x) => {
        if (x.idToAdd === index) {
          if (index.includes("old-")) {
            const id = parseInt((index.split("-").pop()), 10)
            x.name = nameTarget || ""
            x.id = id
            x.idToAdd = index
            x.templateId = templateTarget || ""
            x.entityName = entityName
            x.entityType = entityType
            x.state = state
            x.isEdited = true
          } else if (entityType === "mls_setup") {
            x.name = nameTarget || ""
            x.idToAdd = index
            x.templateId = templateTarget || ""
            x.entityType = entityType
            x.state = state
          } else {
            x.name = nameTarget || ""
            x.idToAdd = index
            x.templateId = templateTarget || ""
            x.entityName = entityName
            x.entityType = entityType
            x.state = state
          }
        }
      })
    }
  }

  const handleDelete = (indexLocal) => {
    const checkId = indexLocal.split("-").pop()
    if (indexLocal.includes("old-")) {
      templateData.map((x) => {
        if (x.id.toString() === checkId) {
          deleteTemplates.push(x.id)
          localTemplateData.splice(localTemplateData.indexOf(x), 1)
          setLocalTemplateData([...localTemplateData])
        }
      })
    } else if (addArray.length > 1) {
      const localArr = addArray.filter((sc, ind) => ind.toString() !== checkId)
      setAddArray([...localArr])
    }
    templateList.map((x) => {
      if (x.idToAdd === indexLocal) {
        templateList.splice(templateList.indexOf(x), 1)
      }
    })

    const checkIdInTemplateDataIndex = templateData.findIndex(x => x.id.toString() === checkId)

    if (checkIdInTemplateDataIndex !== -1) {
      templateData.map((x) => {
        if (x.id.toString() !== checkId) {
          setTemplateInvalid(false)
        }
      })
    } else {
      setTemplateInvalid(false)
    }

    setDeleteModal(false)
  }

  //Function to keep save button disabled if even one template ID is duplicate
  const checkDisabled = () => {
    if (templateList.length > 1) {
      setInvalidArray([])
      templateList.map((x) => {
        templateList.map((y) => {
          if (x.templateId === y.templateId && x.idToAdd !== y.idToAdd) {
            invalidArray.push(y)
          }
        })
      })
      if (invalidArray.length > 0) {
        setTemplateInvalid(true)
      } else {
        setTemplateInvalid(false)
      }
    }
  }

  const checkIsDeleted = (indexLocal) => {
    const checkId = indexLocal.split("-").pop()
    deleteTemplates.map((x) => {
      if (x.toString() === checkId) {
        return true
      }
      return false
    })
  }

  const handleCancel = () => {
    setDeleteModal(false)
  }

  return (
    <>
      {!isShifted ? (
        <InfoDiv
          body
        >
          <Section>
            <SmallTitle>Title</SmallTitle>
            <Input
              placeholder="Enter the Document Name"
              disabled={!isEdit}
              style={{
                width: 400,
                height: 32,
                background: "#F9F8FF",
                border: 1,
                borderColor: "#D9D9D9",
                borderRadius: 2,
                color: "black",
              }}
              value={name}
              onChange={(e) => {
                setName(e.target.value)
                handleAddTemplate(e.target.value, template, index)
              }}
            />
          </Section>
          <Modal
            title="Delete Template"
            visible={deleteModal}
            onOk={() => handleDelete(index)}
            onCancel={handleCancel}
          >
            Are you sure you want to delete this template?
          </Modal>
          <Section>
            <SmallTitle>Template ID</SmallTitle>
            <Input
              placeholder="Enter the Template ID"
              disabled={!isEdit}
              required
              style={{
                width: 400,
                height: 32,
                background: "#F9F8FF",
                borderThickness: 1,
                borderColor: "#D9D9D9",
                borderRadius: 2,
                color: "black",
              }}
              value={template}
              onChange={(e) => {
                setTemplate(e.target.value)
                handleAddTemplate(name, e.target.value, index)
              }}
            />
            {isInvalidMessage === true ? (
              <>
                <InvalidText>* The Template ID is duplicate, please enter a different Template ID</InvalidText>
              </>
            ) : null}
          </Section>
          {isEdit ? (
            <>
              {checkIsDeleted === true ? (
                <Button
                  type="primary"
                  style={{
                    alignSelf: "center",
                  }}
                  disabled
                >
                  Deleted
                </Button>
              ) : (
                <Button
                  style={{
                    alignSelf: "center",
                  }}
                  onClick={() => setDeleteModal(true)}
                >
                  Delete
                </Button>

              )}
            </>
          ) : null}
        </InfoDiv>

      ) : (
        <InfoDiv
          owner
        >
          <Section>
            <SmallTitle>Title</SmallTitle>
            <Input
              placeholder="Enter the Document Name"
              disabled={!isEdit}
              style={{
                width: 400,
                height: 32,
                background: "#F9F8FF",
                border: 1,
                borderColor: "#D9D9D9",
                borderRadius: 2,
                color: "black",
              }}
              value={name}
              onChange={(e) => {
                setName(e.target.value)
                handleAddTemplate(e.target.value, template, index)
              }}
            />
          </Section>
          <Modal
            title="Delete Template"
            visible={deleteModal}
            onOk={() => handleDelete(index)}
            onCancel={handleCancel}
          >
            Are you sure you want to delete this template?
          </Modal>
          <Section>
            <SmallTitle>Template ID</SmallTitle>
            <Input
              placeholder="Enter the Template ID"
              disabled={!isEdit}
              required
              style={{
                width: 400,
                height: 32,
                background: "#F9F8FF",
                borderThickness: 1,
                borderColor: "#D9D9D9",
                borderRadius: 2,
                color: "black",
              }}
              value={template}
              onChange={(e) => {
                setTemplate(e.target.value)
                handleAddTemplate(name, e.target.value, index)
              }}
            />
            {isInvalidMessage === true ? (
              <InvalidText>* The Template ID is duplicate, please enter a different Template ID</InvalidText>
            ) : null}
          </Section>
          {isEdit ? (
            <>
              {checkIsDeleted === true ? (
                <Button
                  type="primary"
                  style={{
                    alignSelf: "center",
                  }}
                  disabled
                >
                  Deleted
                </Button>
              ) : (
                <Button
                  style={{
                    alignSelf: "center",
                  }}
                  onClick={() => setDeleteModal(true)}
                >
                  Delete
                </Button>
              )}
            </>
          ) : null}
        </InfoDiv>

      )}
    </>
  )
}

const ViewPage = ({
  getListingTotalCommonTemplate,
  getListingTotalCountTemplateResponse,
  getDocusignTemplate,
  getDocusignTemplateResponse,
  templateList,
  setTemplateInvalid,
  templateInvalid,
  setTemplateList,
  createDocusignTemplate,
  deleteTemplates,
  setDeleteTemplates,
  deleteDocusignTemplate,
}) => {
  const [isEdit, setIsEdit] = useState(false)

  const [addNewOG, setAddNewOG] = useState(false)
  const [addNewMLS, setAddNewMLS] = useState(false)
  const [addNewCoList, setAddNewColist] = useState(false)
  const [addNewOwner, setAddNewOwner] = useState(false)
  const [addNewKey, setAddNewKey] = useState(false)
  const [addNewOther, setAddNewOther] = useState(false)

  const [addOGDoc, setAddOGDoc] = useState([1])
  const [addMLSDoc, setAddMLSDoc] = useState([1])
  const [addCoListDoc, setAddCoListDoc] = useState([1])
  const [addOwnerDoc, setAddOwnerDoc] = useState([1])
  const [addKeyDoc, setAddKeyDoc] = useState([1])
  const [addOtherDoc, setAddOtherDoc] = useState([1])
  const [state, setState] = useState("california")

  const [valueListingType, setValueListingType] = useState("residential_1_4")
  const [valueCoList, setValueCoList] = useState("radius")
  const [valueOwner, setValueOwner] = useState("trust")

  const [localTemplateData, setLocalTemplateData] = useState([])
  const [localList, setLocalList] = useState([])

  const [saveModal, setSaveModal] = useState(false)
  const [showSpin, setShowSpin] = useState(false)

  const {
    data: templateData,
    isFetching,
  } = getDocusignTemplateResponse || {}

  const {
    data: result,
  } = getListingTotalCountTemplateResponse || {}

  useEffect(() => {
    getDocusignTemplate()
    getListingTotalCommonTemplate()
  }, [])

  useEffect(() => {
    if (templateData) {
      templateData.map((x) => {
        templateList.push({
          idToAdd: `old-${x.id}`,
          name: x.name,
          templateId: x.template_id,
          entityName: x.entity_name,
          entityType: x.entity_type,
          state: x.state,
          isEdited: false,
        })
      })
      setLocalTemplateData(templateData)
    }
  }, [templateData])

  const getTotalCountByState = (givenState) => {
    const res = _.find(result, { state: givenState })
    return res ? res.total : 0
  }

  const addOGDocFunc = () => {
    setAddOGDoc([...addOGDoc, addOGDoc.length])
  }

  const addMLSDocFunc = () => {
    setAddMLSDoc([...addMLSDoc, addMLSDoc.length])
  }

  const addCoListDocFunc = () => {
    setAddCoListDoc([...addCoListDoc, addCoListDoc.length])
  }

  const addOwnerDocFunc = () => {
    setAddOwnerDoc([...addOwnerDoc, addOwnerDoc.length])
  }

  const addKeyDocFunc = () => {
    setAddKeyDoc([...addKeyDoc, addKeyDoc.length])
  }

  const addOtherDocFunc = () => {
    setAddOtherDoc([...addOtherDoc, addOtherDoc.length])
  }

  const saveCancel = () => {
    setSaveModal(false)
  }

  const transactionType = () => {
    let types = [
      {
        value: "residential_1_4",
        label: "Standard Residential",
      },
      {
        value: "manufactured_mo",
        label: "Manufactured / Mobile Home",
      },
      {
        value: "vacant_land",
        label: "Vacant Land",
      },
      {
        value: "residential_inc",
        label: "Residential Income Property",
      },
      {
        value: "lease",
        label: "Lease",
      },
    ]
    if (state === "texas") {
      types = [...types, {
        value: "condominium",
        label: "Condominium",
      },
      {
        value: "new_construction",
        label: "New Construction",
      },
      {
        value: "farm_and_ranch",
        label: "Farm and Ranch",
      },
      {
        value: "commercial",
        label: "Commercial",
      },
      ]
    }
    if (state === "washington") {
      types = [...types, {
        value: "condominium",
        label: "Condominium",
      },
      {
        value: "new_construction",
        label: "New Construction",
      },
      ]
    }
    if (state === "georgia") {
      types = [...types, {
        value: "commercial",
        label: "Commercial",
      },
      ]
    }
    if (state === "oregon") {
      types = [...types, {
        value: "new_construction",
        label: "New Construction",
      },
      ]
    }

    if (state === "arizona") {
      types = [
        {
          value: "residential_listing",
          label: "Residential Listing",
        },
        {
          value: "short_sale_listing",
          label: "Short Sale Listing",
        },
        {
          value: "land_lot_listing",
          label: "Lot/Land Listing",
        },
        {
          value: "residential_lease_listing",
          label: "Residential Lease Listing",
        },
      ]
    }
    return types
  }

  const checkIfEmpty = () => {
    let isInvalid = null
    if (!isEmpty(templateList)) {
      templateList.find((x) => {
        if ((x.name === "") || (x.templateId === "")) {
          let keyword = null
          if (x.entityType === "transaction_type") keyword = "Type of Listing"
          if (x.entityType === "mls_setup") keyword = "MLS on Market"
          if (x.entityType === "co_listing_agent") keyword = "Co-Listing Agent"
          if (x.entityType === "ownership") keyword = "Ownership"
          if (x.entityType === "other" && x.entityName === "keybox_placement") keyword = "Keysafe / Lockbox"
          if (x.entityType === "other" && x.entityName === "replacement_property_purchase") keyword = "Replacement Property Purchase"
          isInvalid = window.confirm(`Some values for ${keyword} are empty, please fill appropriate details in the empty fields`)
          if (isInvalid) {
            setSaveModal(false)
          }
        }
      })
    }
    return isInvalid
  }

  const saveList = () => {
    const checking = checkIfEmpty() === true || false
    if (checking === true) {
      setIsEdit(true)
      return
    }
    if (checking === false) {
      if (!isEmpty(templateList)) {
        templateList.forEach((x) => {
          if (x.name !== "" && x.templateId !== "") {
            if (x.isEdited && x.isEdited === true) {
              localList.push(x)
            } else if (!("isEdited" in x)) {
              localList.push(x)
            }
          }
        })
        if (!isEmpty(localList)) {
          createDocusignTemplate({
            payload: localList,
            callback: () => {
              setShowSpin(false)
              root.location.reload()
            },
          })
          setShowSpin(true)
        }
      }
      if (!isEmpty(deleteTemplates)) {
        deleteTemplates.map((id) => {
          deleteDocusignTemplate({
            id,
            callback: () => {
              setShowSpin(false)
              root.location.reload()
            },
          })
        })
        setShowSpin(true)
      }
      if (isEmpty(deleteTemplates) && isEmpty(localList)) {
        root.location.reload()
      }
      setTemplateList([])
      setLocalList([])
      setIsEdit(false)
      setSaveModal(false)
    }
  }

  return (
    <Wrap>
      <TitleWrap>
        <Title body style={{ margin: 0, marginRight: "510px" }}>Listing Document Packet</Title>
        <Button
          style={{
            width: 170,
            background: "#100063",
            color: "white",
          }}

          onClick={() => {
            root.open("/broker/upload-templates-listing")
          }}
        >
          Document Library [
          <span style={{ color: "white" }}>{getTotalCountByState(state)}</span>
]
        </Button>
      </TitleWrap>
      {showSpin === true ? (
        <Spin
          size="large"
          style={{
            alignSelf: "center",
            marginLeft: "25%",
            marginTop: "25%",
          }}
        />
      ) : (
        <Content>
          <Modal
            title="Save Changes"
            visible={saveModal}
            onOk={saveList}
            onCancel={saveCancel}
          >
            Are you sure you want to save these changes?
          </Modal>
          <HeaderWrap>
            <DropdownDiv>
              <Subtitle>State</Subtitle>
              <Select
                defaultValue="California"
                style={{
                  width: 367,
                  marginLeft: 20,
                  marginTop: 0,
                }}
                disabled={isEdit}
                onChange={(value) => {
                  setState(value)
                }}
                options={[
                  {
                    value: "california",
                    label: "California",
                  },
                  {
                    value: "washington",
                    label: "Washington",
                  },
                  {
                    value: "georgia",
                    label: "Georgia",
                  },
                  {
                    value: "texas",
                    label: "Texas",
                  },
                  {
                    value: "florida",
                    label: "Florida",
                  },
                  {
                    value: "colorado",
                    label: "Colorado",
                  },
                  {
                    value: "oregon",
                    label: "Oregon",
                  },
                  {
                    value: "arizona",
                    label: "Arizona",
                  },
                ]}
              />
            </DropdownDiv>
            {isEdit === false ? (
              <Button
                style={{
                  width: 170,
                  background: "#100063",
                  color: "white",
                }}
                onClick={() => {
                  setIsEdit(true)
                }}
              >
                Edit
              </Button>

            ) : (
              <Button
                style={{
                  width: 170,
                  background: "#24AE60",
                  color: "white",
                }}
                disabled={templateInvalid}
                onClick={() => {
                  setSaveModal(true)
                }}
              >
                Save
              </Button>
            )}
          </HeaderWrap>
          <HR />
          <Body>
            <TitleDiv>
              <Title body>Type of listing</Title>
              <Select
                defaultValue="Standard Residential"
                style={{
                  width: 367,
                  marginLeft: 20,
                  marginTop: 0,
                }}
                onChange={(value) => {
                  setValueListingType(value)
                }}
                options={transactionType()}
              />
            </TitleDiv>
            {isFetching === true ? (
              <Loader />
            ) : (
              <>
                {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                  <>
                    {x.entity_type.includes("transaction_type") && x.entity_name === valueListingType ? (
                      <>
                        {x.state === state ? (
                          <TitleAndTemplate
                            index={`old-${x.id}`}
                            deleteTemplates={deleteTemplates}
                            setDeleteTemplates={setDeleteTemplates}
                            templateData={templateData}
                            localTemplateData={localTemplateData}
                            setLocalTemplateData={setLocalTemplateData}
                            isShifted={false}
                            state={state}
                            templateList={templateList}
                            setTemplateList={setTemplateList}
                            entityName={valueListingType}
                            entityType="transaction_type"
                            isEdit={isEdit}
                            addArray={addOGDoc}
                            setAddArray={setAddOGDoc}
                            valueName={x.name}
                            setTemplateInvalid={setTemplateInvalid}
                            valueTemplate={x.template_id}
                          />
                        ) : null}
                      </>
                    ) : null}
                  </>
                )) : (
                  <Text>No documents added for this category yet.</Text>
                )}
              </>
            )}
            {addNewOG === true ? (
              <>
                {addOGDoc.length && addOGDoc.map((x, i) => (
                  <>
                    {i > 0 && (
                      <TitleAndTemplate
                        addArray={addOGDoc}
                        setAddArray={setAddOGDoc}
                        index={`new-og-${i}`}
                        templateData={templateData}
                        isShifted={false}
                        state={state}
                        templateList={templateList}
                        setTemplateList={setTemplateList}
                        setTemplateInvalid={setTemplateInvalid}
                        entityName={valueListingType}
                        entityType="transaction_type"
                        isEdit={isEdit}
                      />
                    )}
                  </>
                ))}
              </>
            ) : null}
            {isEdit === true ? (
              <AddNewDoc
                onClick={() => {
                  setAddNewOG(true)
                  addOGDocFunc()
                  templateList.push({
                    idToAdd: `new-og-${addOGDoc.length}`,
                    name: "",
                    templateId: "",
                    entityName: valueListingType,
                    entityType: "transaction_type",
                    state,
                  })
                }}
              >
                <Image src={PlusSign} />
                <AddNew>Add New Document </AddNew>
              </AddNewDoc>
            ) : null}
            {
              state !== "arizona" && (
                <>
                  <AdditionDocuments>
                    {isEdit === false ? (
                      <Title body>Additional Documents</Title>
                    ) : (
                      <Divider>
                        <SmallHR />
                        <SmallText>Additional Documents</SmallText>
                        <SmallHR />
                      </Divider>
                    )
                    }
                    <MLSDiv>
                      <Subtitle body>MLS on Market</Subtitle>
                      <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        defaultChecked={false}
                        disabled
                      />
                      {/* <Text>(Yes)</Text> */}
                    </MLSDiv>
                    <Text>
                      If there is more than a three days difference between the Listing Date and MLS
                      Date OR Seller elects for property to not be marked to the public at all:
                    </Text>
                    <Text>
                      If Yes: (SELM)
                      Seller Instruction to Exclude Listing From MLS
                    </Text>
                    {isFetching === true ? (
                      <Loader />
                    ) : (
                <>
                  {!isEmpty(localTemplateData) && localTemplateData ? localTemplateData.map(x => (
                    <>
                      {x.entity_type.includes("mls_setup") ? (
                        <>
                          {x.state === state ? (
                            <TitleAndTemplate
                              index={`old-${x.id}`}
                              deleteTemplates={deleteTemplates}
                              setDeleteTemplates={setDeleteTemplates}
                              templateData={templateData}
                              localTemplateData={localTemplateData}
                              setLocalTemplateData={setLocalTemplateData}
                              addArray={addMLSDoc}
                              setAddArray={setAddMLSDoc}
                              isShifted={false}
                              state={state}
                              templateList={templateList}
                              setTemplateList={setTemplateList}
                              setTemplateInvalid={setTemplateInvalid}
                              entityName={["mls_date_listing_date_diff"]}
                              entityType="mls_setup"
                              isEdit={isEdit}
                              valueName={x.name}
                              valueTemplate={x.template_id}
                            />
                          ) : null}
                        </>
                      ) : null}
                    </>
                  )) : (
                    <Text>No documents added for this category yet.</Text>
                  )}
                </>
                    )}
                    {addNewMLS === true ? (
                <>
                  {addMLSDoc.length && addMLSDoc.map((x, i) => (
                    <>
                      {i > 0 && (
                        <TitleAndTemplate
                          addArray={addMLSDoc}
                          setAddArray={setAddMLSDoc}
                          index={`new-mls-${i}`}
                          templateData={templateData}
                          isShifted={false}
                          state={state}
                          templateList={templateList}
                          setTemplateList={setTemplateList}
                          setTemplateInvalid={setTemplateInvalid}
                          entityName={["mls_date_listing_date_diff"]}
                          entityType="mls_setup"
                          isEdit={isEdit}
                        />
                      )}
                    </>
                  ))}
                </>
                    ) : null}
                    {isEdit === true ? (
                      <AddNewDoc
                        onClick={() => {
                          setAddNewMLS(true)
                          addMLSDocFunc()
                          templateList.push({
                            idToAdd: `new-mls-${addMLSDoc.length}`,
                            name: "",
                            templateId: "",
                            entityName: "mls_date_listing_date_diff",
                            entityType: "mls_setup",
                            state,
                          })
                        }}
                      >
                        <Image src={PlusSign} />
                        <AddNew>Add New Document </AddNew>
                      </AddNewDoc>
                    ) : null}
                    <HR />
                  </AdditionDocuments>
                </>
              )
            }
            {
              state !== "arizona" && (
                <>
                  <CoListingDiv>
                    <DropdownDiv>
                      <Subtitle>Co listing Agent</Subtitle>
                      <Select
                        defaultValue="From Radius"
                        style={{
                          width: 367,
                          marginLeft: 20,
                          marginTop: 0,
                        }}
                        onChange={(value) => {
                          setValueCoList(value)
                        }}
                        options={[
                          {
                            value: "radius",
                            label: "From Radius",
                          },
                          {
                            value: "non_radius",
                            label: "Not From Radius",
                          },
                        ]}
                      />
                    </DropdownDiv>
                    {isFetching === true ? (
                      <Loader />
                    ) : (
                <>
                  {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                    <>
                      {x.entity_type.includes("co_listing_agent") && x.entity_name === valueCoList ? (
                        <>
                          {x.state === state ? (
                            <TitleAndTemplate
                              index={`old-${x.id}`}
                              deleteTemplates={deleteTemplates}
                              setDeleteTemplates={setDeleteTemplates}
                              templateData={templateData}
                              localTemplateData={localTemplateData}
                              setLocalTemplateData={setLocalTemplateData}
                              addArray={addCoListDoc}
                              setAddArray={setAddCoListDoc}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName={valueCoList}
                              entityType="co_listing_agent"
                              toAdd={addNewCoList}
                              isEdit={isEdit}
                              valueName={x.name}
                              valueTemplate={x.template_id}
                            />
                          ) : null}
                        </>
                      ) : null}
                    </>
                  )) : (
                    <Text>No documents added for this category yet.</Text>
                  )}
                </>
                    )}
                    {addNewCoList === true ? (
                <>
                  {addCoListDoc.length && addCoListDoc.map((x, i) => (
                    <>
                      {i > 0 && (
                        <TitleAndTemplate
                          addArray={addCoListDoc}
                          setAddArray={setAddCoListDoc}
                          index={`new-colist-${i}`}
                          templateData={templateData}
                          isShifted
                          state={state}
                          templateList={templateList}
                          setTemplateInvalid={setTemplateInvalid}
                          setTemplateList={setTemplateList}
                          entityName={valueCoList}
                          entityType="co_listing_agent"
                          isEdit={isEdit}
                        />
                      )}
                    </>
                  ))}
                </>
                    ) : null}
                    {isEdit === true ? (
                      <AddNewDoc
                        onClick={() => {
                          setAddNewColist(true)
                          addCoListDocFunc()
                          templateList.push({
                            idToAdd: `new-colist-${addCoListDoc.length}`,
                            name: "",
                            templateId: "",
                            entityName: valueCoList,
                            entityType: "co_listing_agent",
                            state,
                          })
                        }}
                      >
                        <Image src={PlusSign} />
                        <AddNew>Add New Document </AddNew>
                      </AddNewDoc>
                    ) : null}
                    <HR colist />
                  </CoListingDiv>
                </>
              )
            }
            {
              state !== "arizona" && (
                <>
                  <OwnershipDiv>
                    <DropdownDiv>
                      <Subtitle>Ownership</Subtitle>
                      <Select
                        defaultValue="Trust"
                        style={{
                          width: 367,
                          marginLeft: 20,
                          marginTop: 0,
                        }}
                        onChange={(value) => {
                          setValueOwner(value)
                        }}
                        options={[
                          {
                            value: "trust",
                            label: "Trust",
                          },
                          {
                            value: "successor_trust",
                            label: "Successor Trust",
                          },
                          {
                            value: "entity",
                            label: "Entity",
                          },
                          {
                            value: "probate",
                            label: "Probate",
                          },
                          {
                            value: "power_of_attorney",
                            label: "Power Of Attorney",
                          },
                          {
                            value: "estate",
                            label: "Estate",
                          },
                        ]}
                      />
                    </DropdownDiv>
                    {isFetching === true ? (
                      <Loader />
                    ) : (
                <>
                  {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                    <>
                      {x.entity_type.includes("ownership") && x.entity_name === valueOwner ? (
                        <>
                          {x.state === state ? (
                            <TitleAndTemplate
                              index={`old-${x.id}`}
                              deleteTemplates={deleteTemplates}
                              setDeleteTemplates={setDeleteTemplates}
                              templateData={templateData}
                              localTemplateData={localTemplateData}
                              setLocalTemplateData={setLocalTemplateData}
                              addArray={addOwnerDoc}
                              setAddArray={setAddOwnerDoc}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName={valueOwner}
                              entityType="ownership"
                              isEdit={isEdit}
                              valueName={x.name}
                              valueTemplate={x.template_id}
                            />
                          ) : null}
                        </>
                      ) : null}
                    </>
                  )) : (
                    <Text>No documents added for this category yet.</Text>
                  )}
                </>
                    )}
                    {addNewOwner === true ? (
                <>
                  {addOwnerDoc.length && addOwnerDoc.map((x, i) => (
                    <>
                      {i > 0 && (
                        <TitleAndTemplate
                          addArray={addOwnerDoc}
                          setAddArray={setAddOwnerDoc}
                          index={`new-owner-${i}`}
                          templateData={templateData}
                          isShifted
                          state={state}
                          templateList={templateList}
                          setTemplateInvalid={setTemplateInvalid}
                          setTemplateList={setTemplateList}
                          entityName={valueOwner}
                          entityType="ownership"
                          isEdit={isEdit}
                        />
                      )}
                    </>
                  ))}
                </>
                    ) : null}
                    {isEdit === true ? (
                      <AddNewDoc
                        onClick={() => {
                          setAddNewOwner(true)
                          addOwnerDocFunc()
                          templateList.push({
                            idToAdd: `new-owner-${addOwnerDoc.length}`,
                            name: "",
                            templateId: "",
                            entityName: valueOwner,
                            entityType: "ownership",
                            state,
                          })
                        }}
                      >
                        <Image src={PlusSign} />
                        <AddNew>Add New Document </AddNew>
                      </AddNewDoc>
                    ) : null}
                    <HR colist />
                  </OwnershipDiv>
                </>
              )
            }
            {
              state !== "arizona" && (
                <>
                  <KeysafeDiv>
                    <MLSDiv>
                      <Subtitle>Keysafe / Lockbox</Subtitle>
                      <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        defaultChecked
                        disabled
                        style={{
                          marginTop: 7,
                        }}
                      />
                    </MLSDiv>
                    <Text colist>
                Keysafe/Lockbox to be placed on the property.
                    </Text>
                    {isFetching === true ? (
                      <Loader />
                    ) : (
                <>
                  {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                    <>
                      {x.entity_type.includes("other") && x.entity_name === "keybox_placement" ? (
                        <>
                          {x.state === state ? (
                            <TitleAndTemplate
                              index={`old-${x.id}`}
                              deleteTemplates={deleteTemplates}
                              setDeleteTemplates={setDeleteTemplates}
                              templateData={templateData}
                              localTemplateData={localTemplateData}
                              setLocalTemplateData={setLocalTemplateData}
                              addArray={addKeyDoc}
                              setAddArray={setAddKeyDoc}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName="keybox_placement"
                              entityType="other"
                              isEdit={isEdit}
                              valueName={x.name}
                              valueTemplate={x.template_id}
                            />
                          ) : null}
                        </>
                      ) : null}
                    </>
                  )) : (
                    <Text>No documents added for this category yet.</Text>
                  )}
                </>
                    )}
                    {addNewKey === true ? (
                <>
                  {addKeyDoc.length && addKeyDoc.map((x, i) => (
                    <>
                      {i > 0 && (
                        <TitleAndTemplate
                          addArray={addKeyDoc}
                          setAddArray={setAddKeyDoc}
                          index={`new-key-${i}`}
                          templateData={templateData}
                          isShifted
                          state={state}
                          templateList={templateList}
                          setTemplateInvalid={setTemplateInvalid}
                          setTemplateList={setTemplateList}
                          entityName="keybox_placement"
                          entityType="other"
                          toAdd={addNewKey}
                          isEdit={isEdit}
                        />
                      )}
                    </>
                  ))}
                </>
                    ) : null}
                    {isEdit === true ? (
                      <AddNewDoc
                        onClick={() => {
                          setAddNewKey(true)
                          addKeyDocFunc()
                          templateList.push({
                            idToAdd: `new-key-${addKeyDoc.length}`,
                            name: "",
                            templateId: "",
                            entityName: "keybox_placement",
                            entityType: "other",
                            state,
                          })
                        }}
                      >
                        <Image src={PlusSign} />
                        <AddNew>Add New Document </AddNew>
                      </AddNewDoc>
                    ) : null}
                    <HR colist />
                  </KeysafeDiv>
                </>
              )
            }
            {
              state !== "arizona" && (
                <>
                  <KeysafeDiv>
                    <MLSDiv contingency>
                      <Subtitle>Does Seller Intend to include a contingency to purchase a replacement property?</Subtitle>
                      <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        defaultChecked
                        disabled
                        style={{
                          marginTop: 7,
                        }}
                      />
                    </MLSDiv>
                    {isFetching === true ? (
                      <Loader />
                    ) : (
                <>
                  {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                    <>
                      {x.entity_type.includes("other") && x.entity_name === "replacement_property_purchase" ? (
                        <>
                          {x.state === state ? (
                            <TitleAndTemplate
                              index={`old-${x.id}`}
                              deleteTemplates={deleteTemplates}
                              setDeleteTemplates={setDeleteTemplates}
                              templateData={templateData}
                              localTemplateData={localTemplateData}
                              setLocalTemplateData={setLocalTemplateData}
                              addArray={addOtherDoc}
                              setAddArray={setAddOtherDoc}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName="replacement_property_purchase"
                              entityType="other"
                              isEdit={isEdit}
                              valueName={x.name}
                              valueTemplate={x.template_id}
                            />
                          ) : null}
                        </>
                      ) : null}
                    </>
                  )) : (
                    <Text>No documents added for this category yet.</Text>
                  )}
                </>
                    )}
                    {addNewOther === true ? (
                <>
                  {addOtherDoc.length && addOtherDoc.map((x, i) => (
                    <>
                      {i > 0 && (
                        <TitleAndTemplate
                          addArray={addOtherDoc}
                          setAddArray={setAddOtherDoc}
                          index={`new-replacement-${i}`}
                          templateData={templateData}
                          isShifted
                          state={state}
                          templateList={templateList}
                          setTemplateInvalid={setTemplateInvalid}
                          setTemplateList={setTemplateList}
                          entityName="replacement_property_purchase"
                          entityType="other"
                          isEdit={isEdit}
                        />
                      )}
                    </>
                  ))}
                </>
                    ) : null}
                    {isEdit === true ? (
                      <AddNewDoc
                        onClick={() => {
                          setAddNewOther(true)
                          addOtherDocFunc()
                          templateList.push({
                            idToAdd: `new-replacement-${addOtherDoc.length}`,
                            name: "",
                            templateId: "",
                            entityName: "replacement_property_purchase",
                            entityType: "other",
                            state,
                          })
                        }}
                      >
                        <Image src={PlusSign} />
                        <AddNew>Add New Document </AddNew>
                      </AddNewDoc>
                    ) : null}
                    <HR last />
                  </KeysafeDiv>
                </>
              )
            }

          </Body>
        </Content>
      )}
    </Wrap>
  )
}

export default container(ViewPage)
