
import React, { useEffect } from "react";
import styled from "styled-components";
import SubContainer from "container/BrokerDashboard/subscriptionContainer";
import sidebarContainer from "container/BrokerDashboard/sidebarContainer"
import AgentContainer from "container/Agent";
import { ShimmerBox } from "shared/styles/animation";
import { withRouter } from "react-router-dom";
import { get } from "lodash";
import { Select } from "antd";
import { Container, Title, TitleWrap, ShimmerWrap } from "../../common";
import { showConfirm } from "../../../ICA/components/ConfirmationModalUtil";

const Wrap = styled.div``;

const MonthlySupportFee = ({
  subscriptions,
  selectedPlanList,
  getPlanbenefits,
  selectPlan,
  selectedPlan,
  plans,
  currentPlan,
  selectedAddOns,
  selectAddon,
  couponCodeResponse,
  removeCouponCode,
  durationValue,
  setSupportFee,
  fetchSubStatusResponse,
  ...props
}) => {
  const currentMonthlySupportFee = get(currentPlan, "currentPlan");

  useEffect(() => {
    if (currentMonthlySupportFee) {
      selectPlan({
        planId: currentMonthlySupportFee.id,
        plan: currentMonthlySupportFee,
      });
    }
  }, [currentMonthlySupportFee, plans]);

  console.log("all plans", plans)

  const agentId = get(props, "match.params.agentId", "");
  const { isFetching } = plans || {};
  const { data: couponData } = couponCodeResponse || {};
  const {
    isFetching: isFetchingSubsStatus,
    data: subsStatusData,
  } = fetchSubStatusResponse || {}


  // const getPlanListPrice = (planList) =>
  //   planList.billing_cycle === "YEARLY"
  //     ? `$${planList.unit_price}/yearly`
  //     : `$${planList.unit_price}/monthly`;
  
  const getPlanListPrice = (planList) => {
    if (durationValue === "yearly") {
      return planList.billing_cycle === "YEARLY" ? `$${planList.unit_price}/yearly` : "";
    } else {
      return planList.billing_cycle != "YEARLY" ? `$${planList.unit_price}/monthly` : "";
    }
  };

  const handleChange = (value) => {
    const selectedPlan = selectedPlanList.find((plan) => plan.plan_id === value);
    if (!selectedPlan) return;

    const message = "A coupon is applied, do you wish to change?";
    setSupportFee(selectedPlan?.unit_price)
    const postAction = (deleteCoupon) => {
      if (deleteCoupon) {
        removeCouponCode();
      }
      getPlanbenefits({ planId: selectedPlan.plan_id, agentId });
      selectPlan({ planId: selectedPlan.plan_id, plan: selectedPlan });
      selectAddon("removeKVCore");
    };

    if (couponData && couponData.trialInfo) {
      showConfirm("Do you wish to change?", () => postAction(true), message);
    } else {
      postAction();
    }
  };

  const selectedPlanValue = (() => {
    // Check if any subscription contains a specific plan
    const hasZeroDollarPlan = subscriptions?.data?.subscriptions?.some(subscription =>
      subscription.items.data.some(item =>
        ["broker_test_rar_zero_dollar_qa", "broker_zero_monthly_0"].includes(item.plan.id)
      )
    );
  
    if (hasZeroDollarPlan) return "$0/month";
  
    // If plan is null, check items.data for a matching plan.id in selectedPlanList
    for (const subscription of subscriptions?.data?.subscriptions || []) {
      if (!subscription.plan) {
        for (const item of subscription.items.data || []) {
          if (selectedPlanList.some(plan => plan.plan_id === item.plan.id)) {
            return item.plan.id; // Return the matching plan ID
          }
        }
      }
    }
  
    // return selectedPlan?.planId || undefined;

    const planId = selectedPlan?.planId;
    const isPlanInList = selectedPlanList.some(plan => plan.plan_id === planId);
    console.log("check plan", planId, isPlanInList)

    if (!isPlanInList && planId) {
      // const priceMatch = planId.match(/\d+/); // Extracts numeric part (e.g., "100" from "broker_base_monthly_100")
      // if (priceMatch) {
      //   console.log("price match", `$${priceMatch[0]}`)
      //   return `$${priceMatch[0]}`;
      // }

      if(plans && plans?.data && plans?.data?.single_plans) {
        const allPlans = [...(plans?.data?.single_plans?.rn || []), ...(plans?.data?.single_plans?.broker || [])];

        const matchedPlan = allPlans.find(plan => plan.plan_id === planId);

        if (matchedPlan) {
          return `$${matchedPlan.unit_price}/${matchedPlan.billing_cycle.toLowerCase()}`
        }
      }      
    } else if (subsStatusData?.response?.status === "incomplete_expired") {
      const expired_planId = subsStatusData?.response?.items?.data[0]?.plan?.id

      if(plans && plans?.data && plans?.data?.single_plans) {
        const allPlans = [...(plans?.data?.single_plans?.rn || []), ...(plans?.data?.single_plans?.broker || [])];

        const matchedPlan = allPlans.find(plan => plan.plan_id === expired_planId);

        if (matchedPlan) {
          return `$${matchedPlan.unit_price}/${matchedPlan.billing_cycle.toLowerCase()}`
        }
      }
    }

    return selectedPlan?.planId;
  })();


  return (
    <Wrap>
      <Container>
        <TitleWrap>
          <Title>Software fee</Title>
        </TitleWrap>
        {isFetching ? (
          <ShimmerWrap>
            {/* {[...Array(4)].map((_, index) => (
              <ShimmerBox key={index} w="223px" h="102px" />
            ))} */}
            <ShimmerBox w="1000px" h="50px" />
          </ShimmerWrap>
        ) : (
          <Select
            placeholder="Select software fee"
            onChange={handleChange}
            // value={selectedPlan?.planId || selectedPlanValue}
            value={selectedPlanList.some(plan => plan.plan_id === selectedPlan?.planId) 
              ? selectedPlan?.planId 
              : selectedPlanValue}
            // value={selectedPlan?.planId || undefined}
          >
            {/* Network Users Group */}
            <Select.OptGroup label="Software users">
              {selectedPlanList
                .filter(
                  (planList) =>
                    planList.plan_id.includes("rn_") &&
                    ((durationValue === "yearly" && planList.billing_cycle === "YEARLY") ||
                      (durationValue !== "yearly" && planList.billing_cycle === "MONTHLY"))
                )
                .map((planList) => (
                  <Select.Option key={planList.plan_id} value={planList.plan_id}>
                    {getPlanListPrice(planList)}
                  </Select.Option>
                ))}
            </Select.OptGroup>

            {/* Software Agents Group */}
            <Select.OptGroup label="Brokerage agents">
              {selectedPlanList
                .filter(
                  (planList) =>
                    planList.plan_id.includes("broker_")  &&
                    ((durationValue === "yearly" && planList.billing_cycle === "YEARLY") ||
                      (durationValue !== "yearly" && planList.billing_cycle === "MONTHLY"))
                )
                .map((planList) => (
                  <Select.Option key={planList.plan_id} value={planList.plan_id}>
                    {getPlanListPrice(planList)}
                  </Select.Option>
                ))}
            </Select.OptGroup>
          </Select>
        )}
      </Container>
    </Wrap>
  );
};

export default sidebarContainer(withRouter(AgentContainer(SubContainer(MonthlySupportFee))));
