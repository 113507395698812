import React, { useEffect, useState } from "react";
import {
  Dropdown,
  Space,
  Menu,
  Table,
  Select,
  Input,
  Popconfirm,
  Modal,
  Form,
  message,
} from "antd";
import { actionMenu } from "./utils";
import { MoreOutlined, EditOutlined } from "@ant-design/icons";
import {
  isEditing,
  createPlaceholder,
  isAnyItemEditing,
  TitleColumn,
  DocumentTitleIDColumn,
  DocumentTitleColumn,
  StatusColumn,
} from "./helper";
import CheckList from "container/CheckList/index";
import { RemoveModal } from "./RemoveModal";
import AddCriteriaModal from "./AddCriteriaModal";
import AddDocumentsToSection from "./AddDocumentsToSection";
const { Option } = Select;

const TableEditForm = ({
  fetchAllTemplatesResponse,
  selectedSection,
  deleteTemplate,
  deleteTemplateResponse,
  fetchAllChecklistDataResponse,
  saveNewItemToDocument,
  fetchAllTemplates,
  deleteDocument,
  deleteDocumentResponse,
  saveNewItemDocumentResponse,
  dropdownDataArray,
  addNewItemClicked,
  setHandleAddNewItem,
  addNewChecklistTemplateResponse,
  fetchDocumentCriteria,
  fetchBrbcDocuments,
  addBrbcDocument,
  fetchBrbcDocResponse,
  addBrbcDocResponse,
}) => {
  const [isEditFLow, setIsEditFlow] = useState(false);
  const [isAddingTemplate, setIsAddingTemplate] = useState(false);
  const [editingKeyMap, setEditingKeyMap] = useState({});
  const [dataSourceList, setDataSourceList] = useState([]);
  const [form] = Form.useForm();
  const [isBRBCModalVisible, setIsBRBCModalVisible] = useState(false);
  const [BrbcDocId, setBrbcDocId] = useState();
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [removingItem, setRemovingItem] = useState(null);
  const [isAddCriteriaModalOpen, setIsAddCriteriaModalOpen] = useState(false);
  const [documentItemAddCriteria, setDocumentItemAddCriteria] = useState(null);
  const [
    isAddingTemplateToExistingDoc,
    setIsAddingTemplateToExistingDoc,
  ] = useState(false);
  useEffect(() => {
    initializeDataSourceMap();
    if (
      addNewItemClicked?.checklist_value === selectedSection.checklist_value
    ) {
      handleAddNewDocument();
    }
  }, [
    fetchAllTemplatesResponse,
    addNewItemClicked,
    addNewChecklistTemplateResponse,
  ]);

  const initializeDataSourceMap = () => {
    if (fetchAllTemplatesResponse?.data?.length > 0) {
      const allChecklistItems = {};
      fetchAllTemplatesResponse.data.forEach((item) => {
        const transformedData = item.document_items.flatMap((documentItem) => {
          const baseObj = {
            key: `${item.checklist}-${documentItem?.document_item_id}`,
            checklist: item.checklist,
            due_date: item.due_date,
            document_item: documentItem.document_item,
            document_item_id: documentItem.document_item_id,
            mandatory: documentItem.mandatory ? "Mandatory" : "If Applicable",
            redirect: documentItem.redirect ? documentItem.redirect : false,
            // isBrbc: documentItem.templates[0]?.isBRBC,
          };

          if (documentItem.templates.length === 0) {
            return [baseObj];
          } else {
            return documentItem.templates.map((template, templateIndex) => ({
              ...baseObj,
              key: `${item.checklist}-${documentItem?.document_item_id}-${template?.id}`,
              template_id: template.id,
              template_name: template.name,
              template_templateId: template.templateId,
              checklist: item.checklist,
              isBrbc: template?.isBRBC,
            }));
          }
        });
        allChecklistItems[item.checklist] = transformedData;
      });
      setDataSourceList(allChecklistItems);
    } else {
      setDataSourceList([]);
    }
  };

  const cancelRemovingModal = () => {
    Modal.destroyAll();
    setIsRemoveModalOpen((prevState) => !prevState);
    setRemovingItem(null);
  };

  const cancelAddCriteriaModalOpen = () => {
    Modal.destroyAll();
    setIsAddCriteriaModalOpen((prevState) => !prevState);
  };

  const handleRemoveModalOpen = (record) => {
    setIsRemoveModalOpen(true);
    setRemovingItem(record);
  };

  const renderActionMenu = (record) => {
    if (record.mandatory === "Mandatory") {
      return actionMenu.filter((item) => item.label !== "Add Criteria");
    } else {
      return actionMenu;
    }
  };

  useEffect(() => {
    if(addBrbcDocResponse?.data === true) {
      setIsBRBCModalVisible(false);
    }
  }, [addBrbcDocResponse])

  const columns = [
    TitleColumn,
    DocumentTitleColumn,
    DocumentTitleIDColumn,
    StatusColumn,
    {
      title: (
        <>
          <div className="">
            <EditOutlined /> &nbsp; Action
          </div>
        </>
      ),
      key: "operation",
      width: "200px",
      editable: false,

      render: (_, record) => {
        const editable = isEditing(record, editingKeyMap);
        if (record.redirect) {
          return (
            <a
              href="/broker/listing-documents"
              target="_blank"
              rel="noopener noreferrer"
            >
              Edit
            </a>
          );
        } else {
          return editable ? (
            <>
              <a
                onClick={() => handleSave(record)}
                style={{ color: "#5A5FF2", marginRight: "10px" }}
              >
                Save
              </a>
              <Popconfirm
                title="Sure to discard?"
                onConfirm={() =>
                  isEditFLow ? handleCancel() : handleDiscard(record)
                }
              >
                <a style={{ color: "#EE4946" }}>Discard</a>
              </Popconfirm>
            </>
          ) : (
            <Space>
              {editingKeyMap[record.checklist] !== record.key ? (
                <Dropdown
                  overlay={
                    <Menu
                      style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)" }}
                    >
                      {renderActionMenu(record).map((item) =>
                        item.label === "Remove" ? (
                          <Menu.Item
                            className="menu-items"
                            key={item.key}
                            style={{
                              borderBottom: item.borderBottom
                                ? "1px solid #f0f0f0"
                                : "none",
                            }}
                          >
                            <a
                              onClick={() => {
                                handleRemoveModalOpen(record);
                              }}
                            >
                              {item.icon} &nbsp;
                              <span>{item.label}</span>
                            </a>
                          </Menu.Item>
                        ) : (
                          <Menu.Item
                            className="menu-items"
                            key={item.key}
                            style={{
                              borderBottom: item.borderBottom
                                ? "1px solid #f0f0f0"
                                : "none",
                            }}
                            onClick={() => handleMenuItemClick(item, record)}
                          >
                            <Space>
                              {item.icon}
                              <span>{item.label}</span>
                            </Space>
                          </Menu.Item>
                        )
                      )}
                      {/* {actionMenu.map((item) =>
                        item.label === "Remove" ? (
                          <Menu.Item
                            className="menu-items"
                            key={item.key}
                            style={{
                              borderBottom: item.borderBottom
                                ? "1px solid #f0f0f0"
                                : "none",
                            }}
                          >
                            <a
                              onClick={() => {
                                handleRemoveModalOpen(record);
                              }}
                            >
                              {item.icon} &nbsp;
                              <span>{item.label}</span>
                            </a>
                          </Menu.Item>
                        ) : (
                          
                          <Menu.Item
                            className="menu-items"
                            key={item.key}
                            style={{
                              borderBottom: item.borderBottom
                                ? "1px solid #f0f0f0"
                                : "none",
                            }}
                            onClick={() => handleMenuItemClick(item, record)}
                          >
                            <Space>
                              {item.icon}
                              <span>{item.label}</span>
                            </Space>
                          </Menu.Item>
                        )
                      )} */}
                    </Menu>
                  }
                  trigger={["click"]}
                  placement="bottomCenter"
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <MoreOutlined className="action-btn" />
                  </a>
                </Dropdown>
              ) : null}
            </Space>
          );
        }
      },
    },
  ];

  const editableColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "mandatory" ? "dropdown" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record, editingKeyMap),
      }),
    };
  });

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode =
      inputType === "dropdown" ? (
        <Select
          disabled={(dataIndex === "mandatory" && isAddingTemplate) || !editing}
          placeholder="Select Status"
          style={{ width: "100%" }}
        >
          <Option value="Mandatory">Mandatory</Option>
          <Option value="If Applicable">If Applicable</Option>
        </Select>
      ) : (
        <Input
          placeholder={createPlaceholder(dataIndex)}
          disabled={dataIndex === "document_item" && isAddingTemplate}
        />
      );

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required:
                  !isAddingTemplate &&
                  !isEditFLow &&
                  (dataIndex === "template_name" ||
                    dataIndex === "template_templateId")
                    ? false
                    : true,
                message: `Please ${createPlaceholder(dataIndex)}`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const handleMenuItemClick = (item, record) => {
    if (isAnyItemEditing(editingKeyMap)) {
      message.warning("Please complete the current editing action.");
      return;
    }
    if (
      !dataSourceList[record.checklist].some(
        (data) => data.document_item === ""
      ) ||
      !dataSourceList[record.checklist].some(
        (data) => data.template_name === ""
      ) ||
      !dataSourceList[record.checklist].some(
        (data) => data.template_templateId === ""
      ) ||
      !dataSourceList[record.checklist].some((data) => data.mandatory === "")
    ) {
      switch (item.label) {
        case "Edit":
          handleEdit(record);
          break;
        case "Add a New Document":
          handleAddNewTemplate(record);
          break;
        case "Mark as BRBC":
          handleAddBrbc(record);
          break;
        case "Add Criteria":
          handleAddCriteria(record);
          break;
        case "Remove":
          handleRemove(record);
          break;
        default:
          break;
      }
    } else {
      message.error("Please fill in all fields before adding a new item");
    }
  };

  const handleAddCriteria = (record) => {
    setIsAddCriteriaModalOpen(true);
    setDocumentItemAddCriteria(record);
  };

  const handleRemove = async () => {
    const record = removingItem;
    if (!record) return;
    const templatesPayload = {
      state: dropdownDataArray.state,
      type: dropdownDataArray.type,
      checklistTemplate:
        dropdownDataArray.checklistTemplate.checklist_template_value,
    };
    if (record.template_id) {
      const payload = record.template_id;
      await deleteTemplate({
        payload,
        callback: (e) => {
          fetchAllTemplates(templatesPayload);
        },
      });
    } else if (record.document_item_id) {
      const payload = record.document_item_id;
      await deleteDocument({
        payload,
        callback: (e) => {
          fetchAllTemplates(templatesPayload);
        },
      });
    }
    cancelRemovingModal();
  };

  const handleSave = async (record) => {
    const values = await form.validateFields();

    const index = dataSourceList[record?.checklist]?.findIndex(
      (item) => item.key === editingKeyMap[record?.checklist]
    );

    values.key = `${dataSourceList[record.checklist]?.length}-${
      values.document_item
    }`;
    let checklistValue = fetchAllChecklistDataResponse?.data?.find(
      (value) => value.checklist_value === record.checklist
    );
    let payload;
    if (!isEditFLow) {
      payload = [
        {
          checklistTemplateId: dropdownDataArray.checklistTemplate.id,
          checklistId: checklistValue.id,
          name: values.template_name,
          mandatory: values.mandatory === "Mandatory" ? 1 : 0,
          documentItem: values.document_item,
          templateId: values.template_templateId,
        },
      ];
    } else if (isEditFLow && isAddingTemplateToExistingDoc) {
      payload = [
        {
          checklistTemplateId: dropdownDataArray.checklistTemplate.id,
          checklistId: checklistValue.id,
          name: values.template_name,
          mandatory: values.mandatory === "Mandatory" ? 1 : 0,
          documentItem: values.document_item,
          templateId: values.template_templateId,
        },
      ];
    } else {
      payload = [
        {
          id: record.template_id,
          documentItemId: record.document_item_id,
          name: values.template_name,
          mandatory: values.mandatory === "Mandatory" ? 1 : 0,
          documentItem: values.document_item,
          templateId: values.template_templateId,
        },
      ];
    }
    const templatesPayload = {
      state: dropdownDataArray.state,
      type: dropdownDataArray.type,
      checklistTemplate:
        dropdownDataArray.checklistTemplate.checklist_template_value,
    };
    await saveNewItemToDocument({
      payload,
      callback: (e) => {
        fetchAllTemplates(templatesPayload);
        handleCancel();
        setIsAddingTemplateToExistingDoc(false);
      },
    });

    if (index > -1) {
      setEditingKeyMap({ ...editingKeyMap, [record.checklist]: "" });
    }
    setIsEditFlow(false);

    setHandleAddNewItem(false);
  };

  const handleAddNewDocument = async () => {
    setIsAddingTemplate(false);
    if (isAnyItemEditing(editingKeyMap)) {
      return;
    }
    const newKey = `${selectedSection.checklist_value}-${dataSourceList[
      selectedSection.checklist_value
    ]?.length || 0}`;
    const newData = {
      document_item: "",
      template_name: "",
      template_templateId: "",
      mandatory: "",
      key: newKey,
      checklist: selectedSection.checklist_value,
    };
    form.resetFields();

    setDataSourceList((prevDataSourceMap) => {
      const checklistData =
        prevDataSourceMap[selectedSection.checklist_value] || [];
      const updatedSelectedSectionData = [newData, ...checklistData];

      return {
        ...prevDataSourceMap,
        [selectedSection.checklist_value]: updatedSelectedSectionData,
      };
    });

    setEditingKeyMap((prevEditingKeyMap) => ({
      ...prevEditingKeyMap,
      [selectedSection.checklist_value]: newKey,
    }));
  };

  const handleAddNewTemplate = (record) => {
    setIsAddingTemplate(true);
    const index = dataSourceList[record.checklist].findIndex(
      (item) => item.key === record.key
    );
    const newData = {
      key: `${index + 1}-${record.document_item}`,
      document_item: record.document_item,
      template_name: "",
      template_templateId: "",
      mandatory: record.mandatory,
      checklist: record.checklist,
    };

    form.setFieldsValue({
      document_item: record.document_item,
      template_name: "",
      template_templateId: "",
      mandatory: record.mandatory,
      checklist: record.checklist,
    });
    const newDataSource = [...dataSourceList[record.checklist]];
    newDataSource.splice(index + 1, 0, newData);
    setDataSourceList({ ...dataSourceList, [record.checklist]: newDataSource });
    setEditingKeyMap({ ...editingKeyMap, [record.checklist]: newData.key });
  };

  const handleAddBrbc = (record) => {
    if(fetchBrbcDocResponse?.data.length > 0){
      setIsBRBCModalVisible(true);
      setBrbcDocId(record.template_id);
    }
    else {
      addBrbcDocument({
        state: dropdownDataArray.state,
        id: record.template_id,
        callback: (() => {
          fetchBrbcDocuments({state: dropdownDataArray.state, callback: (() => {
            fetchAllTemplates({
              state: dropdownDataArray.state,
              type: dropdownDataArray.type,
              checklistTemplate: dropdownDataArray.checklistTemplate.checklist_template_value,
            })
          })});
        })
      })
    }
  }

  const handleDiscard = (record) => {
    const newDataSource = dataSourceList[record.checklist].filter(
      (item) => item.key !== record.key
    );
    setDataSourceList((prevDataSourceMap) => ({
      ...prevDataSourceMap,
      [record.checklist]: newDataSource,
    }));

    if (editingKeyMap[record.checklist] === record.key) {
      setEditingKeyMap((prevEditingKeyMap) => ({
        ...prevEditingKeyMap,
        [record.checklist]: "",
      }));
      form.resetFields();
    }
    setHandleAddNewItem(false);
  };

  const handleCancel = () => {
    setEditingKeyMap("");
    form.resetFields();
    setIsEditFlow(false);
  };

  const handleEdit = (record) => {
    setIsAddingTemplate(false);
    if (!record.template_id) {
      setIsAddingTemplateToExistingDoc(true);
    }

    const updatedEditingKeyMap = {
      ...editingKeyMap,
      [record.checklist]: record.key,
    };
    setEditingKeyMap(updatedEditingKeyMap);
    form.setFieldsValue(record);
    setIsEditFlow(true);
  };

  return (
    <>
      {dataSourceList[selectedSection.checklist_value]?.length > 0 ? (
        <Form
          form={form}
          component={false}
          key={selectedSection.checklist_value}
          className="editing-row"
        >
          <Table
            loading={
              fetchAllTemplatesResponse?.isFetching ||
              saveNewItemDocumentResponse?.isFetching ||
              deleteDocumentResponse?.isFetching ||
              deleteTemplateResponse?.isFetching
            }
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            dataSource={dataSourceList[selectedSection.checklist_value]}
            columns={editableColumns}
            rowClassName="editable-row"
            pagination={false}
            style={{ border: "none" }}
          />
        </Form>
      ) : (
        <AddDocumentsToSection selectedSection={selectedSection} />
      )}

      <RemoveModal
        isRemoveModalOpen={isRemoveModalOpen}
        handleRemove={handleRemove}
        cancelRemovingModal={cancelRemovingModal}
      />
      {isAddCriteriaModalOpen && (
        <AddCriteriaModal
          documentItem={documentItemAddCriteria}
          isAddCriteriaModalOpen={isAddCriteriaModalOpen}
          cancelAddCriteriaModal={cancelAddCriteriaModalOpen}
        />
      )}
      <Modal
        visible={isBRBCModalVisible}
        title="Replace existing BRBC document?"
        okText={addBrbcDocResponse?.isFetching ? "Updating..." : "Replace Document"}
        cancelText="Keep current BRBC"
        onOk={() => {
          addBrbcDocument({
            state: dropdownDataArray.state,
            id: BrbcDocId,
            callback: (() => {
              fetchBrbcDocuments({state: dropdownDataArray.state, callback: (() => {
                fetchAllTemplates({
                  state: dropdownDataArray.state,
                  type: dropdownDataArray.type,
                  checklistTemplate: dropdownDataArray.checklistTemplate.checklist_template_value,
                })
              })});
            })
          })

        }}
        onCancel={() => setIsBRBCModalVisible(false)}  
      >
        <p className="brbc-text">
          You have already marked a document as the BRBC.
        </p>
        <p className="brbc-text">
          Marking this new document will replace the existing BRBC.
        </p>
        <p className="brbc-text">Do you want to proceed?</p>
      </Modal>
    </>
  );
};
export default CheckList(TableEditForm);
