import { isEmpty } from "lodash"
import { checkForUserObject } from "services/Utils"

export const authCheck = () => {
  const user = checkForUserObject("user")
  if (!user || isEmpty(user)) {
    return false
  }
  return true
}
