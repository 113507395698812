import React, { useState, useEffect } from "react";
import {
  EditOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { Collapse } from "antd";

import CheckList from "container/CheckList/index";

import TableEditForm from "./TableEditForm";
import DragOrderTable from "./DragOrderTable";
const { Panel } = Collapse;

const ChecklistDocumentAccordion = ({
  fetchAllTemplatesResponse,
  fetchAllChecklistDataResponse,
  saveDueDate,
  saveDueDateResponse,
  fetchAllTemplates,
  dropdownDataArray,
  saveNewItemDocumentresponse,
}) => {
  const [dueDateInputVisible, setDueDateInputVisible] = useState({});
  const [dueDateValue, setDueDateValue] = useState({});
  const [checkListData, setCheckListData] = useState([]);
  const [handleAddNewItem, setHandleAddNewItem] = useState(false);
  const [activeKey, setActiveKey] = useState([]);
  const [isDragOrderTableModalOpen, setIsDragOrderTableModalOpen] = useState(false);
  const [selectedDocumentSection, setSelectedDocumentSection] = useState({})
  useEffect(() => {
    addDueDateToChecklistData();
  }, [fetchAllTemplatesResponse]);

  const addDueDateToChecklistData = () => {
    if (fetchAllTemplatesResponse?.data?.length > 0) {
      const data = fetchAllChecklistDataResponse?.data?.map((item1) => {
        const matchingDoc = fetchAllTemplatesResponse?.data?.find(
          (item2) => item2.checklist === item1.checklist_value
        );
        if (matchingDoc) {
          return { ...item1, due_date: matchingDoc.due_date || null };
        } else {
          return item1;
        }
      });

      setCheckListData(data);
    } else {
      setCheckListData(fetchAllChecklistDataResponse?.data);
    }
  };

  const setDueDateVisibility = (item) => {
    setDueDateInputVisible((prevState) => ({
      ...prevState,
      [item.checklist_value]: !prevState[item.checklist_value],
    }));
  };

  const handleButtonClick = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  const handleAddDueDateClick = (e, item) => {
    e.stopPropagation();
    setDueDateInputVisible((prevState) => ({
      ...prevState,
      [item.checklist_value]: !prevState[item.checklist_value],
    }));
  };

  const handleDueDateChange = async (e, checklist_value) => {
    e.stopPropagation();
    setDueDateValue((prevState) => ({
      ...prevState,
      [checklist_value]: e.target.value,
    }));
  };

  const handleSaveDueDate = async (e, item) => {
    e.stopPropagation();

    setDueDateInputVisible((prevState) => ({
      ...prevState,
      [item.checklist_value]: false,
    }));

    const payload = {
      checklistTemplateId: dropdownDataArray.checklistTemplate.id,
      checklistId: item.id,
      dueDate: dueDateValue[item.checklist_value] ? dueDateValue[item.checklist_value] : null,
    };

    await saveDueDate({
      payload,
      callback: (e) => {
        fetchAllTemplates({
          state: dropdownDataArray.state,
          type: dropdownDataArray.type,
          checklistTemplate:
            dropdownDataArray.checklistTemplate.checklist_template_value,
        });
      },
    });
  };

  const handleAddNewDocument = (e, item, index) => {
    e.stopPropagation();
    const isPanelOpen = activeKey.find((e) => e === index);
    if (!isPanelOpen) {
      onChange(index);
    }
    setHandleAddNewItem(item);
  };

  const onChange = (index) => {
    setActiveKey((prevActiveKeys) => {
      if (prevActiveKeys.includes(index)) {
        return prevActiveKeys.filter((key) => key !== index);
      } else {
        return [...prevActiveKeys, index];
      }
    });
  };
  const handleRearrangeTable = (e, item, index) => {
        e.stopPropagation();

    setIsDragOrderTableModalOpen(true);
    setSelectedDocumentSection(item);
  }

  return (
    <>
      {checkListData?.length > 0 &&
        checkListData?.map((item, index) => {
          return (
            <Collapse
              key={item.checklist_value}
              activeKey={activeKey}
              onChange={() => onChange(index.toString())}
            >
              <Panel
                header={
                  <div className="accordion-header">
                    <div className="checklist-header">{item.checklist}</div>
                    <div
                      className="checklist-header-btn"
                      onClick={(e) => handleButtonClick(e, item)}
                    >
                       <button
                        className={`add-due-btn add-due-btn-color mr-2`}
                        onClick={(e) =>
                          handleRearrangeTable(e, item, index.toString())
                        }
                      >
                        Rearrange Table
                      </button>
                      {dueDateInputVisible[item.checklist_value] ||
                      item.due_date ? (
                        <div className="due-date-input-wrapper mr-2">
                          <div className="due-date-input-container">
                            <CalendarOutlined className="calendar-icon" />
                            <input
                              style={{ background: "white" }}
                              type="number"
                              placeholder="Enter"
                              className="due-input"
                              min={1}
                              disabled={
                                !dueDateInputVisible[item.checklist_value]
                              }
                              defaultValue={item.due_date || ""}
                              onKeyDown={(e) => {
                                if (
                                  e.key === "." ||
                                  e.key.toLowerCase() === "e"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) =>
                                handleDueDateChange(e, item.checklist_value)
                              }
                            />
                            <span className="input-suffix">days</span>
                          </div>
                          {dueDateInputVisible[item.checklist_value] ? (
                            <button
                                className="save-due-date-btn"
                                onClick={(e) => handleSaveDueDate(e, item)}
                            >
                              Save
                            </button>
                          ) : (
                            <button
                              className="save-due-date-btn pl-20"
                              onClick={() => setDueDateVisibility(item)}
                            >
                              <EditOutlined />
                            </button>
                          )}
                        </div>
                      ) : (
                        <button
                          className="add-due-btn add-due-btn-color mr-2"
                            onClick={(e) => handleAddDueDateClick(e, item)}
                        >
                          + Add due date
                        </button>
                      )}
                    </div>
                      <button
                        className={`add-due-btn ${
                          handleAddNewItem ? "disabled" : "add-due-btn-color"
                        }`}
                        onClick={(e) =>
                          handleAddNewDocument(e, item, index.toString())
                        }
                        disabled={handleAddNewItem}
                        style={
                          handleAddNewItem
                            ? {
                                border: "none",
                                background: "lightgray",
                                width: "140px",
                                padding: "0px",
                              }
                            : {}
                        }
                      >
                        + Add a new item
                      </button>
                  </div>
                }
                key={index}
              >
                <TableEditForm
                  addNewItemClicked={handleAddNewItem}
                  selectedSection={item}
                  setHandleAddNewItem={setHandleAddNewItem}
                />
              </Panel>
            </Collapse>
          );
        })}
      {
        isDragOrderTableModalOpen && 
          <DragOrderTable isDragOrderTableModalOpen={isDragOrderTableModalOpen} setIsDragOrderTableModalOpen={setIsDragOrderTableModalOpen}
          selectedSection={selectedDocumentSection}/>
      }
    </>
  );
};

export default CheckList(ChecklistDocumentAccordion);
