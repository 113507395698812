import React, { useState } from "react"
import styled from "styled-components"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
const toolbarOptions = [
  ["bold", "italic", "underline", "strike"],
  ["blockquote", "code-block"],

  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }],
  [{ indent: "-1" }, { indent: "+1" }],
  [{ direction: "rtl" }],

  [{ color: [] }],
  [{ align: [] }],

  ["clean"],
]

const Wrap = styled.div`
  .ql-editor {
    ${props => props.ht && !props.readOnly && `
      height: ${props.ht}
    `}
    font-weight: 200;
    p {
      font-size: 16px;
      font-weight: 100;
    }
    ul, ol {
      font-size: 16px;
      font-weight: 100;
    }
    li {
      font-size: 16px;
      line-height: 20px;
      font-weight: 100;
    }
    strong {
      font-weight: bolder;
    }
    em {
      font-style: italic;
    }
    h1 {
      font-size: 28px;
    }
    h2 {
      font-size: 22px;
    }
    h3 {
      font-size: 18px;
      font-weight: 200;
    }
    ${props => props.readOnly && `
      font-style: normal;
      font-weight: 200;
      color: #303030;
      padding: 0;
      p {
        font-size: 16px;
      }
    `}

    ${props => props.wt && `
      width: ${props.wt || "100%"};
    `}
  }

  .ql-tooltip {
    ${props => props.readOnly && `
      display: none;
    `}
  }
  .ql-container.ql-snow {
    ${props => props.readOnly && `
      border: none;
      padding: 0;
    `}
  }
  .ql-toolbar {
    ${props => props.readOnly && `
      display: none;
    `}
  }
`

const RichEditor = ({
  saveRTF,
  text = "",
  height,
  readOnly,
  wt,
  savePlainText,
  enablePlainText,
}) => {
  // const [rtfContent, setRtfContent] = useState("")
  const [editorValue, setEditorValue] = useState(text)
  const saveEditorValue = (value) => {
    setEditorValue(convertToRTF(value))
    if (saveRTF) saveRTF(convertToRTF(value))

    if (savePlainText) {
      savePlainText(value.replace(/<[^>]*>/g, ""))
    }
  }

  const convertToHTML = (data) => {
    let localData = data
    try {
      localData = decodeURIComponent(escape(atob(data)))
    } catch (e) {
      localData = data
    }
    return localData
  }

  const convertToRTF = data => btoa(unescape(encodeURIComponent(data)))

  const editorValueForQuill = convertToHTML(editorValue)

  return (
    <Wrap ht={height} wt={wt} readOnly={readOnly}>
      <ReactQuill readOnly={readOnly} theme="snow" modules={{ toolbar: enablePlainText ? false : toolbarOptions }} value={editorValueForQuill} onChange={saveEditorValue} />
    </Wrap>
  )
}

export default RichEditor
