import styled from "styled-components"
// import colors from "@colors"
import { InfoCircleOutlined } from "@ant-design/icons"

export const Wrap = styled.div`
display: flex;
padding: 16px;
flex-direction: column;
align-items: flex-start;
gap: 16px;
align-self: stretch;
`

export const AddOnChecklistWrap = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 16px;
align-self: stretch;
`

export const AddOnsWrap = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 16px;
align-self: stretch;
padding: 12px 20px;
border-radius: 8px;
border: 1px solid var(--Neutral-200, #E5E7EB);
p {
color: var(--Neutral-900, #111827);
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
margin: 0px;
}
`

export const AddOnListWrap = styled.div`
display: flex;
padding: 12px 20px;
align-items: center;
gap: 32px;
align-self: stretch;
border-radius: 12px;
border: 1px solid var(--Neutral-200, #E5E7EB);
background: var(--Generic-White, #FFF);
`
export const AddOnHeading = styled.div`
color: ${props => props.color ? props.color : "#111827"}
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 30px */
letter-spacing: 0.5px;
`
export const AddOnPrice = styled.div`
color: ${props => props.color ? props.color : "#111827"}
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px;
margin-left: auto;
`

export const CommonText = styled.div`
color: ${props => props.color ? props.color : "#6B7280"}
align-self: stretch;
font-family: Inter;
font-size: ${props => props.fontSize ? props.fontSize : "14px"}
font-style: normal;
font-weight: ${props => props.weight ? props.weight : 400}
${props => props.marginBottom && `
  margin-top: 25px;
`}
${props => props.lineHeigth && `
    line-height: 24px; 
`}
${props => props.marginLeft && `
   margin-left: auto;
`}
${props => props.marginTop && `
  margin-top: auto;
`}
`
export const CurrentPlanDiv = styled.div`
display: flex;
padding: 12px 20px;
align-items: flex-start;
flex-direction: column;
align-self: stretch;
border-radius: 8px;
border: 1px solid var(--Neutral-200, #E5E7EB);
background: #FFF;
`
export const ActivePlanDiv = styled.div`
display: flex;
padding: 16px 0px;
align-items: center;
align-self: stretch;
border-bottom: 1px solid var(--Global-Mapping-Gray-100, #E0E0EB);
`
export const ButtonWrap = styled.button`
display: flex;
padding: 8px 24px;
justify-content: center;
align-items: center;
gap: 12px;
border-radius: 8px;
background: #F4F4F4;
color: var(--Neutral-700, #374151);
text-align: center;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
border: none;
outline: none;
line-height: 16px; /* 114.286% */
${props => props.width && `
  width: 50%;
`}
`
export const ActiveButton = styled.button`
display: flex;
padding: 8px 24px;
justify-content: center;
align-items: center;
border-radius: 8px;
border: 1px solid var(--Global-Mapping-Primary, #5A5FF2);
background: var(--Generic-White, #FFF);
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04), 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
color: var(--Primary-600, #4F46E5);
text-align: center;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 16px; /* 114.286% */
${props => props.width && `
  width: 50%;
`}
`
export const RadioDiv = styled.div`
display: flex;
padding: 4px;
align-items: center;
gap: 16px;
border-radius: 6px;
background: #F4F4F4;
`

export const ActiveLabel = styled.div`
display: flex;
padding: 6px 12px;
margin-left: auto;
justify-content: center;
align-items: center;
border-radius: 24px;
background: var(--Color-Green-Green-50, #E7F5F1);
color: var(--Color-Green-Green-500, #0C9F6E);
text-align: center;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 16px;
`

export const TeamWrap = styled.div`
display: flex;
padding: 16px 0px;
align-items: flex-start;
gap: 12px;
align-self: stretch;
${props => props.space && `
  align-items: center;
  justify-content: space-between;
  `}
`
export const HorizontalBar = styled.div`
  width: 100%;
  height: 1px;
  background-color: #EFEFF4;
  ${props => props.marginTop && `
    margin-top: 16px;
 `}
`

export const addPlanButton = styled.div`
display: flex;
padding: 14px 20px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 8px;
border: 1px solid var(--Primary-100, #E0E7FF);
background: var(--Primary-50, #EEF2FF);

box-shadow: 0px 2px 6px 0px rgba(16, 24, 40, 0.06);
`

export const BillingsLabelWrap = styled.div`
display: flex;
padding: 12px 20px;
align-items: flex-start;
gap: 12px;
align-self: stretch;
margin-top: 16px;
border-radius: 8px;
background: var(--Color-Primary-Primary-50, #EFEFFE);
`
export const InfoDiv = styled(InfoCircleOutlined)`
  position: relative;
  margin-top: 6px;
  width: 20px;
  color: #5A5FF2;
`

export const BillingDiv = styled.div`
display: flex;
gap: 12px;
`
export const CardDiv = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 7px;
align-self: stretch;
margin-top: 16px;
`

export const CardNumberDiv = styled.div`
display: flex;
height: 40px;
width: 100%;
padding: 8px 12px;
align-items: center;
justify-content: space-between;
//gap: 12px;
align-self: stretch;
border-radius: 8px;
border: 1px solid var(--Neutral-200, #E5E7EB);
background: var(--Generic-White, #FFF);
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04), 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
color: var(--Neutral-400, #9CA3AF);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 16px; /* 114.286% */
`
export const CardLogo = styled.img`
  width: 30px; /* Adjust size as needed */
  height: auto;
`

export const BillingHistoryList = styled.div`
display: flex;
// padding: 16px 0px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
align-self: stretch;
`
export const BillingHistory = styled.div`
display: flex;
padding: 12px 16px;
align-items: center;
align-self: stretch;
border-bottom: 1px solid var(--Neutral-200, #E5E7EB);
background: var(--Neutral-50, #F9FAFB);
`

export const BillingEdit = styled.button`
display: flex;
cursor: pointer;
${props => props.marginLeft && `
  margin-left: auto;
`}
padding: 0px;
justify-content: center;
align-items: center;
color: var(--primary-500-main, #6366F1);
text-align: center;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 14px; 
text-decoration-line: underline;
text-decoration-style: solid;
text-decoration-skip-ink: none;
text-decoration-thickness: auto;
text-underline-offset: auto;
text-underline-position: from-font;
border: none; /* Remove border */
background: none; /* Remove background */
outline: none; /* Remove focus outline (if undesired) */
`

export const AddNewCard = styled.button`
display: flex;
  padding: 14px 20px;
  justify-content: center;
  margin-top: 20px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Primary-100, #E0E7FF);
  background: var(--Primary-50, #EEF2FF);
  box-shadow: 0px 2px 6px 0px rgba(16, 24, 40, 0.06);
  color: var(--Global-Mapping-Primary, #5A5FF2); /* This sets the text color */
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
`
export const EditDetails = styled.button`
display: flex;
padding: 0px;
justify-content: center;
align-items: center;
color: var(--primary-500-main, #6366F1);
text-align: center;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 14px; 
text-decoration-style: solid;
text-decoration-skip-ink: none;
text-decoration-thickness: auto;
text-underline-offset: auto;
text-underline-position: from-font;
border: none;
background: none; 
outline: none;
margin-right: 40px;
`