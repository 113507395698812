import React, { useState } from "react"
import Modal from "dumbComponents/common/UI/Modal"
import subscriptionContainer from "container/BrokerDashboard/subscriptionContainer"
import EditAgentContainer from "container/BrokerDashboard/editAgentContainer"
import SubContainer from "container/BrokerDashboard/subscriptionContainer"
import SidebarContainer from "container/BrokerDashboard/sidebarContainer"
import BrokerContainer from "container/BrokerDashboard/subscriptionContainer"
import SubDeetsContainer from "container/Agent"
import moment from "moment"
import {
  message,
} from "antd"
import {
  InviteWithoutSubscriptionForm,
} from "./Forms"
import {
  Title,
} from "./commonStyles"
import { showConfirm } from "../../../ICA/components/ConfirmationModalUtil"

const renderForm = (
  toClose,
  setSending,
  isSending,
  inviteWithoutSubscriptionPayload,
  inviteWithoutSubscription,
  agentDetailsResponse,
  agentBrokerageInfoResponse,
) => {
  const [isDateSelected, setDateSelected] = useState(null)
  const { data: agentData, isFetching: fetchingAgentDetails } = agentDetailsResponse || {}
  const { data: brokerageData, isFetching: fetchingBrokerageDetails } = agentBrokerageInfoResponse || {}
  const {
    team_name,
    brokerageTeamId,
  } = agentData || {}
  const {
    brokerage_team_id,
  } = brokerageData || {}
  const onFinishHandle = async (values) => {
    try {
      const {
        credit_card_pop_up_blocker_time: creditCardBlockerPopupDate,
      } = values || {}

      const {
        payload: finalPayload,
        agentId,
        fetchIca,
        history,
      } = inviteWithoutSubscriptionPayload || {}

      const ccDate = creditCardBlockerPopupDate ? (moment(creditCardBlockerPopupDate).unix()) : null

      if (ccDate) {
        finalPayload.credit_card_pop_up_blocker_time = ccDate
      }
      // if (finalPayload?.team_payment_option) {
      //   finalPayload.team_payment_option.team_id = finalPayload.team_id;
      // }
      const postAction = () => {
        inviteWithoutSubscription({
          payload: finalPayload,
          agentId,
          fetchIca,
          history,
        })
      }

      if (ccDate) {
        const warning = `Since a Subscription Start Date is selected, 
        the user would be shown a popup to enter their credit card details. You can change 
        the date from the side panel in the left under Subscription Start Date section.`

        const confirmation = "Subscription Start Date selected, are you sure?"
        showConfirm(confirmation, postAction, warning)
      } else {
        postAction()
      }
    } catch (e) {
      console.log(e)
      message.error("Something went wrong")
    }
  }

  return (
    <InviteWithoutSubscriptionForm
      onFinishHandle={onFinishHandle}
      toClose={toClose}
      isSending={isSending}
      setDateSelected={setDateSelected}
      isDateSelected={isDateSelected}
    />
  )
}

const FormModal = ({
  toClose,
  inviteWithoutSubscriptionPayload,
  inviteWithoutSubscription,
}) => {
  const [isSending, setSending] = useState(false)
  return (
    <Modal
      toClose={isSending ? () => {} : toClose}
      width="948px"
      show
      showCloseButton
      title="X"
      showHorizontalBar={false}
      styledTextClose={{
        position: "absolute",
        top: "16px",
        right: "0",
        marginRight: "10px",
        cursor: "pointer",
        left: "unset",
        fontSize: "14px",
        fontFamily: "ATC",
        fontWeight: 500,
      }}
    >
      <Title>We need some more details</Title>
      <>
        {renderForm(toClose,
          setSending,
          isSending,
          inviteWithoutSubscriptionPayload,
          inviteWithoutSubscription)}
      </>
    </Modal>
  )
}

export default SubDeetsContainer(BrokerContainer(SidebarContainer(SubContainer(EditAgentContainer(subscriptionContainer(FormModal))))))
