import React from "react"
import Modal from "dumbComponents/common/UI/Modal"
import subscriptionContainer from "container/BrokerDashboard/subscriptionContainer"
import editAgentContainer from "container/BrokerDashboard/editAgentContainer"
import {
  TeamLeaderForm,
  SoloAgentForm,
  TeamMemberForm,
} from "../Forms"
import {
  Title,
} from "./commonStyles"

const createRiskOptions = (planBenefits, agentData) => {
  const filtered = planBenefits && planBenefits.filter(items => items.value === "error_and_omission_insurance")

  console.log("check here render", planBenefits, agentData)
  const options = []
  const {
    risk_fee,
  } = agentData || {}

  const currentRiskPlan = risk_fee.value

  if (filtered && filtered.length > 0) {
    filtered.forEach((item) => {
      item.plan_details.forEach((plan) => {
        const option = {
          ...plan,
          isSelected: false,
        }
        options.push(option)
      })
    })
  }

  options.forEach((item) => {
    if (item.unit_price === parseInt(currentRiskPlan, 10)) {
      // eslint-disable-next-line no-param-reassign
      item.isSelected = true
    }

    // eslint-disable-next-line no-param-reassign
    item.label = `$${item.unit_price}`
    // eslint-disable-next-line no-param-reassign
    item.value = item.plan_id
  })

  /**USE plan_id for risk */

  console.log("check here options", options)
  return options
}

const createSplitOptions = (brokerageMetaInfo, agentData) => {
  const {
    split,
  } = brokerageMetaInfo || {}

  const {
    split: currentSplit,
  } = agentData || {}

  const currentSelectedSplit = currentSplit && currentSplit.value || ""

  const options = [...split]

  options.forEach((item) => {
    if (currentSelectedSplit === item.value) {
      // eslint-disable-next-line no-param-reassign
      item.isSelected = true
    } else {
      // eslint-disable-next-line no-param-reassign
      item.isSelected = false
    }

    // eslint-disable-next-line no-param-reassign
    item.label = `${item.value}`
    // eslint-disable-next-line no-param-reassign
    item.value = item.value
  })

  return options
}

const createCommissionOption = (brokerageMetaInfo, agentData) => {
  const {
    commission_cap,
  } = brokerageMetaInfo || {}

  const {
    commission_split_cap,
  } = agentData || {}

  const currentCommissionSelected = commission_split_cap.value || 0
  const parsedValue = currentCommissionSelected ? currentCommissionSelected.split(",").join("") : "0"

  const options = [...commission_cap]

  options.forEach((item) => {
    if (parsedValue === item.value) {
      // eslint-disable-next-line no-param-reassign
      item.isSelected = true
    } else {
      // eslint-disable-next-line no-param-reassign
      item.isSelected = false
    }

    // eslint-disable-next-line no-param-reassign
    item.label = `$${item.value}`
    // eslint-disable-next-line no-param-reassign
    item.value = item.value
  })

  return options
}

const createSupportFeeOptions = (selectedPlanList, agentData) => {
  const {
    support_fee,
  } = agentData || {}

  const options = [...selectedPlanList]
  const currentSelectedSupportPlan = support_fee && support_fee.value || ""

  options.forEach((item) => {
    if (parseInt(currentSelectedSupportPlan, 10) === item.unit_price) {
      // eslint-disable-next-line no-param-reassign
      item.isSelected = true
    } else {
      // eslint-disable-next-line no-param-reassign
      item.isSelected = false
    }

    // const splitted = item.name && item.name.split("-") || ""
    // const splitAntGetAmount = splitted && splitted[splitted.length - 1].trim()

    // eslint-disable-next-line no-param-reassign
    item.label = item.name
    // eslint-disable-next-line no-param-reassign
    item.value = item.plan_id
  })

  /**
   * use planId as key
   */

  return options
}

const AddOnMapping = (addOnObject) => {

  const mapped = {}

  addOnObject.forEach((item) => {
    mapped[item.value] = item.id
  })

  return mapped
}

const getModalType = (type,
  brokerageMetaInfo,
  agentData,
  selectedAddons,
  planBenefits,
  selectedPlanList,
  inviteWithoutSubscription,
  currentAgentIDViewed,
  addOn,
  submittingForm,
  toClose,
  recruiterData,
  urlBasedTeamId,
  currentTeamName,
  currentTypeICAFlow,
  userInfo) => {
  const RiskOptions = createRiskOptions(planBenefits, agentData)
  const SplitOptions = createSplitOptions(brokerageMetaInfo, agentData)
  const CommissionOptions = createCommissionOption(brokerageMetaInfo, agentData)
  const SupportFeeOptions = createSupportFeeOptions(selectedPlanList, agentData)

  const {
    asm_assigned,
    team_name,
    role,
    production,
  } = agentData || {}

  const onFinishHandle = (values) => {

    const {
      subscription_fee,
      self_generated_lead_split,
      team_lead_generated_split,
      team_name,
      state,
      split,
      role,
      risk_fee,
      commission_cap,
    } = values || {}

    const plansMap = [{
      planId: subscription_fee,
      couponCode: "",
    }]

    const addOnMap = AddOnMapping(RiskOptions)[risk_fee]

    const benefitIds = addOn && addOn.length > 0 && addOn.map(item => item.id) || []

    const addOnPlanIds = {
      plan_id: risk_fee,
      id: addOnMap,
    }

    const payload = {
      plansMap,
      benefitIds,
      add_on_plan_ids: [addOnPlanIds] || [],
      production: production.value,
      split,
      commission_cap: commission_cap ? commission_cap.split(",").join("") : 0,
      asm_assigned: asm_assigned.value || (recruiterData && recruiterData.asm_assigned) || null,
      generate_ica_for_state: state,
      recruiter: recruiterData && recruiterData.recruiter || null,
    }

    if (type === "Team Member") {
      payload.self_generated_lead_split = self_generated_lead_split
      payload.team_lead_generated_split = team_lead_generated_split
    }

    if (urlBasedTeamId) {
      payload.team_id = parseInt(urlBasedTeamId, 10)
    }

    if (userInfo && userInfo.brokerage_team_id) {
      payload.team_id = parseInt(userInfo.brokerage_team_id, 10)
    }

    inviteWithoutSubscription({
      payload,
      agentId: currentAgentIDViewed,
      fetchIca: currentTypeICAFlow !== "upload_ica",
      isUploadingICA: currentTypeICAFlow === "upload_ica",
    })
  }

  switch (type) {
    case "Individual Agent":
      return (
        <SoloAgentForm
          riskOptions={RiskOptions}
          splitOptions={SplitOptions}
          commissionOptions={CommissionOptions}
          supportFeeOptions={SupportFeeOptions}
          onFinishHandle={onFinishHandle}
          role={role.value}
          submitting={submittingForm}
          toClose={toClose}
        />
      )
    case "Team Member":
      return (
        <TeamMemberForm
          riskOptions={RiskOptions}
          splitOptions={SplitOptions}
          commissionOptions={CommissionOptions}
          supportFeeOptions={SupportFeeOptions}
          onFinishHandle={onFinishHandle}
          teamName={currentTeamName || team_name && team_name.value}
          role={role.value}
          submitting={submittingForm}
          toClose={toClose}
        />
      )
    case "Co Team Lead":
    case "Team Lead":
      return (
        <TeamLeaderForm
          riskOptions={RiskOptions}
          splitOptions={SplitOptions}
          commissionOptions={CommissionOptions}
          supportFeeOptions={SupportFeeOptions}
          onFinishHandle={onFinishHandle}
          teamName={currentTeamName || team_name && team_name.value}
          role={role.value}
          submitting={submittingForm}
          toClose={toClose}
        />
      )
    default:
      return (
        <SoloAgentForm
          riskOptions={RiskOptions}
          splitOptions={SplitOptions}
          commissionOptions={CommissionOptions}
          supportFeeOptions={SupportFeeOptions}
          onFinishHandle={onFinishHandle}
          toClose={toClose}
        />
      )
  }
}

const FormModal = ({
  toClose,
  type,
  selectedAddons,
  getBrokerageInfoMetabaseResponse,
  fetchAgentICADetailsResponse,
  planBenefitsResponse,
  selectedPlanList,
  inviteWithoutSubscription,
  currentAgentIDViewed,
  addonsResponse,
  inviteWithoutSubscriptionResponse,
  fetchAgentAsmRecruiterInfoResponse,
  urlBasedTeamId = null,
  rarTeamListResponse,
  currentTypeICAFlow,
  agentBrokerageInfoResponse,
}) => {
  const {
    data: brokerageMetaInfo,
    isFetching: fetchingBrokerageMetaInfo,
  } = getBrokerageInfoMetabaseResponse || {}

  const {
    data: brokerageInfo,
  } = agentBrokerageInfoResponse || {}

  const {
    data: agentData,
    isFetching: fetchingAgentDetailsInfo,
  } = fetchAgentICADetailsResponse || {}

  const {
    data: planBenefits,
    isFetching: fetchingPlanBenefits,
  } = planBenefitsResponse || {}

  const {
    data: addOn,
  } = addonsResponse || {}

  const {
    data: recruiterData,
  } = fetchAgentAsmRecruiterInfoResponse || {}

  const {
    // data: submitData,
    isFetching: submitting,
  } = inviteWithoutSubscriptionResponse || {}

  const {
    data: teamsData,
  } = rarTeamListResponse || {}

  console.log("brokerageInfo", brokerageInfo)

  let currentTeamName = ""
  if (urlBasedTeamId) {
    const team = teamsData && teamsData.filter(item => item.id === parseInt(urlBasedTeamId, 10)) || []
    // eslint-disable-next-line prefer-destructuring
    currentTeamName = team[0].name
  }

  return (
    <Modal
      toClose={submitting ? () => {} : toClose}
      width="948px"
      show
      showCloseButton
      title="X"
      showHorizontalBar={false}
      styledTextClose={{
        position: "absolute",
        top: "16px",
        right: "0",
        marginRight: "10px",
        cursor: "pointer",
        left: "unset",
        fontSize: "14px",
        fontFamily: "ATC",
        fontWeight: 500,
      }}
    >
      <Title>We need some more details</Title>
      {!fetchingBrokerageMetaInfo
      && !fetchingAgentDetailsInfo
        && !fetchingPlanBenefits
      && (
        <>
          {getModalType(type,
            brokerageMetaInfo,
            agentData,
            selectedAddons,
            planBenefits,
            selectedPlanList,
            inviteWithoutSubscription,
            currentAgentIDViewed,
            addOn,
            submitting,
            toClose,
            recruiterData,
            urlBasedTeamId,
            currentTeamName,
            currentTypeICAFlow,
            brokerageInfo)}
        </>
      )}
    </Modal>
  )
}

export default editAgentContainer(subscriptionContainer(FormModal))
