/* eslint-disable global-require */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react"
import root from "window-or-global"
import moment from "moment"
import styled from "styled-components"
import AgentContainer from "container/BrokerDashboard/editAgentContainer"
import BrokerContainer from "container/BrokerDashboard/subscriptionContainer"
import BrokerDashboardContainer from "container/BrokerDashboard/sidebarContainer"
import { get, isEmpty } from "lodash"
import { Radio, Modal } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import {
  Production,
  Split,
  CommissionCap,
  MonthlySupportFee,
  RiskManagement,
  AddOns,
  Summary,
  TeamModal,
  CouponCode,
} from "./components"
import TeamSubscription from "./components/TeamSubscription/index"
import PaymentMethod from "./components/PaymentMethod/index"
import { withRouter } from "react-router-dom"
import SubscriptionContainer from "container/BrokerDashboard/subscriptionContainer"
import sidebarContainer from "container/BrokerDashboard/sidebarContainer"
import Invoices from "./components/Invoices/index"
import { ShimmerBox } from "shared/styles/animation";
import {ShimmerWrap} from "dumbComponents/BrokerDashboard/AgentDashboard/SubscriptionV2/common.js"

const Wrap = styled.div``

const Container = styled.div`
  display: flex;
`

const TitleWrap = styled.div``

const DashboardBusy = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  > img {
    max-width: 200px;
  }
  p {
    font-size: 28px;
  }
`

const Title = styled.h1`
  color: #000;
  font-family: 'Roboto';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  padding: 16px;
`

const NewCard = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 98%;
  background: var(--Success-100, #DCFCE7);
  margin: 10px;
  padding: 16px;
  border-radius: 8px;
  color: var(--Success-800, #166534);
`

const PlansContainer = styled.div `
  width: 60%;
`;

const SummaryContainer = styled.div `
  width: 40%;
`

const CancelSubButton = styled.button `
  display: flex;
  padding: 10px 16px;
  border-radius: 8px;
  border: 1px solid var(--Destructive-200, #FECACA);
  background: var(--Generic-White, #FFF);
  box-shadow: 0px 2px 6px 0px rgba(16, 24, 40, 0.06);
  color: var(--destructive-500-main, #EF4444);
  text-align: center;
  cursor: pointer;
  font-family: Inter;
  margin-left: 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
`

const SubscriptionV2 = ({
  selectedPlan,
  getAgentBrokerageMetadataInfo,
  planBenefitsResponse,
  fetchStripePlans,
  getPlanbenefits,
  currentPlan,
  getSubsribedAddons,
  updateSubscriptionV2Response,
  checkoutBrokerPlanV2Response,
  cancelSubscriptionResponse,
  inviteWithoutSubscriptionResponse,
  toggleTeamModal,
  toggleTeamCreateModal,
  couponCodeResponse,
  cancelSubscription,
  agentDetailsResponse,
  selectedAgentPlans,
  fetchInvoiceDataResponse,
  fetchSubStatusData,
  fetchSubStatusResponse,
  fetchEnoStatusData,
  fetchEnoStatusResponse,
  addonsResponse,
  addOnPlanIds,
  ...props
}) => {
  const isSubmitting = get(checkoutBrokerPlanV2Response, "isFetching")
  const isUpdating = get(updateSubscriptionV2Response, "isFetching")
  const isCancelling = get(cancelSubscriptionResponse, "isFetching")
  const { subscriptionDetails, currentPlan: activePlan } = currentPlan || {}
  const {
    data: agentDetails,
    isFetching: fetchingAgentDetails,
  } = agentDetailsResponse || {}
  const {
    data: couponData,
  } = couponCodeResponse || {}
  const {
    isFetching: subscribingWithoutInvite,
  } = inviteWithoutSubscriptionResponse || {}
  const isBusy = isSubmitting || isUpdating || isCancelling || subscribingWithoutInvite
  const currentMonthlySupportFee = get(currentPlan, "currentPlan")
  const data = get(planBenefitsResponse, "data")
  const agentId = get(props, "match.params.agentId", "");
  const {
    isFetching,
    data: invoiceDataResp,
  } = fetchInvoiceDataResponse || {}

  const {
    isFetching: isFetchingSubsStatus,
    data: subsStatusData,
  } = fetchSubStatusResponse || {}

  const {
    isFetching: isFetchingEnoStatus,
    data: enoStatusData,
  } = fetchEnoStatusResponse || {}

  const {
    response: invoiceData,
  } = invoiceDataResp || {}

  useEffect(() => {
    getAgentBrokerageMetadataInfo()
    fetchStripePlans(agentId)
    fetchSubStatusData(agentId)
    fetchEnoStatusData(agentId);
    getSubsribedAddons({
      agentId,
    })
  }, [])

  console.log("status res", fetchEnoStatusResponse)

  useEffect(() => {
    getPlanbenefits({
      planId: currentMonthlySupportFee?.id,
      agentId,
    })
  }, [currentMonthlySupportFee])

  const [durationValue, setDurationValue] = useState("monthly");

  const handlePlanDurationChange = (e) => {
    setDurationValue(e.target.value);
  };

  useEffect(() => {
    if (selectedPlan?.plan?.billing_cycle) {
      setDurationValue(selectedPlan.plan.billing_cycle.toLowerCase());
    }
  }, [selectedPlan]);

  const handleCancelSubClick = () => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      title: "Cancel Subscription",
      content: "Are you sure you want to cancel your subscription?",
      okText: "Yes",
      cancelText: "Not Now",
      onOk() {
        cancelSubscription({
          agentId,
          callback: () => root.location.reload(),
        })
      },
    })
  }

  const shouldShowTeamSubComponent = () => {
    return selectedAgentPlans.length > 0 && selectedAgentPlans.some(item => item.key === "production" && item.name === "Team Leader");
  };

  const getSubStatus = () => {
    const subStatus = subsStatusData?.response?.status
    if(["incomplete", "incomplete_expired", "overdue", "past_due", "paused",  "canceled", "unpaid"].includes(subStatus)) {
      return "Payment failed";
    } else {
      return "Paid"
    }
  }

  const getEnoStatus = () => {
    const enoStatus = enoStatusData?.response?.status
    if(["incomplete", "incomplete_expired", "overdue", "past_due", "paused", "unpaid"].includes(enoStatus)) {
      return "Payment failed";
    } else {
      return "Paid"
    }
  }

  useEffect(() => {
    if(enoStatusData?.response?.status === "incomplete_expired") {
      getPlanbenefits({
        planId: subsStatusData?.response?.items?.data[0]?.plan?.id,
        agentId,
      })
    }
  }, [enoStatusData, subsStatusData])

  const currentAddOns = get(addonsResponse, "data")
  const ENOData = !isEmpty(currentAddOns) && currentAddOns?.filter(filteredData => filteredData.name === "E&O Insurance")

  const getInvoiceStatus = (invoice) => {
  
    if (!invoice || !invoice.status) return "-";
    
    switch (invoice.status.toLowerCase()) {
      case "open":
        if (invoice.next_payment_attempt &&  invoice.status_transitions?.finalized_at) {
          return "Retrying"
        } else if (invoice.status_transitions?.finalized_at && invoice.billing_reason == "manual") {
          return "Overdue"
        } else if(invoice.status_transitions?.finalized_at) {
          return "Failed"
        } else {
          return "Open"
        }
      default:
        return invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1).toLowerCase();
    }
  };
  // const status= invoiceData && invoiceData.length > 0 ? getInvoiceStatus(invoiceData[0]) : "";
  // const status= enoStatusData && subsStatusData ? {
  //   getSubStatus();
  //   getEnoStatus();
  // } : "";
  
  console.log("aman eno check", addOnPlanIds && addOnPlanIds[0]?.plan_id || ENOData && ENOData.length > 0);

  return (
    <>
      <Wrap>
        {isBusy && (
          <DashboardBusy>
            <img src={require("../../../../images/soul/processing.gif")} alt="" />
            <h4>Processing..</h4>
          </DashboardBusy>
        )}
        {isFetchingSubsStatus && isFetchingEnoStatus && (
          <ShimmerWrap>
            <ShimmerBox w="1000px" h="50px" />
          </ShimmerWrap>
        )}
        <div>
            { enoStatusData && subsStatusData && activePlan && (
              <NewCard>
                {
                  getSubStatus() === "Payment failed" || (getEnoStatus() === "Payment failed" && (addOnPlanIds && addOnPlanIds[0]?.plan_id || ENOData && ENOData.length > 0)) ? (
                    <p>Payment failed</p>
                  ) : (
                    <p>Your subscription is paid</p>
                  )
                }
                <div style={{display: "flex", alignItems: "center"}}>
                  {
                    getSubStatus() === "Payment failed" || (getEnoStatus() === "Payment failed" && (addOnPlanIds && addOnPlanIds[0]?.plan_id || ENOData && ENOData.length > 0)) ? (
                      <p>Next-retry: {moment(subscriptionDetails?.current_period_end * 1000).format("DD MMM, YYYY")}</p>
                    ) : (
                      subsStatusData?.response?.status?.includes("incomplete") || enoStatusData?.response?.status?.includes("incomplete") ? (
                        <p style={{margin: "0"}}>Reattempt Duration: 1 day</p>
                      ) : (
                        <p style={{margin: "0"}}>Auto-renewal: {moment(subscriptionDetails?.current_period_end * 1000).format("DD MMM, YYYY")}</p>
                      )
                    )
                  }
                  <CancelSubButton onClick={() => handleCancelSubClick()}>Cancel subscription</CancelSubButton>
                </div>
              </NewCard>
            )}
            { enoStatusData?.response?.status === "incomplete_expired" && subsStatusData?.response?.status === "incomplete_expired" && !activePlan && (
              <NewCard>
                {
                  getSubStatus() === "Payment failed" || (getEnoStatus() === "Payment failed" && (addOnPlanIds && addOnPlanIds[0]?.plan_id || ENOData && ENOData.length > 0)) ? (
                    <p>Payment failed</p>
                  ) : (
                    <p>Your subscription is paid</p>
                  )
                }
                <div style={{display: "flex", alignItems: "center"}}>
                  <p>Next-retry: {moment(subsStatusData?.response?.current_period_end * 1000).format("DD MMM, YYYY")}</p>
                  <CancelSubButton onClick={() => handleCancelSubClick()}>Cancel subscription</CancelSubButton>
                </div>
              </NewCard>
            )}
            <div style={{display: "flex", justifyContent: "space-between", marginRight: "25px", alignItems: "center"}}>
              <TitleWrap>
                <Title>
                  Subscription details
                </Title>
              </TitleWrap>
              <Radio.Group onChange={handlePlanDurationChange} value={durationValue}>
                <Radio.Button value="monthly">Monthly</Radio.Button>
                <Radio.Button value="yearly">Yearly</Radio.Button>
              </Radio.Group>
            </div>
          </div>
        <Container>
          <PlansContainer>
            <Production />
            <Split />
            <CommissionCap />
            <MonthlySupportFee durationValue={durationValue}/>
            {agentDetails?.isBrokerageCoTeamLead === false && (agentDetails?.isBrokerageTeamLead || shouldShowTeamSubComponent()) ? (
              <TeamSubscription/>
            ) : (<></>)}
            {(!isEmpty(data) || enoStatusData?.response?.status === "incomplete_expired") && (
              <>
                {((selectedPlan?.plan?.product_nickname != "rn" && agentDetailsResponse?.data?.isSubscribedToNetworkPlan === false) || (enoStatusData?.response?.status === "incomplete_expired")) && <RiskManagement durationValue={durationValue}/>}
                <AddOns />
              </>
            )}
            <PaymentMethod agentId={agentId}/>
            {/* <CouponCode /> */}
          </PlansContainer>
          <SummaryContainer>
            <Summary
              agentId={agentId}
              couponData={couponData}
              durationValue={durationValue}
            />
          </SummaryContainer>
        </Container>
        <Invoices agentId={agentId}/>
      </Wrap>
    </>
  )
}

export default withRouter(sidebarContainer(SubscriptionContainer(BrokerContainer(AgentContainer(SubscriptionV2)))))