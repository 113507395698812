import React, { useState, useEffect } from "react"
import styled from "styled-components"
import SidebarContainer from "container/BrokerDashboard/sidebarContainer"
import EditAgentContainer from "container/BrokerDashboard/editAgentContainer"
import SubContainer from "container/BrokerDashboard/subscriptionContainer"
import { ShimmerBox } from "shared/styles/animation"
import { ShimmerWrap } from "../../common";

const CommonText = styled.div`
color: ${props => props.color ? props.color : "#6B7280"}
align-self: stretch;
font-family: Inter;
font-size: ${props => props.fontSize ? props.fontSize : "14px"}
font-style: normal;
font-weight: ${props => props.weight ? props.weight : 400}
${props => props.marginBottom && `
  margin-top: 25px;
`}
${props => props.lineHeigth && `
    line-height: 24px; 
`}
${props => props.marginLeft && `
   margin-left: auto;
`}
${props => props.marginTop && `
  margin-top: auto;
`}
`
const CurrentPlanDiv = styled.div`
display: flex;
padding: 12px 20px;
align-items: flex-start;
flex-direction: column;
align-self: stretch;
border-radius: 8px;
border: 1px solid var(--Neutral-200, #E5E7EB);
background: #FFF;
`
const BillingHistoryList = styled.div`
display: flex;
// padding: 16px 0px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
align-self: stretch;
`
const BillingHistory = styled.div`
display: flex;
padding: 12px 16px;
align-items: center;
align-self: stretch;
border-bottom: 1px solid var(--Neutral-200, #E5E7EB);
background: var(--Neutral-50, #F9FAFB);
`

const Invoices = ({
  fetchInvoiceData,
  fetchInvoiceDataResponse,
  agentId,
  addonsResponse,
}) => {
  const formatDate = (epoch) => {
    const date = new Date(epoch * 1000) // Convert epoch to milliseconds
    return date.toLocaleDateString("en-US", {
      month: "short", // Abbreviated month
      day: "numeric", // Day of the month
      year: "numeric", // Full year
    })
  }

  useEffect(() => {
    fetchInvoiceData(agentId);
  }, []);

  const {
    isFetching,
    data,
  } = fetchInvoiceDataResponse || {}

  const {
    response: invoiceData,
  } = data || {}
  
  const getInvoiceStatus = (invoice) => {
  
    if (!invoice || !invoice.status) return "-";
    
    switch (invoice.status.toLowerCase()) {
      case "open":
        if (invoice.next_payment_attempt &&  invoice.status_transitions?.finalized_at) {
          return "Retrying"
        } else if (invoice.status_transitions?.finalized_at && invoice.billing_reason == "manual") {
          return "Overdue"
        } else if(invoice.status_transitions?.finalized_at) {
          return "Failed"
        } else {
          return "Open"
        }
      default:
        return invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1).toLowerCase();
    }
  };

  return (
    <>
      {(isFetching) ? (
        // <Loader />
        <ShimmerWrap style={{padding: "12px"}}>
          <ShimmerBox w="1100px" h="200px" />
        </ShimmerWrap>
      ) : (
        <CurrentPlanDiv style={{margin: "15px"}}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <CommonText
              color="#111827"
              fontSize="16px"
              weight="600"
              lineHeigth
            >
              Billing history
            </CommonText>
            <CommonText
              color="#6B7280"
              fontSize="14px"
              weight="400"
              lineHeigth
            >
              View and download past invoices
            </CommonText>
          </div>
          <div style={{ maxHeight: "210px", overflowY: "auto", width: "100%" }}>
            {
              invoiceData && invoiceData.length > 0 && invoiceData
              .filter(invoice => invoice.status !== "void" && invoice.status !== "uncollectible").map(
                invoice => (
                  <BillingHistoryList>
                    <BillingHistory>
                      {/* <img src={`${CLOUDFRONT}/brokeragePage/page.svg`} width="28px" style={{ marginRight: "12px" }} /> */}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <CommonText
                          color="#111827"
                          fontSize="14px"
                          weight="500"
                        >
                          {formatDate(invoice.created)}
                        </CommonText>
                        <CommonText
                          color="#4B5563"
                          fontSize="12px"
                          weight="400"
                        >
                          {invoice.lines.data[0].description}
                        </CommonText>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          marginLeft: "auto",
                        }}
                      >
                        <CommonText
                          color="#111827"
                          fontSize="14px"
                          weight="500"
                        >
                          $
                          {/* {invoice.amount_paid / 100} */}
                          {getInvoiceStatus(invoice) === "Paid" 
                            ? invoice.amount_paid / 100 
                            : invoice.amount_due / 100}
                        </CommonText>
                        <CommonText
                          color="#0C9F6E"
                          fontSize="14px"
                          weight="600"
                        >
                          {/* {invoice.status ? (invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1).toLowerCase()) : "-"} */}
                          {getInvoiceStatus(invoice)}
                        </CommonText>
                      </div>
                      <a
                        href={invoice.invoice_pdf}
                        download
                        style={{ marginLeft: "12px", cursor: invoice.invoice_pdf ? "pointer" : "not-allowed" }}
                      >
                        {/* <img
                          src={`${CLOUDFRONT}/brokeragePage/downloadArrow.svg`}
                          width="10px"
                        /> */}
                        <p>Download</p>
                      </a>
                      {/* <img
                        src={`${CLOUDFRONT}/brokeragePage/downloadArrow.svg`}
                        width="10px"
                        style={{ marginLeft: "12px" }}
                      /> */}
                    </BillingHistory>
                  </BillingHistoryList>
                )
              )
            }
          </div>

        </CurrentPlanDiv>

      )}

    </>
  )
}

export default SubContainer(EditAgentContainer(SidebarContainer(Invoices)))
