import React, { useState, useEffect } from "react"
import {
  Modal, Input, Button, Select,
} from "antd"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import {
  ModalWrapper,
  CreditHeadingWrap,
  CreditHeading,
  CreditImage,
  ActiveButton,
  ToggleButtonWrap,
  RadioDiv,
} from "./styles"
import InjectedACHForm from "./InjectedACHForm"
import InjectedCreditCardForm from "./InjectedCreditCardForm"
import SidebarContainer from "container/BrokerDashboard/sidebarContainer"
import EditAgentContainer from "container/BrokerDashboard/editAgentContainer"
import SubContainer from "container/BrokerDashboard/subscriptionContainer"
const STRIPE_PUBLISH_KEY = process.env.REACT_APP_STRIPE_PUBLISH_KEY;

const EditCardModal = ({
  toClose,
  isOpen,
  handleProceed,
  handleClickForCancel,
  agentId,
}) => {
  const stripePromise = loadStripe(STRIPE_PUBLISH_KEY)
  console.log("aman", STRIPE_PUBLISH_KEY);
  const [selectedPaymentMethod, setPaymentMethod] = useState("card")
  const handleSubmit = () => {
    handleProceed()
  }
  const onClickPaymentMethod = (value) => {
    setPaymentMethod(value)
  }

  return (
    <Modal visible={isOpen} onCancel={toClose} footer={null} width={500}>
      <ModalWrapper>
        {/* <CreditHeadingWrap>
          <CreditImage>
            <img src={`${CLOUDFRONT}/brokeragePage/settingsModeule.svg`} width="19px" />
          </CreditImage>
        </CreditHeadingWrap> */}
        <CreditHeadingWrap>
          <CreditHeading>
            Add payment method
          </CreditHeading>
        </CreditHeadingWrap>
        <RadioDiv>
          {selectedPaymentMethod == "card" ? (
            <>
              <ToggleButtonWrap onClick={() => { onClickPaymentMethod("ach") }}>
                {" "}
                <img
                //   src={`${CLOUDFRONT}/brokeragePage/ach.svg`}
                  width="19px"
                />
                {" "}
                ACH transfer
              </ToggleButtonWrap>
              <ActiveButton onClick={() => { onClickPaymentMethod("card") }}>
                {" "}
                <img
                //   src={`${CLOUDFRONT}/brokeragePage/creditCard.svg`}
                  width="19px"
                />
                {" "}
                Credit card
              </ActiveButton>
            </>
          ) : (
            <>
              <ActiveButton onClick={() => { onClickPaymentMethod("ach") }}>
                <img
                //   src={`${CLOUDFRONT}/brokeragePage/activeAch.svg`}
                  width="19px"
                />
                ACH transfer
              </ActiveButton>
              <ToggleButtonWrap onClick={() => { onClickPaymentMethod("card") }}>
                <img
                //   src={`${CLOUDFRONT}/brokeragePage/greyCreditCard.svg`}
                  width="19px"
                />
                Credit card
              </ToggleButtonWrap>
            </>
          )}
        </RadioDiv>
        {/* {isOpen && (
          <Elements key={isOpen} stripe={stripePromise}>
            <InjectedCreditCardForm isOpen={isOpen} handleClick={handleProceed} handleClickForCancel={handleClickForCancel} agentId={agentId}/>
          </Elements>
        )} */}
        {
          selectedPaymentMethod == "card" ? (
            <Elements key={isOpen} stripe={stripePromise}>
              <InjectedCreditCardForm isOpen={isOpen} handleClick={handleProceed} handleClickForCancel={handleClickForCancel} agentId={agentId}/>
            </Elements>
          ) : (
            <Elements stripe={stripePromise}>
              <InjectedACHForm handleClick={handleProceed} handleClickForCancel={handleClickForCancel} agentId={agentId}/>
            </Elements>
          )
        }
      </ModalWrapper>
    </Modal>
  )
}

export default SidebarContainer(EditAgentContainer(SubContainer(EditCardModal)))
