// /* eslint-disable import/no-unresolved */
// import React, { useEffect } from "react"
// import styled from "styled-components"
// import AgentContainer from "container/BrokerDashboard/editAgentContainer"
// import SubDeetsContainer from "container/Agent"
// import { get } from "lodash"
// import { ShimmerBox } from "shared/styles/animation"
// import {
//   Container,
//   PlanClickBar,
//   PlanWrap,
//   PlanPriceWrap,
//   PlanPrice,
//   Title,
//   TitleWrap,
//   ShimmerWrap,
// } from "../../common"

// const Wrap = styled.div``

// const CommissionCap = ({
//   getBrokerageInfoMetabaseResponse,
//   selectAgentPlan,
//   selectedAgentPlans,
//   subscriptions,
// }) => {
//   const isCapAlready = get(subscriptions, "data.additional_info.commission_cap", "")
//   const isFetching = get(getBrokerageInfoMetabaseResponse, "isFetching")
//   const commissionCap = get(getBrokerageInfoMetabaseResponse, "data.commission_cap")

//   useEffect(() => {
//     if (isCapAlready || isCapAlready === 0) {
//       const payload = {
//         key: "commission_cap",
//         commission_cap: isCapAlready.toString(),
//       }
//       selectAgentPlan(payload)
//     }
//   }, [isCapAlready])

//   const handleClick = (value) => {
//     const payload = {
//       key: "commission_cap",
//       commission_cap: value.value,
//     }
//     selectAgentPlan(payload)
//   }
//   return (
//     <Wrap>
//       <Container>
//         <TitleWrap>
//           <Title>
//           Commission Cap
//           </Title>
//         </TitleWrap>
//         {isFetching ? (
//           <ShimmerWrap>
//             <ShimmerBox w="223px" h="102px" />
//             <ShimmerBox w="223px" h="102px" />
//             <ShimmerBox w="223px" h="102px" />
//             <ShimmerBox w="223px" h="102px" />
//             <ShimmerBox w="223px" h="102px" />
//           </ShimmerWrap>
//         ) : (
//           <PlanWrap>
//             {commissionCap && commissionCap.map((obj, index) => ({ index, obj })).sort((a, b) => b.obj.value - a.obj.value).map(({ obj }) => (
//             <>
//               <PlanClickBar
//                 onClick={() => {
//                   handleClick(obj)
//                 }}
//                 key={obj.id}
//                 width={223.67}
//                 isSelected={selectedAgentPlans.some(el => el.commission_cap === obj.value)}
//               >
//                 <PlanPriceWrap
//                   key={obj.id}
//                   isSelected={selectedAgentPlans.some(el => el.commission_cap === obj.value)}
//                 >
//                   <PlanPrice
//                     key={obj.id}
//                     isSelected={selectedAgentPlans.some(el => el.commission_cap === obj.value)}
//                   >
//                     {obj.key}
//                   </PlanPrice>
//                 </PlanPriceWrap>
//               </PlanClickBar>
//             </>
//             ))}
//           </PlanWrap>
//         )}
//       </Container>
//     </Wrap>
//   )
// }

// export default SubDeetsContainer(AgentContainer(CommissionCap))


import React, { useEffect, useState } from "react";
import styled from "styled-components";
import AgentContainer from "container/BrokerDashboard/editAgentContainer";
import SubDeetsContainer from "container/Agent";
import { ShimmerBox } from "shared/styles/animation"
import { get } from "lodash";
import { Select, Spin } from "antd";
import { Container, Title, TitleWrap, ShimmerWrap } from "../../common";

const Wrap = styled.div``;

const CommissionCap = ({
  getBrokerageInfoMetabaseResponse,
  selectAgentPlan,
  selectedAgentPlans,
  subscriptions,
  agentBrokerageInfoResponse,
}) => {
  const isCapAlready = get(subscriptions, "data.additional_info.commission_cap", "");
  const isFetching = get(getBrokerageInfoMetabaseResponse, "isFetching");
  const commissionCap = get(getBrokerageInfoMetabaseResponse, "data.commission_cap", []);
  const isTentativeCap = get(agentBrokerageInfoResponse, "data.cap")

  const [selectedValue, setSelectedValue] = useState(isCapAlready?.toString() || "");

  useEffect(() => {
    if (isCapAlready || isCapAlready === 0) {
      setSelectedValue(isCapAlready.toString());
      selectAgentPlan({ key: "commission_cap", commission_cap: isCapAlready.toString() });
    }
  }, [isCapAlready]);

  const handleChange = (value) => {
    setSelectedValue(value);
    selectAgentPlan({ key: "commission_cap", commission_cap: value });
  };

  const sortedCommissionCap = commissionCap
  .filter((obj) => obj.value !== "0")
  .sort((a, b) => b.value - a.value);


const commissionCapWithZeroFirst = [
  ...commissionCap.filter((obj) => obj.value === "0"),
  ...sortedCommissionCap,
];

  return (
    <Wrap>
      <Container>
        <TitleWrap>
          <Title>Commission Cap</Title>
        </TitleWrap>
        {isFetching ? (
          // <Spin size="large" />
          <ShimmerWrap>
            <ShimmerBox w="1000px" h="50px" />
          </ShimmerWrap>
        ) : (
          <Select
            style={{ width: "100%" }}
            value={selectedValue || isTentativeCap}
            onChange={handleChange}
            placeholder="Select Commission Cap"
          >
            {commissionCapWithZeroFirst.map((obj) => (
                <Select.Option key={obj.id} value={obj.value.toString()}>
                  {obj.key}
                </Select.Option>
              ))}
          </Select>
        )}
      </Container>
    </Wrap>
  );
};

export default SubDeetsContainer(AgentContainer(CommissionCap));
