import React, { useEffect, useState } from "react";
import { Collapse, Radio, Input, Button } from "antd";
import SubDeetsContainer from "container/Agent";
import AgentContainer from "container/BrokerDashboard/editAgentContainer";
import { get } from "lodash";
import { withRouter } from "react-router-dom"
import { InfoCircleOutlined, DollarOutlined } from "@ant-design/icons";
import Container from "container/BrokerDashboard/editAgentContainer"
import { USCurrencyFormat } from "shared/currencyUtils"
import { TeamContainer, Title, TitleWrap } from "../../common";
import SidebarContainer from "container/BrokerDashboard/sidebarContainer"
import EditAgentContainer from "container/BrokerDashboard/editAgentContainer"
import SubContainer from "container/BrokerDashboard/subscriptionContainer"
import BrokerContainer from "container/BrokerDashboard/subscriptionContainer"

const TeamSubscription = ({subscriptions, teamSubPlan, selectedPlan, selectedPlanList, rarTeamListResponse, agentDetailsResponse, agentBrokerageInfoResponse, setPaymentByLead, selectedAgentPlans, setPaymentByLeadAmount, paymentByLeadAmount}) => {
  const [paymentOption, setPaymentOption] = useState("");
  const [showSummary, setShowSummary] = useState(false);
  const [associateAmount, setAssociateAmount] = useState(paymentByLeadAmount);

  const { data: teams = [], isFetching: fetchingTeams } = rarTeamListResponse || {}
  const { data: agentData, isFetching: fetchingAgentDetails } = agentDetailsResponse || {}
  const { data: brokerageData, isFetching: fetchingBrokerageDetails } = agentBrokerageInfoResponse || {}
  const {
    team_name,
    brokerageTeamId,
  } = agentData || {}
  const {
    brokerage_team_id,
  } = brokerageData || {}

  const team = teams && teams.find(team => team.id === brokerageTeamId || team.id === brokerage_team_id);
  const teamSize = team ? team.team_size : "Please select a team";

  const is_paid_by_lead = get(subscriptions, "data.team_pricing_info.is_paid_by_lead", "");
  const paymentAmount = get(subscriptions, "data.team_pricing_info.team_member_pricing.amount", "");

  console.log("checking", is_paid_by_lead)
  
  useEffect(() => {
    if(is_paid_by_lead === 1 || is_paid_by_lead === 0) {
      setPaymentByLead(is_paid_by_lead);
      setPaymentOption(is_paid_by_lead ? "leader" : "member")
    }
    if(paymentAmount) {
      setAssociateAmount(paymentAmount);
    }
    if((is_paid_by_lead === 1 || is_paid_by_lead === 0) && paymentAmount){
      setShowSummary(true);
    }
  }, [subscriptions])

  const handlePlanAdd = () => {
    const payload = {
      "payment": 1234,
    };
    teamSubPlan(payload);
    setShowSummary(true);
    setPaymentByLeadAmount(associateAmount)
  }

  const getMonthlyAmount = (plan) => {
    if (plan && (plan?.plan?.amount === 0 || plan?.plan?.unit_price === 0)) {
      return plan?.plan?.billing_cycle === "YEARLY" ? "$0/yearly" : "$0/monthly"
    }

    return plan?.plan?.billing_cycle === "YEARLY"
      ? `${USCurrencyFormat(plan?.plan?.amount / 100 || plan?.plan?.unit_price)}/yearly`
      : `${USCurrencyFormat(plan?.plan?.amount / 100 || plan?.plan?.unit_price)}/monthly`
  }

  const selectedPlanValue = (() => {
    // Check if any subscription contains a specific plan
    const hasZeroDollarPlan = subscriptions?.data?.subscriptions?.some(subscription =>
      subscription.items.data.some(item =>
        ["broker_test_rar_zero_dollar_qa", "broker_zero_monthly_0"].includes(item.plan.id)
      )
    );
  
    if (hasZeroDollarPlan) return "$0";
  
    // If plan is null, check items.data for a matching plan.id in selectedPlanList
    for (const subscription of subscriptions?.data?.subscriptions || []) {
      if (!subscription.plan) {
        for (const item of subscription.items.data || []) {
          if (selectedPlanList.some(plan => plan.plan_id === item.plan.id)) {
            return item.plan.id; // Return the matching plan ID
          }
        }
      }
    }
  
    return selectedPlan?.planId || undefined;
  })();

  return (
    <TeamContainer padding="5px">   
      <Collapse>
        <Collapse.Panel
          header="Add team subscription details"
          key="1"
        >
          <div
            style={{
              background: "#f5f5f5",
              padding: "10px",
              borderRadius: "5px",
              marginBottom: "15px",
            }}
          >
            <InfoCircleOutlined style={{ marginRight: 5 }} />
            Team name can be selected from side menu
          </div>
        {!showSummary ? (
            <>
              <div>
                <div className="" style={{ display: 'flex' , justifyContent: 'space-between'}}>
                  <h4>Payment Option<span style={{ color: 'red' }}>*</span></h4>
                  {paymentOption && 
                    <span style={{ color: 'red', cursor: 'pointer' }} onClick={() => setPaymentOption("")}>Clear</span>
                  }
                </div>
              
                <Radio.Group
                onChange={(e) => {
                  setPaymentOption(e.target.value)
                  e.target.value == "leader" ? setPaymentByLead(true) : setPaymentByLead(false)
                }}
                value={paymentOption}
                >
                <Radio value="leader">Team leader pays for all associates</Radio>
                <Radio value="member">
                    Each team member pays for their own account
                </Radio>
                </Radio.Group>
            </div>
            <div style={{ marginTop: "15px" }}>
                {paymentOption && (
                <div>
                    <h4>
                    {paymentOption === "leader"
                        ? "Subscription price"
                        : "Subscription price per associate"}
                    </h4>
                    <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "8px 12px",
                        borderRadius: "8px",
                        border: "1px solid #d9d9d9",
                        fontSize: "16px",
                    }}
                    >
                    {/* <div>
                        <DollarOutlined style={{ marginRight: "8px" }} />
                        100{" "}
                    </div>
                    <span style={{ marginLeft: "8px", color: "#8c8c8c" }}>
                        per month
                    </span> */}
                    <Input
                      placeholder="Enter amount"
                      value={associateAmount}
                      prefix={<DollarOutlined />}
                      suffix="per month"
                      defaultValue={50}
                      onChange={(e) => setAssociateAmount(e.target.value)}
                    />
                    </div>
                    <Button type="primary" style={{ marginTop: "10px" }} onClick={handlePlanAdd}>
                    Add to plan
                    </Button>
                </div>
                )}
            </div>
            </>
        ) : (
            <div
          style={{
            border: "1px solid #d9d9d9",
            borderRadius: "8px",
            padding: "15px",
            background: "#ffffff",
          }}
        >
          <h4 style={{ fontWeight: "bold" }}>Subscription Details:</h4>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px" }}>
            {/* <span style={{ color: "#8c8c8c" }}>Team size</span> */}
            {/* <span>{teamSize} members</span> */}
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px" }}>
            <span style={{ color: "#8c8c8c" }}>Payment option</span>
            <span>{paymentOption === "leader" ? "Team leader pays" : "Each team member pays"}</span>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px" }}>
            <span style={{ color: "#8c8c8c" }}>Price per agent</span>
            <span>${associateAmount}/month</span>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px" }}>
            <span style={{ color: "#8c8c8c" }}>Price per partner or team lead</span>
            <span>{getMonthlyAmount(selectedPlan) || selectedPlanValue}</span>
          </div>
          {/* <hr style={{ margin: "10px 0" }} />
          <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "bold" }}>
            <span>Total Amount</span>
             <span>
              {typeof selectedPlan?.plan.amount === "number" && selectedPlan?.plan.amount > 0
                ? `$${100 + selectedPlan.plan.amount / 100}`
                : typeof selectedPlan?.plan.unit_price === "number" && selectedPlan?.plan.unit_price > 0
                ? `$${100 + selectedPlan.plan.unit_price}`
                : `$${100 + 0}`
              }
            </span>
          </div> */}

          <Button
            type="default"
            style={{
              marginTop: "10px",
              background: "#f0f0ff",
              color: "#696cff",
              borderRadius: "5px",
            }}
            onClick={() => {
              // setPaymentOption("")
              setShowSummary(false)
              // setPaymentByLead(null)
            }}
          >
            Switch plan
          </Button>
            </div>
          )}
          

          
        </Collapse.Panel>
      </Collapse>
    </TeamContainer>
  );
};

export default withRouter(AgentContainer(SubDeetsContainer(Container(BrokerContainer(SubContainer(EditAgentContainer(SidebarContainer(TeamSubscription))))))));
