import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils";

import {
  fetchChecklistMetadataAction,
  fetchStatesMetadataAction,
  fetchTypeMetadataAction,
  fetchAllTemplatesAction,
  fetchAllTemplatesForReplicationAction,
  fetchAllChecklistDataAction,
  saveNewItemDocumentAction,
  deleteTemplateAction,
  deleteDocumentAction,
  saveDueDateAction,
  dataSourceMapAction,
  dropdownDataAction,
  addNewChecklistTemplateAction,
  updateOrderingTemplatesAction,
  fetchDocumentCriteriaAction,
  saveAddCriteriaAction,
  fetchBrbcDocumentsAction,
  addBrbcDocAction,
} from "./actions";

const initialState = {
  dropdownDataArray: [],
  ...createDeltaReducer("fetchChecklistMetadataResponse"),
  ...createDeltaReducer("fetchStatesMetadataResponse"),
  ...createDeltaReducer("fetchTypeMetadataResponse"),
  ...createDeltaReducer("fetchAllTemplatesResponse"),
  ...createDeltaReducer("fetchAllChecklistDataResponse"),
  ...createDeltaReducer("saveNewItemDocumentResponse"),
  ...createDeltaReducer("deleteTemplateResponse"),
  ...createDeltaReducer("deleteDocumentResponse"),
  ...createDeltaReducer("saveDueDateResponse"),
  ...createDeltaReducer("addNewChecklistTemplateResponse"),
  ...createDeltaReducer("fetchAllTemplatesForReplicationResponse"),
  ...createDeltaReducer("updateOrderingTemplatesResponse"),
  ...createDeltaReducer("fetchDocumentCriteriaResponse"),
  ...createDeltaReducer("saveAddCriteriaResponse"),
  ...createDeltaReducer("fetchBrbcDocResponse"),
};

const checklistReducer = (state = initialState, action) => {
  switch (action.type) {
    case dropdownDataAction.type: {
      return {
        ...state,
        dropdownDataArray: action.data,
      };
    }

    case fetchChecklistMetadataAction.REQUEST:
    case fetchChecklistMetadataAction.SUCCESS:
    case fetchChecklistMetadataAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          fetchChecklistMetadataAction,
          "fetchChecklistMetadataResponse"
        ),
      };
    }

    case fetchStatesMetadataAction.REQUEST:
    case fetchStatesMetadataAction.SUCCESS:
    case fetchStatesMetadataAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          fetchStatesMetadataAction,
          "fetchStatesMetadataResponse"
        ),
      };
    }

    case fetchTypeMetadataAction.REQUEST:
    case fetchTypeMetadataAction.SUCCESS:
    case fetchTypeMetadataAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          fetchTypeMetadataAction,
          "fetchTypeMetadataResponse"
        ),
      };
    }

    case fetchAllTemplatesAction.REQUEST:
    case fetchAllTemplatesAction.SUCCESS:
    case fetchAllTemplatesAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          fetchAllTemplatesAction,
          "fetchAllTemplatesResponse"
        ),
      };
    }

    case fetchAllTemplatesForReplicationAction.REQUEST:
    case fetchAllTemplatesForReplicationAction.SUCCESS:
    case fetchAllTemplatesForReplicationAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          fetchAllTemplatesForReplicationAction,
          "fetchAllTemplatesForReplicationResponse"
        ),
      };
    }

    case fetchAllChecklistDataAction.REQUEST:
    case fetchAllChecklistDataAction.SUCCESS:
    case fetchAllChecklistDataAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          fetchAllChecklistDataAction,
          "fetchAllChecklistDataResponse"
        ),
      };
    }

    case saveNewItemDocumentAction.REQUEST:
    case saveNewItemDocumentAction.SUCCESS:
    case saveNewItemDocumentAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          saveNewItemDocumentAction,
          "saveNewItemDocumentresponse"
        ),
      };
    }

    case deleteTemplateAction.REQUEST:
    case deleteTemplateAction.SUCCESS:
    case deleteTemplateAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          deleteTemplateAction,
          "deleteTemplateResponse"
        ),
      };
    }

    case deleteDocumentAction.REQUEST:
    case deleteDocumentAction.SUCCESS:
    case deleteDocumentAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          deleteDocumentAction,
          "deleteDocumentResponse"
        ),
      };
    }

    case saveDueDateAction.REQUEST:
    case saveDueDateAction.SUCCESS:
    case saveDueDateAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          saveDueDateAction,
          "saveDueDateResponse"
        ),
      };
    }

        case updateOrderingTemplatesAction.REQUEST:
    case updateOrderingTemplatesAction.SUCCESS:
    case updateOrderingTemplatesAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          updateOrderingTemplatesAction,
          "updateOrderingTemplatesResponse"
        ),
      };
    }
      
    case addNewChecklistTemplateAction.REQUEST:
    case addNewChecklistTemplateAction.SUCCESS:
    case addNewChecklistTemplateAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          addNewChecklistTemplateAction,
          "addNewChecklistTemplateResponse"
        ),
      };
    }

    case fetchDocumentCriteriaAction.REQUEST:
    case fetchDocumentCriteriaAction.SUCCESS:
    case fetchDocumentCriteriaAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          fetchDocumentCriteriaAction,
          "fetchDocumentCriteriaResponse"
        ),
      };
    }

    case saveAddCriteriaAction.REQUEST:
    case saveAddCriteriaAction.SUCCESS:
    case saveAddCriteriaAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          saveAddCriteriaAction,
          "saveAddCriteriaResponse"
        ),
      };
    }

    case fetchBrbcDocumentsAction.REQUEST:
    case fetchBrbcDocumentsAction.SUCCESS:
    case fetchBrbcDocumentsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          fetchBrbcDocumentsAction,
          "fetchBrbcDocResponse"
        ),
      };
    }

    case addBrbcDocAction.REQUEST:
    case addBrbcDocAction.SUCCESS:
    case addBrbcDocAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          addBrbcDocAction,
          "addBrbcDocResponse"
        ),
      };
    }

    default:
      return state;
  }
  
};

export default checklistReducer;
